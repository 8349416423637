import React, { useGlobal } from 'reactn';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import { TextButton } from 'components/common/form/Buttons';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';

import {
  archiveById,
  deleteById,
  fetchById,
  unarchiveById
} from 'services/AdminService';
import PermissionGate from 'components/common/PermissionGate';
import { useTableContext } from './TableProvider';

interface ActionButtonsProps {
  row: any; // TODO: typing from react-table;
  endpoint: string;
  linkTo?: string;
  preDeleteCheck?: (ids: number[]) => Promise<boolean>;
  archivable?: boolean;
  canDelete?: boolean;
  setDialogData?: (data: any) => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  row,
  endpoint,
  linkTo,
  archivable,
  preDeleteCheck,
  canDelete,
  setDialogData
}) => {
  // const { t } = useTranslation('common', { keyPrefix: 'userTable' });
  const [user] = useGlobal('user');
  const { dispatch } = useTableContext();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const editItem = React.useCallback(
    async id => {
      if (id) {
        if (linkTo) {
          navigate(`${linkTo}/${id}`);
        } else {
          try {
            const res = await fetchById(endpoint, id);
            setDialogData?.(res.data);
          } catch (e) {
            console.error(e);
          }
        }
      }
    },
    [endpoint, navigate, linkTo, setDialogData]
  );

  const deleteRow = async (id: number, deleteEndpoint: string) => {
    try {
      if (!preDeleteCheck) {
        await confirm({
          description: `Are you sure you want to ${
            archivable ? 'archive' : 'delete'
          } these ${endpoint}s?`
        });
      } else {
        await preDeleteCheck([id]);
      }

      if (id) {
        try {
          if (archivable) {
            await archiveById(deleteEndpoint, { id });
          } else {
            await deleteById(deleteEndpoint, id);
          }
          enqueueSnackbar(
            `${deleteEndpoint} ${archivable ? 'archived' : 'deleted'}`,
            {
              preventDuplicate: true
            }
          );
        } catch (e) {
          console.error(e);
        }
      }
      dispatch({ type: 'toggleRefresh' });
      return null;
    } catch (e) {
      return null;
    }
  };

  const restoreRow = async (id: number, restoreEndpoint: string) => {
    try {
      const description = `Are you sure you want to restore this ${restoreEndpoint}?`;

      await confirm({
        description
      });
      if (id) {
        try {
          await unarchiveById(restoreEndpoint, id);
          enqueueSnackbar(`${restoreEndpoint} restored`, {
            preventDuplicate: true
          });
        } catch (e) {
          console.error(e);
        }
      }
      dispatch({ type: 'toggleRefresh' });
      return null;
    } catch (e) {
      return null;
    }
  };

  const archived = row.original.deletedAt || row.original.archivedAt;

  return (
    <div
      style={{
        textAlign: 'right',
        whiteSpace: 'nowrap'
      }}
    >
      <PermissionGate
        scopes={['user_update', 'userRole_assign', 'userRole_batchAssign']}
      >
        {!archived && (
          <>
            {/* <TextButton
            startIcon={<SettingsIcon />}
            onClick={() => editItem(row.original.id)}
            color="secondary"
          >
            {t('edit')}
          </TextButton>
          <TextButton
            startIcon={<DeleteIcon />}
            onClick={() => deleteRow(row.original.id, endpoint)}
            color="secondary"
          >
            {t('delete')}
          </TextButton> */}
            {!linkTo && (
              <Tooltip title="Edit">
                <IconButton
                  aria-label="edit"
                  onClick={() => editItem(row.original.id)}
                  size="large"
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            {linkTo && (
              <Link to={`${linkTo}/${row.original.id}`}>
                <Tooltip title="Edit">
                  <IconButton
                    aria-label="edit"
                    onClick={() => editItem(row.original.id)}
                    size="large"
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Link>
            )}
          </>
        )}
      </PermissionGate>
      <br />
      <PermissionGate scopes={['user_softDelete']}>
        {!archived && canDelete && (
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              onClick={() => deleteRow(row.original.id, endpoint)}
              size="large"
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </PermissionGate>
      {user?.isAdmin && archived && (
        <Tooltip title="Restore">
          <IconButton
            aria-label="restore"
            onClick={() => restoreRow(row.original.id, endpoint)}
            size="large"
          >
            <RestoreFromTrashIcon style={{ fill: 'white' }} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default ActionButtons;

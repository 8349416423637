import Api from 'utils/Api';
import settings from 'config';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

const SUPPORT_API_BASE_URL = `${BASE_URL}/support/`;

export const addSupport = (support: any, entity: any) => {
  const formattedSupport = support;
  formattedSupport.supportingId = entity.id;
  formattedSupport.supportedId = support.supportedId;
  formattedSupport.planId = entity.planId;
  return Api.post(SUPPORT_API_BASE_URL, formattedSupport, getAuthHeader());
};
export const deleteSupport = (supportId: number | string) => {
  return Api.delete(`${SUPPORT_API_BASE_URL}${supportId}`, getAuthHeader());
};

export const editSupport = (id: number | string, support: any) => {
  return Api.put(`${SUPPORT_API_BASE_URL}${id}`, support, getAuthHeader());
};

export const fetchSupports = (options: any) => {
  const data: any = getAuthHeader();
  if (options) {
    if (options.sort && options.sort.length) {
      data.sort = options.sort[0].id;
      data.order = options.sort[0].desc ? 'DESC' : 'ASC';
    }
    data.planEntityIds = options.planEntityIds;
  }
  return Api.post(`${SUPPORT_API_BASE_URL}search`, data, getAuthHeader());
};

import settings from 'config';
import Api from '../utils/Api';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;
const COMPOUND_URL = `${BASE_URL}/compound/`;

export interface AutocompleteOptions extends APIQueryOptions {
  typeIds?: number[];
  simple?: boolean;
  parentId?: number;
  limit?: number;
  q?: string;
  where?: any;
}

export const autocomplete = <T>(
  endpoint: string,
  { simple, parentId, ...options }: AutocompleteOptions = {}
): Promise<T[]> => {
  if (parentId) {
    endpoint = `${endpoint}/${parentId}`;
  }
  const data = {
    ...getAuthHeader(),
    params: {
      ...options,
      q: options.q,
      limit: options.limit ?? 500,
      simple: simple ?? true
    }
  };

  return Api.get(`${BASE_URL}/autocomplete/${endpoint}`, data).then(
    res => res.data.results
  );
};

export const userAutocomplete = <T>(
  planId: number,
  options: AutocompleteOptions = {}
): Promise<T[]> => {
  return Api.post(
    `${BASE_URL}/autocomplete/users/${planId}`,
    options,
    getAuthHeader()
  ).then(res => res.data.results);
};

export const compoundAutocomplete = <T>(
  options: AutocompleteOptions = {}
): Promise<T[]> => {
  return Api.post(`${COMPOUND_URL}search`, options, getAuthHeader()).then(
    res => res.data.results
  );
};
export default autocomplete;

import React from 'react';

import { Button, BaseDialog } from 'uninfo-components';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material/';
import MuiAutocomplete, {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderGroupParams
} from '@mui/material/Autocomplete';
import {
  ListSubheader,
  TextField,
  Checkbox,
  CircularProgress
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { keyBy, truncate, isEqual, debounce, uniqueId } from 'lodash';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export const CustomListSubHeader = withStyles({
  root: {
    lineHeight: 1.5,
    fontSize: '1rem',
    backgroundColor: 'var(--color-white)'
  }
})(ListSubheader);

// TODO: could this include a template, so that the caller of
// Autocomplete could include a template?
const buildOptionLabel = (option?: any, versionLabel?: string) => {
  if (option) {
    let optionLabel =
      option.name ??
      (versionLabel &&
        typeof option[versionLabel] !== 'string' &&
        (option[versionLabel]?.name ?? option[versionLabel]?.label)) ??
      option.key;

    if (option.firstname || option.lastname) {
      optionLabel = [option.firstname, option.lastname]
        .filter(a => !!a)
        .join(' ');
    }
    if (option.abbreviation) {
      optionLabel = `${option.abbreviation}: ${optionLabel}`;
    }
    if (option.archivedAt) {
      optionLabel = (
        <span style={{ opacity: 0.5 }}>{optionLabel} (archived)</span>
      );
    }
    return optionLabel ?? '';
  }
  return '';
};

export const Option: React.FC<{
  option: unknown;
  versionLabel?: string;
  selected: boolean;
  hideSelected: boolean;
}> = ({ option, versionLabel, selected, hideSelected, ...props }) => {
  const optionLabel = buildOptionLabel(option, versionLabel);
  const dataCyCheckbox = `${optionLabel}-autcomplete-checkbox`;

  return (
    <li {...props}>
      {!hideSelected && (
        <Checkbox
          data-cy={dataCyCheckbox}
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
      )}
      {optionLabel}
    </li>
  );
};

export const CustomTextField = withStyles(() => ({
  root: {
    textAlign: 'left',
    background: 'white',
    '& .MuiInputBase-root': {
      background: 'transparent'
    },
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none'
    }
  }
}))(TextField);

interface InputFieldProps {
  placeholder?: string;
  name?: string;
  id?: string;
  label: string;
  error: any;
  loading: boolean;
  margin?: 'dense' | 'none' | 'normal';
  inputRef: any;
  size?: 'medium' | 'small';
  required?: boolean;
  variant?: 'filled' | 'standard' | 'outlined';
  helperText?: string;
  InputProps?: any; // FIXME: What type is this?
}

export const InputField: React.FC<InputFieldProps> = ({
  placeholder,
  name,
  id,
  label,
  error,
  loading,
  ...params
}) => (
  <CustomTextField
    type="text"
    variant="outlined"
    fullWidth
    placeholder={placeholder}
    name={name}
    id={id}
    error={error}
    multiline
    label={label}
    {...params}
    InputProps={{
      ...params.InputProps,
      endAdornment: (
        <>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
          {params.InputProps?.endAdornment}
        </>
      )
    }}
  />
);

interface AutocompleteProps {
  id?: string;
  dataCy?: string;
  error?: any;
  fetchOptionsCallback?: (q: string) => Promise<any[]> | any[];
  filterOptions?: ((options: unknown[]) => unknown[]) | undefined;
  onChange: any;
  multiple?: boolean;
  label: string;
  versionLabel?: string;
  requireSearch?: boolean;
  disabled?: boolean;
  CustomOption?: React.FC<{
    option: unknown;
    versionLabel?: string;
    selected: boolean;
    hideSelected: boolean;
  }>;
  helperText?: string;
  value?: any;
  isOptionEqualToValue?: any;
  blurOnSelect?: boolean;
  getOptionDisabled?: any;
  options?: any[];
  optionToAdd?: boolean;
  style?: React.CSSProperties;
  placeholder?: string;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
  clearOnBlur?: boolean;
  clearOnEscape?: boolean;
  clearOnSelect?: boolean;
  limitTags?: number;
  renderTags?: (
    value: unknown[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => React.ReactNode;
  ref?: any;
  variant?: 'filled' | 'standard' | 'outlined';
}

export const AutocompleteMUI = withStyles(() => ({
  popupIndicator: {
    border: '1px solid var(--color-grey)',
    borderRadius: '100%',
    color: 'var(--color-sdg-blue)'
  }
}))(MuiAutocomplete);
/**
 * A re-usable Autocomplete component that doesn't rely on existing
 * parents
 *
 * @param {*} param0
 */
export const Autocomplete: React.FC<AutocompleteProps> = ({
  id,
  dataCy,
  error,
  CustomOption,
  fetchOptionsCallback,
  filterOptions,
  multiple,
  label,
  versionLabel,
  requireSearch,
  helperText,
  value,
  isOptionEqualToValue,
  optionToAdd,
  required,
  variant,
  clearOnSelect,
  ref,
  options: defaultOptions,
  ...props
}) => {
  const [optionsMap, setOptionsMap] = React.useState<{ [key: string]: any }>(
    {}
  );
  const [randomKey, setRandomKey] = React.useState<string>(); // See: https://stackoverflow.com/a/59845474/154392
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const [debouncedInputValue, setDebouncedInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const debounceSetInputValue = React.useCallback(
    debounce(qString => {
      setDebouncedInputValue(qString);
    }, 500),
    []
  );

  React.useEffect(() => {
    debounceSetInputValue(inputValue);
  }, [debounceSetInputValue, inputValue]);

  React.useEffect(() => {
    const newMap = keyBy(options, 'id');
    if (!isEqual(optionsMap, newMap)) {
      setOptionsMap(newMap);
    }
  }, [options, optionsMap]);

  const buildGroupTitle = (option: any) => {
    return String(option.goalId || option.targetId || 'All results');
  };

  const renderGroup = (params: AutocompleteRenderGroupParams) => {
    const groupTitle = optionsMap[+params.group]
      ? truncate(optionsMap[+params.group].name, { length: 80 })
      : ' All results';
    return [
      <CustomListSubHeader key={params.key}>{groupTitle}</CustomListSubHeader>,
      params.children
    ];
  };

  const noOptionsText = requireSearch ? 'Type to search' : 'No options';

  React.useEffect(() => {
    if (open && (!requireSearch || debouncedInputValue.length > 1)) {
      const fetchWrapper = async () => {
        setLoading(true);
        // This way if there's a value already selected, then we'll
        // still show the entire list in the dropdown
        const selected =
          buildOptionLabel(value, versionLabel) === debouncedInputValue;
        const newOptions =
          (await fetchOptionsCallback?.(
            !selected ? debouncedInputValue : ''
          )) ??
          defaultOptions ??
          [];
        if (optionToAdd && debouncedInputValue !== '') {
          newOptions.push({
            // localInputValue is only set for *new* objects
            localInputValue: debouncedInputValue,
            id: 0,
            name: `Add "${debouncedInputValue}"`
          });
        }
        if (!isEqual(newOptions, options)) {
          setOptions(newOptions);
        }
        setLoading(false);
      };

      fetchWrapper();
    }
  }, [
    debouncedInputValue,
    options,
    requireSearch,
    fetchOptionsCallback,
    optionToAdd,
    defaultOptions,
    open,
    value,
    versionLabel
  ]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <AutocompleteMUI
      data-cy={`${dataCy}-autocomplete`}
      key={randomKey}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      id={id}
      open={open}
      noOptionsText={noOptionsText}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setInputValue('');
        if (clearOnSelect) {
          // See: https://stackoverflow.com/a/59845474/154392
          setRandomKey(uniqueId());
        }
        setOpen(false);
      }}
      options={options}
      value={value || ''}
      filterOptions={filterOptions ?? (x => x)}
      isOptionEqualToValue={
        isOptionEqualToValue || ((option: any, v: any) => option?.id === v?.id)
      }
      getOptionLabel={obj => buildOptionLabel(obj, versionLabel)}
      renderInput={params => (
        <InputField
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          inputRef={ref}
          placeholder=""
          variant={variant || 'outlined'}
          loading={loading}
          required={required}
          size="small"
        />
      )}
      renderOption={(autoProps, option, { selected }) =>
        CustomOption ? (
          <CustomOption
            data-cy={`${dataCy}-autocomplete-option`}
            option={option}
            selected={selected}
            hideSelected={!multiple}
            versionLabel={versionLabel}
            {...autoProps}
          />
        ) : (
          <Option
            data-cy={`${dataCy}-autocomplete-option`}
            option={option}
            selected={selected}
            hideSelected={!multiple}
            versionLabel={versionLabel}
            {...autoProps}
          />
        )
      }
      renderGroup={renderGroup}
      groupBy={buildGroupTitle}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      {...props}
    />
  );
};

interface AddNewOptionDialogProps {
  dialogOpen: boolean;
  setDialogOpen: (arg0: boolean) => void;
  title: string;
}

/**
 * Creates a Dialog to add a new option to the autocomplete.
 *
 * TODO: Think about whether this can be rolled into the AutoComplete component
 * instead of being exported separately and being handled by the component that
 * wants to add a new Autocomplete option.
 *
 * @param {*} param0
 */
export const AddNewOptionDialog: React.FC<AddNewOptionDialogProps> = ({
  children,
  dialogOpen,
  setDialogOpen,
  title
}) => {
  return (
    <BaseDialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      title={`Add ${title}`}
      aria-labelledby="form-dialog-title"
      actions={
        <Button onClick={() => setDialogOpen(false)} color="primary">
          Cancel
        </Button>
      }
    >
      {children}
    </BaseDialog>
  );
};

export default Autocomplete;

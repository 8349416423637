import { Chip, Theme, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { TableInstance } from 'react-table';

const useStyles = makeStyles((theme: Theme) => ({
  filtersActiveLabel: {
    color: '#998'
  },
  filterBar: {
    padding: theme.spacing(3),
    alignItems: 'center'
  },
  chipZone: {
    padding: '18px 0 5px 10px',
    width: '100%'
  },
  chipLabel: {
    fontWeight: 500,
    marginRight: 5
  },
  filterChip: {
    marginRight: 4,
    color: '#222'
  }
}));

const getFilterValue = (column: any, filterValue: any[]) => {
  switch (column.filter) {
    case 'between': {
      const min = filterValue[0];
      const max = filterValue[1];
      if (min) {
        return max ? `${min}-${max}` : `>=${min}`;
      }
      return `<=${max}`;
    }
    default:
      return filterValue;
  }
};

const FilterChipBar: React.FC<{
  instance: TableInstance<any>;
}> = ({ instance }) => {
  const classes = useStyles();
  const {
    allColumns,
    setFilter,
    state: { filters }
  } = instance;

  const handleDelete = useCallback(
    id => {
      setFilter(id, undefined);
    },
    [setFilter]
  );

  return Object.keys(filters).length > 0 ? (
    <Grid container className={classes.filterBar}>
      <Grid item xs={1} className={classes.filtersActiveLabel}>
        Active filters:
      </Grid>
      <Grid item xs={10}>
        {filters &&
          allColumns.map((column: any) => {
            const filter = filters.find((f: any) => f.id === column.id);

            const value =
              filter && filter.value.map((v: any) => v.name).join(', ');
            return (
              value && (
                <Chip
                  className={classes.filterChip}
                  key={column.id}
                  label={
                    <>
                      <span className={classes.chipLabel}>
                        {column.render('Header')}:{' '}
                      </span>
                      {getFilterValue(column, value)}
                    </>
                  }
                  onDelete={() => handleDelete(column.id)}
                  variant="outlined"
                />
              )
            );
          })}
      </Grid>
    </Grid>
  ) : null;
};

export default FilterChipBar;

import Checkbox from '@mui/material/Checkbox';
import React, { useEffect } from 'react';

export const IndeterminateCheckbox = React.forwardRef<
  HTMLButtonElement,
  { indeterminate?: any; className?: string }
>(({ indeterminate, ...rest }, ref: React.Ref<HTMLButtonElement>) => {
  const defaultRef = React.useRef<HTMLButtonElement>(null);
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME: where get current?
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox ref={resolvedRef} {...rest} />
    </>
  );
});

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

export default IndeterminateCheckbox;

import React from 'react';
import { useFormContext, Controller, Control } from 'react-hook-form';
import MuiTextField, { StandardTextFieldProps } from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

export interface LocalTextFieldProps extends StandardTextFieldProps {
  label?: string;
  name: string;
  disabled?: boolean;
  id?: string;
  rules?: any;
  defaultValue?: any;
  required?: boolean;
  hasLabel?: boolean;
  control?: Control<Record<string, any>>;
}

/**
 * A text field for useFormHook, requires FormProvider
 * @param {} param0
 */
export const TextField: React.FC<LocalTextFieldProps> = ({
  label,
  name,
  id,
  rules,
  fullWidth = true,
  defaultValue,
  required,
  hasLabel,
  disabled,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, ...controllerProps }) => (
        <>
          {hasLabel && (
            <InputLabel id={id ?? `${label}-select`}>{label}</InputLabel>
          )}

          <MuiTextField
            autoComplete="fname"
            size="small"
            variant="outlined"
            fullWidth={fullWidth}
            id={id}
            required={required}
            disabled={disabled}
            label={label}
            onChange={onChange}
            {...controllerProps}
            {...props}
          />
        </>
      )}
    />
  );
};

export default TextField;

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { createStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  classes?: {
    loadingMessage: string;
  };
  loading: boolean;
}

const styles = createStyles({
  loadingMessage: {
    position: 'absolute',
    top: '40%',
    left: '40%'
  }
});

const Loading: React.FC<Props> = props => {
  const { classes, loading } = props;
  return (
    <div
      style={loading ? { display: 'block' } : { display: 'none' }}
      className={classes?.loadingMessage}
    >
      <CircularProgress />
    </div>
  );
};

export default withStyles(styles)(Loading);

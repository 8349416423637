import React, { useCallback } from 'reactn';
import { Controller, useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';

import { Autocomplete } from 'components/common/AutoComplete';
import { autocomplete } from 'services/AutocompleteService';

export const RcForm = () => {
  const { control } = useFormContext();

  const fetchedNationality = useCallback(async inputValue => {
    return autocomplete('country', {
      q: inputValue
    });
  }, []);
  const fetchedDataitem = useCallback(async (inputValue, id: number) => {
    return autocomplete('dataitem', {
      parentId: id,
      simple: true,
      q: inputValue
    });
  }, []);
  return (
    <>
      <Grid item xs={12} />

      <Grid item xs={12} sm={6}>
        <Controller
          name="rcFunctions"
          control={control}
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) => fetchedDataitem(e, 82)}
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="Functions"
              multiple
              {...props}
            />
          )}
          rules={{ required: true }}
        />
      </Grid>
    </>
  );
};

export default RcForm;

export const columns = [
  {
    Header: 'Id',
    accessor: 'id',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Name',
    accessor: 'name',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'ISO 3',
    accessor: 'iso3',
    disableFilters: true,
    sortType: 'basic'
  }
];

export default {
  columns
};

import { setGlobal, getGlobal } from 'reactn';

import axios from 'axios';
import axiosRetry from 'axios-retry';
import settings from 'config';
import SnackbarUtils from 'utils/SnackbarUtils.tsx';

export const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

let timer;

export const clearSessionExpireTimer = () => {
  clearTimeout(timer);
};

const Api = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  validateStatus: function validateStatus(status) {
    return status >= 200 && status < 300 && status !== 202;
  }
});

// Axios Retry doesn't handle 202 errors out of the box
// https://github.com/softonic/axios-retry/issues/100
axiosRetry(Api, {
  retries: 5,
  retryDelay: retryCount => {
    return retryCount * 3000;
  },
  retryCondition: error => {
    // return true to retry, and false to not retry
    const shouldRetry =
      axiosRetry.isNetworkError(error) || error.response.status === '409';

    if (error.response.status === 202) {
      return true;
    }
    return shouldRetry;
  }
});

const logOut = () => {
  setGlobal({ user: null, sessionExpireSoon: false });
  localStorage.removeItem('userInfo');
};

const AUTHENTICATION_ERROR = 'Unable to authenticate with login and password';
const USER_MISSING_PERMISSION_ERROR = 'User missing permission';
const SCOPE_ROLE_ASSIGNMENT_ERROR =
  'You cannot assign this role within this scope';

const SESSION_EXPIRED_TOAST_MSG = 'Session expired';
const WRONG_CREDENTIALS_TOAST_MSG =
  'Oops! you may have entered a wrong username or password. Please check your login credentials and try again';

const customErrors = response => {
  const responseMessage = response?.data?.message?.replace(/API Error: /, '');
  const msg = `Error: ${responseMessage ?? 'Unknown error'}`;
  if (response?.status === 401) {
    if (responseMessage?.match(AUTHENTICATION_ERROR)) {
      return WRONG_CREDENTIALS_TOAST_MSG;
    }
    return SESSION_EXPIRED_TOAST_MSG;
  }
  if (response?.status === 403 || response?.status === 409) {
    // TEMP: commented for debugging purposes in order to display full msg from api
    // if (responseMessage?.match(SCOPE_ROLE_ASSIGNMENT_ERROR)) {
    //   return SCOPE_ROLE_ASSIGNMENT_ERROR;
    // }
    // if (responseMessage?.match(USER_MISSING_PERMISSION_ERROR)) {
    //   return USER_MISSING_PERMISSION_ERROR;
    // }
    return responseMessage;
  }
  return msg;
};

Api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.error('error', error);
    if (
      error.response?.status === 401 &&
      // we don't need to trigger log out if the user is trying
      // to log in
      error.response?.data?.message !==
        'Unable to authenticate with login and password'
    ) {
      const globalState = getGlobal();
      // we only want to store the first request, cause
      // that's indicative of what the user wanted to do
      if (!globalState.sessionExpiredBecauseOf) {
        setGlobal({ sessionExpiredBecauseOf: error.config.method });
      }
      // Set a timer that will log out the user if they don't log back in after 60 seconds.
      // If there's already a timer we shouldn't set another one.
      if (!timer) {
        timer = setTimeout(() => {
          logOut();
          window.location.reload();
        }, 120000);
      }
    }

    if (
      error?.response?.data instanceof ArrayBuffer &&
      error?.response?.status === 422
    ) {
      const decoded = JSON.parse(
        String.fromCharCode(...new Uint8Array(error.response.data))
      );
      error.response.data = {
        message: decoded.message
      };
    }

    if (
      error?.response &&
      error.response?.status !== 401 &&
      (!error.response.data || !error.response.data.message)
    ) {
      error.response.data = {
        message: 'Unknown error'
      };
    }

    if (error.response?.status === 403 || error.response?.status === 409) {
      SnackbarUtils.warning(customErrors(error.response), {
        preventDuplicate: true
      });
    } else {
      SnackbarUtils.error(customErrors(error.response), {
        preventDuplicate: true
      });
    }

    return Promise.reject(error.response);
  }
);

export default Api;

import React, { useState, useCallback, useGlobal } from 'reactn';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { InputLabel, styled } from '@mui/material';
import { lighten, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import List from '@mui/icons-material/List';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ViewColumnsIcon from '@mui/icons-material/ViewColumn';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import RefreshIcon from '@mui/icons-material/Refresh';

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import Toolbar from '@mui/material/Toolbar';

import ExportExcel from 'components/common/export/exportExcel';
import { TableInstance } from 'react-table';
import { useTableContext } from './TableProvider';

import GlobalFilter from './GlobalFilter';
import Filters from './Filters';
import SortingPopUp from './SortingPopUp';
import ColumnHide from './ColumnHide';
import RestoreFromTrashButton from './RestoreFromTrashButton';

const GreyButton = styled(Button)({
  borderColor: '#BDBDBD',
  color: '#757575',
  background: 'var(--color-white)'
});

const useToolbarStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between'
  },
  highlight: {
    ...(theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        })
  },
  filters: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    flex: '1 1 100%',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    textTransform: 'uppercase'
  },
  button: {
    whiteSpace: 'nowrap',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2)
    },
    '&:last-child': {
      marginRight: theme.spacing(2)
    }
  }
}));

interface Props {
  numSelected: number;
  deleteRowHandler: () => void;
  totalRecords: number;
  tableInstance: TableInstance;
  endpoint: string;
  archivable?: boolean;
}

const TableToolbar: React.FC<Props> = ({
  numSelected,
  deleteRowHandler,
  totalRecords,
  tableInstance,
  endpoint,
  archivable
}) => {
  const classes = useToolbarStyles();
  const { state, dispatch } = useTableContext();
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sortingOpen, setSortingOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(undefined);

  const displayFilter = tableInstance.allColumns.find(
    // @ts-ignore FIXME
    column => column.Filter?.name
  );

  const hideableColumns = tableInstance.allColumns.filter(
    (column: { id: string }) =>
      !(column.id === 'selection' || column.id === 'actions')
  );

  const handleColumnsClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
      setColumnsOpen(true);
    },
    [setAnchorEl, setColumnsOpen]
  );

  const handleFilterClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
      setFilterOpen(true);
    },
    [setAnchorEl, setFilterOpen]
  );

  const handleSortingClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
      setSortingOpen(true);
    },
    [setAnchorEl, setSortingOpen]
  );

  const handleShowArchivedClick = useCallback(() => {
    dispatch({ type: 'toggleIncludeSoftDeleted' });
  }, [dispatch]);

  const handleRefresh = useCallback(() => {
    dispatch({ type: 'toggleRefresh' });
  }, [dispatch]);

  const handleClose = useCallback(() => {
    setColumnsOpen(false);
    setFilterOpen(false);
    setSortingOpen(false);
    setAnchorEl(undefined);
  }, []);

  const displaySelectedToolbar = numSelected !== 0;

  const containsArchived = state?.tableData?.find(
    (t: any) => t.archivedAt !== null
  );

  const [user] = useGlobal('user');
  const triggerToggle = () => {
    dispatch({ type: 'toggleCanSelect' });
  };

  const { toggleAllPageRowsSelected } = tableInstance;

  const clearSelection = () => {
    toggleAllPageRowsSelected(false);
    dispatch({ type: 'setCanSelect', canSelect: false });
    dispatch({ type: 'setAllRowsSelected', allRowsSelected: false });
  };

  const selectAll = () => {
    toggleAllPageRowsSelected(!state.allRowsSelected);
    dispatch({ type: 'toggleAllRowsSelected' });
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {!displaySelectedToolbar && (
        <>
          <Typography className={classes.title} variant="h6" id="tableTitle">
            {`${endpoint} management`}
          </Typography>
        </>
      )}
      {displaySelectedToolbar && (
        <>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} selected
          </Typography>

          {/* {containsArchived && (
            <RestoreFromTrashButton
              tableInstance={tableInstance}
              endpoint={endpoint}
            />
          )} */}
          <Tooltip title={archivable ? 'Archive' : 'Delete'}>
            <IconButton
              aria-label="archive"
              onClick={deleteRowHandler}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <div className={classes.filters}>
        {!displaySelectedToolbar && !state.canSelect && (
          <>
            <GlobalFilter totalRecords={totalRecords} />
            {displayFilter && (
              <>
                <Filters
                  instance={tableInstance}
                  onClose={handleClose}
                  show={filterOpen}
                  anchorEl={anchorEl}
                />
                <GreyButton
                  color="inherit"
                  variant="outlined"
                  onClick={handleFilterClick}
                  className={classes.button}
                  startIcon={<FilterListIcon />}
                >
                  Filter
                  {filterOpen ? <ExpandLess /> : <ExpandMore />}
                </GreyButton>
                {/* <InputLabel id="filter-select-label">Filter by:</InputLabel>
                <Tooltip title="Filters" aria-label="Filters">
                  <span>
                    <IconButton onClick={handleFilterClick} size="large">
                      <FilterListIcon />
                    </IconButton>
                  </span>
                </Tooltip> */}

                <SortingPopUp
                  instance={tableInstance}
                  onClose={handleClose}
                  show={sortingOpen}
                  anchorEl={anchorEl}
                  archivable={archivable}
                  includeSoftDeleted={state.includeSoftDeleted}
                />
                <GreyButton
                  color="inherit"
                  variant="outlined"
                  onClick={handleSortingClick}
                  className={classes.button}
                  startIcon={<SortIcon />}
                >
                  Sort
                  {sortingOpen ? <ExpandLess /> : <ExpandMore />}
                </GreyButton>
                {/* <InputLabel id="sort-select-label">Sort by:</InputLabel>
                <Tooltip title="Sorting" aria-label="Sort">
                  <span>
                    <IconButton onClick={handleSortingClick} size="large">
                      <SortIcon />
                    </IconButton>
                  </span>
                </Tooltip> */}
              </>
            )}
            <Tooltip title="Refresh" aria-label="Refresh">
              <span>
                <IconButton onClick={handleRefresh} size="large">
                  <RefreshIcon />
                </IconButton>
              </span>
            </Tooltip>
            {hideableColumns?.length > 1 && (
              <>
                <ColumnHide
                  instance={tableInstance}
                  onClose={handleClose}
                  show={columnsOpen}
                  anchorEl={anchorEl}
                />
                <Tooltip title="Columns" aria-label="Columns">
                  <span>
                    <IconButton onClick={handleColumnsClick} size="large">
                      <ViewColumnsIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )}
            <Tooltip
              title={`Include ${archivable ? 'archived' : 'deleted'} entities`}
              aria-label="Filters"
            >
              <span>
                <IconButton
                  onClick={handleShowArchivedClick}
                  color={state.includeSoftDeleted ? 'primary' : undefined}
                  size="large"
                >
                  <RestoreFromTrashIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Export">
              <ExportExcel table={tableInstance} endpoint={endpoint} />
            </Tooltip>
          </>
        )}
        {state.canSelect && (
          <>
            <Button
              variant="outlined"
              onClick={selectAll}
              className={classes.button}
            >
              {!state.allRowsSelected ? 'Select All' : 'Deselect All'}
            </Button>
            <Button
              variant="outlined"
              onClick={clearSelection}
              className={classes.button}
            >
              Cancel Selecting
            </Button>
          </>
        )}
        {user && user.isAdmin && !state.canSelect && (
          <IconButton
            aria-label="toggle to list"
            onClick={triggerToggle}
            size="large"
          >
            <List />
          </IconButton>
        )}
      </div>
    </Toolbar>
  );
};

export default TableToolbar;

// import Asynchronous from 'components/table/AsynchronousFilter';

export const unitColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    disableFilters: true,
    sortType: 'basic'
  }
];

export default {
  unitColumns
};

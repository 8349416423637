// @ts-ignore
import randomWords from 'random-words';
import { capitalize } from 'utils/helpers';
import Api from 'utils/Api';
import settings from 'config';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

export const fetchAll = <T,>(
  endpoint: string | null,
  options: {
    apiPage?: number;
    globalFilter?: string;
    limit?: number | null;
    filters?: any[];
    exclude?: string[];
    sort?: any;
    includeSoftDeleted?: boolean;
    includeArchived?: boolean;
    statuses?: string[];
    extraParams?: any;
  }
): Promise<{ data: { results: T[]; pagination: { total: number } } }> => {
  const data: any = getAuthHeader();
  if (options) {
    data.q = options.globalFilter;
    data.page = options.apiPage;
    data.limit = options.limit;
    data.exclude = options.exclude;
    if (options.filters) {
      options.filters.forEach((filter: any) => {
        let key = filter.id;
        if (key.endsWith('Id')) {
          key += 's';
        }
        if (Array.isArray(filter.value) && filter.value.length) {
          data[`${key}`] = filter.value?.map((v: any) => v?.id ?? v);
        } else if (filter.value) {
          data[`${key}`] = filter.value;
        }
      });
    }
    if (options.statuses) {
      data.statuses = options.statuses;
    }
    if (options.extraParams) {
      Object.keys(options.extraParams).forEach((param: string) => {
        data[param] = options.extraParams[param];
      });
    }
    data.includeArchived = options.includeArchived;
    data.includeSoftDeleted = options.includeSoftDeleted;
    data.sort = options.sort;
  }
  return Api.post(`${BASE_URL}/${endpoint}/search`, data, getAuthHeader());
};

export function fetchById<T>(
  endpoint: string,
  id: number
): Promise<APISingleResult<T>> {
  return Api.get(`${BASE_URL}/${endpoint}/${id}`, getAuthHeader());
}

export const deleteById = (endpoint: string, id: number): Promise<unknown> => {
  return Api.delete(`${BASE_URL}/${endpoint}/${id}`, getAuthHeader());
};

export const archiveById = (
  endpoint: string,
  data: { id: number }
): Promise<unknown> => {
  return Api.put(`${BASE_URL}/${endpoint}/archive`, data, getAuthHeader());
};

export const unarchiveById = (
  endpoint: string,
  id: number
): Promise<unknown> => {
  const data: { id: number } = {
    id
  };
  return Api.put(`${BASE_URL}/${endpoint}/unarchive`, data, getAuthHeader());
};

export const restoreByIds = (
  endpoint: string,
  entityIds: number[]
): Promise<unknown> => {
  const data: { entityIds: number[] } = {
    entityIds
  };
  return Api.put(
    `${BASE_URL}/paranoid/restore/${endpoint}`,
    data,
    getAuthHeader()
  );
};

export const mergeByIds = (
  endpoint: string,
  previousId: number,
  nextId: number
): Promise<unknown> => {
  return Api.put(
    `${BASE_URL}/${endpoint}/merge`,
    {
      previousId,
      nextId
    },
    getAuthHeader()
  );
};

export const add = <T,>(
  endpoint: string,
  options: any
): Promise<{ data: T }> => {
  return Api.post(`${BASE_URL}/${endpoint}`, options, getAuthHeader());
};

export const duplicate = (
  item: any,
  endpoint: any
): Promise<{ data: { id: number } }> => {
  const duplicateItem = item;
  delete duplicateItem.id;
  duplicateItem.name = randomWords(3)
    .map((word: any) => capitalize(word))
    .join(' ');
  return Api.post(`${BASE_URL}/${endpoint}`, duplicateItem, getAuthHeader());
};

export const edit = <T,>(
  endpoint: string,
  id: number,
  options: any
): Promise<{ data: T }> => {
  return Api.put(`${BASE_URL}/${endpoint}/${id}`, options, getAuthHeader());
};

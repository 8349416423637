import React, { useCallback, useState } from 'reactn';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'uninfo-components';

import { useSnackbar } from 'notistack';

import { Grid, TextField, Typography } from '@mui/material';

import { Autocomplete } from 'components/common/AutoComplete';
import { fetchCountries } from 'services/LocationService';

import {
  addWorkspace,
  fetchWorkspaceById,
  editWorkspace
} from 'services/WorkspaceService';
import { autocomplete } from 'services/AutocompleteService';

import PaperWrapper from 'components/common/PaperWrapper';
import WorkspacePortalVisibility from './WorkspacePortalVisibility';

type RouterParam = {
  id: string;
};

type CountryType = {
  countryTags: any[];
  createdAt: string;
  deletedAt: string | null;
  id: number;
  latestTaggedVersion: boolean;
  latestVersion: boolean;
  organizations: Organization[];
  teams: any[];
  updatedAt: string;
  versionTags: string[];
  workspaces: Workspace[];
};

const workspacePortalWidgets = [
  'genderHumanRightsContributionLevel',
  'markerContributionBySDG',
  'markerContributionByOutcome'
];

const AdminWorkspaceBuilder: React.FC = () => {
  const { id } = useParams<RouterParam>();
  const [loading, setLoading] = useState(false);
  const [workspace, setWorkspace] = useState<Workspace | null>(({
    name: '',
    countries: [],
    workspaceTypeId: null
  } as unknown) as Workspace);
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm();
  const { control, handleSubmit, errors } = methods;
  const [isSaving, setIsSaving] = React.useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    setIsSaving(true);
    try {
      const localItem = data;
      localItem.countryIds = localItem.countries.map(
        (item: CountryType) => item.id
      );
      localItem.workspaceTypeId = localItem.workspaceType?.id;
      let successMessage = 'Workspace';
      if (id === 'add') {
        const res = await addWorkspace(localItem);
        const path = `/dashboard/admin/workspace/${res.data.id}`;
        successMessage = `${successMessage} created`;
        navigate(path);
      } else {
        localItem.id = id;
        successMessage = `${successMessage} updated`;
        await editWorkspace(localItem);
      }
      enqueueSnackbar(successMessage, {
        variant: 'success',
        autoHideDuration: 3000,
        preventDuplicate: true
      });
    } catch (e) {
      console.error('error: ', e);
    } finally {
      setIsSaving(false);
    }
  };

  const fetchCb = useCallback(async () => {
    try {
      if (id && id !== 'add') {
        const res = await fetchWorkspaceById(id);
        setWorkspace(res);
      }
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  const fetchedWorkspaceTypes = useCallback(async () => {
    const response = await autocomplete('workspaceType', {
      simple: false
    });
    return response;
  }, []);

  const fetchedCountries = useCallback(async inputValue => {
    const items = await fetchCountries({
      q: inputValue
    });
    const formattedOptions = items.map(item => {
      return {
        ...item,
        name: item.name
      };
    });
    return formattedOptions;
  }, []);

  React.useEffect(() => {
    setLoading(true);
    fetchCb();
    setLoading(false);
  }, [fetchCb]);

  return (
    <>
      {!loading &&
        (id === 'add' || (id !== 'add' && workspace && workspace.id)) && (
          <Grid item xs={12}>
            <PaperWrapper variant="outlined">
              <Typography variant="h2">
                Workspace {id === 'add' ? 'Creation' : 'Editing'}
              </Typography>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="name"
                        as={
                          <TextField
                            fullWidth
                            label="Name"
                            {...(errors.name ? { error: true } : {})}
                          />
                        }
                        control={control}
                        defaultValue={workspace?.name ?? ''}
                        rules={{ required: true }}
                        variant="outlined"
                        size="small"
                      />
                      {errors.name && (
                        <Typography
                          color="error"
                          variant="body2"
                          sx={{ m: 0.5, ml: 1 }}
                        >
                          Name is required
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ marginBottom: 8 }}>
                      <Controller
                        control={control}
                        name="workspaceType"
                        defaultValue={workspace?.workspaceType || null}
                        rules={{ required: true }}
                        onChange={([, value]: any) => value}
                        render={({ onChange, ...props }) => (
                          <Autocomplete
                            fullWidth
                            label="Workspace Type"
                            {...(errors.workspaceType ? { error: true } : {})}
                            fetchOptionsCallback={fetchedWorkspaceTypes}
                            onChange={(event: any, data: any) => onChange(data)}
                            {...props}
                          />
                        )}
                      />
                      {errors.workspaceType && (
                        <Typography
                          color="error"
                          variant="body2"
                          sx={{ m: 0.5 }}
                        >
                          Workspace type is required
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginBottom: 8 }}>
                      <Controller
                        control={control}
                        name="countries"
                        defaultValue={
                          workspace?.countries?.map((item: any) => {
                            return {
                              ...item,
                              name: item.name
                            };
                          }) || []
                        }
                        rules={{ required: true }}
                        onChange={([, value]: any) => value}
                        render={({ onChange, ...props }) => (
                          <Autocomplete
                            fullWidth
                            multiple
                            label="Countries"
                            fetchOptionsCallback={fetchedCountries}
                            onChange={(event: any, data: any) => onChange(data)}
                            {...props}
                          />
                        )}
                      />
                      {errors.countries && 'Countries are required'}
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      isLoading={isSaving}
                      showSuccess
                      color="primary"
                    >
                      {id === 'add' ? 'Create' : 'Save'}
                    </Button>
                  </Grid>
                </form>
              </FormProvider>
              {workspace && <WorkspacePortalVisibility workspace={workspace} />}
            </PaperWrapper>
          </Grid>
        )}
    </>
  );
};
export default AdminWorkspaceBuilder;

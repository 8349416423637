/* eslint-disable import/prefer-default-export */
import settings from 'config';
import Api from '../utils/Api';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

const COUNTRY_API_BASE_URL = `${BASE_URL}/country/search`;
const LOCATION_API_BASE_URL = `${BASE_URL}/location/`;

export const fetchCountries = (
  options?: APIQueryOptions
): Promise<Country[]> => {
  const data = {
    ...options,
    sort: options?.sort
  };

  return Api.post(COUNTRY_API_BASE_URL, data, getAuthHeader()).then(
    res => res.data.results
  );
};

interface LocationOptions extends APIQueryOptions {
  countryIds?: number[];
  adminLevels?: number[];
  workspaceIds?: number[];
  parentIds?: number[];
  planEntityId?: number | null;
  planParentId?: number | null;
}

export const fetchLocations = (
  options: LocationOptions
): Promise<LocationAPI[]> => {
  return Api.post(
    `${LOCATION_API_BASE_URL}search`,
    options,
    getAuthHeader()
  ).then(res => res.data.results);
};

export const batchCreateLocations = (
  locations: Partial<LocationAPI>[]
): Promise<LocationAPI[]> => {
  const formattedItem = { locations };

  return Api.post(
    `${LOCATION_API_BASE_URL}batchCreate`,
    formattedItem,
    getAuthHeader()
  ).then(res => res.data.results);
};

export const findEntitiesForLocations = (
  ids: number[]
): Promise<{
  locations: {
    id: number;
    entities: {
      children: LocationAPI[];
      planEntities: PlanEntity[];
      planEntityCells: Cell[];
      premises: Premise[];
      total: number;
    };
  }[];
  total: number;
}> => {
  return Api.post(
    `${LOCATION_API_BASE_URL}entities/`,
    { ids },
    getAuthHeader()
  ).then(res => res.data);
};

export const uploadLocationCSV = (
  file: File,
  countryId: number
): Promise<{ traitedLocations: unknown[]; untraitedRows: unknown[] }> => {
  const formData = new FormData();
  formData.append('csvFile', file);
  formData.append('countryId', `${countryId}`);
  formData.append('name', 'test.csv');
  return Api.post(
    `${LOCATION_API_BASE_URL}csvImport`,
    formData,
    getAuthHeader()
  ).then(res => res.data);
};
export const downloadLocationCSV = (options: {
  countryId: number;
  limit?: number;
}): Promise<any> => {
  const postData = {
    countryId: options.countryId,
    limit: options.limit
  };

  return Api.post(
    `${LOCATION_API_BASE_URL}csvExport`,
    postData,
    getAuthHeader()
  ).then(res => res.data);
};

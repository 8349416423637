import React from 'reactn';

import { Button, CircularProgress } from 'uninfo-components';
import { unsetPlansCompleteFlag } from 'services/BlueprintService';
import { useSnackbar } from 'notistack';

import { IMS_DOMAIN_ID } from 'utils/constants';

const BlueprintUnsubmit = ({ cell: { row } }: { cell: { row: any } }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState<boolean>(false);

  const unSubmitAllPlans = React.useCallback(
    async blueprintId => {
      setLoading(true);

      try {
        await unsetPlansCompleteFlag(blueprintId);
        enqueueSnackbar('Success, plans unsubmitted', {
          variant: 'success',
          autoHideDuration: 3000,
          preventDuplicate: true
        });
      } catch (e) {
        enqueueSnackbar('Error during unsubmit', {
          variant: 'error',
          autoHideDuration: 3000,
          preventDuplicate: true
        });
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  const onClick = () => {
    unSubmitAllPlans(row.original.id);
  };

  return (
    <>
      {row.original.planTypeId === IMS_DOMAIN_ID && (
        <Button variant="outlined" onClick={onClick} isLoading={loading}>
          Unsubmit plans
        </Button>
      )}
    </>
  );
};

export default BlueprintUnsubmit;

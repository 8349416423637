import React, { useCallback } from 'reactn';
import { Controller, useFormContext } from 'react-hook-form';

import FormError from 'components/common/form/FormError';
import { Grid, TextField } from '@mui/material';
import KeyboardDatePicker from 'components/common/KeyboardDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Autocomplete } from 'components/common/AutoComplete';
import { autocomplete } from 'services/AutocompleteService';

export const UnctMemberForm: React.FC = () => {
  const { watch, errors } = useFormContext();
  const watchJobTitle = watch('jobTitle');

  const fetchedDataitem = useCallback(async (inputValue, id: number) => {
    return autocomplete('dataitem', {
      parentId: id,
      simple: true,
      q: inputValue
    });
  }, []);

  return (
    <>
      <Grid item xs={12} />
      <Grid item xs={12} sm={6}>
        <Controller
          name="jobTitle"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ref, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) => fetchedDataitem(e, 33)}
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="JobTitle"
              required
              style={{ backgroundColor: 'white' }}
              {...props}
            />
          )}
          rules={{ required: true }}
        />
      </Grid>
      {watchJobTitle?.name === 'Other' && (
        <Grid item xs={12} sm={6}>
          <Controller
            name="jobTitleOther"
            as={TextField}
            rules={{ required: true }}
            fullWidth
            placeholder="Please specify"
            required
            style={{ backgroundColor: 'white' }}
            size="small"
            label="Please specify"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <FormError errors={errors} name="jobTitleOther" />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <Controller
          name="postLevel"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ref, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) => fetchedDataitem(e, 20)}
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="Post Level"
              {...props}
            />
          )}
        />
      </Grid>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="dutyEntry"
            render={({ ref, ...props }) => (
              <KeyboardDatePicker
                {...props}
                id="dutyEntry"
                label="Duty Entry"
                inputRef={ref}
                textfieldStyle={{ backgroundColor: 'white' }}
              />
            )}
          />
        </Grid>
      </LocalizationProvider>
    </>
  );
};

export default UnctMemberForm;

import React from 'react';
import { styled } from '@mui/material/styles';
import { range } from 'lodash';

const Shine = styled('div')`
  animation: 0.75s shine linear infinite;
  background: #eee;

  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

export const LoadingBlocks: React.FC<{ rows?: number }> = ({ rows = 4 }) => {
  return (
    <>
      {range(rows).map(r => (
        <Shine
          key={r}
          style={{
            width: '100%',
            height: '3rem',
            marginTop: '1rem',
            marginBottom: '1rem'
          }}
        />
      ))}
    </>
  );
};

export default LoadingBlocks;

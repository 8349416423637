import React from 'reactn';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: '100%',
    border: 'none',
    borderCollapse: 'collapse',
    '& tbody tr:nth-child(odd)': {
      background: theme.palette.grey[200]
    },
    '& thead': {},
    '& th': {
      textAlign: 'left',
      position: 'sticky',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2),0 0 0 rgba(0,0,0,0.14),0 0 0 rgba(0,0,0,0.12)',
      top: '-12px',
      zIndex: 2,
      backgroundColor: theme.palette.grey[200]
    },
    '& td, & th': {
      padding: theme.spacing(1, 2)
    },
    '& td.strong': {
      fontWeight: 'bold'
    },
    '& td.alignRight, & th.alignRight': {
      textAlign: 'right'
    }
  }
}));

interface TableProps {
  className?: string;
  style?: React.CSSProperties;
  id?: string;
}

export const Table: React.FC<TableProps> = ({
  children,
  className,
  style,
  id
}) => {
  const classes = useStyles();

  return (
    <table
      style={style}
      className={`${className ?? ''} ${classes.table}`}
      id={id}
    >
      {children}
    </table>
  );
};

export default Table;

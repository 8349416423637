import { hasRole } from 'components/common/PermissionGate';

export const canUserViewPlanOfDomain = (
  user: User,
  domainId: number
): boolean => {
  if (user.isAdmin) {
    return true;
  }
  return !!user.userRoles.find(pT => pT.contextDomainId === domainId);
};

export default {
  canUserViewPlanOfDomain
};

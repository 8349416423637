import settings from 'config';
import Api from '../utils/Api';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

const APP_NOTIFICATION_API_BASE_URL = `${BASE_URL}/appNotification/`;

interface AppNotificationOptions extends APIQueryOptions {
  userIds?: number[];
}

export const fetchAppNotifications = async (
  options: AppNotificationOptions
): Promise<AppNotification[]> => {
  const data = {
    ...options
  };

  return Api.post(
    `${APP_NOTIFICATION_API_BASE_URL}search`,
    data,
    getAuthHeader()
  ).then(res => res.data.results);
};

export const deleteAppNotification = (notificationId: number): Promise<any> => {
  return Api.delete(
    `${APP_NOTIFICATION_API_BASE_URL}${notificationId}`,
    getAuthHeader()
  );
};

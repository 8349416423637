import React, { memo, useState } from 'reactn';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { BaseDialog } from 'uninfo-components';

import EnhancedTable from 'components/table/EnhancedTable';
import { add, edit } from 'services/AdminService';
import useAddMultipleToggle from 'utils/useAddMultipleToggle';
import { isExisting } from 'utils/typeguards';

import { dataSourceColumns } from './dataSource/DataSourceColumns';
import { DataSourceForm } from './dataSource/DataSourceForm';

const AdminDataSource = () => {
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState({});
  const {
    resetSwitch,
    switchState,
    ToggleInFormDescription
  } = useAddMultipleToggle();

  const columns = React.useMemo(() => dataSourceColumns, []);

  async function handleSubmit(item: DataSource | DataSourceNew) {
    try {
      let res;
      if (isExisting(editItem)) {
        res = await edit<DataSource>('dataSource', editItem.id, item);
      } else {
        res = await add<DataSource>('dataSource', item);
      }
      if (switchState.addMultiple) {
        setOpen(true);
      } else {
        setOpen(false);
        setEditItem({});
      }
    } catch (e) {
      console.error('error: ', e);
    }
  }

  const handleClose = () => {
    setOpen(false);
    resetSwitch();
    setEditItem({});
  };

  return (
    <Grid item xs={12}>
      <Paper>
        <EnhancedTable
          setOpen={setOpen}
          setEditItem={setEditItem}
          endpoint="dataSource"
          columns={columns}
          hiddenColumns={[]}
        />
        <BaseDialog
          open={open}
          onClose={handleClose}
          description={<ToggleInFormDescription />}
          title="DataSource"
        >
          <DataSourceForm onSubmit={handleSubmit} editItem={editItem} />
        </BaseDialog>
      </Paper>
    </Grid>
  );
};

export default memo(AdminDataSource);

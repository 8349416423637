import { createTheme } from '@mui/material/styles';
import { ConfirmOptions } from 'material-ui-confirm';

const sdgBlue = '#006cb7';

const theme = createTheme({
  palette: {
    secondary: {
      main: sdgBlue
    },
    primary: {
      main: '#006cb7'
    },
    error: {
      main: '#a71d44'
    },
    warning: {
      main: 'rgb(255, 150, 46)',
      light: 'rgba(253, 150, 48, 0.41)'
    },
    cfr: {
      main: '#07642b',
      light: '#B4D0BF'
    },
    bos: {
      main: '#ad6500'
    },
    ims: {
      main: '#005e80'
    },
    cpr: {
      main: '#5a4f96'
    },
    cbo: {
      main: '#972086'
    }
  },
  typography: {
    fontFamily: ['"Roboto"', 'sans-serif'].join(','),
    subtitle1: {
      fontWeight: 'bold'
    },
    subtitle2: {
      fontSize: '0.875rem',
      color: 'rgb(117, 117, 117)',
      fontWeight: 'normal'
    },
    h1: {
      fontSize: '1.7rem'
    },
    h2: {
      fontSize: '1.5rem',
      marginBottom: '1rem'
    },
    h3: {
      fontSize: '1.2rem',
      color: '#424040'
    },
    h4: {
      fontSize: '1.1rem'
    }
  }
});

const themeWithSpecificRules = createTheme(theme, {
  ...theme,
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '.9rem'
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.4)'
        },
        icon: {
          opacity: 0.5
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          whiteSpace: 'nowrap'
        }
      }
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          background: 'rgba(255, 255, 255, 0.6)',
          '&.Mui-disabled': {
            display: 'none'
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          paddingBottom: 0
        },
        spacer: {
          display: 'none'
        }
      }
    }
  }
});

export const confirmDefaultOptions: ConfirmOptions = {
  confirmationText: 'Confirm',
  confirmationButtonProps: {
    variant: 'contained',
    style: { borderRadius: '2rem', textTransform: 'none' }
  },
  cancellationButtonProps: {
    variant: 'outlined',
    style: { borderRadius: '2rem', textTransform: 'none' }
  }
};

export default themeWithSpecificRules;

import React, { useCallback } from 'reactn';
import { Controller, useFormContext } from 'react-hook-form';
import FormError from 'components/common/form/FormError';
import { Grid } from '@mui/material';
import { TextField } from 'uninfo-components';

import { Autocomplete } from 'components/common/AutoComplete';
import { autocomplete } from 'services/AutocompleteService';

export const UnicForm: React.FC = () => {
  const fetchedDataitem = useCallback(
    async (inputValue, parentId: number, id?: number) => {
      return autocomplete('dataitem', {
        parentId,
        simple: true,
        q: inputValue,
        ...(id ? { where: { id } } : {})
      });
    },
    []
  );

  return (
    <>
      <Grid item xs={12} />

      <Grid item xs={12} sm={6}>
        <Controller
          name="jobTitle"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ref, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) => fetchedDataitem(e, 18, 217)}
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="JobTitle"
              required
              {...props}
            />
          )}
          rules={{ required: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="postLevel"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ref, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) => fetchedDataitem(e, 20)}
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="Post Level"
              required
              {...props}
            />
          )}
          rules={{ required: true }}
        />
      </Grid>
    </>
  );
};

export default UnicForm;

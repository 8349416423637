import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DATE_FORMAT } from 'utils/constants';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

/*
 * Beware workarounds involving bugs in material-ui-pickers' design.
 *
 * See https://github.com/mui-org/material-ui-pickers/issues/1358#issuecomment-628015527
 *
 * @material-ui/pickers operate on a Date, but we really want a String.
 * These funky DateUtils let @material-ui/pickers pick dates in the local
 * timezone ... but they ensure outside callers only see ISO8601 Strings.
 */

/**
 * Convert a _local-time_ value to an ISO-8601 Date string.
 *
 * For instance: given 2020-05-13T03:59:50.000Z, if we're in UTC-4,
 * return "2020-05-12".
 *
 * Why? Because material-ui selects dates in local time, not in UTC. If we
 * were to run date.toISOString(), that would convert to UTC and then
 * convert to String; but if we convert to UTC, that changes the date.
 */
function jsDateToLocalISO8601DateString(date: any) {
  return [
    String(date.getFullYear()),
    String(101 + date.getMonth()).substring(1),
    String(100 + date.getDate()).substring(1)
  ].join('-');
}

function dateStringToLocalDate(s: any) {
  if (!s) return null;
  return new DateFnsUtils().parse(s, DATE_FORMAT);
}

interface Props {
  [x: string]: any;
  id: string;
  label: string;
  value?: any;
  inheritedValue?: any;
  onChange: any;
  textfieldStyle?: React.CSSProperties;
}

KeyboardDatePicker.defaultProps = {
  value: null,
  inheritedValue: null
};

export default function KeyboardDatePicker({
  id,
  label,
  value,
  inheritedValue,
  onChange,
  textfieldStyle,
  ...props
}: Props): React.ReactElement {
  const handleChange = React.useCallback(
    date => {
      onChange(date ? jsDateToLocalISO8601DateString(date) : null);
    },
    [onChange]
  );
  const [valueFlag, setValueFlag] = React.useState(false);
  if (inheritedValue && !value && !valueFlag) {
    handleChange(dateStringToLocalDate(inheritedValue));
    setValueFlag(!valueFlag);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={['year', 'day']}
        label={label}
        value={dateStringToLocalDate(value)}
        onChange={handleChange}
        renderInput={params => (
          <TextField
            {...params}
            required={props.required}
            size="small"
            style={textfieldStyle}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
}

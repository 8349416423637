import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  stepWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  step: {
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderRadius: '2rem',
    backgroundColor: 'var(--color-grey)',
    '&.completed': {
      backgroundColor: 'var(--color-sdg-blue)'
    }
  }
}));

interface Props {
  entityPrototypes: EntityPrototype[];
}

const PlanStepsCompleted: React.FC<Props> = ({ entityPrototypes }) => {
  const steps = entityPrototypes
    .filter(eP => eP.properties.step.socle)
    .sort((a, b) => {
      return a.properties?.step?.id - b.properties?.step?.id;
    });

  const classes = useStyles();
  return (
    <div className={classes.stepWrapper}>
      {steps.map(step => (
        <span
          key={step.id}
          className={clsx([classes.step, step.complete ? 'completed' : ''])}
        />
      ))}
    </div>
  );
};

export default PlanStepsCompleted;

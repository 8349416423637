import React from 'reactn';
import Asynchronous from 'components/table/AsynchronousFilter';
import { Autocomplete } from 'components/common/AutoComplete';
import { autocomplete } from 'services/AutocompleteService';
import { get } from 'lodash';
import { FilterProps } from 'react-table';
import BooleanCheckCell from 'components/table/BooleanCheckCell';
import { Chip } from '@mui/material';

function GroupFilter({
  column: { filterValue, setFilter },
  columns
}: FilterProps<Contact>): React.ReactElement {
  const fetchedGroup = React.useCallback(async () => {
    return [
      { id: 'Entity representative', name: 'Entity representative' },
      { id: 'UNCG member', name: 'UNCG member' },
      { id: 'RCO', name: 'RCO' },
      { id: 'RO', name: 'RO' },
      { id: 'RD', name: 'RD' },
      { id: 'RC', name: 'RC' }
    ];
  }, []);

  return (
    <Autocomplete
      style={{ width: 200 }}
      multiple
      label="Group"
      value={filterValue || []}
      onChange={(_e: any, value: any[]) => {
        const uncgLead = columns.find(c => c.id === 'uncgLead');
        const postLevel = columns.find(c => c.id === 'postLevelId');
        if (value.find(v => v.id === 'UNCG member')) {
          uncgLead?.toggleHidden(false);
          postLevel?.toggleHidden(false);
        } else {
          uncgLead?.toggleHidden(true);
          postLevel?.toggleHidden(false);
        }
        setFilter(value);
      }}
      fetchOptionsCallback={fetchedGroup}
    />
  );
}

function MainUNCGFilter({
  column: { setFilter, filterValue, toggleHidden }
}: FilterProps<Contact>): React.ReactElement {
  const fetchedGroup = React.useCallback(async () => {
    return [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' }
    ];
  }, []);

  return (
    <Autocomplete
      style={{ width: 200 }}
      multiple
      label="Main UNCG Contact"
      value={filterValue || []}
      onChange={(_e: any, value: any) => {
        setFilter(value);
        toggleHidden(!value?.[0]?.id);
      }}
      fetchOptionsCallback={fetchedGroup}
    />
  );
}

function MainCommsFilter({
  column: { setFilter, filterValue, toggleHidden }
}: FilterProps<Contact>): React.ReactElement {
  const fetchedGroup = React.useCallback(async () => {
    return [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' }
    ];
  }, []);

  return (
    <Autocomplete
      style={{ width: 200 }}
      multiple
      label="Comms Officers"
      value={filterValue || []}
      onChange={(_e: any, value: any) => {
        setFilter(value);
        toggleHidden(!value?.[0]?.id);
      }}
      fetchOptionsCallback={fetchedGroup}
    />
  );
}

function TeamLeadsFilter({
  column: { setFilter, filterValue, toggleHidden }
}: FilterProps<Contact>): React.ReactElement {
  const fetchedGroup = React.useCallback(async () => {
    return [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' }
    ];
  }, []);

  return (
    <Autocomplete
      style={{ width: 200 }}
      multiple
      label="Team Leads"
      value={filterValue || []}
      onChange={(_e: any, value: any) => {
        setFilter(value);
        toggleHidden(!value?.[0]?.id);
      }}
      fetchOptionsCallback={fetchedGroup}
    />
  );
}
interface AsynchronousResults {
  name?: string;
  abbreviation?: string;
  id: number;
}
interface MultipleSourceProps {
  column: {
    filterValue: string;
    setFilter: (value: string, bool: boolean) => void;
    endpoint: string;
    datasourceIds: number[];
    filterLabel: string;
    filterAccessor?: string;
  };
}

function MultipleSource<T extends AsynchronousResults>({
  column: {
    filterValue,
    setFilter,
    endpoint,
    filterLabel,
    filterAccessor,
    datasourceIds
  }
}: MultipleSourceProps): React.ReactElement {
  const fetchOptionsCallback = React.useCallback(
    async value => {
      const promiseArray: unknown[] = [];
      let items: any[] = []; // FIXME: this feels like it's a hack
      datasourceIds.forEach((id: number) => {
        promiseArray.push(
          autocomplete(`${endpoint}/${id}`, {
            q: value || '',
            limit: 500,
            simple: false
          })
        );
      });
      const results = await Promise.all(promiseArray);
      results.forEach((r: any) => {
        items = items.concat(r);
      });

      const formattedOptions = items.map(item => {
        if (filterAccessor) {
          return {
            name: get(item, filterAccessor),
            id: item.id
          };
        }
        return {
          name: item.dataitemVersion.name,
          id: item.id
        };
      });

      return formattedOptions;
    },
    [endpoint, filterAccessor, datasourceIds] // This is highlighted by eslint because it doesn't know how to handle the generic T
  );

  return (
    <Autocomplete
      style={{ width: 200 }}
      multiple
      label={filterLabel}
      value={filterValue || []}
      onChange={(_e: any, value: string) => {
        setFilter(value, true);
      }}
      fetchOptionsCallback={fetchOptionsCallback}
    />
  );
}

export const contactColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    sortType: 'basic',
    disableFilters: true,
    // eslint-disable-next-line react/display-name
    Cell: ({
      cell: { row }
    }: ReactTableCell<string, Contact>): React.ReactElement => {
      return <>{row.original.name}</>;
    }
  },
  {
    Header: 'Email',
    accessor: 'email',
    sortType: 'basic',
    disableFilters: true
  },
  {
    Header: 'Organization Contacts',
    id: 'organizationContactId',
    Cell: ({
      cell: { row }
    }: ReactTableCell<string, Contact>): React.ReactElement => {
      return (
        <>
          {row.original.organizationContacts
            ?.map(o => [
              o?.workspaceOrganization?.workspace?.name,
              o?.workspaceOrganization?.organization?.abbreviation,
              o?.type,
              o?.jobTitle?.dataitemVersion?.name === 'Other'
                ? o?.jobTitleOther
                : o?.jobTitle?.dataitemVersion?.name
            ])
            .map(c => c.filter(x => x).join(', '))
            .map(c => (
              <Chip sx={{ maxWidth: '450px' }} key={c} label={c} />
            ))}
        </>
      );
    },
    disableSortBy: true,
    disableFilters: true
  },
  {
    Header: 'Agency',
    id: 'organizationId',
    accessor: 'agency.abbreviation',
    endpoint: 'organization',
    filterLabel: 'Agency',
    Filter: Asynchronous,
    hide: true,
    hideToggleHide: true
  },
  {
    Header: 'Group',
    id: 'type',
    accessor: 'type',
    Filter: GroupFilter,
    sortType: 'basic',
    hide: true,
    hideToggleHide: true
  },
  {
    Header: 'Job Title',
    id: 'jobTitleId',
    accessor: 'jobTitle.dataitemVersion.name',
    style: { wordBreak: 'break-word' },
    endpoint: 'dataitem',
    datasourceIds: [18, 142, 33],
    Filter: MultipleSource,
    filterLabel: 'Job Title',
    sortType: 'basic',
    hide: true,
    hideToggleHide: true
  },
  {
    Header: 'Post Level',
    id: 'postLevelId',
    accessor: 'postLevel.dataitemVersion.name',
    style: { wordBreak: 'break-word' },
    endpoint: 'dataitem',
    disableFilters: true,
    sortType: 'basic',
    hide: true,
    hideToggleHide: true
  },
  {
    Header: 'Workspace',
    id: 'workspaceId',
    accessor: 'workspace.name',
    endpoint: 'workspace',
    style: { wordBreak: 'break-word', maxWidth: '200px' },
    filterLabel: 'Workspace',
    Filter: Asynchronous,
    hide: true,
    hideToggleHide: true,
    filterByUserWorkspaces: true,
    permission: 'contact_view'
  },
  {
    Header: 'UNCG Chair',
    id: 'uncgLead',
    accessor: 'uncgLead',
    style: { wordBreak: 'break-word' },
    Filter: MainUNCGFilter,
    sortType: 'basic',
    Cell: BooleanCheckCell,
    hide: true,
    hideToggleHide: true
  },
  {
    Header: 'Source of financings',
    id: 'financingIds',
    accessor: 'financing.dataitemVersion.name',
    endpoint: 'dataitem',
    style: { wordBreak: 'break-word', maxWidth: '200px' },
    datasourceIds: [53, 143],
    Filter: MultipleSource,
    filterLabel: 'Source of financings',
    hide: true,
    hideToggleHide: true
  },
  {
    Header: 'Comms Officers',
    id: 'commsRCOContact',
    accessor: 'commsRCOContact',
    Filter: MainCommsFilter,
    Cell: BooleanCheckCell,
    hide: true,
    hideToggleHide: true
  },
  {
    Header: 'Team Leads',
    id: 'teamLead',
    accessor: 'teamLead',
    Filter: TeamLeadsFilter,
    Cell: BooleanCheckCell,
    hide: true,
    hideToggleHide: true
  }
];

export default {
  contactColumns
};

import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { isEmpty } from 'lodash';
import I18nTextField from 'components/common/form/I18nTextField';
import { TextField } from 'components/common/form/TextField';
import { Autocomplete } from 'components/common/AutoComplete';
import { Checkbox, DialogActions } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import { fetchLocations } from 'services/LocationService';
import { Button } from 'uninfo-components';
import { NestedLocation } from './LocationsList';

interface Props {
  editItem: LocationAPI;
  country: Country;
  handleSubmit: (item: NestedLocation) => void;
}

export const LocationForm: React.FC<Props> = ({
  editItem,
  country,
  handleSubmit: handleSubmitForm
}) => {
  const { t } = useTranslation('common');

  const fetchedLocations = React.useCallback(
    async (q: string) => {
      return fetchLocations({
        q,
        countryIds: [country.id],
        sort: [
          ['country.name', 'DESC'],
          ['adminLevel', 'DESC']
        ]
      });
    },
    [country.id]
  );

  const initialValues = {
    name: editItem?.name ?? editItem.name,
    iso3: '',
    nameI18n: editItem?.nameI18n,
    latitude: editItem?.latitude,
    longitude: editItem?.longitude,
    pcode: editItem?.pcode,
    location: !isEmpty(editItem)
      ? {
          ...editItem?.parent,
          name: editItem?.parent?.name
        }
      : null,
    isCity: editItem?.isCity,
    isCapital: editItem?.isCapital
  };

  const methods = useForm({
    defaultValues: initialValues,
    shouldUnregister: false // needed for the translation pop-up
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset
  } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <I18nTextField
              name="name"
              placeholder="Location"
              label={t('Name')}
              required
              rules={{ required: true }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name="location"
              onChange={([, value]: LocationAPI[]) => value}
              render={({ onChange, ref, ...controllerProps }) => (
                <Autocomplete
                  fullWidth
                  label="Parent Location"
                  fetchOptionsCallback={fetchedLocations}
                  onChange={(_event: any, data: LocationAPI) => onChange(data)}
                  {...controllerProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="latitude"
              placeholder="Latitude"
              label={t('Latitude')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="longitude"
              placeholder="Longitude"
              label={t('Longitude')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="pcode" placeholder="pcode" label={t('pcode')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Controller
                  name="isCity"
                  control={control}
                  render={props => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={e => {
                        if (e.target.checked === false) {
                          props.onChange(e.target.checked);
                          reset({ isCapital: e.target.checked });
                        } else {
                          props.onChange(e.target.checked);
                        }
                      }}
                    />
                  )}
                />
              }
              label="Is this location a city ?"
            />
            <FormControlLabel
              control={
                <Controller
                  name="isCapital"
                  control={control}
                  render={props => {
                    return (
                      <Checkbox
                        {...props}
                        // Need to do that to force the unchecked
                        checked={watch('isCity') ? props.value : false}
                        onChange={e => props.onChange(e.target.checked)}
                        disabled={!watch('isCity')}
                      />
                    );
                  }}
                />
              }
              label="Is this location a capital ?"
            />
          </Grid>

          <DialogActions>
            <Button
              type="submit"
              fullWidth
              isLoading={isSubmitting}
              variant="contained"
              color="primary"
            >
              {editItem.id ? 'Save' : 'Add'}
            </Button>
          </DialogActions>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default {
  LocationForm
};

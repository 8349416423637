import React, { useCallback, useState, useGlobal } from 'reactn';

import { useParams, useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { Select } from 'components/common/form/Select';

import { Autocomplete } from 'components/common/AutoComplete';
import { Button, BaseDialog } from 'uninfo-components';
import { fetchCountries } from 'services/LocationService';
import { isExisting } from 'utils/typeguards';
import {
  addOrganization,
  fetchOrganizationById,
  fetchOrganizations,
  fetchOrganizationTypes,
  editOrganization
} from 'services/OrganizationService';

import PaperWrapper from 'components/common/PaperWrapper';

import BulkOrganizationForm from './BulkOrganizationForm';

interface FormData {
  id?: number;
  abbreviation: string | null;
  name: string | null;
  countryIds?: number[];
  iatiOrgId: string | null;
  iatiPubId: string | null;
  status: string;
  parent: Organization | undefined;
  organizationType: OrganizationType | null;
  country: { name: string; id: number } | null;
}

interface OrganizationFormProps {
  organization: Organization | OrganizationNew;
  id: string;
  afterSubmitAction?: (org: Organization) => void;
}

export const OrganizationForm: React.FC<OrganizationFormProps> = ({
  organization,
  id,
  afterSubmitAction
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [unSDGChecked, setUnSDGChecked] = useState(organization.unsdg);
  const [unCTChecked, setUnCTChecked] = useState(organization.unct);
  const [TTCPChecked, setTTCPChecked] = useState(organization.ttcp);
  const [cpScopeChecked, setCpScopeChecked] = useState(organization.cpScope);
  const navigate = useNavigate();

  const handleSDGChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnSDGChecked(event.target.checked);
  };

  const handleTTCPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTTCPChecked(event.target.checked);
  };

  const handleCTChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnCTChecked(event.target.checked);
  };
  const handleCpScopeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCpScopeChecked(event.target.checked);
  };

  const methods = useForm<FormData>({
    defaultValues: {
      abbreviation: organization.abbreviation ?? null,
      name: organization.name ?? null,
      iatiOrgId: organization.iatiOrgId ?? null,
      iatiPubId: organization.iatiPubId ?? null,
      parent: organization.parent ?? undefined,
      status: organization.status ?? 'disabled',
      organizationType: organization.organizationType ?? null,
      country: organization.countries?.length
        ? {
            ...organization.countries[0],
            name: organization.countries[0].name
          } ?? null
        : null
    }
  });
  const { handleSubmit, control, errors } = methods;

  const fetchedCountries = useCallback(async inputValue => {
    const items = await fetchCountries({
      q: inputValue
    });
    const formattedOptions = items.map(item => {
      return {
        ...item,
        name: item.name
      };
    });
    return formattedOptions;
  }, []);

  const fetchedOrganizationTypes = React.useCallback(async () => {
    return fetchOrganizationTypes();
  }, []);
  const fetchedOrganization = React.useCallback(async inputValue => {
    return fetchOrganizations({ limit: undefined, q: inputValue });
  }, []);

  // React.useEffect(() => {
  //   if (organization.unsdg) {
  //     setUnSDGChecked(organization.unsdg);
  //   }
  //   if (organization.unct) {
  //     setUnCTChecked(organization.unct);
  //   }
  //   if (organization.cpScope) {
  //     setCpScopeChecked(organization.cpScope);
  //   }
  // }, [organization.unsdg, organization.unct, organization.cpScope]);

  const save = async (data: FormData) => {
    if (!loading) {
      setLoading(true);
      try {
        const localItem = {
          ...data,
          iatiOrgId: data.iatiOrgId ?? undefined,
          iatiPubId: data.iatiPubId ?? undefined,
          organizationType: data.organizationType ?? undefined,
          abbreviation: data.abbreviation ?? undefined,
          name: data.name ?? undefined,
          id: isExisting<Organization>(organization)
            ? organization.id
            : undefined,
          organizationCountries: data.country
            ? [{ countryId: data.country?.id }]
            : undefined,
          typeId: data.organizationType?.id,
          status: data.status ?? 'disabled',
          unsdg: unSDGChecked,
          ttcp: TTCPChecked,
          unct: unCTChecked,
          cpScope: cpScopeChecked,
          parentId: data.parent?.id
        };

        if (id === 'add') {
          const res = await addOrganization(localItem);
          if (afterSubmitAction) {
            afterSubmitAction(res.data);
          } else {
            navigate(`/dashboard/admin/organization/${res.data.id}`);
          }
        } else if (isExisting<Organization>(localItem)) {
          await editOrganization(localItem);
        }
        setLoading(false);
        enqueueSnackbar('Organization updated', {
          variant: 'success',
          autoHideDuration: 3000,
          preventDuplicate: true
        });
      } catch (e) {
        console.error('error: ', e);
      } finally {
        setLoading(false);
      }
    }
  };
  const organizationStatusOptions = [
    {
      label: 'Active',
      value: 'active'
    },
    {
      label: 'Disabled',
      value: 'disabled'
    }
  ];
  return (
    <FormProvider {...methods}>
      <PaperWrapper component="form" onSubmit={handleSubmit(save)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              as={TextField}
              name="name"
              label="Name"
              control={control}
              variant="outlined"
              rules={{ required: true }}
              required
              placeholder="Organization Name"
              fullWidth
            />
            <ErrorMessage errors={errors} name="name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              as={TextField}
              name="abbreviation"
              control={control}
              rules={{ required: true }}
              required
              variant="outlined"
              placeholder="Abbreviation"
              label="Abbreviation"
              fullWidth
            />
            <ErrorMessage errors={errors} name="abbreviation" />
          </Grid>
          <Grid item xs={8}>
            <Select
              name="status"
              required
              label="Organization Status"
              options={organizationStatusOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={TextField}
              name="iatiOrgId"
              control={control}
              variant="outlined"
              placeholder="IATI Org Id"
              label="IATI Org Id"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={TextField}
              name="iatiPubId"
              control={control}
              variant="outlined"
              placeholder="Publisher Org Id"
              label="Publisher Org Id"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="parent"
              onChange={([, value]: Organization[]) => value}
              render={({ onChange, ref, ...controllerProps }) => (
                <Autocomplete
                  fullWidth
                  label="Parent Organization"
                  fetchOptionsCallback={fetchedOrganization}
                  onChange={(_event: any, data: Organization) => onChange(data)}
                  {...controllerProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="organizationType"
              rules={{ required: true }}
              onChange={([, value]: OrganizationType[]) => value}
              render={({ onChange, ref, ...controllerProps }) => (
                <Autocomplete
                  fullWidth
                  label="Type"
                  required
                  fetchOptionsCallback={fetchedOrganizationTypes}
                  helperText="Link is required"
                  onChange={(_event: any, data: OrganizationType) =>
                    onChange(data)
                  }
                  {...controllerProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={unSDGChecked}
                  onChange={handleSDGChange}
                  name="checked"
                  color="primary"
                />
              }
              label="UN SDG"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={unCTChecked}
                  onChange={handleCTChange}
                  name="checked"
                  color="primary"
                />
              }
              label="UNCT"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={TTCPChecked}
                  onChange={handleTTCPChange}
                  name="checked"
                  color="primary"
                />
              }
              label="TTCP"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={cpScopeChecked}
                  onChange={handleCpScopeChange}
                  name="checked"
                  color="primary"
                />
              }
              label="CP scope"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="country"
              onChange={([, value]: Country[]) => value}
              render={({ onChange, ...controllerProps }) => (
                <Autocomplete
                  fullWidth
                  required
                  label="Country"
                  fetchOptionsCallback={fetchedCountries}
                  onChange={(event: any, data: Country) => onChange(data)}
                  {...controllerProps}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              disabled={loading}
              variant="contained"
              color="primary"
            >
              {id === 'add' ? 'Create' : 'Edit'}
            </Button>
          </Grid>
        </Grid>
      </PaperWrapper>
    </FormProvider>
  );
};

const AdminOrganizationBuilder = () => {
  const { id } = useParams<{ id: string }>();
  const [user] = useGlobal('user');
  const [loading, setLoading] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  const [organization, setOrganization] = useState<
    Organization | OrganizationNew
  >({
    name: '',
    abbreviation: '',
    countries: [],
    iatiOrgId: '',
    iatiPubId: '',
    unsdg: false,
    cpScope: false,
    unct: false,
    status: '',
    organizationType: undefined
  });

  const fetchCb = useCallback(async () => {
    try {
      if (id && id !== 'add') {
        const res = await fetchOrganizationById(+id);
        setOrganization(res.data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  React.useEffect(() => {
    setLoading(true);
    fetchCb();
    setLoading(false);
  }, [fetchCb]);

  return (
    <>
      {!loading &&
        id &&
        (id === 'add' || isExisting<Organization>(organization)) && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {id === 'add' ? 'Create' : 'Edit'} Organization
              </Typography>
            </Grid>
            {user?.isAdmin && (
              <Grid item xs={12}>
                Want to create organizations in bulk?{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link onClick={() => setOpenBulk(true)} component="button">
                  click here
                </Link>
              </Grid>
            )}
            <OrganizationForm organization={organization} id={id} />
            <BaseDialog
              maxWidth="lg"
              open={openBulk}
              onClose={() => setOpenBulk(false)}
              title="Bulk Create Organizations"
            >
              <BulkOrganizationForm />
            </BaseDialog>
          </Grid>
        )}
    </>
  );
};

export default AdminOrganizationBuilder;

import { Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'reactn';
import { Button } from 'uninfo-components';
import { useSnackbar } from 'notistack';

import { Autocomplete } from 'components/common/AutoComplete';
import autocomplete from 'services/AutocompleteService';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';

import {
  addContact,
  addContactToOrganization,
  editContactToOrganization,
  fetchContactById,
  fetchContacts,
  searchOrganizationContacts
} from 'services/ContactService';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  ContactBasicFormBuilder,
  ContactBasicForm
} from 'pages/admin/contact/ContactBuilder';

import PermissionGate from 'components/common/PermissionGate';
import { isWorkspaceOrganizationContact } from 'utils/typeguards';
import ContactOrganizationForm from './ContactOrganizationForm';

const Form = styled('form')`
  h3 {
    font-size: 1.2rem;
  }
`;

interface FormValues {
  contact: Contact | null;
  nationality: Country | null;
  gender: DataItem['dataitemVersion'] | null;
  email: string | null;
  name: string | null;
  type: string | null;
  mainRCOContact: boolean;
  jobTitle: DataItem['dataitemVersion'] | null;
  jobTitleOther: string | null;
  officeType: string | null;
  officeCity: string | null;
  workspace: Workspace;
  financings: DataItem['dataitemVersion'][] | null;
  financingOther: string | null;
  postLevel: DataItem['dataitemVersion'] | null;
  contractModality: DataItem['dataitemVersion'] | null;
  inKindPersonnelContribution: string | null;
  areas: DataItem['dataitemVersion'][] | null;
  rcFunctions: DataItem['dataitemVersion'][] | null;
  areaOther: string | null;
  dutyEntry: string | null;
  uncgLead: boolean;
  contributionKind: DataItem['dataitemVersion'] | null;
  teamLead: boolean;
  commsRCOContact: boolean;
}

const mapper = (v: DataItem) => ({
  ...v.dataitemVersion,
  id: v.id
});

const buildDefaultValues = (
  workspace: Workspace,
  contact?: WorkspaceOrganizationContact | null,
  defaults?: { jobTitle?: DataItem }
): FormValues => {
  const orgContact = contact?.organizationContact;
  let jobTitle = (orgContact?.jobTitle && mapper(orgContact.jobTitle)) ?? null;
  if (defaults) {
    if (defaults.jobTitle) {
      jobTitle = mapper(defaults.jobTitle);
    }
  }

  const newObj = {
    contact: contact ?? null,
    nationality: contact?.nationality ?? null,
    name: contact?.name ?? null,
    gender: (contact?.gender && mapper(contact?.gender)) ?? null,
    email: contact?.email ?? null,
    type: orgContact?.type ?? null,
    workspace,
    financings: orgContact?.financings?.map(mapper) ?? [],
    financingOther: orgContact?.financingOther ?? null,
    contractModality:
      (orgContact?.contractModality && mapper(orgContact.contractModality)) ??
      null,
    postLevel: (orgContact?.postLevel && mapper(orgContact.postLevel)) ?? null,
    officeCity: orgContact?.officeCity ?? null,
    officeType: orgContact?.officeType ?? null,
    inKindPersonnelContribution:
      orgContact?.inKindPersonnelContribution ?? null,
    contributionKind:
      (orgContact?.contributionKind && mapper(orgContact.contributionKind)) ??
      null,
    areas: orgContact?.areas?.map(mapper) ?? [],
    dutyEntry: orgContact?.dutyEntry ?? null,
    jobTitle,
    jobTitleOther: orgContact?.jobTitleOther ?? null,
    rcFunctions: orgContact?.rcFunctions?.map(mapper) ?? [],
    areaOther: orgContact?.areaOther ?? null,
    mainRCOContact: orgContact?.mainRCOContact ?? false,
    uncgLead: orgContact?.uncgLead ?? false,
    teamLead: orgContact?.teamLead ?? false,
    commsRCOContact: orgContact?.commsRCOContact ?? false
  };
  return newObj;
};

const ContactForm: React.FC<{
  workspaceOrganizationId: number;
  workspaceOrganization: WorkspaceOrganization;
  workspace: Workspace;
  mainRcoContact?: WorkspaceOrganizationContact | null;
  reload?: (close?: boolean) => Promise<void>;
  existingContact?: WorkspaceOrganizationContact;
  contactTypes: string[];
  onCancel?: () => void;
  blockContactAdding?: boolean;
  defaults?: { jobTitle?: DataItem };
  onDeleteContactClick?: (contact: WorkspaceOrganizationContact) => void;
  customFormType?: 'UNIC' | 'UNDCO';
}> = ({
  workspaceOrganizationId,
  workspaceOrganization,
  workspace,
  mainRcoContact,
  reload,
  existingContact,
  contactTypes,
  defaults,
  blockContactAdding,
  onCancel,
  onDeleteContactClick,
  customFormType
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'unEntities' });
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [isSaving, setIsSaving] = React.useState(false);
  const [isAddingNewContact, setIsAddingNewContact] = React.useState(false);
  const [baseContact, setBaseContact] = React.useState<Contact>();

  const methods = useForm({
    defaultValues: buildDefaultValues(workspace, existingContact, defaults)
  });
  const { watch, reset, setValue } = methods;

  const contact = watch('contact');

  const loadInContact = React.useCallback(
    async (loadingContact?: WorkspaceOrganizationContact | null) => {
      if (loadingContact) {
        if (isWorkspaceOrganizationContact(loadingContact)) {
          const newOrgContact = await searchOrganizationContacts({
            contactIds: [loadingContact.id],
            workspaceOrganizationIds: [workspaceOrganizationId]
          });

          const currentContact = await fetchContactById(loadingContact.id);
          setBaseContact(currentContact);

          // eslint-disable-next-line prefer-destructuring
          loadingContact.organizationContact =
            newOrgContact.find(
              noc => noc.id === loadingContact?.organizationContact.id
            ) ?? newOrgContact[0];

          loadingContact = {
            ...loadingContact,
            ...currentContact
          };
        }
      }
      reset(buildDefaultValues(workspace, loadingContact, defaults));
    },
    [reset, workspaceOrganizationId, workspace, defaults]
  );

  React.useEffect(() => {
    // @ts-ignore
    // localInputValue gets set for a new contact by autocomplete
    if (contact?.localInputValue) {
      setIsAddingNewContact(true);
    } else {
      setIsAddingNewContact(false);
    }
  }, [contact]);

  React.useEffect(() => {
    if (isAddingNewContact) {
      // @ts-ignore
      setValue('name', contact?.localInputValue?.trim());
      setValue('type', 'Entity representative');
    } else {
      setValue('name', contact?.name);
      setValue('email', contact?.email);
      setValue('gender', (contact?.gender && mapper(contact?.gender)) ?? null);
      setValue('nationality', contact?.nationality);
    }
  }, [contact, setValue, isAddingNewContact]);

  React.useEffect(() => {
    loadInContact(existingContact);
  }, [existingContact, loadInContact]);

  React.useEffect(() => {
    const setBasicContactDetails = async (id: number) => {
      const selectedContact = await fetchContactById(id);
      setValue('name', selectedContact?.name);
      setValue('email', selectedContact?.email);
      setValue(
        'gender',
        (selectedContact?.gender && mapper(selectedContact?.gender)) ?? null
      );
      setValue('nationality', selectedContact?.nationality);
      setBaseContact(selectedContact);
    };
    // @ts-ignore
    if (!existingContact && contact && !contact.localInputValue) {
      setBasicContactDetails(contact.id);
    }
  }, [contact, existingContact, setValue]);

  const fetchContact = React.useCallback(async (inputValue: string) => {
    const response = await autocomplete('contact', {
      simple: true,
      q: inputValue,
      limit: 10
    });
    return response;
  }, []);

  const onSave = React.useCallback(
    async (data: FormValues) => {
      if (data.contact) {
        setIsSaving(true);
        try {
          const teamLead =
            data.mainRCOContact ||
            (data.jobTitle?.id === 213 &&
              workspaceOrganization.organization?.id === 50);

          const commsRCOContact =
            (workspaceOrganization.organization?.id === 56 &&
              workspaceOrganization.integrated &&
              data.type === 'Entity representative') ||
            (data.jobTitle?.id === 217 &&
              workspaceOrganization.organization?.id === 50);

          let contactId = data.contact.id;
          const values = {
            mainRCOContact: data.mainRCOContact,
            teamLead,
            commsRCOContact,
            officeCity: data.officeCity ?? undefined,
            officeType: data.officeType ?? undefined,
            dutyEntry: data.dutyEntry ?? undefined,
            financingOther: data.financingOther ?? undefined,
            inKindPersonnelContribution: data?.inKindPersonnelContribution
              ? data.inKindPersonnelContribution?.replace?.(/( |,|\$)/g, '')
              : undefined,
            areaOther: data.areaOther ?? undefined,
            uncgLead: data.uncgLead ?? undefined,
            type: data.type ?? undefined,
            postLevelId: data.postLevel?.id ?? undefined,
            jobTitleId: data.jobTitle?.id ?? undefined,
            jobTitleOther: data.jobTitleOther ?? undefined,
            contributionKindId: data.contributionKind?.id ?? undefined,
            financingIds: data.financings?.map(f => f.id),
            contractModalityId: data.contractModality?.id,
            areaIds: data.areas?.map(a => a.id),
            rcFunctionIds: data.rcFunctions?.map(f => f.id)
          };

          if (isAddingNewContact) {
            const exists = await fetchContacts({
              email: data.email ?? ''
            });

            if (exists.length > 0) {
              await confirm({
                description: `There is another contact record (id ${exists[0].id}, ${exists[0].name}) 
                with the same email address and country.  Contacts should 
                not normally be duplicated unless they represent multiple 
                entities.  Do you want to proceed with saving this record?`
              });
            }
            const newContact = await addContact({
              name: data.name ?? '',
              email: data.email ?? '',
              genderId: data.gender?.id,
              nationalityId: data.nationality?.id
            });
            contactId = newContact.id;
          }
          const existed = existingContact && existingContact.id === contactId;
          if (existed) {
            await editContactToOrganization(
              existingContact.organizationContact.id,
              {
                contactId,
                workspaceOrganizationId,
                ...values
              }
            );
          } else {
            await addContactToOrganization(
              contactId,
              workspaceOrganizationId,
              values
            );
          }
          await reload?.(!existed);
          setIsAddingNewContact(false);
          enqueueSnackbar('Contact saved', {
            variant: 'success',
            preventDuplicate: true
          });
        } catch (e) {
          if ((e as any)?.status === 409) {
            enqueueSnackbar(
              'Contact already exists for this workspace/organization/contact/type',
              {
                variant: 'warning',
                autoHideDuration: 5000,
                preventDuplicate: true
              }
            );
          } else {
            enqueueSnackbar(
              'A problem occurred creating the organization contact link',
              {
                variant: 'error',
                autoHideDuration: 5000,
                preventDuplicate: true
              }
            );
          }
          console.error(e);
        } finally {
          setIsSaving(false);
        }
      }
    },
    [
      isAddingNewContact,
      existingContact,
      reload,
      workspaceOrganizationId,
      enqueueSnackbar
    ]
  );

  const deleteContactFromEntity = React.useCallback(
    async (contactToDelete: WorkspaceOrganizationContact) => {
      try {
        await confirm({
          description: t('areYouSureDelete')
        });
        setIsSaving(true);
        if (onDeleteContactClick) {
          await onDeleteContactClick(contactToDelete);
          await reload?.(true);
          enqueueSnackbar('Deleted', {
            variant: 'success',
            preventDuplicate: true
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsSaving(false);
      }
    },
    [onDeleteContactClick, confirm]
  );

  const setSaving = React.useCallback(
    (value: boolean) => {
      setIsSaving(value);
    },
    [setIsSaving]
  );

  const addingExistingContact =
    // @ts-ignore
    !existingContact && contact && !contact.localInputValue;

  const updateContact = React.useCallback(
    async (c: Contact) => {
      setValue('contact', c);
    },
    [setValue]
  );

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={methods.handleSubmit(onSave)}
        style={{ marginTop: '1rem' }}
      >
        <Grid container spacing={2}>
          {blockContactAdding && (
            <Grid
              item
              xs={12}
              style={{ background: 'bisque', padding: '1rem' }}
            >
              Please the save the changes made to the UN entity&apos;s details
              before continuing.
            </Grid>
          )}
          <Grid item xs={12} style={{ paddingTop: '.75rem' }}>
            <Controller
              name="contact"
              render={({ onChange, ref, ...props }) => (
                <Autocomplete
                  fetchOptionsCallback={(e: string) => fetchContact(e)}
                  onChange={(_event: unknown, data: Contact) => onChange(data)}
                  fullWidth
                  disabled={!!existingContact || blockContactAdding}
                  label={t('addNewContactToEntity')}
                  required
                  optionToAdd
                  {...props}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: '1rem' }}>
          {(contact || isAddingNewContact) && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Organizational contact details
              </Typography>
            </Grid>
          )}
          {existingContact && (
            <>
              <Grid item xs={12}>
                Email: {baseContact?.email ?? existingContact.email}
                {onDeleteContactClick && (
                  <>
                    <Typography variant="subtitle2" gutterBottom>
                      Note: If the contact is no longer in this position, please
                      click this button to remove them from this position.
                    </Typography>
                    <PermissionGate
                      workspaceIds={[workspace.id]}
                      regionIds={[workspace.regionId]}
                      scopes="organizationContact_softDelete"
                    >
                      <Button
                        style={{ marginLeft: '0rem', marginBottom: '.75rem' }}
                        onClick={() => deleteContactFromEntity(existingContact)}
                        isLoading={isSaving}
                        disabled={blockContactAdding}
                        variant="outlined"
                      >
                        Contact has left position
                      </Button>
                    </PermissionGate>
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
        {contact && isAddingNewContact && (
          <Grid container spacing={1} style={{ marginTop: '1rem' }}>
            <ContactBasicForm />
          </Grid>
        )}
        {(contact || isAddingNewContact) && (
          <ContactOrganizationForm
            contactTypes={contactTypes}
            customFormType={customFormType}
            mainRcoContact={mainRcoContact}
          />
        )}
        {(contact || isAddingNewContact) && (
          <Grid container style={{ marginTop: '.5rem' }}>
            <PermissionGate
              workspaceIds={[workspace.id]}
              regionIds={[workspace.regionId]}
              scopes={[
                'organizationContact_update',
                'organizationContact_create'
              ]}
            >
              <Grid item xs={12}>
                <Button
                  style={{ marginBottom: '.75rem' }}
                  type="submit"
                  isLoading={isSaving}
                  disabled={blockContactAdding}
                >
                  Save
                </Button>
                {onCancel && (
                  <Button
                    style={{ marginBottom: '.75rem' }}
                    onClick={() => {
                      setIsAddingNewContact(false);
                      setValue('contact', null);
                      onCancel();
                    }}
                    variant="outlined"
                    isLoading={isSaving}
                  >
                    Cancel
                  </Button>
                )}
              </Grid>
            </PermissionGate>
          </Grid>
        )}
      </Form>
      {contact && !isAddingNewContact && baseContact && (
        <>
          <Divider
            sx={{
              borderBottomWidth: 2,
              background: '#00000030',
              marginTop: 4
            }}
          />

          <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <Typography variant="subtitle1" gutterBottom>
              Personal contact details
            </Typography>
          </Grid>
          <ContactBasicFormBuilder
            contact={baseContact}
            hasOrganizationContact={!!existingContact}
            isLoading={isSaving}
            setLoading={setSaving}
            disabled={blockContactAdding}
            setContact={addingExistingContact ? updateContact : undefined}
            reload={reload && !addingExistingContact ? reload : undefined}
          />
        </>
      )}
    </FormProvider>
  );
};

export default ContactForm;

import { Checkbox } from '@mui/material';
import React from 'reactn';

const CheckboxCell = ({
  cell: { value }
}: ReactTableCell<boolean, any>): React.ReactElement => (
  <Checkbox checked={value} disabled />
);

export default CheckboxCell;

/* eslint-disable import/prefer-default-export */
/**
 * figure out item list to create and item list to delete
 * from original item list and modified item list
 *
 * @param {*} modifiedList
 * @param {*} originalList
 */
export const manyToManyUpdate = ({ modifiedList, originalList }) => {
  const toCreate = [];
  const toDelete = [];
  modifiedList.forEach(item => {
    if (!item.id) {
      toCreate.push(item);
    }
  });
  originalList.forEach(item => {
    const found = modifiedList.find(s => s.id === item.id);
    if (!found) {
      toDelete.push(item);
    }
  });
  return { toCreate, toDelete };
};

import settings from 'config';
import Api from '../utils/Api';
import { getAuthHeader } from './AuthService';

const FILE_URL = `${settings.API_URL}${settings.API_VERSION}/file`;

export const upload = (file: Blob | string, name: string): Promise<void> => {
  const formData = new FormData();
  formData.append('files', file);
  formData.append('name', name);
  return Api.post(`${FILE_URL}`, formData, getAuthHeader()).then(
    res => res.data
  );
};

export default upload;

import React, { ReactElement } from 'react';
import { Controller, useFormContext, Validate } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MoreInfoPopover from 'components/common/MoreInfoPopover';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

interface CheckboxProps {
  name: string;
  label: ReactElement | string;
  hideLabel?: boolean;
  rules?: {
    required?: boolean;
    validate?: Validate;
  };
  moreInfo?: string;
  defaultValue?: boolean;
  disabled?: boolean;
}

/**
 * A Checkbox component that works specifically with react-hook-form
 */
export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  rules,
  moreInfo,
  hideLabel,
  defaultValue,
  disabled = false
}) => {
  const { control, errors } = useFormContext();
  return (
    <>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={rules}
        render={({ onChange, onBlur, value, name: renderName }) => (
          <>
            <FormControlLabel
              control={
                <MuiCheckbox
                  onBlur={onBlur}
                  onChange={e => onChange(e.target.checked)}
                  checked={value}
                  name={renderName}
                  disabled={disabled}
                />
              }
              label={!hideLabel ? label : ''}
            />
            {moreInfo && <MoreInfoPopover>{moreInfo}</MoreInfoPopover>}
          </>
        )}
      />
      <Grid item xs={12}>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <Alert severity="error">{message}</Alert>}
        />
      </Grid>
    </>
  );
};

export default Checkbox;

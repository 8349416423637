import React, { useGlobal } from 'reactn';
import { format } from 'date-fns';
import { union, truncate } from 'lodash';
import { Link } from 'react-router-dom';

import Badge from '@mui/material/Badge';
import Notifications from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { withStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import { userFullName } from 'utils/user';
import { DATE_FORMAT } from 'utils/constants';
import { fetchPlans } from 'services/PlanService';
import {
  deleteAppNotification,
  fetchAppNotifications
} from 'services/AppNotificationService';

const useStyles = makeStyles(() => ({
  menu: {
    maxWidth: '380px',
    maxHeight: '500px'
  }
}));

interface PlanMap {
  [key: number]: Plan;
}

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary, & .MuiListItemText-secondary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem) as typeof MenuItem; // using withStyles loses the type of MenuItem, so
// we need to cast it back to MenuItem type. Not ideal, but https://github.com/mui-org/material-ui/issues/15695

const NotificationPopUp: React.FC = () => {
  const classes = useStyles();
  const [user] = useGlobal('user');
  const [notifications, setNotifications] = React.useState<AppNotification[]>(
    []
  );
  const [planMap, setPlanMap] = React.useState<PlanMap>({});
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const fetchAppNotificationsCallback = React.useCallback(async () => {
    try {
      if (user) {
        const fetchedNotifications = await fetchAppNotifications({
          userIds: [user.id]
        });

        const planIds = fetchedNotifications.reduce<number[]>(
          (set, notification) => {
            const ePPlanId = notification.comment.entityPrototype?.planId;
            const submissionPlanId =
              notification.comment.planSubmission?.planId;
            return union(
              set,
              submissionPlanId ? [submissionPlanId] : [],
              ePPlanId ? [ePPlanId] : []
            );
          },
          []
        );

        const commentPlans = await fetchPlans({
          planIds,
          exclude: [
            'workspace',
            'blueprint',
            'planTags',
            'assessments',
            'entityPrototypes',
            'planSubmissions'
          ]
        });

        setPlanMap(
          commentPlans.results.reduce<PlanMap>((agg, plan) => {
            return {
              ...agg,
              [plan.id]: plan
            };
          }, {})
        );

        setNotifications(fetchedNotifications.filter(n => n.comment.comment));
      }
    } catch (e) {
      console.error(e);
    }
  }, [user]);

  React.useEffect(() => {
    fetchAppNotificationsCallback();
  }, [fetchAppNotificationsCallback]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClickMarkAsRead = async (notification: AppNotification) => {
    await deleteAppNotification(notification.id);
    setTimeout(() => {
      fetchAppNotificationsCallback();
    }, 1000);
  };

  return (
    <div>
      <IconButton
        aria-label="show no new notifications"
        color="inherit"
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <Badge badgeContent={notifications.length} color="secondary">
          <Notifications fontSize="large" color="secondary" />
        </Badge>
      </IconButton>

      <Menu
        className={classes.menu}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {notifications.length === 0 && (
          <StyledMenuItem>
            <ListItemText primary="No new notifications" />
          </StyledMenuItem>
        )}
        {notifications.length > 0 &&
          notifications.map(notification => {
            const { comment } = notification;
            const entity =
              comment.entityPrototype ??
              comment.planSubmission?.entityPrototype;

            const name = entity
              ? planMap[entity.planId]?.planVersion?.name
              : '';
            const date = format(
              new Date(comment.createdAt),
              `${DATE_FORMAT} p`
            );
            const secondaryText = (
              <>
                {date}
                <br />
                {userFullName(comment.user)} - {name}: {entity?.code}
              </>
            );

            const step = entity?.id;
            const formTagId = comment.planSubmission
              ? `#formTag-${comment.planSubmission.formTagId}`
              : undefined;

            return (
              <StyledMenuItem
                component={Link}
                to={`/dashboard/plan/${entity?.planId}/build/prototype/${step ??
                  ''}${formTagId ?? '#stepComments'}`}
                key={comment.id}
              >
                <ListItemText
                  primary={truncate(comment.comment, { length: 100 })}
                  primaryTypographyProps={{ noWrap: true }}
                  secondaryTypographyProps={{ noWrap: true }}
                  secondary={secondaryText}
                />
                <Tooltip title="Mark as read">
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleClickMarkAsRead(notification);
                    }}
                    size="large"
                  >
                    <CheckBoxOutlineBlank />
                  </IconButton>
                </Tooltip>
              </StyledMenuItem>
            );
          })}
      </Menu>
    </div>
  );
};

export default NotificationPopUp;

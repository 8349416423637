import React, { useCallback, useState, useGlobal, useEffect } from 'reactn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { Autocomplete } from 'components/common/AutoComplete';

import { useConfirm } from 'material-ui-confirm';

import { Button } from 'uninfo-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from 'components/common/Accordion';
import {
  Alert,
  Avatar,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  Theme
} from '@mui/material';
import { Trans } from 'react-i18next';
import {
  addUserRoles,
  deleteUserRoles,
  fetchRoles
} from 'services/RolesService';
import { fetchRegions, fetchWorkspaces } from 'services/WorkspaceService';
import autocomplete from 'services/AutocompleteService';
import { GroupAdd } from '@mui/icons-material';
import ButtonsText from 'components/common/form/ButtonsText';
import PaperWrapper from 'components/common/PaperWrapper';
import useLoadingAnimation from 'utils/useLoadingAnimation';
import { useTableRefreshContext } from 'components/table/TableRefreshProvider';

const ALL = 'ALL';

// TODO: move to a dedicated file
const PERMISSION_USERROLE_SEARCH = 'userRole_search';
const PERMISSION_ROLE_BATCHASSIGN = 'userRole_batchAssign';
const PERMISSION_ROLE_ASSIGN = 'userRole_assign';
const PERMISSION_ROLE_UNASSIGN = 'userRole_batchUnassign';
const PERMISSION_USER_UPDATE = 'user_update';
const PERMISSION_USER_DELETE = 'user_softDelete';

const CFPO_SHORT = 'CFPO';

const CP_DOMAIN = 4;
const CPC_ROLE_SHORT = 'CPC'; // id 12
const ROLES_CPC_CAN_ASSIGN = ['AFP', 'CPWGC', 'PVFP', 'CPV'];
const ROLE_IDS_CPC_CAN_ASSIGN = [12, 14, 16, 18];

const IMS_DOMAIN = 5;
const IMS_TEAM_LEAD_ROLE_SHORT = 'IMSTL'; // id 7
const IMS_DMO_ROLE_SHORT = 'IMSDMO'; // id 9

const ROLES_IMS_TEAM_LEAD_CAN_ASSIGN = ['IMSDMO', 'IMSE', 'IMSV'];
const ROLES_IMS_DMO_CAN_ASSIGN = ['IMSE', 'IMSV'];

const ROLE_IDS_IMS_TEAM_LEAD_CAN_ASSIGN = [9, 5, 10];
const ROLE_IDS_IMS_DMO_CAN_ASSIGN = [5, 10];

const useUserFormStyles = makeStyles((theme: Theme) => ({
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  saveButton: {
    marginTop: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(0, 1),
    color: 'black'
  },
  wrapIcon: {
    alignItems: 'center',
    display: 'flex'
  }
}));

interface DisplayUserRole extends NewUserRole {
  id?: number;
  composedKey: string;
}

const getComposedKey = <T, S extends keyof T>(r: T, props: S[]) => {
  return props.map(p => r[p]).join('-');
};

const composedKeys: (keyof NewUserRole)[] = [
  'roleId',
  'contextRegionId',
  'contextWorkspaceId'
];

const addComposedKey = (role: UserRole[]) => {
  return role.map((r: UserRole) => {
    return {
      ...r,
      composedKey: getComposedKey(r, composedKeys)
    };
  });
};

const removeDuplicates = (role: DisplayUserRole[]) => {
  return role.reduce((acc, current) => {
    const x = acc.find(item => item.composedKey === current.composedKey);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, [] as DisplayUserRole[]);
};

const getRolesWithAssignementPermission = (user: User) => {
  const userRolesWithAssignementPermission = user.userRoles.filter(r => {
    return r.role.rolePermissions
      .map(rp => rp.permission)
      .find(
        p =>
          p?.key === PERMISSION_ROLE_ASSIGN ||
          p?.key === PERMISSION_ROLE_BATCHASSIGN
      );
  });
  return userRolesWithAssignementPermission;
};

const filterRoleOptions = (roles: Role[], loggedInUser: User | undefined) => {
  if (loggedInUser?.isAdmin) {
    return roles;
  }
  if (!loggedInUser) return [];

  const rolesWithAssignementPermission: UserRole[] = getRolesWithAssignementPermission(
    loggedInUser
  );
  const shouldHaveAllCPDomainRoles = rolesWithAssignementPermission
    .filter(r => r.role.domainId === CP_DOMAIN)
    .find(r => r.role.short !== CPC_ROLE_SHORT);
  const shouldHaveAllIMSDomainRoles = rolesWithAssignementPermission
    .filter(r => r.role.domainId === IMS_DOMAIN)
    .find(
      r =>
        r.role.short !== IMS_TEAM_LEAD_ROLE_SHORT &&
        r.role.short !== IMS_DMO_ROLE_SHORT
    );
  const isImsTeamLead = rolesWithAssignementPermission
    .filter(r => r.role.domainId === IMS_DOMAIN)
    .find(r => r.role.short === IMS_TEAM_LEAD_ROLE_SHORT);
  const isImsDmo = rolesWithAssignementPermission
    .filter(r => r.role.domainId === IMS_DOMAIN)
    .find(r => r.role.short === IMS_DMO_ROLE_SHORT);

  const iSCFPO = rolesWithAssignementPermission.find(
    r => r.role.short === CFPO_SHORT
  );

  const domainIds = rolesWithAssignementPermission.map(r => r.role.domainId);

  return roles.filter((r: any) => {
    if (domainIds.includes(r.domainId)) {
      if (r.domainId === CP_DOMAIN && !shouldHaveAllCPDomainRoles) {
        if (ROLES_CPC_CAN_ASSIGN.includes(r.short)) {
          return true;
        }
        return false;
      }
      if (r.domainId === IMS_DOMAIN && !shouldHaveAllIMSDomainRoles) {
        if (
          (isImsTeamLead && ROLES_IMS_TEAM_LEAD_CAN_ASSIGN.includes(r.short)) ||
          (isImsDmo && ROLES_IMS_DMO_CAN_ASSIGN.includes(r.short))
        ) {
          return true;
        }
        return false;
      }
      if (!iSCFPO && r.short === CFPO_SHORT) {
        return false;
      }
      // all domain roles
      return true;
    }
    // logged in user doesnt have any domain admin roles
    return false;
  });
};

const filterOptionsWithIds = <T extends { id: number }>(
  options: T[],
  ids: (number | null)[]
) => {
  if (ids.includes(null)) {
    return options;
  }
  return options.filter((o: T) => {
    return ids.includes(o.id);
  });
};

const shouldSkipNullScope = (
  userRole: UserRole,
  context: 'contextWorkspaceId' | 'contextRegionId'
) => {
  if (context === 'contextWorkspaceId') {
    return !userRole.contextWorkspaceId && userRole.contextRegionId;
  }
  if (context === 'contextRegionId') {
    return userRole.contextWorkspaceId && !userRole.contextRegionId;
  }
  return false;
};

const getUniqueRoleScopes = ({
  context,
  loggedInUser,
  domainId,
  roleShort
}: {
  context: 'contextWorkspaceId' | 'contextRegionId';
  loggedInUser: User;
  domainId: number;
  roleShort: string;
}) => {
  const rolesWithAssignementPermission: UserRole[] = getRolesWithAssignementPermission(
    loggedInUser
  ).filter(
    r => r.role.domainId === domainId && !shouldSkipNullScope(r, context)
  );
  const roles = rolesWithAssignementPermission.filter(
    r => r.role.short === roleShort
  );
  const otherRoles = rolesWithAssignementPermission.filter(
    r => r.role.short !== roleShort
  );
  const roleScopes = roles.map(r => r[context]);
  const otherRoleScopes = otherRoles.map(r => r[context]);
  const uniqueRoleScopes = roleScopes.filter(x => !otherRoleScopes.includes(x));
  return uniqueRoleScopes;
};

const filterRoleRestrictedScopeIds = ({
  ids,
  role,
  context,
  loggedInUser
}: {
  ids: number[];
  role: Role;
  context: 'contextWorkspaceId' | 'contextRegionId';
  loggedInUser: User;
}) => {
  if (
    role.domainId === CP_DOMAIN &&
    !ROLES_CPC_CAN_ASSIGN.includes(role.short)
  ) {
    const cpcUniqueScopes = getUniqueRoleScopes({
      loggedInUser,
      context,
      domainId: CP_DOMAIN,
      roleShort: CPC_ROLE_SHORT
    });
    if (cpcUniqueScopes.length) {
      return ids.filter(id => !cpcUniqueScopes.includes(id));
    }
  }
  if (role.domainId === IMS_DOMAIN && role.short === IMS_DMO_ROLE_SHORT) {
    const teamLeadUniqueScopes = getUniqueRoleScopes({
      loggedInUser,
      context,
      domainId: IMS_DOMAIN,
      roleShort: IMS_TEAM_LEAD_ROLE_SHORT
    });
    if (teamLeadUniqueScopes.length) {
      return ids.filter(id => teamLeadUniqueScopes.includes(id));
    }
  }
  return ids;
};

const getDomainContextChoices = (loggedInUser: User | undefined) => {
  if (!loggedInUser) return {};

  const choices: { [index: string]: any } = {};

  const domainContextChoices = getRolesWithAssignementPermission(
    loggedInUser
  ).reduce((contextChoices, userRole) => {
    const skipNullWorkspace =
      !userRole.contextWorkspaceId && userRole.contextRegionId;
    const skipNullRegion =
      userRole.contextWorkspaceId && !userRole.contextRegionId;
    if (!contextChoices[userRole.contextDomainId ?? ALL]) {
      contextChoices[userRole.contextDomainId ?? ALL] = {
        workspaceIds: skipNullWorkspace
          ? new Set()
          : new Set([userRole.contextWorkspaceId]),
        regionIds: skipNullRegion
          ? new Set()
          : new Set([userRole.contextRegionId])
      };
    } else {
      contextChoices[userRole.contextDomainId ?? ALL] = {
        workspaceIds: skipNullWorkspace
          ? contextChoices[userRole.contextDomainId ?? ALL].workspaceIds
          : contextChoices[userRole.contextDomainId ?? ALL].workspaceIds.add(
              userRole.contextWorkspaceId
            ),
        regionIds: skipNullRegion
          ? contextChoices[userRole.contextDomainId ?? ALL].regionIds
          : contextChoices[userRole.contextDomainId ?? ALL].regionIds.add(
              userRole.contextRegionId
            )
      };
    }
    return contextChoices;
  }, choices);

  return domainContextChoices;
};

const getLoggedInUserPermissions = (user: User) => {
  const userPermissions = user.userRoles
    .flatMap(ur => ur.role.rolePermissions)
    .map(rp => rp.permission);
  const { isAdmin } = user;
  return {
    userRoleSearch:
      isAdmin ||
      !!userPermissions.find(up => up?.key === PERMISSION_USERROLE_SEARCH),
    userRoleAssign:
      isAdmin ||
      !!userPermissions.find(
        up =>
          up?.key === PERMISSION_ROLE_ASSIGN ||
          up?.key === PERMISSION_ROLE_BATCHASSIGN
      ),
    userRoleUnassign:
      isAdmin ||
      !!userPermissions.find(up => up?.key === PERMISSION_ROLE_UNASSIGN)
  };
};

const canRoleBeEditedByUser = (
  role: DisplayUserRole,
  currentRoleDomainOptions: any
) => {
  let canEdit = false;

  if (!currentRoleDomainOptions) {
    return false;
  }
  if (role.contextRegionId) {
    canEdit =
      currentRoleDomainOptions.regionIds.has(role.contextRegionId) ||
      currentRoleDomainOptions.regionIds.has(null);
  }

  if (role.contextWorkspaceId) {
    canEdit =
      currentRoleDomainOptions.workspaceIds.has(role.contextWorkspaceId) ||
      currentRoleDomainOptions.workspaceIds.has(null);
  }

  if (!role.contextRegionId && !role.contextWorkspaceId) {
    canEdit =
      currentRoleDomainOptions.workspaceIds.has(null) &&
      currentRoleDomainOptions.regionIds.has(null);
  }

  return canEdit;
};

const addEditableProperty = (
  userRole: DisplayUserRole,
  loggedInUser: User | undefined,
  domainContextChoices: any
) => {
  return {
    ...userRole,
    editable:
      loggedInUser?.isAdmin ||
      (canRoleBeEditedByUser(
        userRole,
        domainContextChoices[userRole.contextDomainId ?? ALL]
      ) &&
        filterRoleOptions([userRole.role], loggedInUser).length)
  };
};

const UserRoleInfo = ({
  userRole,
  workspaceNameLookup,
  regionNameLookup
}: {
  userRole: DisplayUserRole;
  workspaceNameLookup: { [key: number]: string };
  regionNameLookup: { [key: number]: string };
}): JSX.Element => {
  return (
    <>
      {userRole?.role?.name}
      {': '}
      {!userRole?.contextWorkspaceId && !userRole?.contextRegionId
        ? ' all'
        : ''}
      {userRole?.contextWorkspaceId &&
        workspaceNameLookup[userRole?.contextWorkspaceId]}
      {userRole?.contextRegionId && regionNameLookup[userRole?.contextRegionId]}
    </>
  );
};

const useChipStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.2)
    }
  }
}));

interface RolePermissionChipsProps {
  permissions?: RolePermission[];
  domain?: string;
}

const PermissionChips: React.FC<RolePermissionChipsProps> = ({
  permissions,
  domain
}) => {
  const classes = useChipStyles();
  if (permissions && permissions.length) {
    return (
      <>
        <div className={classes.root}>
          {domain && <Chip size="small" label={domain} />}
          {permissions.map(rolePermission => {
            return (
              <Chip
                key={rolePermission.id}
                size="small"
                label={rolePermission?.permission?.description}
              />
            );
          })}
        </div>
      </>
    );
  }
  return null;
};

const RoleInfo = ({
  currentRoleSelected,
  domains
}: {
  currentRoleSelected: Role | null;
  domains:
    | {
        [key: string]: any;
      }[]
    | null;
}): JSX.Element | null => {
  const domainString = `Domain: ${domains?.find(
    d => d.domainId === currentRoleSelected?.domainId
  )?.name ??
    currentRoleSelected?.domainId ??
    '(all)'}`;

  if (!currentRoleSelected) return null;

  return (
    <PaperWrapper>
      <Grid container direction="row" alignItems="center">
        <Grid item sx={{ marginRight: '5px' }}>
          <InfoOutlinedIcon fontSize="small" />
        </Grid>
        <Grid item>
          <Typography>Information about the selected role</Typography>
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom component="div">
        <b>{currentRoleSelected?.name}</b>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Predefined permissions:
      </Typography>
      <PermissionChips
        permissions={currentRoleSelected?.rolePermissions}
        domain={domainString}
      />
      <br />
      <Typography variant="body1" gutterBottom>
        Notes:
        <br />
        Specify a workspace or region to restrict access to this role
      </Typography>
    </PaperWrapper>
  );
};

type UserRoleFormInputs = {
  role: Role | null;
  workspace: Workspace | null;
  workspaces: Workspace[] | null;
  region: Region | null;
};

interface UserRolesFormProps {
  user: User;
  roles: UserRole[];
}

export const UserRolesForm: React.FC<UserRolesFormProps> = ({
  user,
  roles
}) => {
  const classes = useUserFormStyles();
  const [loading, setLoading] = useState(false);
  const [deleteSelectedLoading, setDeleteSelectedLoading] = useState(false);
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);
  const [saveAllLoading, setSaveAllLoading] = useState(false);
  const [saveSelectedLoading, setSaveSelectedLoading] = useState(false);

  const {
    isLoading,
    startLoading,
    stopLoading,
    FullScreenLoading
  } = useLoadingAnimation();

  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const [loggedInUser] = useGlobal('user');
  const { dispatch: dispatchRefresh } = useTableRefreshContext();

  let loggedInUserPermissions: { [index: string]: boolean } = {};
  if (loggedInUser) {
    loggedInUserPermissions = getLoggedInUserPermissions(loggedInUser);
  }

  const methods = useForm<UserRoleFormInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      role: null,
      workspace: null,
      workspaces: [],
      region: null
    }
  });
  const {
    control,
    errors,
    trigger,
    getValues,
    watch,
    handleSubmit,
    reset,
    setValue
  } = methods;

  const [roleList, setRoleList] = useState<any[]>(addComposedKey(roles));
  const [roleDisplayList, setRoleDisplayList] = useState<any[]>(
    removeDuplicates(addComposedKey(roles))
  );
  const [checkedRoles, setCheckedRoles] = React.useState<string[]>([]);
  const handleRoleCheck = (checked: boolean, key: string) => {
    if (checked) {
      setCheckedRoles([...checkedRoles, key]);
    } else {
      setCheckedRoles([...checkedRoles.filter(r => r !== key)]);
    }
  };
  const [domainContextChoices, setDomainContextChoices] = useState<{
    [index: string]: any;
  }>({});
  React.useEffect(() => {
    setDomainContextChoices(getDomainContextChoices(loggedInUser));
  }, [loggedInUser]);

  const filterRoleOptionsCb = React.useCallback(
    options => {
      if (loggedInUser) {
        return filterRoleOptions(options, loggedInUser);
      }
      return [];
    },
    [loggedInUser]
  );

  const filterWorkspaceOptionsCb = React.useCallback(
    options => {
      if (!loggedInUser) return [];
      const role: Role | null = getValues('role');
      if (!role || loggedInUser.isAdmin) return options;
      const ids = filterRoleRestrictedScopeIds({
        ids: [...domainContextChoices[role.domainId ?? ALL].workspaceIds],
        role,
        context: 'contextWorkspaceId',
        loggedInUser
      });
      return filterOptionsWithIds(options, ids);
    },
    [domainContextChoices, loggedInUser]
  );

  const filterRegionOptionsCb = React.useCallback(
    options => {
      if (!loggedInUser) return [];
      const role: Role | null = getValues('role');
      if (!role || loggedInUser.isAdmin) return options;
      const ids = filterRoleRestrictedScopeIds({
        ids: [...domainContextChoices[role.domainId ?? ALL].regionIds],
        role,
        context: 'contextRegionId',
        loggedInUser
      });
      return filterOptionsWithIds(options, ids);
    },
    [domainContextChoices, loggedInUser]
  );

  const fetchedRolesCb = React.useCallback(async a => {
    return fetchRoles({
      globalFilter: a || undefined
    });
  }, []);

  const fetchedDomainsPure = useCallback(async inputValue => {
    const domains = await autocomplete<Domain>('domain', {
      q: inputValue,
      simple: false
    });

    const composedDomain = domains.reduce((prev: any, cur: any) => {
      // eslint-disable-next-line no-restricted-syntax
      prev.push({
        name: `${cur.name}`,
        domainId: cur.id,
        domain: { ...cur }
      });
      return prev;
    }, []);
    return composedDomain;
  }, []);

  const fetchRegionsCallbackPure = useCallback(async inputValue => {
    const regions = await fetchRegions({
      globalFilter: inputValue
    });

    const composedRegion = regions.reduce((prev: any, cur: any) => {
      // eslint-disable-next-line no-restricted-syntax
      prev.push({
        name: `${cur.name}`,
        regionId: cur.id,
        id: cur.id,
        region: { ...cur }
      });
      return prev;
    }, []);

    return composedRegion;
  }, []);

  const fetchedWorkspacesPure = useCallback(
    inputValue =>
      fetchWorkspaces({
        globalFilter: inputValue,
        workspaceIds: loggedInUser?.isAdmin ? null : null,
        exclude: [
          'contacts',
          'region',
          'countries',
          'organizations',
          'workspaceType',
          'parent',
          'children'
        ]
      }),
    [loggedInUser]
  );

  const addRoles = async () => {
    const notify = (message: string) => {
      enqueueSnackbar(message, {
        variant: 'info',
        autoHideDuration: 3000,
        preventDuplicate: true
      });
      setLoading(false);
    };

    try {
      const all = getValues();
      const { role } = all;
      if (!role) {
        enqueueSnackbar('Please specify a role', {
          variant: 'info',
          autoHideDuration: 3000,
          preventDuplicate: true
        });
        return;
      }

      if (all.role?.short === 'AFP' && !all.workspaces?.length) {
        enqueueSnackbar('Please specify a workspace ', {
          variant: 'info',
          autoHideDuration: 3000,
          preventDuplicate: true
        });
        return;
      }

      if (all.region && all.workspaces?.length) {
        enqueueSnackbar(
          'Please specify a workspace or region to restrict access to this role',
          {
            variant: 'warning',
            autoHideDuration: 3000,
            preventDuplicate: true
          }
        );
        return;
      }

      if (domainContextChoices && !loggedInUser?.isAdmin) {
        const currentRoleDomainOptions =
          domainContextChoices[role.domainId ?? ALL];
        const notAllowedContext =
          currentRoleDomainOptions &&
          (all.workspaces?.length
            ? !all.workspaces
                ?.map(w => w.id)
                .every(wid => currentRoleDomainOptions.workspaceIds.has(wid))
            : !currentRoleDomainOptions.workspaceIds.has(null)) &&
          !currentRoleDomainOptions.regionIds.has(all.region?.regionId ?? null);
        if (notAllowedContext) {
          const notAllowedWorkspaces = all.workspaces
            ?.filter(w => !currentRoleDomainOptions.workspaceIds.has(w.id))
            .map(nw => workspaces?.find(w => w.id === nw.id)?.name ?? null)
            .filter(r => r)
            .join(', ');
          enqueueSnackbar(
            `Not permitted: please specify an allowed workspace or region to restrict access to this role: ${notAllowedWorkspaces}`,
            {
              variant: 'warning',
              autoHideDuration: 3000,
              preventDuplicate: true
            }
          );
          return;
        }
      }

      const newRoleContexts: { type: string; id: number | null }[] = [];
      if (!all.region?.regionId && !all.workspaces?.length) {
        newRoleContexts.push({
          type: 'global',
          id: null
        });
      }
      if (all.region?.regionId) {
        newRoleContexts.push({
          type: 'region',
          id: all.region?.regionId
        });
      }
      if (all.workspaces?.length) {
        all.workspaces.forEach(w => {
          newRoleContexts.push({
            type: 'workspace',
            id: w.id
          });
        });
      }

      const newDbUserRoles: DisplayUserRole[] = [];
      const newDisplayUserRoles: DisplayUserRole[] = [];

      newRoleContexts.forEach(newRoleContext => {
        const newUserRole: DisplayUserRole = {
          userId: user.id,
          roleId: role.id,
          contextDomainId: role.domainId,
          contextOrganizationId: null,
          contextRegionId:
            newRoleContext.type === 'region' ? newRoleContext.id : null,
          contextRoleId: null,
          contextWorkspaceId:
            newRoleContext.type === 'workspace' ? newRoleContext.id : null,
          role,
          composedKey: ''
        };

        if (role.short === 'AFP') {
          newUserRole.contextOrganizationId = user.organization?.id;
        }

        if (
          ROLES_CPC_CAN_ASSIGN.includes(role.short) ||
          ROLES_IMS_DMO_CAN_ASSIGN.includes(role.short) ||
          ROLES_IMS_TEAM_LEAD_CAN_ASSIGN.includes(role.short)
        ) {
          newUserRole.contextRoleId = role.id;
        }

        newUserRole.composedKey = getComposedKey(newUserRole, composedKeys);

        const newUserRoles: DisplayUserRole[] = [];

        let contextRoleIds;
        if (role.short === CPC_ROLE_SHORT) {
          contextRoleIds = ROLE_IDS_CPC_CAN_ASSIGN;
        }
        if (role.short === IMS_TEAM_LEAD_ROLE_SHORT) {
          contextRoleIds = ROLE_IDS_IMS_TEAM_LEAD_CAN_ASSIGN;
        }
        if (role.short === IMS_DMO_ROLE_SHORT) {
          contextRoleIds = ROLE_IDS_IMS_DMO_CAN_ASSIGN;
        }

        if (contextRoleIds) {
          contextRoleIds.forEach((rid, i) => {
            if (i) {
              const newUserRoleCopy: DisplayUserRole = {
                ...newUserRole,
                contextRoleId: rid
              };
              newUserRoles.push(newUserRoleCopy);
            } else {
              newUserRole.contextRoleId = rid;
              newUserRoles.push(newUserRole);
            }
          });
        }

        if (!newUserRoles.length) {
          newUserRoles.push(newUserRole);
        }

        newDbUserRoles.push(...newUserRoles);
        newDisplayUserRoles.push(newUserRole);
      });

      const dublicateRoles = roleDisplayList.filter(rdl =>
        newDisplayUserRoles.map(r => r.composedKey).includes(rdl.composedKey)
      );

      if (dublicateRoles.length) {
        let contexts: string;
        if (
          dublicateRoles.filter(
            r => !r.contextWorkspaceId && !r.contextRegionId
          ).length
        ) {
          contexts = 'All';
        } else {
          const contextWorkspaceIds = dublicateRoles
            .map(r => r.contextWorkspaceId)
            .map(r => (r && workspaces?.find(w => w.id === r)?.name) ?? null)
            .filter(r => r);
          const contextRegionIds = dublicateRoles
            .map(r => r.contextRegionId)
            .map(dr => (dr && regions?.find(r => r.id === dr)?.name) ?? null)
            .filter(r => r);
          contexts = contextWorkspaceIds.concat(contextRegionIds).join(', ');
        }
        notify(
          `Role with such workspace and region already added: ${contexts}`
        );
        return;
      }

      setRoleList([...roleList, ...newDbUserRoles]);
      setRoleDisplayList([...roleDisplayList, ...newDisplayUserRoles]);

      enqueueSnackbar('Role added to the list, please save to commit', {
        variant: 'success',
        autoHideDuration: 3000,
        preventDuplicate: true
      });

      reset();
    } catch {
      setLoading(false);
    }
  };

  const saveRoles = async (saveSelected?: boolean) => {
    try {
      await confirm({
        title: `Are you sure you want to save the roles?`,
        description: (
          <>
            <Trans i18nKey="thisWillAddUserRoles">
              This will save the roles
            </Trans>
            <br />
            <br />
          </>
        )
      });

      startLoading();
      let newUserRoles;
      if (saveSelected) {
        setSaveSelectedLoading(true);
        newUserRoles = roleList.filter(
          r => !r.id && checkedRoles.includes(r.composedKey)
        );
      } else {
        setSaveAllLoading(true);
        newUserRoles = roleList.filter(r => !r.id);
      }

      if (!newUserRoles.length) {
        enqueueSnackbar('No user roles to save', {
          variant: 'success',
          autoHideDuration: 3000,
          preventDuplicate: true
        });
        setCheckedRoles([]);
        stopLoading();
        setSaveSelectedLoading(false);
        setSaveAllLoading(false);
        return;
      }

      const userRolesToSave = newUserRoles.map((userRoleToSave: UserRole) => {
        const userRoleCopyToSave = {
          roleId: userRoleToSave.roleId ?? null,
          userId: userRoleToSave.userId ?? null,
          contextDomainId: userRoleToSave.contextDomainId ?? null,
          contextWorkspaceId: userRoleToSave.contextWorkspaceId ?? null,
          contextRegionId: userRoleToSave.contextRegionId ?? null,
          contextRoleId: userRoleToSave.contextRoleId ?? null,
          contextOrganizationId: userRoleToSave.contextOrganizationId ?? null
        };

        return userRoleCopyToSave;
      });

      const results = await addUserRoles({ userRoles: userRolesToSave });
      const newRolesUpdatedWithComposedKey = addComposedKey(results.data);
      const newRolesUpdated = newUserRoles.map((r, i) => ({
        ...r,
        ...newRolesUpdatedWithComposedKey[i]
      }));

      const udpatedRoleList = roleList
        .filter(item => item.id)
        .concat(newRolesUpdated);
      const newDisplayRoleUpdated = removeDuplicates(newRolesUpdated);
      const updatedRoleDisplayList = roleDisplayList.map(item =>
        newDisplayRoleUpdated.find(r => r.composedKey === item.composedKey)
          ? newDisplayRoleUpdated.find(r => r.composedKey === item.composedKey)
          : item
      );
      setRoleList(udpatedRoleList);
      setRoleDisplayList(updatedRoleDisplayList);
      if (saveSelected) {
        setCheckedRoles([]);
      }

      enqueueSnackbar('User roles updated', {
        variant: 'success',
        autoHideDuration: 3000,
        preventDuplicate: true
      });
      dispatchRefresh({ type: 'setShouldRefresh', shouldRefresh: true });
      stopLoading();
      setSaveSelectedLoading(false);
      setSaveAllLoading(false);
    } catch {
      stopLoading();
      setSaveSelectedLoading(false);
      setSaveAllLoading(false);
    }
  };

  const deleteSelected = async (all?: boolean) => {
    try {
      await confirm({
        title: `Are you sure you want to remove the selected roles?`,
        description: (
          <>
            <Trans i18nKey="removeUserRole">
              This will remove the selected roles
            </Trans>
            <br />
            <br />
            This action is not reversible
          </>
        )
      });
      let userRolesToDelete = roleList;
      if (all) {
        setDeleteAllLoading(true);
        userRolesToDelete = roleList;
      } else {
        setDeleteSelectedLoading(true);
        userRolesToDelete = roleList.filter(
          r => r.id && checkedRoles.includes(r.composedKey)
        );
      }
      let error = false;
      if (domainContextChoices && !loggedInUser?.isAdmin) {
        userRolesToDelete.forEach((role, i) => {
          const currentRoleDomainOptions =
            domainContextChoices[role.contextDomainId ?? ALL];

          if (
            !currentRoleDomainOptions ||
            (!currentRoleDomainOptions.workspaceIds.has(
              role?.contextWorkspaceId ?? null
            ) &&
              !currentRoleDomainOptions.regionIds.has(
                role?.contextRegionId ?? null
              ))
          ) {
            enqueueSnackbar(
              'Not permitted: there is not enough workspace or region user permissions for this scope',
              {
                variant: 'warning',
                autoHideDuration: 3000,
                preventDuplicate: true
              }
            );
            error = true;
          }
        });
      }
      if (error) {
        setDeleteAllLoading(false);
        setDeleteSelectedLoading(false);
        return;
      }

      const userRolesToDeleteIds = userRolesToDelete.map(ur => ur.id);
      await deleteUserRoles({ userRoleIds: userRolesToDeleteIds });

      const updatedRoleList = all
        ? []
        : roleList.filter(ur => !checkedRoles.includes(ur.composedKey));
      const updatedRoleDisplayList = all
        ? []
        : roleDisplayList.filter(ur => !checkedRoles.includes(ur.composedKey));

      setRoleList(updatedRoleList);
      setRoleDisplayList(updatedRoleDisplayList);
      setCheckedRoles([]);

      dispatchRefresh({ type: 'setShouldRefresh', shouldRefresh: true });
      enqueueSnackbar(`The selected user roles deleted`, {
        variant: 'success',
        autoHideDuration: 3000,
        preventDuplicate: true
      });
      setDeleteAllLoading(false);
      setDeleteSelectedLoading(false);
    } catch {
      setDeleteAllLoading(false);
      setDeleteSelectedLoading(false);
    }
  };

  const selectAll = () => {
    setCheckedRoles(
      roleDisplayList
        .map(r => addEditableProperty(r, loggedInUser, domainContextChoices))
        .filter(r => r.editable)
        .map(r => r.composedKey)
    );
  };

  const deselectAll = () => {
    setCheckedRoles([]);
  };

  const [domains, setDomains] = React.useState<{ [key: string]: any }[] | null>(
    null
  );

  const [workspaces, setWorkspaces] = React.useState<Workspace[] | null>(null);

  const [workspaceNameLookup, setWorkspaceNameLookup] = React.useState<{
    [key: string]: string;
  }>({});

  const [regions, setRegions] = React.useState<{ [key: string]: any }[] | null>(
    null
  );

  const regionNameLookup =
    regions?.reduce((a, v) => {
      return { ...a, [v.id]: v.name };
    }, {}) ?? {};

  React.useEffect(() => {
    const fetchDomains = async () => {
      const domainsAll = await fetchedDomainsPure('');
      setDomains(domainsAll);
    };
    fetchDomains();
  }, []);

  React.useEffect(() => {
    const fetchWorkspacesAll = async () => {
      const workspacesAll = await fetchedWorkspacesPure('');
      setWorkspaces(workspacesAll);
      setWorkspaceNameLookup(
        workspacesAll?.reduce((a, v) => {
          return { ...a, [v.id]: v.name };
        }, {}) ?? {}
      );
    };
    fetchWorkspacesAll();
  }, []);

  React.useEffect(() => {
    const fetchRegionsAll = async () => {
      const regionsAll = await fetchRegionsCallbackPure('');
      setRegions(regionsAll);
    };
    fetchRegionsAll();
  }, []);

  // TODO: move to deleteRoles
  const deleteRole = async (userRole: any) => {
    try {
      await confirm({
        title: `Are you sure you want to remove this role (${userRole.role.name})?`,
        description: (
          <>
            <Trans
              i18nKey="removeUserRole"
              values={{
                name: userRole.role.name
              }}
            >
              This will remove <strong>{userRole.role.name}</strong>
            </Trans>
            <br />
            <br />
            This action is not reversible
          </>
        )
      });

      setLoading(true);
      startLoading();

      if (userRole.id) {
        const userRolesToDelete = roleList.filter(
          r => r.composedKey === userRole.composedKey
        );

        let error = false;
        if (domainContextChoices && !loggedInUser?.isAdmin) {
          userRolesToDelete.forEach((role, i) => {
            const currentRoleDomainOptions =
              domainContextChoices[role.contextDomainId ?? ALL];

            if (
              !currentRoleDomainOptions ||
              (!currentRoleDomainOptions.workspaceIds.has(
                role?.contextWorkspaceId ?? null
              ) &&
                !currentRoleDomainOptions.regionIds.has(
                  role?.contextRegionId ?? null
                ))
            ) {
              enqueueSnackbar(
                'Not permitted: there is not enough workspace or region user permissions for this scope',
                {
                  variant: 'warning',
                  autoHideDuration: 3000,
                  preventDuplicate: true
                }
              );
              error = true;
            }
          });
        }
        if (error) {
          setLoading(false);
          stopLoading();
          return;
        }
        const userRolesToDeleteIds = userRolesToDelete.map(ur => ur.id);
        await deleteUserRoles({ userRoleIds: userRolesToDeleteIds });
      }

      const updatedRoleList = roleList.filter(
        ur => ur.composedKey !== userRole.composedKey
      );
      const updatedRoleDisplayList = roleDisplayList.filter(
        ur => ur.composedKey !== userRole.composedKey
      );

      setRoleList(updatedRoleList);
      setRoleDisplayList(updatedRoleDisplayList);

      enqueueSnackbar(`User role ${userRole.id ? `deleted` : `removed`}`, {
        variant: 'success',
        autoHideDuration: 3000,
        preventDuplicate: true
      });

      dispatchRefresh({ type: 'setShouldRefresh', shouldRefresh: true });

      setLoading(false);
      stopLoading();
    } catch {
      setLoading(false);
      stopLoading();
    }
  };

  // TODO: move to saveRoles
  const saveRole = async (userRole: DisplayUserRole) => {
    try {
      await confirm({
        title: `Are you sure you want to add this role (${userRole.role.name})?`,
        description: (
          <>
            <Trans i18nKey="thisWillAddUserRole">
              This action will add <strong>{userRole.role.name}</strong> to the
              list of user roles
            </Trans>
          </>
        )
      });

      setLoading(true);
      startLoading();

      const newUserRoles = roleList.filter(
        r => r.composedKey === userRole.composedKey
      );

      const userRolesToSave = newUserRoles.map((userRoleToSave: UserRole) => {
        const userRoleCopyToSave = {
          roleId: userRoleToSave.roleId ?? null,
          userId: userRoleToSave.userId ?? null,
          contextDomainId: userRoleToSave.contextDomainId ?? null,
          contextWorkspaceId: userRoleToSave.contextWorkspaceId ?? null,
          contextRegionId: userRoleToSave.contextRegionId ?? null,
          contextRoleId: userRoleToSave.contextRoleId ?? null,
          contextOrganizationId: userRoleToSave.contextOrganizationId ?? null
        };
        return userRoleCopyToSave;
      });

      const results = await addUserRoles({ userRoles: userRolesToSave });
      const newRolesUpdatedWithComposedKey = addComposedKey(results.data);
      const newRolesUpdated = newUserRoles.map((r, i) => ({
        ...r,
        ...newRolesUpdatedWithComposedKey[i]
      }));
      const udpatedRoleList = roleList
        .filter(item => item.composedKey !== userRole.composedKey)
        .concat(newRolesUpdated);
      const newDisplayRoleUpdated = removeDuplicates(newRolesUpdated)[0];
      const updatedRoleDisplayList = roleDisplayList.map(item =>
        item.composedKey === newDisplayRoleUpdated.composedKey
          ? newDisplayRoleUpdated
          : item
      );
      setRoleList(udpatedRoleList);
      setRoleDisplayList(updatedRoleDisplayList);

      enqueueSnackbar('User role updated', {
        variant: 'success',
        autoHideDuration: 3000,
        preventDuplicate: true
      });
      // await refresh();
      dispatchRefresh({ type: 'setShouldRefresh', shouldRefresh: true });
      setLoading(false);
      stopLoading();
    } catch {
      setLoading(false);
      stopLoading();
    }
  };

  const resetContextSelections = () => {
    setValue('workspace', null);
    setValue('region', null);
  };

  const currentRoleSelected: Role | null = watch('role');

  const shouldDisplaySelection = {
    region: false,
    workspace: false
  };

  if (currentRoleSelected) {
    shouldDisplaySelection.region =
      loggedInUser?.isAdmin ||
      !!domainContextChoices[currentRoleSelected?.domainId ?? ALL]?.regionIds
        .size;
    shouldDisplaySelection.workspace =
      loggedInUser?.isAdmin ||
      !!domainContextChoices[currentRoleSelected?.domainId ?? ALL]?.workspaceIds
        .size;
  }

  const isRoleAdded = roleList.find(r => !r.id);

  const checkedRolesLookup = checkedRoles.reduce(
    (crl: { [key: string]: boolean }, cr: string) => {
      crl[cr] = true;
      return crl;
    },
    {}
  );

  const roleDisplayListExtended = roleDisplayList.map(r =>
    addEditableProperty(r, loggedInUser, domainContextChoices)
  );

  if (!loggedInUser) return null;

  const areRolesUpdating =
    isLoading ||
    saveAllLoading ||
    saveSelectedLoading ||
    deleteAllLoading ||
    deleteSelectedLoading;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(addRoles)}>
        {isLoading && <FullScreenLoading />}
        <fieldset disabled={loading}>
          <Grid item xs={12} className={classes.header}>
            <Accordion
              square={false}
              TransitionProps={{ unmountOnExit: true }}
              expanded
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel-add-header"
              >
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Avatar style={{ marginRight: '10px' }}>
                      <IconButton
                        aria-label="Add new user role"
                        aria-haspopup="true"
                        color="inherit"
                        size="large"
                      >
                        <GroupAdd />
                      </IconButton>
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant="button">
                      SELECT AND ASSIGN ROLES
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={6} sm={6}>
                      <Grid
                        container
                        direction="row"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item xs={12} sm={12}>
                          <Controller
                            name="role"
                            control={control}
                            render={({ onChange, ...props }) => (
                              <Autocomplete
                                fetchOptionsCallback={fetchedRolesCb}
                                filterOptions={filterRoleOptionsCb}
                                onChange={(_event: any, data: Role) => {
                                  onChange(data);
                                  resetContextSelections();
                                  trigger();
                                }}
                                fullWidth
                                label="Roles"
                                disabled={false}
                                // required
                                {...props}
                              />
                            )}
                            rules={{
                              // required: true,
                              validate: {
                                roleSelected: (role: any) => {
                                  if (role) return true;
                                  return false;
                                }
                              }
                            }}
                          />
                        </Grid>
                        {shouldDisplaySelection.region && (
                          <Grid item xs={12} sm={12}>
                            <Controller
                              name="region"
                              control={control}
                              render={({ onChange, ...props }) => (
                                <Autocomplete
                                  fetchOptionsCallback={
                                    fetchRegionsCallbackPure
                                  }
                                  filterOptions={filterRegionOptionsCb}
                                  onChange={(_event: any, data: Region) => {
                                    onChange(data);
                                    trigger();
                                  }}
                                  fullWidth
                                  label="Region"
                                  disabled={!shouldDisplaySelection.region}
                                  {...props}
                                />
                              )}
                              // rules={{ required: true }}
                            />
                          </Grid>
                        )}
                        {shouldDisplaySelection.workspace && (
                          <Grid item xs={12} sm={12}>
                            <Controller
                              name="workspaces"
                              control={control}
                              render={({ onChange, ...props }) => (
                                <Autocomplete
                                  fetchOptionsCallback={fetchedWorkspacesPure}
                                  filterOptions={filterWorkspaceOptionsCb}
                                  onChange={(
                                    _event: any,
                                    data: Workspace[]
                                  ) => {
                                    onChange(data);
                                    trigger();
                                  }}
                                  fullWidth
                                  multiple
                                  label="Workspace"
                                  disabled={!shouldDisplaySelection.workspace}
                                  // required
                                  {...props}
                                />
                              )}
                              rules={{
                                // required: true,
                                validate: {
                                  regionOverlap: (workspace: any) => {
                                    const region = getValues('region');
                                    if (workspace?.length && region)
                                      return false;
                                    return true;
                                  }
                                }
                              }}
                            />
                            {// eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore see top of file for description
                            errors.workspaces?.type === 'regionOverlap' && (
                              <Alert
                                style={{ marginTop: '1rem' }}
                                severity="error"
                              >
                                Please select either Region or Workspace
                              </Alert>
                            )}
                            {errors.role && (
                              <Alert severity="error">
                                A role should be selected
                              </Alert>
                            )}
                          </Grid>
                        )}

                        {loggedInUserPermissions.userRoleAssign && (
                          <Grid
                            container
                            xs={12}
                            sm={12}
                            justifyContent="flex-end"
                          >
                            <Button
                              type="submit"
                              color="primary"
                              variant="outlined"
                              className={classes.saveButton}
                              size="small"
                              disabled={
                                !currentRoleSelected ||
                                loading ||
                                areRolesUpdating
                              }
                            >
                              Add Role <KeyboardArrowRight />
                            </Button>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                          <RoleInfo
                            currentRoleSelected={currentRoleSelected}
                            domains={domains}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          component="div"
                          sx={{ paddingLeft: '24px' }}
                        >
                          <strong>Selected role combinations:</strong>
                        </Typography>
                        <Grid container>
                          <Grid item xs={10}>
                            <ButtonsText
                              onClick={selectAll}
                              // type="submit"
                              color="primary"
                              variant="text"
                              className={classes.saveButton}
                              disabled={areRolesUpdating}
                              // isLoading={loading}
                            >
                              SELECT ALL
                            </ButtonsText>
                          </Grid>
                          <Grid item xs={2}>
                            <ButtonsText
                              onClick={deselectAll}
                              // type="submit"
                              color="primary"
                              variant="text"
                              className={classes.saveButton}
                              disabled={areRolesUpdating}
                              // isLoading={loading}
                            >
                              CLEAR
                            </ButtonsText>
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container>
                          {!roleDisplayListExtended?.length && (
                            <Typography
                              variant="overline"
                              display="block"
                              gutterBottom
                              sx={{
                                paddingLeft: '23px',
                                transform: 'uppercase'
                              }}
                            >
                              THIS USER DOES NOT HAVE ANY ROLES ASSIGNED
                            </Typography>
                          )}
                          {roleDisplayListExtended.map(userRole => (
                            <>
                              <Grid container>
                                <Grid
                                  key={userRole.composedKey}
                                  item
                                  xs={10}
                                  sx={{ paddingLeft: '14px' }}
                                >
                                  {(loggedInUserPermissions.userRoleAssign ||
                                    loggedInUserPermissions.userRoleUnassign) && (
                                    <Checkbox
                                      checked={
                                        !!checkedRolesLookup[
                                          userRole.composedKey
                                        ]
                                      }
                                      disabled={!userRole.editable}
                                      onChange={e =>
                                        handleRoleCheck(
                                          e.target.checked,
                                          userRole.composedKey
                                        )
                                      }
                                    />
                                  )}
                                  <UserRoleInfo
                                    userRole={userRole}
                                    workspaceNameLookup={workspaceNameLookup}
                                    regionNameLookup={regionNameLookup}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  style={userRole.id ? { paddingLeft: 20 } : {}}
                                >
                                  {!userRole.id && (
                                    <IconButton
                                      aria-label="edit"
                                      onClick={() => saveRole(userRole)}
                                      size="large"
                                      disabled={loading || areRolesUpdating}
                                    >
                                      <SaveAsIcon />
                                    </IconButton>
                                  )}
                                  {loggedInUserPermissions.userRoleUnassign && (
                                    <IconButton
                                      aria-label="edit"
                                      onClick={() => deleteRole(userRole)}
                                      size="large"
                                      disabled={
                                        loading ||
                                        !userRole.editable ||
                                        areRolesUpdating
                                      }
                                    >
                                      <DeleteOutlinedIcon />
                                    </IconButton>
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          ))}
                        </Grid>
                      </>
                      <Divider />
                      {loggedInUserPermissions.userRoleAssign && (
                        <Grid container xs={12} justifyContent="space-between">
                          {loggedInUserPermissions.userRoleUnassign && (
                            <ButtonsText
                              onClick={e => deleteSelected()}
                              color="primary"
                              variant="text"
                              className={classes.saveButton}
                              disabled={
                                !checkedRoles.length || areRolesUpdating
                              }
                              isLoading={deleteAllLoading}
                              startIcon={<DeleteIcon />}
                            >
                              Delete selected
                            </ButtonsText>
                          )}
                          {loggedInUserPermissions.userRoleAssign && (
                            <ButtonsText
                              onClick={e => saveRoles(true)}
                              color="primary"
                              variant="text"
                              className={classes.saveButton}
                              disabled={
                                (!isRoleAdded && !checkedRoles.length) ||
                                areRolesUpdating
                              }
                              isLoading={saveSelectedLoading}
                              startIcon={<SaveAsIcon />}
                            >
                              Save selected
                            </ButtonsText>
                          )}
                          {loggedInUserPermissions.userRoleUnassign && (
                            <ButtonsText
                              onClick={e => deleteSelected(true)}
                              color="primary"
                              variant="text"
                              className={classes.saveButton}
                              disabled={
                                !roleDisplayListExtended.length ||
                                areRolesUpdating
                              }
                              isLoading={deleteAllLoading}
                              startIcon={<DeleteIcon />}
                            >
                              Delete All
                            </ButtonsText>
                          )}
                          {loggedInUserPermissions.userRoleAssign && (
                            <ButtonsText
                              onClick={e => saveRoles()}
                              color="primary"
                              variant="text"
                              className={classes.saveButton}
                              disabled={!isRoleAdded || areRolesUpdating}
                              isLoading={saveAllLoading}
                              startIcon={<SaveAsIcon />}
                            >
                              Save All
                            </ButtonsText>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </fieldset>
      </form>
    </FormProvider>
  );
};

export default {
  UserRolesForm
};

import React, { memo, useGlobal } from 'reactn';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';

import Paper from '@mui/material/Paper';
import { hasPermission } from 'components/common/PermissionGate';
import EnhancedTable from 'components/table/EnhancedTable';
import { contactColumns } from './ContactColumns';

const AdminContact = () => {
  const [user] = useGlobal('user');
  const columns = React.useMemo(() => contactColumns, []);

  const canEditContact = hasPermission({
    scopes: 'contact_update',
    user
  });

  return (
    <Grid item xs={12} style={{ overflowX: 'scroll' }}>
      <Helmet>
        <title>UN Info - Contacts</title>
      </Helmet>
      <Paper elevation={0}>
        <EnhancedTable
          linkTo="/dashboard/admin/contact"
          canDelete={canEditContact}
          endpoint="contact"
          pageSize={50}
          columns={columns}
          hiddenColumns={[
            'financingIds',
            'mainRCOContact',
            'uncgLead',
            'postLevelId',
            'type',
            'jobTitleId',
            'workspaceId',
            'organizationId',
            'commsRCOContact',
            'teamLead'
          ]}
        />
      </Paper>
    </Grid>
  );
};

export default memo(AdminContact);

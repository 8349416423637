import { Add, Edit } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchDocuments } from 'services/DocumentService';
import { fetchPlanById } from 'services/PlanService';
import { Button, Table } from 'uninfo-components';
import LoadingBlocks from './LoadingBlocks';

const COOPERATION_FRAMEWORK = 4;
const COUNTRY_PLANS = 9;
const UNDAF = 5;

const Documents: React.FC = () => {
  const [cfDocuments, setCFDocuments] = React.useState<DocumentAPI[]>([]);
  const params = useParams();
  const [loading, setLoading] = React.useState(true);

  const fetchDocumentsCallback = React.useCallback(async () => {
    if (params.id) {
      const fetchedPlan = await fetchPlanById(+params.id);

      const cfDocs = await fetchDocuments({
        fileDocumentTypeIds: [COOPERATION_FRAMEWORK, UNDAF],
        planIds: [fetchedPlan.id]
      });
      setCFDocuments(cfDocs);

      setLoading(false);
    }
  }, [params.id]);

  React.useEffect(() => {
    fetchDocumentsCallback();
  }, [fetchDocumentsCallback]);

  return (
    <>
      <div
        style={{
          marginBottom: '.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h2">Cooperation Frameworks</Typography>
        <Link
          to={`/dashboard/admin/document/add?planId=${params.id}&documentTypeId=${COOPERATION_FRAMEWORK}`}
        >
          <Button startIcon={<Add />} variant="outlined">
            Add documents to documents library
          </Button>
        </Link>
      </div>
      {loading && <LoadingBlocks rows={2} />}
      {!loading && (
        <>
          <Table>
            <thead>
              <tr>
                <th>Document name</th>
                <th>Document type</th>
                <th>Status</th>
                <th>Link</th>
                <th>Published date</th>
                <th>Last updated date</th>
                <th className="alignRight">Manage</th>
              </tr>
            </thead>
            <tbody>
              {cfDocuments.map(doc => (
                <tr key={doc.id}>
                  <td>{doc.name}</td>
                  <td>{doc.fileDocumentType.name}</td>
                  <td>{doc.status}</td>
                  <td>
                    <a href={doc.url}>Link</a>
                  </td>
                  <td>{doc.publishedDate}</td>
                  <td>{doc.updatedAt?.split('T')?.[0]}</td>
                  <td className="alignRight">
                    <Link
                      to={`/dashboard/admin/document/${doc.id}?planId=${params.id}`}
                    >
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {cfDocuments.length === 0 && (
            <Typography variant="subtitle1" style={{ padding: '1rem' }}>
              No cooperation framework documents found.{' '}
              <Link
                to={`/dashboard/admin/document/add?planId=${params.id}&documentTypeId=${COUNTRY_PLANS}`}
              >
                Add them to documents library
              </Link>
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default Documents;

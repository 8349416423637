import React, { useCallback, useGlobal, useState } from 'reactn';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'uninfo-components';

import { useSnackbar } from 'notistack';

import {
  Grid,
  Switch,
  TextField,
  ToggleButton,
  Tooltip,
  Typography
} from '@mui/material';

import { Autocomplete } from 'components/common/AutoComplete';
import { fetchCountries } from 'services/LocationService';

import {
  addWorkspace,
  fetchWorkspaceById,
  editWorkspace
} from 'services/WorkspaceService';
import { autocomplete } from 'services/AutocompleteService';

import PaperWrapper from 'components/common/PaperWrapper';

type RouterParam = {
  id: string;
};

type CountryType = {
  countryTags: any[];
  createdAt: string;
  deletedAt: string | null;
  id: number;
  latestTaggedVersion: boolean;
  latestVersion: boolean;
  organizations: Organization[];
  teams: any[];
  updatedAt: string;
  versionTags: string[];
  workspaces: Workspace[];
};

const workspacePortalWidgets = [
  'genderHumanRightsContributionLevel',
  'markerContributionBySDG',
  'markerContributionByOutcome',
  'unctTableRCOContacts'
];

const WorkspacePortalVisibility: React.FC<{ workspace: Workspace }> = ({
  workspace
}) => {
  const [user] = useGlobal('user');

  const [states, setStates] = React.useState<{ [key: string]: boolean }>(
    workspacePortalWidgets.reduce((aggr, key) => {
      return { ...aggr, [key]: workspace.options?.[key]?.show ?? false };
    }, {})
  );

  const handleSwitchChange = React.useCallback(
    async (name: string, val: boolean) => {
      const newVal = { ...states, [name]: val };
      setStates(newVal);

      await editWorkspace({
        id: workspace.id,
        options: Object.keys(newVal).reduce((aggr, key) => {
          return {
            ...aggr,
            [key]: {
              show: newVal[key]
            }
          };
        }, {})
      });
    },
    [states, workspace]
  );

  if (!user?.isAdmin) {
    return null;
  }

  return (
    <>
      <Typography variant="h4" style={{ marginTop: '2rem' }}>
        Display of widgets on Portal
      </Typography>
      <ul>
        {workspacePortalWidgets.map(w => (
          <li key={w}>
            <Tooltip title={`Display the ${w} graph`}>
              <div>
                Display the {w} graph
                <Switch
                  checked={states[w]}
                  onChange={e => handleSwitchChange(w, e.target.checked)}
                  value="addMultiple"
                  inputProps={{ 'aria-label': `Display the ${w} graph` }}
                />
              </div>
            </Tooltip>
          </li>
        ))}
      </ul>
    </>
  );
};
export default WorkspacePortalVisibility;

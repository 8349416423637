import { deleteCell } from 'services/CellService';
import { entityIsPlanEntity } from 'utils/typeguards';
import { NON_MONETARY_ASSISTANCE_CATEGORY_ID } from './constants';

// TODO: Move this to utils/cells.js once it's typescript
export interface LinkWithValue extends Link {
  value: {
    name: string;
  }[];
}

interface AssessmentValue extends Assessment {
  name?: string;
  id: number;
}

// TODO: Move this to utils/cells.js once it's typescript
export interface MetricWithValue extends Metric {
  id: number;
  timeframeId: number;
  periodicity: string;
  displayPeriodicity: boolean;
  value: AssessmentValue[];
}

// FIXME: this type is a bit of a weird combination of both PlanEntity and Plan types
export interface MappedEntity extends PlanEntity {
  cells: Cell[];
  links: LinkWithValue[];
  metrics: MetricWithValue[];
  planVersion?: PlanVersion;
  planSubmissions?: Submission[];
  supporting?: any;
}

export function entityIsMappedEntity(entity: unknown): entity is MappedEntity {
  if (!entity) {
    return false;
  }
  if (entityIsPlanEntity(entity)) {
    return (entity as MappedEntity).links !== undefined;
  }
  return false;
}

/**
 * converts the cell results to an object:
 * {
 *  id,
 *  cells: [{
 *    cellKey, id, linkId
 *  }]
 * }
 *
 * @param {*} result
 * @param {*} link
 * @param {*} links
 */
export const buildLinksFromResult = (
  result: any[],
  link: Link,
  links: Link[]
): Link[] => {
  const localCells: Partial<Cell>[] = [];
  result
    .filter(v => !!v)
    .forEach(v => {
      const cell: Partial<Cell> = {};
      cell[link.properties.cellKey] = v.id;
      cell.name = v.name;
      cell.id = v.cellId;
      cell.linkId = link.id;
      cell.dataitem = v.dataitem;
      localCells.push(cell);
    });
  // TODO: check if sometime find probably not ...
  const localLinks = links.filter(l => l.id !== link.id);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore find things wrong with this
  localLinks.push({
    id: link.id,
    cells: localCells as Cell[] // FIXME: we need to guarantee this
  });
  return localLinks;
};

/**
 * Loops through the cells and sees if they store values for
 * the passed metric. Only looks at cells that are not disaggregated.
 *
 * @param {*} cells
 * @param {*} metric
 */
export const findMetricValueInCells = (
  cells: Cell[],
  metric: MetricBase
): Assessment[] => {
  // If the assessments or the cells start getting very large
  // it might be worth creating a map here to keep track of assessments
  // and checking for id there.
  const results: Assessment[] = [];
  cells
    .filter(
      c =>
        c.agencyId === null &&
        c.contributingPartnerId === null &&
        c.designedCategoryId === null &&
        c.implementingPartnerId === null
    )
    .forEach(cell => {
      const values =
        cell.assessments?.filter(a => {
          return a.metricId === metric.id;
        }) || [];
      results.push(...values);
    });

  return results.sort((a, b) => a.timeframeId - b.timeframeId);
};

/**
 * looks through the metrics defined on the entityPrototype and
 * compares them to the cells to see if the cells contain any
 * values for those properties.
 *
 * @param {*} cells
 * @param {*} eP
 */
export const mapCellsToMetrics = (
  cells: Cell[],
  eP: EntityPrototype,
  periodAndUnit: { periodicity: string; unit: string }
): Assessment[] => {
  if (cells && eP.properties?.metrics) {
    return eP.properties.metrics.map(metric => {
      const value = findMetricValueInCells(cells, metric);
      const displayPeriodicity =
        metric.type === 'measure' || metric.type === 'expenditure';
      return {
        ...metric,
        value,
        timeframeId: 0,
        metricId: 0,
        planEntityCellId: 0,
        displayPeriodicity,
        ...periodAndUnit
      };
    });
  }
  return [];
};

/**
 * Loops through the cells and sees if they store designedCategoryId
 *
 * @param {*} cells
 */
export const findCategoriesInCells = (cells: Cell[]): DesignedCategory[] => {
  const cellsWithDesignedCategory = cells.filter(
    cell =>
      cell.designedCategory &&
      cell.designedCategoryId &&
      !cell.locationId &&
      !cell.contributingPartner &&
      !cell.agencyId &&
      !cell.implementingPartnerId
  ) as DesignedCategoryCell[];
  return cellsWithDesignedCategory.map(cell => {
    return cell.designedCategory;
  });
};

export const buildCells = (customCategories: DesignedCategory[]): Cell[] => {
  const cells = [];
  // eslint-disable-next-line no-restricted-syntax
  if (customCategories && customCategories.length) {
    customCategories.forEach(c => {
      cells.push({ assessments: [], designedCategory: c.id });
    });
  }
  // this creates a cell that isn't linked to anything. this cell is
  // useful for the assessments of the indicator itself (non-disaggregated)
  const cell = { assessments: [] };
  cells.push(cell);
  return cells;
};

export const updateDisagFromLinks = async (
  cells: Cell[],
  localEntity: PlanEntity
): Promise<void> => {
  const promises: any[] = [];
  localEntity.planEntityOrganizations?.forEach((org: any) => {
    if (org.toDelete) {
      cells
        ?.filter(cell => cell[`${org.type}Id`] === org.organizationId)
        .forEach(c => {
          promises.push(deleteCell(c.id));
        });
    }
  });
  await Promise.all(promises);
};

export const mapOrganizationsToLink = (
  editItem: PlanEntity,
  link: Link
): any[] => {
  return (
    editItem?.organizations
      ?.filter(
        (o: any) =>
          o.planEntityOrganization.type === link.linkedKey.replace('Id', '')
      )
      .map((o: any) => {
        return {
          id: o.id,
          name: o.name,
          type: o.planEntityOrganization.type,
          archivedAt: o.archivedAt,
          linkedTableName: link.linkedTableName
        };
      }) ?? []
  );
};

export const mapLocationsToLink = (editItem: PlanEntity, link: Link): any[] => {
  return (
    editItem?.locations?.map((o: any) => {
      return {
        id: o.id,
        adminLevel: o.adminLevel,
        name: o.name,
        archivedAt: o.archivedAt,
        parentId: o.parentId
      };
    }) ?? []
  );
};

export const mapSdgsToLink = (editItem: PlanEntity, link: Link): any[] => {
  return (
    editItem?.planEntitySdgs?.map((o: any) => {
      return {
        id: o.target?.id || o.goal?.id || o.indicator?.id,
        goalId: o.goalId || null,
        indicatorId: o.indicatorId || null,
        targetId: o.targetId || null,
        // todo : get name depending on link.entityName linkedKey
        name: `${o.target?.ref || o.goal?.ref || o.indicator?.ref}:${o.target
          ?.name ||
          o.goal?.name ||
          o.indicator?.name}`
      };
    }) ?? []
  );
};

export const mapDataItemToLink = (editItem: PlanEntity, link: Link): any[] => {
  return (
    editItem?.dataitems
      ?.filter((o: any) => o.planEntityDataitem.linkId === link.id)
      .map((o: any) => {
        return {
          id: o.planEntityDataitem.dataitemId,
          planEntityDataitemId: o.planEntityDataitem.id,
          linkId: link.id,
          narrative: o.planEntityDataitem.narrative,
          name: o.dataitemVersion?.name || 'not in api response'
        };
      }) ?? []
  );
};
export const mapFocalUserItems = (editItem: PlanEntity, link: Link): any[] => {
  return (
    editItem?.focalUsers?.map((o: any) => {
      return {
        id: o.id,
        linkId: link.id,
        name: `${o.firstname} ${o.lastname}`
      };
    }) ?? []
  );
};

export const findAgencyCell = (
  cells: Cell[],
  agencyId: number,
  locationId?: number | null
): Cell | undefined => {
  return cells.find(
    c =>
      c.agencyId === agencyId &&
      c.locationId === locationId &&
      c.contributingPartnerId === null &&
      c.implementingPartnerId === null &&
      c.designedCategoryId === null
  );
};

export const findAgencyCPCell = (
  cells: Cell[],
  agencyId: number,
  cpId: number,
  locationId?: number | null
): Cell | undefined => {
  return cells.find(
    c =>
      c.agencyId === agencyId &&
      c.locationId === locationId &&
      c.contributingPartnerId === cpId &&
      c.implementingPartnerId === null
  );
};

export const isAgencyWithAnyCPCell = (
  c: Cell,
  agencyId: number,
  locationId?: number | null
): boolean => {
  return (
    c.agencyId === agencyId &&
    c.locationId === locationId &&
    c.contributingPartnerId !== null &&
    c.implementingPartnerId === null &&
    c.designedCategoryId === null
  );
};

export const findAgencyCellWithOptionalNMA = (
  cells: Cell[],
  agencyId: number,
  designedCategoryId: number | null,
  locationId?: number | null
): Cell | undefined => {
  return cells.find(
    c =>
      c.agencyId === agencyId &&
      c.contributingPartner === null &&
      c.implementingPartnerId === null &&
      c.designedCategoryId === designedCategoryId &&
      c.locationId === locationId
  );
};

export const isAgencyCellWithOptionalNMAAndContributingPartner = (
  cell: Cell,
  agencyId: number
): boolean => {
  return (
    cell.agencyId === agencyId &&
    cell.contributingPartner === null &&
    cell.implementingPartnerId === null &&
    (cell.designedCategoryId === null ||
      cell.designedCategoryId === NON_MONETARY_ASSISTANCE_CATEGORY_ID)
  );
};

export const findAssessment = (
  metricId: number,
  timeframeId: number,
  cell?: Cell
): Assessment | undefined => {
  return cell?.assessments?.find(
    a => a.metricId === metricId && a.timeframeId === timeframeId
  );
};

export const findTotalsCell = (cells: Cell[]): Cell | undefined => {
  return cells.find(
    c =>
      c.agencyId === null &&
      c.contributingPartner === null &&
      c.implementingPartnerId === null &&
      c.locationId === null &&
      c.designedCategoryId === null
  );
};

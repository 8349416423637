import { withStyles } from '@mui/styles';

import MuiExpansionPanel from '@mui/material/Accordion';
import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import MuiExpansionPanelDetails from '@mui/material/AccordionDetails';

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    border: 0,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel);

export const AccordionSummary = withStyles(theme => ({
  root: {
    border: (props: { isNew?: boolean; color?: 'cfr' | 'primary' }) => {
      return `1px ${props.isNew ? 'dashed' : 'solid'} ${
        theme.palette[props.color ?? 'primary']?.main
      }`;
    },
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderRadius: '.5rem',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {},
  expandIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '7px',
    marginRight: '-7px'
  }
}))(MuiExpansionPanelSummary);

export const AccordionDetails = withStyles(theme => ({
  root: {
    display: 'block',
    border: '1px solid var(--color-grey)',
    borderTop: 'none',
    boxShadow: 'none',
    padding: theme.spacing(2),
    borderBottomRightRadius: '.5rem',
    borderBottomLeftRadius: '.5rem'
  }
}))(MuiExpansionPanelDetails);

import Asynchronous from 'components/table/AsynchronousFilter';
import BooleanCheckCell from 'components/table/BooleanCheckCell';
import DateCell from 'components/table/DateCell';

export const documentColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    sortType: 'basic',
    disableFilters: true,
    style: { maxWidth: '300px' }
  },
  {
    Header: 'Type',
    id: 'fileDocumentTypeId',
    accessor: 'fileDocumentType.name',
    sortType: 'basic',
    endpoint: 'fileDocumentType',
    filterLabel: 'fileDocumentType',
    Filter: Asynchronous
  },
  {
    Header: 'Workspace',
    id: 'workspaceId',
    accessor: 'workspace.name',
    endpoint: 'workspace',
    style: { wordBreak: 'break-word', maxWidth: '200px' },
    filterLabel: 'workspace',
    Filter: Asynchronous,
    filterByUserWorkspaces: true
  },
  {
    Header: 'Region',
    id: 'regionId',
    accessor: 'region.name',
    endpoint: 'region',
    style: { wordBreak: 'break-word', maxWidth: '200px' },
    disableFilters: true
  },

  {
    Header: 'Private',
    id: 'private',
    accessor: 'private',
    sortType: 'basic',
    disableFilters: true,
    Cell: BooleanCheckCell
  },
  {
    Header: 'Published',
    id: 'publishedDate',
    accessor: 'publishedDate',
    sortType: 'basic',
    disableFilters: true,
    Cell: DateCell
  },
  {
    Header: 'Created',
    id: 'createdAt',
    accessor: 'createdAt',
    sortType: 'basic',
    disableFilters: true,
    Cell: DateCell
  },
  {
    Header: 'Updated',
    id: 'updatedAt',
    accessor: 'updatedAt',
    sortType: 'basic',
    disableFilters: true,
    Cell: DateCell
  }
];

export default {
  documentColumns
};

import Api from 'utils/Api';
import settings from 'config';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

const ROLE_API_BASE_URL = `${BASE_URL}/role/`;
const USER_ROLE_API_BASE_URL = `${BASE_URL}/userRole/`;
// const USER_ROLE_API_BASE_URL = `${BASE_URL}/userRole/`;

interface FetchRolesOptions {
  globalFilter: string;
  page?: number;
  limit?: number;
  // includePermissions?: boolean;
  domainIds?: number[];
}

interface FetchUserRolesOptions {
  id: number;
  userId: number;
  roleId: number;
  userIds: number[] | number;
}

export const fetchRoles = (options?: FetchRolesOptions): Promise<Role[]> => {
  const data = {
    q: options?.globalFilter ?? null,
    page: options?.page ?? null,
    limit: options?.limit ?? null,
    includePermissions: true,
    exclude: ['userRoles'],
    domainIds: options?.domainIds ?? null
  };

  return Api.post(`${ROLE_API_BASE_URL}search`, data, getAuthHeader()).then(
    res => res.data.results
  );
};

export const fetchRoleById = (id: number): Promise<Role> => {
  return Api.get(`${ROLE_API_BASE_URL}${id}`, getAuthHeader()).then(
    res => res.data
  );
};

export const fetchUserRoles = (
  options?: Partial<FetchUserRolesOptions>
): Promise<UserRole[]> => {
  const data = options;

  return Api.post(
    `${USER_ROLE_API_BASE_URL}search`,
    data,
    getAuthHeader()
  ).then((res: any) => res.data.results);
};

export const addUserRoles = (data: any) => {
  return Api.post(
    `${USER_ROLE_API_BASE_URL}batchAssign`,
    data,
    getAuthHeader()
  );
};

export const deleteUserRoles = (data: { userRoleIds: number[] }) => {
  return Api.post(
    `${USER_ROLE_API_BASE_URL}batchUnassign`,
    data,
    getAuthHeader()
  );
};

export const commonServiceManagerClaim = (data: any) => {
  return Api.post(
    `${USER_ROLE_API_BASE_URL}commonServiceManagerClaim`,
    data,
    getAuthHeader()
  );
};

export const commonServiceManagerUnclaim = (data: any) => {
  return Api.post(
    `${USER_ROLE_API_BASE_URL}commonServiceManagerUnclaim`,
    data,
    getAuthHeader()
  );
};

export const updateRole = (
  id: number,
  data: { rolePermissions: number[] }
): Promise<unknown> => {
  return Api.put(`${ROLE_API_BASE_URL}${id}`, data, getAuthHeader());
};

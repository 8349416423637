import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface UseLoadingAnimationHookReturn {
  isLoading: boolean;
  setIsLoading(arg0: boolean): void;
  startLoading: () => void;
  stopLoading: () => void;
  FullScreenLoading: React.FC;
  SimpleScreenLoading: React.FC;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      position: 'absolute',
      width: '100%',
      top: 0,
      zIndex: 99999,
      right: 0,
      left: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0, 0, 0, .3)'
    }
  })
);

export const useLoadingAnimation = (): UseLoadingAnimationHookReturn => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);

  return {
    isLoading,
    setIsLoading,
    startLoading: React.useCallback(() => {
      setIsLoading(true);
    }, []),
    stopLoading: React.useCallback(() => {
      // const timer = setTimeout(() => {
      setIsLoading(false);
      // }, 500);
      // return () => clearTimeout(timer);
    }, []),
    // eslint-disable-next-line react/display-name
    FullScreenLoading: () => {
      return isLoading ? (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      ) : null;
    },
    // eslint-disable-next-line react/display-name
    SimpleScreenLoading: () => {
      return isLoading ? <CircularProgress /> : null;
    }
  };
};

export default useLoadingAnimation;

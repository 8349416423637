import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Button } from 'uninfo-components';
import DialogActions from '@mui/material/DialogActions';
import { isExisting } from 'utils/typeguards';

interface Props {
  handleSubmitForm: (data: any) => void;
  editItem: any;
  buttonText?: string;
}

export const LinkForm: React.FC<Props> = ({ handleSubmitForm, editItem }) => {
  const { t } = useTranslation('common');
  const initialValues = {
    name: editItem.name ?? ''
  };
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: initialValues
  });

  const onSubmit = (data: any) => {
    handleSubmitForm(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            rules={{ required: true, maxLength: 20 }}
            name="name"
            render={({ onChange, value }) => (
              <TextField
                error={errors.name}
                variant="outlined"
                label={t('Name')}
                type="text"
                placeholder="UNOPS"
                fullWidth
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>

        <DialogActions style={{ width: '100%' }}>
          <Button
            disabled={isSubmitting}
            color="primary"
            variant="contained"
            type="submit"
          >
            {isExisting(editItem) ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Grid>
    </form>
  );
};

export default { LinkForm };

import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CircularProgress from 'components/dialogs/CircularProgress';

import { SurveyPreparationResponse } from 'services/PlanSurveyService';
import { AGENCY_TYPE_ID } from 'utils/constants';
import {
  createDefaultValues,
  createOrUpdate,
  Datum,
  Question,
  fetchPlanAndBuildSurveyForm
} from './utils';

import { QuestionForm } from './QuestionForm';
import LoadingBlocks from './LoadingBlocks';

export const questions: Question[] = [
  {
    question: `C.1.3.2 - Is the period of the current Cooperation Framework/UNDAF or equivalent document
      aligned with the Government medium-term planning and budgeting cycle*`,
    slug: 'isPeriodOfCooperationFrameworkAligned',
    type: 'dataitem',
    required: true,
    filterId: 61
  },
  {
    question: 'C.1.3.3.a - Framework signed by Government *',
    slug: 'isFrameworkSignedByGovernment',
    type: 'boolean',
    required: true
  },
  {
    question: 'C.1.3.3.b - Framework contains legal annex *',
    slug: 'isFrameworkContainsLegalAnnex',
    type: 'boolean',
    required: true
  },
  {
    question: `C.1.3.4 - With which actors did the UNCT consult in the process of developing its current
      programming instrument (Cooperation Framework/UNDAF or equivalent instrument)?*
      (select all that apply) [QCPR: 3.1.8]*`,
    slug: 'surveyPreparationActor',
    type: 'dataitem',
    filterId: 411,
    multiple: true,
    required: true
  },
  {
    question:
      'C.1.3.4.1 - If other groups of actors were consulted but are not in the list above, please specify',
    slug: 'ifOtherGroupOfActorsPleaseSpecify',
    type: 'textField',
    dependsOn: 'surveyPreparationActor',
    dependsOnFnc: (field: { name: string }[]): boolean => {
      return !!field?.find(f => f.name.startsWith('Other'));
    }
  },
  {
    question:
      'C.1.3.6 - Did the RC/UNCT conduct a configuration exercise for this programme document *',
    slug: 'didRCUNCTConductConfigurationExercise',
    type: 'boolean',
    required: true
  },
  {
    question:
      'C.1.3.6.1 - Which UN Entities joined the UNCT as a result of the configuration exercise *',
    slug: 'surveyPreparationEJ',
    type: 'organization',
    filterId: AGENCY_TYPE_ID,
    multiple: true,
    dependsOn: 'didRCUNCTConductConfigurationExercise',
    allowNone: true
  },
  {
    question:
      'C.1.3.6.2 - Which UN Entities left as a result of the configuration exercise *',
    slug: 'surveyPreparationEL',
    type: 'organization',
    multiple: true,
    filterId: AGENCY_TYPE_ID,
    dependsOn: 'didRCUNCTConductConfigurationExercise',
    allowNone: true
  },
  {
    question:
      'C.1.3.9 - What are the governance/management arrangements in place for the Cooperation Framework/UNDAF?* [Select All that Apply]',
    slug: 'surveyPreparationArr',
    type: 'dataitem',
    filterId: 159,
    required: true,
    multiple: true
  },
  {
    question: 'C.1.3.9.1 - If “other”, please specify',
    slug: 'ifOtherPleaseSpecify',
    type: 'textField',
    dependsOn: 'surveyPreparationArr',
    dependsOnFnc: (field: { name: string }[]): boolean => {
      return !!field?.find(f => f.name.startsWith('Other'));
    }
  }
];

const surveyName = 'preparation';

const Preparation: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [plan, setPlan] = React.useState<Plan>();

  const [preparation, setPreparation] = React.useState<
    SurveyPreparationResponse['surveyPreparationVersion']
  >();

  const fetchPlanCallback = React.useCallback(async () => {
    const {
      form: surveyMapped,
      plan: fetchedPlan
    } = await fetchPlanAndBuildSurveyForm<SurveyPreparationResponse>({
      id: params.id,
      questions,
      surveyName
    });
    setPlan(fetchedPlan);
    setPreparation(surveyMapped);
    setIsLoading(false);
  }, [params.id]);

  React.useEffect(() => {
    fetchPlanCallback();
  }, [fetchPlanCallback, params.id]);

  const methods = useForm({
    defaultValues: createDefaultValues(questions)
  });
  const { reset } = methods;

  React.useEffect(() => {
    if (preparation) {
      reset(createDefaultValues(questions, preparation));
    }
  }, [preparation, reset]);

  const onSubmit = React.useCallback(
    async (data: { [key: string]: Datum }) => {
      if (params.id) {
        setIsSaving(true);
        const msg = await createOrUpdate({
          questions,
          planId: +params.id,
          data,
          surveyName,
          survey: preparation
        });
        await fetchPlanCallback();
        enqueueSnackbar(msg, {
          variant: 'success',
          autoHideDuration: 5000,
          preventDuplicate: true
        });
        setIsSaving(false);
      }
    },
    [params.id, preparation, fetchPlanCallback, enqueueSnackbar]
  );

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingBlocks />}
      {isSaving && <CircularProgress />}
      {!isLoading && plan && (
        <QuestionForm
          questions={questions}
          plan={plan}
          onSubmit={onSubmit}
          disabled={isSaving}
          surveyVersion={preparation}
        />
      )}
    </FormProvider>
  );
};

export default Preparation;

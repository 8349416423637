import settings from 'config';
import Api from '../utils/Api';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

const USER_API_BASE_URL = `${BASE_URL}/user/`;
const USER_PLAN_API_BASE_URL = `${BASE_URL}/plan/users/`;
const USERWS_API_BASE_URL = `${BASE_URL}/userWorkspace/`;
const USERDOMAIN_API_BASE_URL = `${BASE_URL}/userDomain/`;
const USERREGION_API_BASE_URL = `${BASE_URL}/userRegion/`;

interface FetchUsersOptions extends APIQueryOptions {
  workspaceRoleIds?: number[];
  domainIds?: number[];
  includeNoWorkspace?: boolean;
  workspaceIds?: (number | null)[] | string[];
}

export const fetchUsers = async (
  options: FetchUsersOptions
): Promise<User[]> => {
  const data = {
    ...options
  };

  return Api.post(`${USER_API_BASE_URL}search`, data, getAuthHeader()).then(
    res => res.data.results
  );
};
export const fetchUserById = (
  userId: number,
  rolesOnly: boolean
): Promise<User> => {
  return Api.get(
    `${USER_API_BASE_URL}${userId}?rolesOnly=${rolesOnly}`,
    getAuthHeader()
  ).then(res => res.data);
};
export const fetchUsersByPlan = (planId: number): Promise<Partial<User>[]> => {
  return Api.get(`${USER_PLAN_API_BASE_URL}${planId}`, getAuthHeader()).then(
    res => res.data
  );
};
export const fetchProfile = (): Promise<APISingleResult<User>> => {
  return Api.get(`${USER_API_BASE_URL}profile`, getAuthHeader());
};
export const editProfile = (user: User): Promise<User> => {
  const data: User = {
    ...user
  };
  if (!data.preferences) {
    data.preferences = {};
  }
  data.preferences.lang = user.lang;
  return Api.put(`${USER_API_BASE_URL}profile`, user, getAuthHeader());
};

export const deleteUser = (userId: number): Promise<void> => {
  return Api.delete(`${USER_API_BASE_URL}${userId}`, getAuthHeader());
};

export const addUser = (user: User): Promise<User> => {
  return Api.post(USER_API_BASE_URL, user, getAuthHeader());
};

export const editUser = (user: User): Promise<User> => {
  const data: User = {
    ...user
  };
  if (user.lang) {
    if (!data.preferences) {
      data.preferences = {};
    }
    data.preferences.lang = user.lang;
  }
  return Api.put(`${USER_API_BASE_URL}${user.id}`, user, getAuthHeader());
};

interface UserWorkspaceOptions extends APIQueryOptions {
  userId: number;
  workspaceId: number;
}

export const deleteUserWs = (options: UserWorkspaceOptions): Promise<void> => {
  const data: APIDeleteData<UserWorkspaceOptions> = getAuthHeader();
  data.params = { ...options };

  return Api.delete(USERWS_API_BASE_URL, data);
};

export const updateUserWs = (values: UserWorkspaceOptions): Promise<void> => {
  return Api.put(USERWS_API_BASE_URL, values, getAuthHeader());
};

export const addUserWs = (values: UserWorkspaceOptions): Promise<void> => {
  return Api.post(USERWS_API_BASE_URL, values, getAuthHeader()).then(
    res => res.data
  );
};

interface UserRegionOptions extends APIQueryOptions {
  userId: number;
  regionId: number;
}

export const deleteUserRegions = (
  options: UserRegionOptions
): Promise<void> => {
  const data: APIDeleteData<UserRegionOptions> = getAuthHeader();
  data.params = {
    ...options
  };

  return Api.delete(USERREGION_API_BASE_URL, data);
};

export const updateUserRegions = (values: UserRegionOptions): Promise<void> => {
  return Api.put(USERREGION_API_BASE_URL, values, getAuthHeader());
};

export const addUserRegions = (values: UserRegionOptions): Promise<void> => {
  return Api.post(USERREGION_API_BASE_URL, values, getAuthHeader()).then(
    res => res.data
  );
};

interface UserDomainOptions extends APIQueryOptions {
  domainId: number;
  userId: number;
}

export const deleteUserDomain = ({
  userId,
  domainId
}: UserDomainOptions): Promise<void> => {
  const data: APIDeleteData<UserDomainOptions> = getAuthHeader();

  data.params = {
    userId,
    domainId
  };

  return Api.delete(USERDOMAIN_API_BASE_URL, data);
};

export const updateUserDomain = (values: UserDomainOptions): Promise<void> => {
  const data = {
    userId: values.userId,
    domainId: values.domainId
  };

  return Api.put(USERDOMAIN_API_BASE_URL, data, getAuthHeader());
};

export const addUserDomain = (values: UserDomainOptions): Promise<void> => {
  const data = {
    userId: values.userId,
    domainId: values.domainId
  };

  return Api.post(USERDOMAIN_API_BASE_URL, data, getAuthHeader()).then(
    res => res.data
  );
};

export const restoreUserById = (userId: number): Promise<User> => {
  return Api.get(`${USER_API_BASE_URL}restore/${userId}`, getAuthHeader()).then(
    res => res.data
  );
};

import React, { memo } from 'reactn';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import EnhancedTable from 'components/table/EnhancedTable';
import { customFormColumns } from './customForm/CustomFormColumns';

const AdminCustomForm = () => {
  const columns = React.useMemo(() => customFormColumns, []);

  return (
    <Grid item xs={12}>
      <Paper>
        <EnhancedTable
          linkTo="/dashboard/admin/formbuilder"
          endpoint="form"
          columns={columns}
          hiddenColumns={[]}
        />
      </Paper>
    </Grid>
  );
};

export default memo(AdminCustomForm);

import DateCell from 'components/table/DateCell';
import CheckboxCell from 'components/table/CheckboxCell';

export const locationColumns = [
  {
    Header: 'Id',
    accessor: 'id',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Name',
    accessor: 'name',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Admin Level',
    accessor: 'adminLevel',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'PCode',
    accessor: 'pcode',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Parent Id',
    accessor: 'parentId',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Parent name',
    accessor: 'parent.name',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'ISO 3',
    accessor: 'country.iso3',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Is City',
    accessor: 'isCity',
    disableFilters: true,
    sortType: 'basic',
    Cell: CheckboxCell
  },
  {
    Header: 'Is Capital',
    accessor: 'isCapital',
    disableFilters: true,
    sortType: 'basic',
    Cell: CheckboxCell
  },

  {
    Header: 'Updated at',
    accessor: 'updatedAt',
    disableFilters: true,
    sortType: 'basic',
    Cell: DateCell
  },
  {
    Header: 'Created at ',
    accessor: 'createdAt',
    disableFilters: true,
    sortType: 'basic',
    Cell: DateCell
  },
  {
    Header: 'Deleted at ',
    accessor: 'deletedAt',
    disableFilters: true,
    sortType: 'basic',
    Cell: DateCell
  },
  {
    Header: 'Archived at ',
    accessor: 'archivedAt',
    disableFilters: true,
    sortType: 'basic',
    Cell: DateCell
  }
];

export default {
  locationColumns
};

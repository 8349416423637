import settings from 'config';
import { AxiosRequestConfig } from 'axios';
import Api from '../utils/Api';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;
const USER_API_BASE_URL = `${BASE_URL}/user/`;

interface Credentials {
  email: string;
  password: string;
}

interface LostPasswordCredentials {
  email: string;
}

interface XlsxExport {
  responseType: 'arraybuffer';
  headers: { [key: string]: string };
}

export const register = (credentials: Credentials) => {
  return Api.post(`${USER_API_BASE_URL}signup`, credentials);
};

export const confirmEmail = (oneTimeToken: string) => {
  return Api.post(`${USER_API_BASE_URL}confirmEmail`, { oneTimeToken });
};

export const login = (credentials: Credentials) => {
  return Api.post(`${USER_API_BASE_URL}login`, credentials);
};

export const lostPassword = (credentials: LostPasswordCredentials) => {
  return Api.post(`${USER_API_BASE_URL}lostPassword`, credentials);
};

export const resetPassword = (credentials: Credentials) => {
  return Api.post(`${USER_API_BASE_URL}resetPassword`, credentials);
};

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem('userInfo') ?? '{}') || {};
};

export const currentSession = () => {
  return !!localStorage.getItem('userInfo');
};

export interface AuthHeader {
  headers: {
    Authorization: string;
  };
}

export const getAuthHeader = () => {
  return { headers: { Authorization: `Bearer ${getUserInfo().token}` } };
};

export const getXlsxExportHeader = (): XlsxExport => {
  return {
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${getUserInfo().token}`,
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  };
};

export const logOut = () => {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('token');
};

import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { Button, BaseDialog } from 'uninfo-components';

import Grid from '@mui/material/Grid';
import { Language, CloseRounded } from '@mui/icons-material';
import { cloneDeep } from 'lodash';

import { useFormContext } from 'react-hook-form';
import DialogActions from '@mui/material/DialogActions';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import { LocalTextFieldProps, TextField } from './TextField';

/**
 * A text field for useFormHook, requires FormProvider
 * @param {} param0
 */
export const I18nTextField: React.FC<LocalTextFieldProps> = ({ ...props }) => {
  const [openI18n, setOpenI18n] = React.useState(false);
  const [newLanguage, setNewLanguage] = React.useState('');
  const { getValues, setValue } = useFormContext();
  const propName = `${props.name}I18n`;
  const allExistingI18n = getValues(propName) ?? { en: '' };
  const defaultLanguages = allExistingI18n
    ? Object.keys(allExistingI18n).map(key => ({
        short: key
      }))
    : [{ short: 'en' }];
  const [languages, setLanguages] = React.useState(defaultLanguages);

  const setLanguageValues = React.useCallback(
    (currentLanguages: { [key: string]: string }[]) => {
      const currentValues = getValues(propName);

      const newValues = currentLanguages.reduce(
        (built: { [key: string]: string }, lang) => {
          built[lang.short] = currentValues[lang.short] ?? '';
          return built;
        },
        {} as { [key: string]: string }
      );

      setValue(propName, newValues);
    },
    [getValues, propName, setValue]
  );

  const addLanguage = React.useCallback(() => {
    const newLanguages = [...languages, { short: newLanguage }];
    setLanguages(newLanguages);
    setLanguageValues(newLanguages);
    setNewLanguage('');
  }, [languages, newLanguage, setLanguageValues]);

  const removeLanguage = React.useCallback(
    (idx: number) => {
      const temp = cloneDeep(languages);
      temp.splice(idx, 1);

      setLanguages(temp);
      setLanguageValues(temp);
    },
    [languages, setLanguageValues]
  );

  return (
    <>
      <TextField
        InputProps={{
          endAdornment: (
            <Badge
              badgeContent={Object.keys(allExistingI18n).length}
              color="primary"
              style={{ cursor: 'pointer' }}
            >
              <InputAdornment
                position="end"
                onClick={() => setOpenI18n(true)}
                style={{ cursor: 'pointer' }}
              >
                <Language />
              </InputAdornment>
            </Badge>
          )
        }}
        {...props}
      />
      <BaseDialog
        maxWidth="lg"
        open={openI18n}
        onClose={() => setOpenI18n(false)}
        title={`Translations for ${getValues(props.name)}`}
      >
        {languages.map((ln, idx) => (
          <Grid
            container
            key={ln.short}
            style={{ marginBottom: '1rem' }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={11}>
              <TextField
                name={`${propName}.${ln.short}`}
                label={`${props.label} in "${ln.short}"`}
                defaultValue={getValues(propName)}
                key={ln.short}
              />
            </Grid>
            {languages.length !== 1 && (
              <Grid item xs={1}>
                <CloseRounded onClick={() => removeLanguage(idx)} />
              </Grid>
            )}
          </Grid>
        ))}
        <hr />
        <Typography variant="body1">
          Want to add a new language? Use the form below:
        </Typography>
        <OutlinedInput
          value={newLanguage}
          onChange={(
            e:
              | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              | undefined
          ) => setNewLanguage(e?.target.value ?? '')}
        />
        <Button
          variant="outlined"
          onClick={addLanguage}
          disabled={newLanguage.length !== 2}
        >
          Add Language
        </Button>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: '1rem' }}
            onClick={() => setOpenI18n(false)}
          >
            Done
          </Button>
        </DialogActions>
      </BaseDialog>
    </>
  );
};

export default I18nTextField;

import BlueprintProgress from './BlueprintProgress';
import BlueprintUnsubmit from './BlueprintUnsubmit';

export const blueprintColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    aggregate: 'count',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Plan Type',
    accessor: 'planType.short',
    aggregate: 'count',
    sortType: 'basic'
  },
  {
    Header: 'Reports',
    aggregate: 'count',
    disableFilters: true,
    Cell: BlueprintProgress
  },
  {
    Header: 'Unsubmit',
    disableFilters: true,
    Cell: BlueprintUnsubmit
  }
];

export default {
  blueprintColumns
};

import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Button } from 'uninfo-components';

interface ErrorBoundaryProps {
  hasError: boolean;
  error: string;
}

class ErrorBoundary extends React.Component<unknown, ErrorBoundaryProps> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.toString() };
  }

  componentDidCatch(/* error: Error, errorInfo: any */): void {
    // You can also log the error to an error reporting service
    // TODO: We should do something
  }

  onClick = () => {
    window.location.reload();
    return false;
  };

  render(): JSX.Element | React.ReactNode {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Alert
          variant="outlined"
          severity="error"
          style={{
            marginTop: 50
          }}
          action={
            <Button color="inherit" size="small" onClick={this.onClick}>
              Reload App
            </Button>
          }
        >
          <AlertTitle>
            Oops! Something went wrong - please refresh the page.
          </AlertTitle>
          <p>{error}</p>
        </Alert>
      );
    }
    return children ?? null;
  }
}

export default ErrorBoundary;

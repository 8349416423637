import React from 'reactn';
import Grid from '@mui/material/Grid';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'uninfo-components';

import { TextField } from 'components/common/form/TextField';
import { addUnit, updateUnit } from 'services/UnitService';
import { DialogActions } from '@mui/material';

interface FormProps {
  unit?: Unit;
  afterSubmitAction: (arg0: Unit) => void;
  isEdit?: boolean;
}

const UnitForm: React.FC<FormProps> = ({
  unit,
  afterSubmitAction,
  isEdit = false
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { t } = useTranslation('common');
  const methods = useForm({
    defaultValues: unit ?? {
      name: ''
    }
  });
  const { handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    try {
      let newUnit;
      if (!isEdit) {
        newUnit = await addUnit(data);
      } else if (unit) {
        newUnit = await updateUnit({
          id: unit.id,
          ...data
        });
      }
      afterSubmitAction(newUnit);
    } catch (e) {
      console.error(`Error: ${(e as any).data.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={e => {
          e.stopPropagation();
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
            <TextField
              label={t('Name')}
              name="name"
              placeholder="Volunteers"
              required
            />
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              color="primary"
            >
              {isEdit ? 'Save' : 'Add'} Unit
            </Button>
          </DialogActions>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default UnitForm;

import React, { useGlobal } from 'reactn';
import { Navigate, useLocation } from 'react-router-dom';

type Props = {
  denyUserAccess?: () => boolean;
  children: React.ReactNode;
};
const AuthWrapper: React.FC<Props> = ({ denyUserAccess, children }) => {
  const location = useLocation();
  const [user] = useGlobal('user');
  const signedIn = user && user.id;
  if (
    !signedIn &&
    location.pathname !== '/' &&
    location.pathname !== '/register' &&
    location.pathname !== '/forgotPassword' &&
    location.pathname !== '/confirmEmail/' &&
    !location.pathname.startsWith('/confirmEmail') &&
    !location.pathname.startsWith('/resetPassword')
  ) {
    if (denyUserAccess && denyUserAccess()) {
      return <Navigate to="/" />;
    }
    localStorage.removeItem('userInfo');
    return <Navigate to={`/?next=${location.pathname}`} />;
  }
  if (denyUserAccess && denyUserAccess()) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

export default AuthWrapper;

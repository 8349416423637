import React, { useGlobal } from 'reactn';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Link, useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';

import {
  archiveById,
  deleteById,
  duplicate,
  unarchiveById
} from 'services/AdminService';

import { restoreUserById } from 'services/UserService';

import PermissionGate, {
  hasPermission
} from 'components/common/PermissionGate';
import { useTableContext } from './TableProvider';

interface ActionButtonsProps {
  row: any; // TODO: typing from react-table;
  endpoint: string;
  linkTo?: string;
  editRow: (index: number, endpoint: string) => void;
  preDeleteCheck?: (ids: number[]) => Promise<boolean>;
  archivable?: boolean;
  canDelete?: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  row,
  endpoint,
  linkTo,
  editRow,
  archivable,
  preDeleteCheck,
  canDelete
}) => {
  const [user] = useGlobal('user');
  const { dispatch } = useTableContext();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const deleteRow = async (id: number, deleteEndpoint: string) => {
    try {
      if (!preDeleteCheck) {
        await confirm({
          description: `Are you sure you want to ${
            archivable ? 'archive' : 'delete'
          } these ${endpoint}s?`
        });
      } else {
        await preDeleteCheck([id]);
      }

      if (id) {
        try {
          if (archivable) {
            await archiveById(deleteEndpoint, { id });
          } else {
            await deleteById(deleteEndpoint, id);
          }
          enqueueSnackbar(
            `${deleteEndpoint} ${archivable ? 'archived' : 'deleted'}`,
            {
              preventDuplicate: true
            }
          );
        } catch (e) {
          console.error(e);
        }
      }
      dispatch({ type: 'toggleRefresh' });
      return null;
    } catch (e) {
      return null;
    }
  };

  const restoreRow = async (id: number, restoreEndpoint: string) => {
    try {
      const description = `Are you sure you want to restore this ${restoreEndpoint}?`;

      await confirm({
        description
      });
      if (id) {
        try {
          if (restoreEndpoint === 'user') {
            await restoreUserById(id);
          } else {
            await unarchiveById(restoreEndpoint, id);
          }
          enqueueSnackbar(`${restoreEndpoint} restored`, {
            preventDuplicate: true
          });
        } catch (e) {
          console.error(e);
        }
      }
      dispatch({ type: 'toggleRefresh' });
      return null;
    } catch (e) {
      return null;
    }
  };

  const duplicateRow = async (item: any, duplicateEndpoint: string) => {
    if (item) {
      try {
        const res = await duplicate(item, duplicateEndpoint);
        if (linkTo) {
          navigate(`${linkTo}/${res.data.id}`);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const archived = row.original.deletedAt || row.original.archivedAt;

  let updateScope = `${endpoint}_update` as Scopes;
  if (endpoint === 'country') {
    updateScope = `location_update` as Scopes;
  }
  const viewScope = `${endpoint}_view` as Scopes;
  const deleteScope = `${endpoint}_softDelete` as Scopes;

  const workspaceIds = row.original.workspaceId
    ? { workspaceIds: [row.original.workspaceId as number] }
    : {};
  const regionIds = row.original.workspaceId
    ? { regionIds: [row.original.workspace.regionId as number] }
    : {};

  const displayEditIcon = hasPermission({
    user,
    ...workspaceIds,
    ...regionIds,
    scopes: updateScope
  });

  return (
    <div
      style={{
        textAlign: 'right',
        whiteSpace: 'nowrap'
      }}
    >
      {!archived && (
        <PermissionGate
          scopes={[viewScope, updateScope]}
          {...workspaceIds}
          {...regionIds}
        >
          <Tooltip title="Edit">
            <>
              {!linkTo && (
                <IconButton
                  aria-label="edit"
                  onClick={() => editRow(row.original.id, endpoint)}
                  size="large"
                >
                  {displayEditIcon ? <CreateIcon /> : <VisibilityIcon />}
                </IconButton>
              )}
              {linkTo && (
                <Link to={`${linkTo}/${row.original.id}`}>
                  <IconButton
                    aria-label="edit"
                    onClick={() => editRow(row.original.id, endpoint)}
                    size="large"
                  >
                    {displayEditIcon ? <CreateIcon /> : <VisibilityIcon />}
                  </IconButton>
                </Link>
              )}
            </>
          </Tooltip>
        </PermissionGate>
      )}
      {!archived && canDelete && endpoint !== 'organization' && (
        <PermissionGate scopes={deleteScope} {...workspaceIds} {...regionIds}>
          <>
            <Tooltip title="Archive">
              <IconButton
                aria-label="delete"
                onClick={() => deleteRow(row.original.id, endpoint)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        </PermissionGate>
      )}
      {user?.isAdmin && archived && (
        <PermissionGate scopes={updateScope} {...workspaceIds} {...regionIds}>
          <Tooltip title="Restore">
            <IconButton
              aria-label="restore"
              onClick={() => restoreRow(row.original.id, endpoint)}
              size="large"
            >
              <RestoreFromTrashIcon style={{ fill: 'white' }} />
            </IconButton>
          </Tooltip>
        </PermissionGate>
      )}

      {endpoint === 'blueprint' && (
        <Tooltip title="Duplicate">
          <IconButton
            aria-label="duplicate"
            onClick={() => duplicateRow(row.original, endpoint)}
            size="large"
          >
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default ActionButtons;

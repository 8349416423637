import React, { memo, useState } from 'reactn';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { BaseDialog } from 'uninfo-components';

import EnhancedTable from 'components/table/EnhancedTable';
import { add, edit } from 'services/AdminService';

import { isExisting } from 'utils/typeguards';
import useAddMultipleToggle from 'utils/useAddMultipleToggle';

import { DataItemForm } from './dataItem/DataItemForm';
import { dataItemColumns } from './dataItem/DataItemColumns';

const AdminDataItem = () => {
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<DataItem | DataItemNew>({});
  const {
    resetSwitch,
    switchState,
    ToggleInFormDescription
  } = useAddMultipleToggle();

  const columns = React.useMemo(() => dataItemColumns, []);

  async function handleDataItemSubmit(item: DataItem | DataItemNew) {
    try {
      let res;
      const dataItem = item;
      if (isExisting(editItem)) {
        res = await edit<DataItem>('dataitem', editItem.id, dataItem);
        setEditItem(res.data);
      } else {
        res = await add<DataItem>('dataitem', dataItem);
        setEditItem(res.data);
      }
      setEditItem({
        datasource: dataItem.datasource
      });
      // eslint-disable-next-line no-unused-expressions
      switchState.addMultiple ? setOpen(true) : setOpen(false);
    } catch (e) {
      console.error('error: ', e);
    }
  }

  const handleClose = () => {
    setOpen(false);
    resetSwitch();
  };

  return (
    <Grid item xs={12}>
      <Paper>
        <EnhancedTable
          setOpen={setOpen}
          setEditItem={setEditItem}
          endpoint="dataitem"
          hiddenColumns={[]}
          columns={columns}
        />
        <BaseDialog
          fullWidth
          open={open}
          onClose={handleClose}
          title="Add DataItem"
          description={<ToggleInFormDescription />}
        >
          <DataItemForm
            handleSubmit={handleDataItemSubmit}
            editItem={editItem}
          />
        </BaseDialog>
      </Paper>
    </Grid>
  );
};

export default memo(AdminDataItem);

export const userFullName = (user: User): string => {
  if (!user) {
    return '';
  }
  return [user.firstname, user.lastname].filter(n => !!n).join(' ');
};

export const userInitials = (user: User): string => {
  return (user.firstname?.[0] ?? '') + (user.lastname?.[0] ?? '');
};

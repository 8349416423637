import { format } from 'date-fns';

import { fetchAll } from 'services/AdminService';
import { fetchRoles } from 'services/RolesService';

import { TableInstance } from 'react-table';
import { omit } from 'lodash';

import { createXLSXFromData } from 'utils/export';
import { DATE_FORMAT } from 'utils/constants';
import config from 'config';
import { downloadFileThroughLinkHref } from './exportPDFUtils';

const turnRowIntoPlanTable = (original: Plan): unknown => {
  const currentTag = original.planTags[original.planTags.length - 1];
  return {
    planId: original.id,
    code: original.planVersion.code,
    blueprintId: original.blueprintId,
    workspace: original.workspace?.name,
    name: original.planVersion.name,
    planType: original.planType?.short,
    publishedStatus: currentTag
      ? `${currentTag.name} ${currentTag.public ? '(public)' : ''}`
      : '',
    createdAt: original.createdAt,
    updatedAt: original.updatedAt
  };
};

export const turnRowIntoUserTable = ({
  user,
  roles,
  workspaces,
  regions
}: {
  user: User;
  roles: Role[];
  workspaces: Workspace[];
  regions: Region[];
}): unknown => {
  const processedUser: any = {
    ...user,
    'Organization abbreviation': user.organization?.abbreviation,
    Organization: user.organization?.name,
    'User Roles': user.userRoles
      ?.map(ur =>
        [
          roles.find(r => r.id === ur.roleId)?.name,
          ur.contextWorkspaceId &&
            workspaces.find(w => w.id === ur.contextWorkspaceId)?.name,
          ur.contextRegionId &&
            regions.find(w => w.id === ur.contextRegionId)?.name,
          !ur.contextWorkspaceId && !ur.contextRegionId && 'Global'
        ]
          .filter(n => n)
          .join(' - ')
      )
      .filter((r: string, i: number, ra: string[]) => ra.indexOf(r) === i)
      .sort()
      .join(',  ')
  };
  delete processedUser.planSubmissions;
  delete processedUser.organization;
  delete processedUser.planEntities;
  delete processedUser.oneTimeToken;
  delete processedUser.preferences;
  delete processedUser.userRoles;
  delete processedUser.comments;

  return processedUser;
};

export const turnRowIntoDocumentTable = (
  document: DocumentAPI,
  regions: Region[]
): unknown => {
  return {
    ...omit(document, ['planDocuments']),
    planIds: document.planDocuments?.map(pd => pd.planId).join(', '),
    regionId: document.workspace?.regionId,
    fileDocumentType: document.fileDocumentType.name,
    workspace: document.workspace?.name,
    region: regions.find((r: any) => r.id === document.workspace?.regionId)
      ?.name
  };
};

export const unnestOrganizationContacts = (
  contacts: Contact[]
): UnnestedContact[] =>
  contacts.flatMap(r => {
    const { organizationContacts, ...c } = r;
    return organizationContacts?.length
      ? organizationContacts.map(oc => ({
          ...c,
          organizationContact: oc
        }))
      : [c];
  });

export const turnRowIntoContactTable = (
  contact: Partial<UnnestedContact>,
  regions: Region[]
) => {
  Object.keys(contact).forEach(key => {
    if (key.endsWith('Id')) {
      // @ts-ignore
      delete contact[key];
    }
  });

  delete contact.nameI18n;
  delete contact.createdAt;
  delete contact.deletedAt;
  delete contact.updatedAt;

  const { organizationContact } = contact;
  const workspaceOrganization = organizationContact?.workspaceOrganization;

  const isRco = workspaceOrganization?.organizationId === 50;

  delete contact.organizationContact;

  return {
    ...contact,
    inCountryPresence: organizationContact?.workspaceOrganization
      ?.inCountryPresence
      ? 'yes'
      : '',
    teamLead: organizationContact?.teamLead ? 'yes' : '',
    commsRCOContact: organizationContact?.commsRCOContact ? 'yes' : '',
    uncgChair: organizationContact?.uncgLead ? 'yes' : '',
    agency: workspaceOrganization?.organization?.name,
    unctMember: workspaceOrganization?.unctMember && !isRco ? 'yes' : '',
    uncgMember: workspaceOrganization?.uncgMember && !isRco ? 'yes' : '',
    workspace: workspaceOrganization?.workspace?.name,
    region: regions.find(
      (r: any) => r.id === workspaceOrganization?.workspace?.regionId
    )?.name,
    title: contact.title?.dataitemVersion.name,
    gender: contact.gender?.dataitemVersion.name,
    presence: isRco
      ? ''
      : 'workspaceOrganization?.presence?.dataitemVersion.name',
    contractModality:
      organizationContact?.contractModality?.dataitemVersion.name,
    group: organizationContact?.type,
    jobTitle: organizationContact?.jobTitle?.dataitemVersion.name,
    jobTitleOther: organizationContact?.jobTitleOther,
    postLevel: organizationContact?.postLevel?.dataitemVersion.name,
    dutyEntry: organizationContact?.dutyEntry,
    officeType: organizationContact?.officeType,
    officeCity: organizationContact?.officeCity,
    nationality: contact.nationality?.name,
    contributionKind:
      organizationContact?.contributionKind?.dataitemVersion.name,
    inKindPersonnelContribution:
      organizationContact?.inKindPersonnelContribution,
    areas: organizationContact?.areas
      ?.map((area: any) => `${area.dataitemVersion.name}`)
      .join(', '),
    financings: organizationContact?.financings
      ?.map((f: any) => `${f.dataitemVersion.name}`)
      .join(', '),
    financingOther: organizationContact?.financingOther,
    rcFunctions: organizationContact?.rcFunctions
      ?.map((f: any) => `${f.dataitemVersion.name}`)
      .join(', '),
    updatedAt: organizationContact?.updatedAt
  };
};

export const turnRowIntoLocationTable = (
  location: Partial<LocationAPI>
): unknown => {
  delete location.nameI18n;
  delete location.deletedAt;
  return {
    ...location,
    name: location?.name,
    parent: location.parent?.name,
    country: location.country?.name,
    countryISO: location.country?.iso3
  };
};

export const exportCurrentToExcel = async (
  endpoint: string | null,
  csv: ReactTableRow<any>[],
  visibleColumns: any[]
): Promise<void> => {
  let exportedData: any[] = [];
  if (csv && endpoint !== 'plan' && endpoint !== 'user') {
    exportedData = csv.map(row => {
      return row.cells
        .filter(
          cell =>
            !(cell.column.id === 'selection' || cell.column.id === 'actions')
        )
        .map(c => c.value);
    });
    exportedData.unshift(
      visibleColumns
        .filter(
          column => !(column.id === 'selection' || column.id === 'actions')
        )
        .map(c => c.Header)
    );
  } else if (csv && endpoint === 'plan') {
    exportedData = csv.map(row => turnRowIntoPlanTable(row.original));
  } else if (csv && endpoint === 'user') {
    const limit = 500;
    const roles = await fetchRoles();
    const workspaces = await fetchAll<Workspace>('workspace', {
      limit,
      exclude: [
        'countries',
        'parent',
        'organizations',
        'workspaceType',
        'children'
      ]
    }).then(response => {
      return response.data.results;
    });
    const regions = await fetchAll<Region>('region', {
      limit
    }).then(response => {
      return response.data.results;
    });
    exportedData = csv.map(row =>
      turnRowIntoUserTable({ user: row.original, roles, workspaces, regions })
    );
  }
  createXLSXFromData(exportedData, endpoint || 'view');
};

export const findFilter = (filters: any[], id: string): any[] | undefined => {
  return filters
    .find((f: any) => f?.id === id)
    ?.value?.map((v: any) => v?.id ?? v);
};

export const filterNestedOrganizationContacts = (
  organizationContacts: OrganizationContact[],
  filter: number[] | undefined,
  prop: 'workspaceId' | 'organizationId'
) => {
  if (!filter) return organizationContacts;
  return organizationContacts.filter(o => {
    const value = o.workspaceOrganization?.[prop];
    if (!value) return o;
    return filter.includes(value);
  });
};

export const filterBoolNestedOrganizationContacts = (
  organizationContacts: OrganizationContact[],
  filter: boolean[] | undefined,
  prop: 'teamLead' | 'commsRCOContact'
) => {
  if (!filter) return organizationContacts;
  return organizationContacts.filter(o => {
    const value = o[prop];
    return filter.includes(value);
  });
};

export const filterDataItemNestedOrganizationContacts = (
  organizationContacts: OrganizationContact[],
  filter: number[] | undefined,
  prop: 'jobTitle'
) => {
  if (!filter) return organizationContacts;
  return organizationContacts.filter(o => {
    const value = o[prop]?.dataitemVersion.dataitemId;
    return filter.includes(value ?? 0);
  });
};

export const exportFullToExcel = async ({
  endpoint,
  table,
  options
}: {
  endpoint: string | null;
  table: TableInstance;
  options: any;
}): Promise<void> => {
  const limit = 50000;
  try {
    let exclude;
    if (endpoint === 'plan') {
      exclude = ['assessments', 'entityPrototypes', 'planSubmissions'];
    } else if (endpoint === 'user') {
      exclude = ['planSubmissions', 'preferences', 'comments'];
    }
    const res = await fetchAll(endpoint, {
      limit,
      sort: table.state.sortBy.map(sortObject => {
        return [sortObject.id, sortObject.desc ? 'DESC' : 'ASC'];
      }),
      exclude,
      filters: options?.filters,
      globalFilter: options?.globalFilter,
      includeArchived: options?.includeArchived
    });
    let data = res.data.results;

    if (endpoint === 'user') {
      const roles = await fetchRoles();
      const workspaces = await fetchAll<Workspace>('workspace', {
        limit,
        exclude: [
          'countries',
          'parent',
          'organizations',
          'workspaceType',
          'children'
        ]
      }).then(response => {
        return response.data.results;
      });
      const regions = await fetchAll<Region>('region', {
        limit,
        exclude: ['workspaces']
      }).then(response => {
        return response.data.results;
      });
      data = (data as User[]).map(user =>
        turnRowIntoUserTable({ user, roles, workspaces, regions })
      );
    }

    if (endpoint === 'fileDocument') {
      const regions = await fetchAll<Region>('region', {
        limit
      }).then(response => {
        return response.data.results;
      });
      data = (data as DocumentAPI[]).map(d =>
        turnRowIntoDocumentTable(d, regions)
      );
    }

    if (endpoint === 'contact') {
      const regions = await fetchAll<Region>('region', {
        limit
      }).then(response => {
        return response.data.results;
      });

      const filterWorkspaces = findFilter(options.filters, 'workspaceId');
      const filterOrganizations = findFilter(options.filters, 'organizationId');
      const filterTeamLeaders = findFilter(options.filters, 'teamLead');
      const filterCommsRCOContact = findFilter(
        options.filters,
        'commsRCOContact'
      );
      const filterJobTitleContact = findFilter(options.filters, 'jobTitleId');

      data = (data as Contact[]).map(c => {
        let { organizationContacts } = c;
        organizationContacts = filterNestedOrganizationContacts(
          organizationContacts,
          filterWorkspaces,
          'workspaceId'
        );
        organizationContacts = filterNestedOrganizationContacts(
          organizationContacts,
          filterOrganizations,
          'organizationId'
        );
        organizationContacts = filterBoolNestedOrganizationContacts(
          organizationContacts,
          filterTeamLeaders,
          'teamLead'
        );
        organizationContacts = filterBoolNestedOrganizationContacts(
          organizationContacts,
          filterCommsRCOContact,
          'commsRCOContact'
        );
        organizationContacts = filterDataItemNestedOrganizationContacts(
          organizationContacts,
          filterJobTitleContact,
          'jobTitle'
        );

        return {
          ...c,
          organizationContacts
        };
      });

      if (regions?.length) {
        data = unnestOrganizationContacts(data as Contact[]).map(contact =>
          turnRowIntoContactTable(contact, regions)
        );
      }
    }

    if (endpoint === 'location') {
      data = (data as LocationAPI[]).map(turnRowIntoLocationTable);
    }

    if (endpoint === 'plan') {
      data = (data as Plan[]).map(turnRowIntoPlanTable);
    }

    createXLSXFromData(data, `${endpoint}-${format(new Date(), DATE_FORMAT)}`);
  } catch (e) {
    console.error(e);
  }
};

const setFileName = (title: string) => {
  const fileTitle = title.split(' ').join('_');
  return fileTitle;
};

/**
 * edits `html` in place from "chart id"
 * @param chart
 * @param html
 */
export const addTables = (chart: string, html: string[]): void => {
  const nodes = document.querySelectorAll(`[id^=${chart}]`);
  nodes.forEach(n => {
    const longName =
      nodes.length > 1
        ? n.id.replace(chart, '').replace(/'\*|\/|:|\?|\[|\]/, '')
        : n.id;
    const shortName = longName; // snakeKeyToSpaced(longName).substr(0, 30);
    html.push(`<table name="${shortName}">
      <tr><td>
        <strong>${longName}</strong>
      </td></tr>
      <tr><td></td></tr>
      ${n?.innerHTML.replace(/&nbsp;/g, '')}</table>`);
  });
};

export const downloadDocraptorExcel = async (
  tableIds: string[] | string,
  fileName: string
): Promise<void> => {
  try {
    const html: string[] = [];
    if (Array.isArray(tableIds)) {
      html.push('<tables>');
      // eslint-disable-next-line no-restricted-syntax
      for (const chart of tableIds) {
        addTables(chart, html);
      }
      html.push('</tables>');
    } else {
      addTables(tableIds, html);
    }

    const options = {
      test: config.NODE_ENV === 'production' || config.NODE_ENV === 'staging', // test documents are free but watermarked
      document_content: html.join(''),
      // "document_url": "http://docraptor.com/examples/invoice.html", // or use a url
      type: 'xlsx', // pdf or xls or xlsx
      user_credentials: 'nrLagE31tGeS198le0pQ',
      // javascript: true, // enable JavaScript processing
      // ignore_resource_errors: false,
      prince_options: {
        // media: "screen", // use screen styles instead of print styles
        // TODO: this will always need to be either stage or prod, can't be localhost.
        baseurl: 'https://dco-uninfo-data-portal-staging.dev.devqube.io' // pretend URL when using document_content
      }
    };
    const result = await fetch('https://docraptor.com/docs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(options)
    });

    const blob = await result.blob();
    const newBlob = new Blob([blob]);

    const blobUrl = window.URL.createObjectURL(newBlob);

    downloadFileThroughLinkHref(
      blobUrl,
      `${setFileName(fileName ?? 'downloaded-report')}.xlsx`
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.URL.revokeObjectURL(blob);
  } catch (e) {
    console.error(e);
  }
};

export default {
  exportFullToExcel,
  exportCurrentToExcel
};

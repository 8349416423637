import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { autocomplete } from 'services/AutocompleteService';
import { isExisting } from 'utils/typeguards';
import { TextField } from 'components/common/form/TextField';
import { Select } from 'components/common/form/Select';
import { DialogActions } from '@mui/material';
import { Button } from 'uninfo-components';

interface NewDataItem {
  name: string;
  datasourceId: number;
}

interface DataItemForm {
  editItem: DataItem | DataItemNew;
  handleSubmit: (item: NewDataItem) => void;
}

export const DataItemForm: React.FC<DataItemForm> = ({
  editItem,
  handleSubmit
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');
  const [dataSources, setDataSources] = useState<
    { label: string; value: number }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    name: isExisting(editItem) ? editItem.dataitemVersion?.name ?? '' : '',
    datasource: editItem.datasource || ''
  };

  const methods = useForm({
    defaultValues: initialValues
  });

  const { handleSubmit: handleFormSubmit, reset } = methods;

  const fetchedDataSources = useCallback(async () => {
    try {
      setLoading(true);
      const res = await autocomplete<DataSource>('datasource', { limit: 1000 });
      setDataSources(
        res.map(ds => ({
          label: ds.name,
          value: ds.id
        }))
      );
      setLoading(false);
    } catch (e) {
      enqueueSnackbar(`Error : ${(e as any).data.message}`, {
        preventDuplicate: true,
        variant: 'error'
      });
      console.error((e as any).data);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    setLoading(true);
    fetchedDataSources();
    setLoading(false);
  }, [fetchedDataSources]);

  const onSubmitCallback = async (data: any) => {
    handleSubmit(data);
    reset({
      name: '',
      datasource: editItem.datasource
    });
  };

  return (
    <div>
      {!loading && (
        <FormProvider {...methods}>
          <form
            onSubmit={e => {
              e.stopPropagation();
              e.preventDefault();
              handleFormSubmit(onSubmitCallback)(e);
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label={t('Name')}
                  name="name"
                  placeholder="Volunteers"
                  required
                  hasLabel
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  withTitle
                  name="datasourceId"
                  label="Data Source"
                  options={dataSources}
                />
              </Grid>

              <DialogActions>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {!isExisting(editItem) ? 'Add' : 'Edit'}
                </Button>
              </DialogActions>
            </Grid>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default {
  DataItemForm
};

import settings from 'config';
import Api from '../utils/Api';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

const CONTACT_API_BASE_URL = `${BASE_URL}/contact/`;
const CONTACT_ORGANIZATION_URL = `${BASE_URL}/organizationContact/`;
interface FetchContactOptions extends APIQueryOptions {
  workspaceRoleIds?: number[];
  workspaceIds?: number[];
  organizationId?: number | number[];
  type?: string | string[];
  email?: string;
  limit?: number | null;
}

export const fetchContacts = async (
  options: FetchContactOptions
): Promise<Contact[]> => {
  const data = {
    ...options
  };
  return Api.post(`${CONTACT_API_BASE_URL}search`, data, getAuthHeader()).then(
    res => res.data.results
  );
};

export const fetchContactById = (contactId: number): Promise<Contact> => {
  return Api.get(`${CONTACT_API_BASE_URL}${contactId}`, getAuthHeader()).then(
    res => res.data
  );
};

export const addContact = (contact: Partial<Contact>): Promise<Contact> => {
  return Api.post(CONTACT_API_BASE_URL, contact, getAuthHeader()).then(
    res => res.data
  );
};

export const deleteContact = (contactId: number): Promise<void> => {
  return Api.delete(CONTACT_API_BASE_URL + contactId, getAuthHeader());
};

export const editContact = (contact: Contact): Promise<Contact> => {
  const data: Contact = {
    ...contact
  };
  return Api.put(
    `${CONTACT_API_BASE_URL}${contact.id}`,
    data,
    getAuthHeader()
  ).then(res => res.data);
};

export const addContactToOrganization = (
  contactId: number,
  workspaceOrganizationId: number,
  data: Partial<OrganizationContact>
): Promise<void> => {
  return Api.post(
    CONTACT_ORGANIZATION_URL,
    { contactId, workspaceOrganizationId, ...data },
    getAuthHeader()
  ).then(res => res.data);
};

export const editContactToOrganization = (
  id: number,
  data: Partial<OrganizationContact>
): Promise<void> => {
  return Api.put(
    `${CONTACT_ORGANIZATION_URL}${id}`,
    data,
    getAuthHeader()
  ).then(res => res.data);
};

export const deleteOrganizationContact = (id: number): Promise<void> => {
  return Api.delete(`${CONTACT_ORGANIZATION_URL}${id}`, getAuthHeader()).then(
    res => res.data
  );
};

export const searchOrganizationContacts = (data: {
  contactIds?: number[];
  workspaceOrganizationIds: number[];
  limit?: number | null;
}): Promise<OrganizationContact[]> => {
  return Api.post(
    `${CONTACT_ORGANIZATION_URL}search`,
    data,
    getAuthHeader()
  ).then(res => res.data.results);
};

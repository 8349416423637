import config from '../../../config';

export const downloadFileThroughLinkHref = (
  href: string,
  name: string
): void => {
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

const setFileName = (title: string) => {
  const fileTitle = title.split(' ').join('_');
  return fileTitle;
};

export const sendToDocraptorAndDownload = async (
  fileName: string,
  html: string
): Promise<void> => {
  const options = {
    test: config.NODE_ENV !== 'production', // test documents are free but watermarked
    document_content: html,
    // "document_url": "http://docraptor.com/examples/invoice.html", // or use a url
    type: 'pdf', // pdf or xls or xlsx
    user_credentials: 'nrLagE31tGeS198le0pQ',
    // javascript: true, // enable JavaScript processing
    // ignore_resource_errors: false,
    prince_options: {
      media: 'screen', // use screen styles instead of print styles
      // TODO: this will always need to be either stage or prod, can't be localhost.
      baseurl: window.location.origin
    }
  };
  const result = await fetch('https://docraptor.com/docs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(options)
  });

  const blob = await result.blob();
  const newBlob = new Blob([blob]);

  const blobUrl = window.URL.createObjectURL(newBlob);

  downloadFileThroughLinkHref(
    blobUrl,
    `${setFileName(fileName ?? 'downloaded-report')}.pdf`
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.URL.revokeObjectURL(blob);
};

export const pdfTemplate = (
  body: string,
  fileName: string,
  options: { styles?: string } = {}
): string => {
  return `<!DOCTYPE html>
        <head>
          ${document.querySelector('head')?.innerHTML ?? ''}
          <style>
            .MuiGrid-root {
              max-height: inherit !important;
            }

            .hide-from-print {
              display: none !important;
            }

            .doc-wrapper > .MuiGrid-container {
              page-break-inside: auto;
              page-break-before: always;
              display: block;
            }

            body, table, th, td {
              font-size: 9px !important;
            }
            
            h2 {
              font-size: 18px !important;
            }

            h3 {
              margin: 30px 0 20px !important;
            }

            h4 {
              font-size: 18px !important;
            }

            h5 { 
              font-size: 16px !important;
            }
      
            h6 {
              font-weight: normal !important;
              font-size: 16px !important;
            }
            ${options.styles ?? ''}

          </style>
        </head>
        <body>
          <div style="display: flex; align-items: center; margin-bottom: 20px">
            <img src="${
              window.location.origin
            }/logos/undg_logo.png" alt="UN Development Goals" class="makeStyles-undgLogo-9" width="170"/>
            <img style="margin-right: 20px" src="${
              window.location.origin
            }/logos/logo-uninfo-small.png" alt="UN Info" class="makeStyles-undgLogo-9" width="100"/>
          </div>
          <div class="doc-wrapper">
          <h1>${fileName}</h1>
          ${body}
          </div>
        </body>
      </html>`;
};

export default {
  pdfTemplate
};

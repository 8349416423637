import * as FileSaver from 'file-saver';
import { unparse } from 'papaparse';

const fileType = 'text/csv;charset=utf-8';
const fileExtension = '.csv';

export const createXLSXFromData = (
  data: unknown[],
  fileName: string,
  columns: string[] = []
): void => {
  const cleanedData = data.map(row => {
    return row && typeof row === 'object'
      ? Object.entries(row).reduce((p, [k, v]) => {
          if (v && typeof v === 'object') {
            return { ...p };
          }
          return { ...p, [k]: v };
        }, {})
      : row;
  });
  const BOM = '\uFEFF';
  let csv = unparse(cleanedData, {
    columns: columns?.length ? columns : undefined
  });
  csv = BOM + csv;
  const file = new Blob([csv], { type: fileType });
  FileSaver.saveAs(file, `${fileName}${fileExtension}`);
};

export default {
  createXLSXFromData
};

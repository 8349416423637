import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}));

export type SelectOnChangeType = (target: {
  value: unknown;
  name?: string;
}) => void;

interface SelectProps {
  label: string;
  name: string;
  options: { label: string; value: string | number | null }[];
  id?: string;
  formControlFullWidth?: boolean;
  className?: string;
  required?: boolean;
  onChange?: SelectOnChangeType;
  tooltipText?: string;
  withTitle?: boolean;
  disabled?: boolean;
}

/**
 * A text field for useFormHook
 * @param {} param0
 */
export const Select: React.FC<SelectProps> = ({
  label,
  name,
  options,
  tooltipText,
  required,
  id,
  onChange: onChangeCallback,
  formControlFullWidth = true,
  withTitle = false,
  disabled = false,
  ...extraProps
}) => {
  const classes = useStyles();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, ...props }) => (
        <>
          {tooltipText && (
            <Tooltip title={tooltipText} arrow>
              <InputLabel id={id ?? `${label}-select`}>
                {label} {required ? '*' : ''}
              </InputLabel>
            </Tooltip>
          )}
          {!tooltipText && withTitle && (
            <InputLabel id={id ?? `${label}-select`}>
              {label} {required ? '*' : ''}
            </InputLabel>
          )}
          <FormControl fullWidth={formControlFullWidth}>
            <TextField
              {...props}
              {...extraProps}
              size="small"
              label={label && !withTitle ? label : null}
              inputProps={{ id, 'data-testid': id }}
              InputLabelProps={{ shrink: !withTitle }}
              select
              onChange={(
                e: React.ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                onChange(e);
                if (onChangeCallback) {
                  onChangeCallback(e.target);
                }
              }}
              required={required}
              disabled={disabled}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value ?? undefined}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </>
      )}
    />
  );
};

export default Select;

import React, { memo, useState } from 'reactn';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { isEmpty, sumBy } from 'lodash';
import { useConfirm } from 'material-ui-confirm';

import EnhancedTable from 'components/table/EnhancedTable';
import { add, edit } from 'services/AdminService';
import useAddMultipleToggle from 'utils/useAddMultipleToggle';
import { isExisting } from 'utils/typeguards';
import { BaseDialog } from 'uninfo-components';
import { findEntitiesForLocations } from 'services/LocationService';

import { locationColumns } from './LocationColumns';
import { LocationForm } from './LocationForm';

export interface NestedLocation extends LocationAPI {
  location: LocationAPI;
}

const LocationsList: React.FC<{
  country: Country;
  refreshCountry: () => Promise<void>;
}> = ({ country, refreshCountry }) => {
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<LocationAPI>({
    parent: country.children.find(
      c => c.adminLevel === 0 && c.parentId === null
    )
  } as LocationAPI);
  const {
    resetSwitch,
    switchState,
    ToggleInFormDescription
  } = useAddMultipleToggle();
  const columns = React.useMemo(() => locationColumns, []);
  const confirm = useConfirm();

  const handleSubmit = React.useCallback(
    async (item: NestedLocation) => {
      try {
        if (item.location) {
          item.countryId = item.location?.country?.id;
          item.parentId = item.location.id;
          item.adminLevel =
            parseInt(`${item.location.adminLevel}`, 10) + 1 ||
            editItem.adminLevel;
        } else {
          item.adminLevel = 0;
        }
        if (isExisting(editItem)) {
          await edit<Location>('location', editItem.id, item);
        } else {
          await add<Location>('location', item);
        }
        if (item.adminLevel === 0) {
          refreshCountry();
        }
        if (switchState.addMultiple) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      } catch (e) {
        console.error('error: ', e);
      }
    },
    [editItem, refreshCountry, switchState.addMultiple]
  );

  const handleClose = () => {
    setOpen(false);
    setEditItem({} as LocationAPI);
    resetSwitch();
  };

  const preDeleteCheck = React.useCallback(
    async (ids: number[]): Promise<boolean> => {
      try {
        const links = await findEntitiesForLocations(ids);
        const totalsLinked = sumBy(links.locations, 'entities.total');
        const description = (
          <>
            Are you sure you want to archive this location?{' '}
            {totalsLinked > 0 && (
              <div>
                Each is linked to:{' '}
                {links.locations.map(location => (
                  <div key={location.id}>
                    <strong>{location.id}</strong>: {location.entities.total}{' '}
                    entities
                  </div>
                ))}
              </div>
            )}
          </>
        );
        await confirm({
          description
        });
        return true;
      } catch (e) {
        throw new Error('Not confirmed');
      }
    },
    [confirm]
  );

  return (
    <Grid item xs={12}>
      <Paper>
        <EnhancedTable
          setOpen={setOpen}
          setEditItem={setEditItem}
          archivable
          allowMerge
          endpoint="location"
          columns={columns}
          preDeleteCheck={preDeleteCheck}
          additionalQueryParams={[{ id: 'countryId', value: [country.id] }]}
          hiddenColumns={[]}
        />
        <BaseDialog
          open={open}
          onClose={handleClose}
          description={<ToggleInFormDescription />}
          title={`${!isEmpty(editItem) ? 'Edit' : 'Add'} Location`}
        >
          <LocationForm
            editItem={editItem}
            handleSubmit={handleSubmit}
            country={country}
          />
        </BaseDialog>
      </Paper>
    </Grid>
  );
};

export default memo(LocationsList);

import Api from 'utils/Api';
import { BASE_URL } from 'utils/constants';
import { getAuthHeader } from './AuthService';

const SURVEY_URL = `${BASE_URL}/planSurvey/`;

export interface SurveyResponse {
  id: number;
  planSurveyId: number;
}

export interface SurveySignatoryResponse extends SurveyResponse {
  organizationId?: number;
  organization?: Organization;
  workspace?: Workspace;
  workspaceId?: number;
  surveySignatoryVersion?: {
    [key: string]: any;
  };
}

export interface SurveyFundingResponse extends SurveyResponse {
  surveyFundingVersion: {
    [key: string]: any;
  };
}

export interface SurveyPreparationResponse extends SurveyResponse {
  surveyPreparationVersion: {
    [key: string]: any;
  };
}

export interface SurveyEvaluationResponse extends SurveyResponse {
  surveyEvaluationVersion: {
    [key: string]: any;
  };
}

export type SurveyNameOptions =
  | 'signatory'
  | 'funding'
  | 'preparation'
  | 'evaluation';

export const searchPlanSurveys = <T>(data: {
  surveyName?: SurveyNameOptions;
  planSurveyId?: number;
  planId?: number;
  limit?: number | null;
  version?: string | null;
}): Promise<APIResults<T>> => {
  return Api.post(`${SURVEY_URL}search`, data, getAuthHeader()).then(
    resp => resp.data
  );
};

export const getPlanSurveyFundingReport = (
  id: number,
  year?: number
): Promise<APISingleResult<{
  isAnnualFundingCompleteForThisYear: boolean;
  amountForRequiredResources: number;
  amountForAvailableResources: number;
  amountForAvailableResourcesAnnualFunding: number;
  isAnnualFundingIncludeAllRequiredResources: boolean;
  isAnnualFundingIncludeAllAvailableResources: boolean;
  isAnnualFundingDisaggregatedByTypeOfFunding: boolean;
  isAnnualFundingDisaggregatedBySourceOfFunding: boolean;
  isAnnualFundingDisaggregatedByUNEntities: boolean;
  currentYear: number;
}>> => {
  let url = `${SURVEY_URL}fundingReport/${id}`;
  if (year) {
    url += `/${year}`;
  }
  return Api.get(url, getAuthHeader()).then(resp => resp.data);
};

export const updatePlanSurveys = (
  id: number,
  data: {
    surveyName: string;
    organizationId?: number;
    workspaceId?: number;
  }
): Promise<void> => {
  return Api.put(`${SURVEY_URL}${id}`, data, getAuthHeader());
};

export const createPlanSurveys = (data: {
  planId: number;
  surveyName: string;
  workspaceId?: number;
  organizationId?: number;
}): Promise<unknown> => {
  return Api.post(`${SURVEY_URL}`, data, getAuthHeader());
};

import React, { setGlobal } from 'reactn';
import addReactNDevTools from 'reactn-devtools';
import ReactDOM from 'react-dom';
import './index.css';
import settings from 'config';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import './i18n';
import 'typeface-roboto';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: settings.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

addReactNDevTools();

setGlobal({
  user: {},
  currentWorkspace: null,
  drawer: 'full',
  loading: false
});

if (settings.MAINTENANCE === true) {
  ReactDOM.render(
    <h1 style={{ textAlign: 'center' }}>
      UNINFO is on maitenance mode please come back later
    </h1>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}

serviceWorker.register({
  onUpdate: (registration: any) => {
    alert('A new version is available. Click to proceed');
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }
});

import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CircularProgress from 'components/dialogs/CircularProgress';
import { SUBOUTPUT_ENTITY_ID } from 'utils/constants';
import { styled } from '@mui/material/styles';
import { BaseDialog, Button, Table } from 'uninfo-components';

import { fetchEntities, fetchPlanById } from 'services/PlanService';
import {
  getPlanSurveyFundingReport,
  SurveyFundingResponse
} from 'services/PlanSurveyService';
import { QuestionForm } from './QuestionForm';
import {
  createDefaultValues,
  createOrUpdate,
  Datum,
  fetchPlanAndBuildSurveyForm,
  Question
} from './utils';
import LoadingBlocks from './LoadingBlocks';

const LinkWrapper = styled('div')`
  cursor: pointer;
  max-width: 20rem;
`;

const CalculatedOutputLinks = ({
  surveyVersion,
  key
}: {
  surveyVersion?: SurveyFundingResponse['surveyFundingVersion'];
  key: string;
}): React.ReactElement | null => {
  const params = useParams();
  const suboutputIds = surveyVersion?.[key];
  const [open, setOpen] = React.useState(false);
  const [urlParams, setUrlParams] = React.useState<PlanEntity[]>([]);
  const [souEntityPrototypeId, setSOUEntityPrototypeId] = React.useState<
    number
  >();

  const fetchPlan = React.useCallback(
    async (id: number) => {
      const plan = await fetchPlanById(id);
      const subOutputEntityPrototype = plan.entityPrototypes.find(
        ep => ep.coreentityTypeId === SUBOUTPUT_ENTITY_ID
      );
      setSOUEntityPrototypeId(subOutputEntityPrototype?.id);

      const entities = await fetchEntities({
        planEntityIds: suboutputIds,
        exclude: [
          'supportedBy',
          'supporting',
          'users',
          'organizations',
          'locations',
          'focalUsers',
          'dataitems',
          'planEntityCells',
          'planEntityDataItems',
          'planEntityLocations',
          'sustainableDevelopmentGoals',
          'sustainableDevelopmentTargets',
          'plan'
        ]
      });

      setUrlParams(entities);
    },
    [suboutputIds]
  );

  React.useEffect(() => {
    if (params.id && suboutputIds.length) {
      fetchPlan(+params.id);
    }
  }, [fetchPlan, params.id, suboutputIds.length]);

  if (
    suboutputIds.length === 0 ||
    !souEntityPrototypeId ||
    urlParams.length === 0
  ) {
    return null;
  }

  return (
    <LinkWrapper>
      <Button
        onClick={() => setOpen(true)}
        variant="text"
        style={{ padding: 0 }}
      >
        {urlParams.length ?? '...'} Suboutputs missing data
      </Button>
      <BaseDialog
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
        title="Bulk Create Locations"
      >
        <Table>
          <tbody>
            {urlParams?.map((entity: PlanEntity) => {
              const p = new URLSearchParams();
              p.set('parentId', `${entity.parentId ?? ''}`);
              p.set('planEntityId', `${entity.id}`);

              return (
                <tr key={p.toString()}>
                  <td>
                    <Link
                      target="_blank"
                      to={`/dashboard/plan/${
                        params.id
                      }/build/prototype/${souEntityPrototypeId}?${p.toString()}`}
                    >
                      {entity.planEntityVersion?.name}
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </BaseDialog>
    </LinkWrapper>
  );
};

const getYear = () => {
  const currentDate = new Date();

  let year = currentDate.getFullYear();
  if (currentDate.getMonth() < 3) {
    year -= 1;
  }
  return year;
};

export const questions: Question[] = [
  {
    question: 'C.1.3.7.2 - Multi-year Funding Framework:',
    slug: 'c1372',
    type: 'title'
  },
  {
    question: `C.1.3.7 - Do you have a Multi-year Funding Framework? *`,
    slug: 'haveMultiYearFundingFramework',
    type: 'dataitem',
    filterId: 432,
    required: true
  },
  {
    question: `C.1.3.7.1 - Is the Multi-year Funding Framework available
    and finalized in UN INFO? *`,
    slug: 'isMultiYearFinalized',
    type: 'boolean',
    dependsOn: 'haveMultiYearFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false,
    required: true
  },
  {
    question: `a - All outputs have identified the full cost of delivering
    the results (i.e. have required resources) [QCPR: 4.5.5] *`,
    slug: 'isOutputsIdentifiedFullCost',
    type: 'boolean',
    dependsOn: 'haveMultiYearFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false,
    required: true
  },
  {
    question: 'b - All outputs have identified available resources *',
    slug: 'isOutputsIdentifiedAvailableResources',
    type: 'boolean',
    dependsOn: 'haveMultiYearFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false,
    required: true
  },
  {
    question: `c - The data is disaggregated by UN entity *`,
    slug: 'isDataDisaggregatedByEntity',
    type: 'boolean',
    dependsOn: 'haveMultiYearFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false,
    required: true
  },
  {
    question: `d - Available resources are disaggregated by source
      of funding; [QCPR: 4.5.5]*`,
    slug: 'isAvailableResourcesDisaggregatedBySource',
    type: 'boolean',
    dependsOn: 'haveMultiYearFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false,
    required: true
  },
  {
    question: `e - Available resources are disaggregated by type
    of funding; [QCPR: 4.5.5]*`,
    slug: 'isAvailableResourcesDisaggregatedByType',
    type: 'boolean',
    dependsOn: 'haveMultiYearFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false,
    required: true
  },
  {
    question: `C.1.3.7.3 - $ amount for <strong>required</strong> resources of the
    Multi-year Funding Framework* (Sum of all outputs - automatically calculated)`,
    slug: 'amountForRequiredResourcesCalculated',
    type: 'value',
    valueType: 'money',
    dependsOn: 'haveMultiYearFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes, and') ?? false
  },
  {
    question: `C.1.3.7.4 - $ amount for <strong>available</strong> resources of the
      Multi-year Funding Framework* (Sum of all outputs - automatically calculated)`,
    slug: 'amountForAvailableResourcesCalculated',
    type: 'value',
    valueType: 'money',
    dependsOn: 'haveMultiYearFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes, and') ?? false
    // required: true
  },
  {
    question: `C.1.3.7.3 - $ amount for <strong>required</strong> resources of the
    Multi-year Funding Framework* (Sum of all outputs)`,
    slug: 'amountForRequiredResources',
    type: 'numberField',
    required: true,
    dependsOn: 'haveMultiYearFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes, but') ?? false
  },
  {
    question: `C.1.3.7.4 - $ amount for <strong>available</strong> resources of the
      Multi-year Funding Framework* (Sum of all outputs)`,
    slug: 'amountForAvailableResources',
    type: 'numberField',
    required: true,
    dependsOn: 'haveMultiYearFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes, but') ?? false
  },
  {
    question: `Annual funding framework for ${getYear()}`,
    slug: 'annualFundingFramework',
    type: 'title',
    description: `The data for these questions is pulled automatically from your JWP.  If you wish to amend it please amend it in your JWP. If your CF follows a fiscal year, then the data displayed here is for the fiscal year beginning with ${getYear()}`
  },
  {
    question: 'C.1.3.7.5 - Do you have an Annual Funding Framework?*',
    slug: 'haveAnnualFundingFramework',
    type: 'dataitem',
    filterId: 434,
    required: true,
    description: `If your Annual Funding Framework is NOT on UN INFO please answer No.`
  },
  {
    question: `
        C.1.3.7.6a - $ amount for available resources of the annual funding
        framework (Sum of all suboutputs <strong>for all years</strong>  - automatically calculated)*
      `,
    slug: 'amountForAvailableResourcesAnnualFunding',
    type: 'value',
    dependsOn: 'haveAnnualFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false,
    valueType: 'money'
  },
  {
    question: `C.1.3.7.6b - $ amount for available resources of the
    annual funding framework (Sum of all suboutputs <strong>for this year</strong>  - automatically calculated)*`,
    slug: 'amountForAvailableResourcesAnnualFundingCurrentYear',
    type: 'value',
    dependsOn: 'haveAnnualFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes'),
    valueType: 'money'
  },
  {
    question: `C.1.3.7.7 - Is annual funding complete for this year? (automatically calculated) *`,
    slug: 'isAnnualFundingCompleteForThisYear',
    type: 'value',
    valueType: 'boolean',
    dependsOn: 'haveAnnualFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false,
    Calculated: ({ value }: { value?: unknown }): React.ReactElement => {
      const params = useParams();
      const [souEntityPrototypeId, setSOUEntityPrototypeId] = React.useState<
        number
      >();

      const fetchPlan = React.useCallback(async (id: number) => {
        const plan = await fetchPlanById(id);
        const subOutputEntityPrototype = plan.entityPrototypes.find(
          ep => ep.coreentityTypeId === SUBOUTPUT_ENTITY_ID
        );
        setSOUEntityPrototypeId(subOutputEntityPrototype?.id);
      }, []);

      React.useEffect(() => {
        if (params.id) {
          fetchPlan(+params.id);
        }
      }, [fetchPlan, params.id]);

      return (
        <>
          {!value && (
            <>
              Your funding framework is not marked as &quot;complete&quot;.
              Please{' '}
              <Link
                target="_blank"
                to={`/dashboard/plan/${params.id}/build/prototype/${souEntityPrototypeId}`}
              >
                mark it as complete here.
              </Link>
            </>
          )}
        </>
      );
    }
  },
  {
    question: `C.1.3.7.8 - Do all relevant suboutputs include
    data on the full cost of delivering the results*
    (i.e. have required resources - automatically calculated)`,
    slug: 'isAnnualFundingIncludeAllRequiredResources',
    type: 'value',
    valueType: 'boolean',
    dependsOn: 'haveAnnualFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false,
    Calculated: props =>
      CalculatedOutputLinks({
        ...props,
        key: 'subOutputsWithoutRequiredResourcesIds'
      })
  },
  {
    question: `C.1.3.7.9 - Do all suboutputs include data on available resources (automatically calculated)`,
    slug: 'isAnnualFundingIncludeAllAvailableResources',
    type: 'value',
    valueType: 'boolean',
    dependsOn: 'haveAnnualFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false,
    Calculated: props =>
      CalculatedOutputLinks({
        ...props,
        key: 'subOutputsWithoutAvailableResourcesIds'
      })
  },
  {
    question: `C.1.3.7.10 - Is annual funding disaggregated by type of funding? [QCPR: 4.5.5] * (automatically calculated)`,
    slug: 'isAnnualFundingDisaggregatedByTypeOfFunding',
    type: 'value',
    valueType: 'boolean',
    dependsOn: 'haveAnnualFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false
  },
  {
    question: `C.1.3.7.11 - Is annual funding disaggregated by source of funding? [QCPR: 4.5.5] * (automatically calculated)`,
    slug: 'isAnnualFundingDisaggregatedBySourceOfFunding',
    type: 'value',
    valueType: 'boolean',
    dependsOn: 'haveAnnualFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false
  },
  {
    question: `C.1.3.7.12 - Is annual funding disaggregated by UN Entities? (automatically calculated)`,
    slug: 'isAnnualFundingDisaggregatedByUNEntities',
    type: 'value',
    valueType: 'boolean',
    dependsOn: 'haveAnnualFundingFramework',
    dependsOnFnc: (field: { name: string }): boolean =>
      field?.name.startsWith('Yes') ?? false
  }
];

const surveyName = 'funding';

const FundingFrameworkDetails: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [plan, setPlan] = React.useState<Plan>();

  const params = useParams();
  const [funding, setFunding] = React.useState<
    SurveyFundingResponse['surveyFundingVersion']
  >();

  const fetchPlanCallback = React.useCallback(async () => {
    const {
      form: surveyMapped,
      plan: fetchedPlan
    } = await fetchPlanAndBuildSurveyForm<SurveyFundingResponse>({
      id: params.id,
      questions,
      surveyName
    });
    setPlan(fetchedPlan);
    let fundingReport;
    if (params.id) {
      try {
        fundingReport = await getPlanSurveyFundingReport(+params.id, getYear());
      } catch (e) {
        console.error(e);
      }
    }

    setFunding({ ...surveyMapped, ...(fundingReport ?? {}) });
    setIsLoading(false);
  }, [params.id]);

  React.useEffect(() => {
    fetchPlanCallback();
  }, [fetchPlanCallback, params.id]);

  const methods = useForm({
    defaultValues: createDefaultValues(questions)
  });
  const { reset } = methods;

  React.useEffect(() => {
    if (funding) {
      reset(createDefaultValues(questions, funding));
    }
  }, [funding, reset]);

  const onSubmit = React.useCallback(
    async (data: { [key: string]: Datum }) => {
      if (params.id) {
        setIsSaving(true);
        const msg = await createOrUpdate({
          questions,
          planId: +params.id,
          data,
          surveyName,
          survey: funding
        });

        await fetchPlanCallback();
        enqueueSnackbar(msg, {
          variant: 'success',
          autoHideDuration: 5000,
          preventDuplicate: true
        });
        setIsSaving(false);
      }
    },
    [params, enqueueSnackbar, fetchPlanCallback, funding]
  );

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingBlocks />}
      {isSaving && <CircularProgress />}
      {!isLoading && plan && (
        <QuestionForm
          plan={plan}
          questions={questions}
          onSubmit={onSubmit}
          disabled={isSaving}
          surveyVersion={funding}
        />
      )}
    </FormProvider>
  );
};

export default FundingFrameworkDetails;

import Api from 'utils/Api';
import settings from 'config';

import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;
const CELL_API_BASE_URL = `${BASE_URL}/planEntityCell/`;

export const fetchCellById = (
  cellId: number
): Promise<APISingleResult<Cell>> => {
  return Api.get(`${CELL_API_BASE_URL}${cellId}`, getAuthHeader());
};

export const deleteCell = (cellId: number): Promise<any> => {
  return Api.delete(`${CELL_API_BASE_URL}${cellId}`, getAuthHeader());
};

export const addCell = (
  cell: Partial<Cell>,
  entityId: number
): Promise<Cell> => {
  const formattedCell = cell;
  formattedCell.planEntityId = entityId;
  return Api.post(CELL_API_BASE_URL, formattedCell, getAuthHeader()).then(
    res => res.data
  );
};

export const editCell = (cell: Cell): Promise<APISingleResult<Cell>> => {
  return Api.put(`${CELL_API_BASE_URL}${cell.id}`, cell, getAuthHeader());
};

export const editAssessment = (
  assessment: Assessment
): Promise<APISingleResult<Assessment>> => {
  const formattedCell = assessment;
  return Api.put(
    `${CELL_API_BASE_URL}assessment/${formattedCell.id}`,
    formattedCell,
    getAuthHeader()
  );
};
export const deleteAssessment = (assessmentId: number): Promise<any> => {
  return Api.delete(
    `${CELL_API_BASE_URL}assessment/${assessmentId}`,
    getAuthHeader()
  );
};

export const createAssesment = (
  assessment: Assessment
): Promise<APISingleResult<Assessment>> => {
  return Api.post(
    `${CELL_API_BASE_URL}assessment/create`,
    assessment,
    getAuthHeader()
  );
};

export const bulkCreateAssessment = (
  cellMetrics: Assessment[],
  timeframeId?: number
  // NOTE: The API doesn't actually return data.results here. It should though.
): Promise<APIResults<Assessment>> => {
  return Api.post(
    `${CELL_API_BASE_URL}assessment/batchCreate`,
    { assessments: cellMetrics, timeframeId },
    getAuthHeader()
  ).then(res => res.data);
};

export const bulkUpdateAssessment = (
  metrics: Assessment[]
  // NOTE: The API doesn't actually return data.results here. It should though
): Promise<APIResults<Assessment>> => {
  const cellMetrics = { assessments: metrics };
  return Api.put(
    `${CELL_API_BASE_URL}assessment/batchUpdate`,
    cellMetrics,
    getAuthHeader()
  ).then(res => res.data);
};

interface FetchCellOptions extends APIQueryOptions {
  planEntityIds: number[];
  limit?: number | undefined;
}

export const fetchCells = (options: FetchCellOptions): Promise<Cell[]> => {
  return Api.post(`${CELL_API_BASE_URL}search`, options, getAuthHeader()).then(
    res => res.data.results
  );
};

import React, { useEffect } from 'react';
import _ from 'lodash';
import autocomplete from 'services/AutocompleteService';
import { Filter } from './TableProvider';

interface AsynchronousResults {
  name?: string;
  abbreviation?: string;
  id: number;
}

export const useTableQueryParameters = (
  searchParams: URLSearchParams,
  columns: any[]
) => {
  const [loadingFilters, setLoading] = React.useState(
    !!Array.from(searchParams.entries()).length
  );
  const [searchParamFilters, setSearchParamFilters] = React.useState<
    Filter[] | undefined
  >();

  const fetchItems = React.useCallback(
    async <T extends AsynchronousResults>(
      qendpoint: string,
      id?: number[],
      inputValue?: any
    ): Promise<T[]> => {
      return autocomplete<T>(qendpoint, {
        simple: false,
        q: inputValue,
        ...(id ? { where: { id } } : {})
      });
    },
    []
  );

  useEffect(() => {
    const getFilters = async (filters: { [index: string]: number[] }) => {
      const outFilters: Filter[] = [];
      /* eslint-disable no-restricted-syntax */
      for await (const filterKey of Object.keys(filters)) {
        const queryEndpoint = columns.find(
          c => c.id === (filterKey ?? filterKey)
        )?.endpoint;
        if (queryEndpoint) {
          const items = await fetchItems(queryEndpoint, filters[filterKey]);
          const values: { id: number; name?: string }[] = [];
          for (const v of filters[filterKey]) {
            values.push({ id: v, name: items.find(f1 => f1.id === v)?.name });
          }
          outFilters.push({ id: filterKey, value: values });
        }
      }
      setSearchParamFilters(outFilters);
      setLoading(false);
    };
    if (loadingFilters) {
      const fixParamNames = (e: [string, string]) => {
        if (e[0] === 'documentTypeId') {
          return ['fileDocumentTypeId', +e[1]];
        }
        return [e[0], +e[1]];
      };

      const paramEntries = Array.from(searchParams.entries())
        .map(fixParamNames)
        .filter(
          p => columns.find(c => c.id === p[0]) && Number.isInteger(p[1])
        );

      if (paramEntries.length) {
        const filterMap: { [index: string]: number[] } = {};
        for (const [pk, pv] of paramEntries) {
          if (filterMap[pk]) {
            filterMap[pk].push(+pv);
          } else {
            filterMap[pk] = [+pv];
          }
        }
        getFilters(filterMap);
      } else if (loadingFilters) {
        setLoading(false);
      }
    }
  }, []);

  return { loadingFilters, searchParamFilters };
};

export default useTableQueryParameters;

import React, { memo } from 'reactn';
import { useConfirm } from 'material-ui-confirm';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { fetchPlans } from 'services/PlanService';

import EnhancedTable from 'components/table/EnhancedTable';
import { workspaceColumns } from './WorkspaceColumns';

const AdminWorkspace = () => {
  const columns = React.useMemo(() => workspaceColumns, []);
  const confirm = useConfirm();

  const preDeleteCheck = React.useCallback(
    async (ids: number[]): Promise<boolean> => {
      try {
        const relatedItems = (
          await fetchPlans({
            workspaceIds: ids
          })
        ).results.map(plan => ({
          name: plan.planVersion.name,
          id: plan.id
        }));
        const description = (
          <>
            Are you sure you want to archive this workspace?{' '}
            {relatedItems.length > 0 && (
              <>
                It is linked to:{' '}
                {relatedItems.map((item, idx) => (
                  <>
                    <strong>{item.name}</strong>
                    {idx < relatedItems.length ? ', ' : ''}
                  </>
                ))}
                .
              </>
            )}
          </>
        );
        await confirm({
          description
        });
        return true;
      } catch (e) {
        throw new Error('Not confirmed');
      }
    },
    [confirm]
  );

  return (
    <Grid item xs={12}>
      <Paper>
        <EnhancedTable
          linkTo="/dashboard/admin/workspace"
          endpoint="workspace"
          columns={columns}
          hiddenColumns={[]}
          preDeleteCheck={preDeleteCheck}
        />
      </Paper>
    </Grid>
  );
};

export default memo(AdminWorkspace);

import Api from 'utils/Api';
import settings from 'config';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

const METRIC_API_BASE_URL = `${BASE_URL}/metric/`;
const LINK_API_BASE_URL = `${BASE_URL}/link/`;

export const fetchMetricById = (
  metricId: number
): Promise<APISingleResult<MetricBase>> => {
  return Api.get(`${METRIC_API_BASE_URL}${metricId}`, getAuthHeader());
};

export const deleteMetric = (
  metricId: number
): Promise<APISingleResult<MetricBase>> => {
  return Api.delete(`${METRIC_API_BASE_URL}${metricId}`, getAuthHeader());
};

export const addMetric = (
  metric: MetricBase
): Promise<APISingleResult<MetricBase>> => {
  const formattedMetric = metric;
  return Api.post(METRIC_API_BASE_URL, formattedMetric, getAuthHeader());
};

export const updateMetric = (
  id: number,
  metric: MetricBase
): Promise<APISingleResult<MetricBase>> => {
  return Api.put(`${METRIC_API_BASE_URL}${id}`, metric, getAuthHeader());
};

export const fetchMetrics = (
  options: APIQueryOptions = {}
): Promise<APIResults<MetricBase>> => {
  return Api.post(
    `${METRIC_API_BASE_URL}search`,
    options,
    getAuthHeader()
  ).then(res => res.data);
};

export const fetchLinkById = (
  linkId: number
): Promise<APISingleResult<Link>> => {
  return Api.get(`${LINK_API_BASE_URL}${linkId}`, getAuthHeader());
};

export const deleteLink = (linkId: number): Promise<APISingleResult<Link>> => {
  return Api.delete(`${LINK_API_BASE_URL}${linkId}`, getAuthHeader());
};

export const addLink = (link: Link): Promise<APISingleResult<Link>> => {
  const formattedLink = link;
  return Api.post(LINK_API_BASE_URL, formattedLink, getAuthHeader());
};

export const editLink = (link: Link): Promise<APISingleResult<Link>> => {
  return Api.put(`${LINK_API_BASE_URL}${link.id}`, link, getAuthHeader());
};

export const fetchLinks = (
  options?: APIQueryOptions
): Promise<APIResults<Link>> => {
  return Api.post(`${LINK_API_BASE_URL}search`, options, getAuthHeader()).then(
    res => res.data
  );
};

import { Tabs, Tab, Grid, Typography } from '@mui/material';
import PaperWrapper from 'components/common/PaperWrapper';
import React, { useGlobal } from 'reactn';
import {
  Outlet,
  Link,
  useParams,
  useLocation,
  Navigate
} from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { fetchPlanById, fetchPlans } from 'services/PlanService';
import { ArrowBack } from '@mui/icons-material';
import { Button } from 'uninfo-components';
import { IMS_ANNUAL_PROGRAMMATIC_ID, IMS_DOMAIN_ID } from 'utils/constants';
import { fetchWorkspaceById } from 'services/WorkspaceService';
import PlanDateSpan from 'components/common/PlanDateSpan';

const Wrapper = styled('div')`
  margin: 1.5rem 0rem 0;
`;

const findIMSPlan = async (plan: Plan, user: User) => {
  let workspaceIds = [plan.workspaceId];
  const workspace = await fetchWorkspaceById(plan.workspaceId);

  if (workspace.workspaceType?.short === 'R') {
    workspaceIds = workspace.children.map(w => w.id);

    if (!user.isAdmin) {
      const userWorkspaceIds = user.userRoles
        .filter(w => w.contextDomainId === IMS_DOMAIN_ID)
        .map(w => w.contextWorkspaceId);

      workspaceIds = workspaceIds.filter(id => userWorkspaceIds.includes(id));
    }
  }

  const date = new Date();
  let year = date.getFullYear();

  if (date.getMonth() < 10) {
    year = date.getFullYear() - 1;
  }

  const plans = await fetchPlans({
    planTypeIds: [IMS_ANNUAL_PROGRAMMATIC_ID],
    workspaceIds,
    sort: [['planVersion.startDate', 'DESC']],
    year,
    exclude: [
      'planSurveys',
      'assessments',
      'planSubmissions',
      'planDocuments',
      'planType',
      'planTags',
      'blueprint',
      'entityPrototypes'
    ]
  });

  return plans.results;
};

const CooperationFrameworkDetails: React.FC = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const [user] = useGlobal('user');
  const [plan, setPlan] = React.useState<Plan>();
  const [imsPlans, setIMSPlans] = React.useState<Plan[]>([]);
  const currentYear = new Date().getUTCFullYear();
  const showOnlyEvaluation =
    (plan?.planVersion.endDate?.split('-')[0] ?? 9999) <= `${currentYear}`;

  const currentPage = pathname.split('/')[pathname.split('/').length - 1];
  const defaultPage = showOnlyEvaluation ? 'evaluation' : 'signatories';
  const [view, setView] = React.useState(
    currentPage !== 'cooperation-framework' ? currentPage : defaultPage
  );

  React.useEffect(() => {
    if (currentPage === 'cooperation-framework') {
      setView(defaultPage);
    }
  }, [defaultPage, currentPage]);

  const fetchPlanCallback = React.useCallback(
    async (id: number) => {
      const fetchedPlan = await fetchPlanById(id);
      if (user) {
        const fetchedIMSPlan = await findIMSPlan(fetchedPlan, user);
        setIMSPlans(fetchedIMSPlan);
      }
      setPlan(fetchedPlan);
    },
    [user]
  );

  React.useEffect(() => {
    if (params?.id) {
      fetchPlanCallback(+params.id);
    }
  }, [params, fetchPlanCallback]);

  if (!plan) {
    return null;
  }

  if (currentPage === 'cooperation-framework') {
    return <Navigate to={defaultPage} />;
  }

  return (
    <PaperWrapper>
      <Grid container style={{ marginBottom: '1rem' }}>
        <Grid item xs={8}>
          <h2>
            {plan?.workspace?.name}: {plan?.planVersion?.name}
          </h2>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <Link to={`/dashboard/plan/${params?.id}/build`}>
            <Button variant="outlined" startIcon={<ArrowBack />}>
              Back to CF Plan
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Cooperation Framework: <PlanDateSpan obj={plan?.planVersion} />
          </Typography>
        </Grid>
        {imsPlans.length > 0 && (
          <Grid
            item
            xs={12}
            style={{
              marginBottom: '1.5rem',
              marginTop: '.5rem',
              textAlign: 'right'
            }}
          >
            View related IMS Programmatic Survey for:
            {imsPlans.map(p => (
              <Link to={`/dashboard/plan/${p.id}/build`} key={p.id}>
                <Button variant="outlined" style={{ marginLeft: '.5rem' }}>
                  {p.workspace?.name}
                </Button>
              </Link>
            ))}
          </Grid>
        )}
      </Grid>
      <Tabs
        value={view}
        onChange={(_e, val) => {
          setView(val);
        }}
      >
        <Tab
          label="Signatories"
          value="signatories"
          component={Link}
          to="signatories"
        />
        <Tab
          label="Preparation Info (IMS)"
          value="preparation"
          component={Link}
          to="preparation"
        />
        <Tab
          label="Funding Framework details"
          value="funding"
          component={Link}
          to="funding"
        />
        <Tab
          label="Documents"
          value="documents"
          component={Link}
          to="documents"
        />
        <Tab
          label="Evaluation"
          value="evaluation"
          component={Link}
          to="evaluation"
        />
      </Tabs>
      <Wrapper>
        <Outlet />
      </Wrapper>
    </PaperWrapper>
  );
};

export default CooperationFrameworkDetails;

import Api from 'utils/Api';
import settings from 'config';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;
const ROLE_API_BASE_URL = `${BASE_URL}/permission/`;

interface FetchRolesOptions {
  limit?: number;
  q?: string;
  permissionIds?: number[];
}

export const fetchPermissions = (
  options?: FetchRolesOptions
): Promise<Permission[]> => {
  const data = {
    ...options,
    limit: options?.limit ?? 1000
  };
  return Api.post(`${ROLE_API_BASE_URL}search`, data, getAuthHeader()).then(
    res => res.data.results
  );
};

export default fetchPermissions;

/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useState, useGlobal } from 'reactn';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { DropzoneField } from 'components/common/form/FileUpload';
import KeyboardDatePicker from 'components/common/KeyboardDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';
import { useConfirm } from 'material-ui-confirm';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import autocomplete from 'services/AutocompleteService';

import { Theme, Grid, TextField, Typography, IconButton } from '@mui/material';
import { Button } from 'uninfo-components';

import { Autocomplete } from 'components/common/AutoComplete';
import PaperWrapper from 'components/common/PaperWrapper';

import {
  fetchDocumentById,
  addDocument,
  editDocument,
  fetchDocumentType,
  publishDocumentById
} from 'services/DocumentService';
import {
  addPlanDocument,
  fetchPlans,
  fetchPlanById,
  deletePlanDocument
} from 'services/PlanService';
import { fetchWorkspaces, fetchRegions } from 'services/WorkspaceService';
import { LinkButton } from 'components/common/form/Buttons';
import FormError from 'components/common/form/FormError';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Select, SelectOnChangeType } from 'components/common/form/Select';

import { isExisting } from 'utils/typeguards';
import PermissionGate, {
  hasPermission
} from 'components/common/PermissionGate';
import { IMS_DOMAIN_ID } from 'utils/constants';
import { determineIfPlanPublished } from 'pages/PlanBuilder/utils';
import { Delete, Link as LinkIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(2)
  }
}));

const useDocumentFormStyles = makeStyles((theme: Theme) => ({
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  saveButton: {
    marginTop: theme.spacing(1)
  }
}));

export const Option: React.FC<{
  option: unknown;
  versionLabel?: string;
  selected: boolean;
  hideSelected: boolean;
  existingPlanIds?: number[];
}> = ({ option, existingPlanIds, ...props }) => {
  const [user] = useGlobal('user');
  const plan = option as Plan;
  let label = plan.planVersion.name;
  const published = determineIfPlanPublished(plan);
  if (published) {
    label += ' (published)';
  }

  if (published && !user?.isAdmin) {
    return null;
  }

  if (existingPlanIds?.includes(plan.id)) {
    return null;
  }

  return (
    <li id={label} {...props}>
      {label}
    </li>
  );
};

interface DocumentFormProps {
  document: DocumentAPI;
  reload: () => Promise<void>;
}
export const DocumentForm: React.FC<DocumentFormProps> = ({
  document,
  reload
}) => {
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState<any>(
    document?.url?.match(/_.*$/)?.[0].substr(1)
  );

  const [createdAt] = useState<string | null>(
    document?.createdAt?.substring(0, 10) ?? null
  );

  const [updatedAt, setUpdatedAt] = useState<string | null>(
    document?.updatedAt?.substring(0, 10) ?? null
  );

  const [lockedField, setLockedField] = useState<boolean>(false);

  const confirm = useConfirm();
  const navigate = useNavigate();

  const [loggedInUser] = useGlobal('user');
  const classes = useDocumentFormStyles();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    defaultValues: {
      checkbox: document.url && !document.url?.match('minio.dev.devqube.io'),
      conductedDate: document.conductedDate ?? null,
      description: document.description ?? null,
      endDate: document.endDate ?? null,
      fileDocumentType: {
        id: document.fileDocumentType?.id,
        name: document.fileDocumentType?.name,
        short: document.fileDocumentType?.short
      },
      name: document.name ?? null,
      plan: document?.plan ?? null,
      private: document.private ?? false,
      publishedDate: document.publishedDate ?? null,
      region: {
        id: document.region?.id,
        name: document.region?.name
      },
      status: document.status ?? null,
      startDate: document.startDate ?? null,
      url: document.url ?? null,
      workspace: {
        id: document.workspace?.id,
        name: document.workspace?.name
      }
    }
  });

  const { handleSubmit, control, errors, watch, reset, setValue } = methods;

  const watchUrl = watch('url');
  const watchCheckbox = watch(
    'checkbox',
    !!(document.url && !document.url?.match('minio.dev.devqube.io'))
  );
  const fileDocumentType = watch('fileDocumentType');
  const selectedWorkspace = watch('workspace');
  const selectedRegion = watch('region');
  const selectedPlan = watch('plan');

  React.useEffect(() => {
    // if _any_ plan is an IMS plan && published, we lock the form.
    document?.planDocuments?.forEach(pd => {
      const { plan } = pd;
      if (
        plan &&
        plan.blueprint?.planType.domainId === IMS_DOMAIN_ID &&
        determineIfPlanPublished(plan) &&
        !loggedInUser?.isAdmin
      ) {
        setLockedField(true);
      }
    });
  }, [document, loggedInUser]);

  React.useEffect(() => {
    reset({
      checkbox: document.url && !document.url?.match('minio.dev.devqube.io'),
      conductedDate: document.conductedDate ?? null,
      description: document.description ?? null,
      endDate: document.endDate ?? null,
      fileDocumentType: {
        id: document.fileDocumentType?.id,
        name: document.fileDocumentType?.name,
        short: document.fileDocumentType?.short
      },
      name: document.name ?? null,
      plan: document?.plan ?? null,
      private: document.private ?? false,
      publishedDate: document.publishedDate ?? null,
      region: {
        id: document.region?.id,
        name: document.region?.name
      },
      status: document.status ?? null,
      startDate: document.startDate ?? null,
      url: document.url ?? null,
      workspace: {
        id: document.workspace?.id,
        name: document.workspace?.name
      }
    });
  }, [document, reset]);

  const fetchedWorkspaces = useCallback(
    async inputValue => {
      const workspaces = await fetchWorkspaces({
        globalFilter: inputValue,
        workspaceIds: loggedInUser?.isAdmin
          ? null
          : loggedInUser?.userRoles.map(r => r.contextWorkspaceId as number) ??
            null
      });

      return workspaces;
    },
    [loggedInUser]
  );

  const fetchedRegions = useCallback(async inputValue => {
    const regions = await fetchRegions({
      globalFilter: inputValue
    });

    return regions;
  }, []);

  const isAdmin = loggedInUser?.isAdmin;

  const fetchedFileDocumentType = React.useCallback(
    async (q: string) => {
      let documentTypes = await autocomplete('fileDocumentType', {
        simple: false,
        q
      });
      if (!isAdmin) {
        documentTypes = documentTypes.filter(
          (dt: any) =>
            dt.short !== 'IG' &&
            dt.short !== 'IR' &&
            dt.short !== 'IF' &&
            dt.short !== 'IC'
        );
      }

      return documentTypes;
    },
    [isAdmin]
  );

  const fetchedPlans = useCallback(async () => {
    const options = {
      ...(selectedWorkspace.id ? { workspaceIds: [selectedWorkspace.id] } : {}),
      exclude: [
        'planSubmissions',
        'assessments',
        'entityPrototypes',
        'planSurveys'
      ],
      includeBlueprint: true
    };
    const { results } = await fetchPlans(options);

    const plans = results
      .filter(
        r =>
          !(
            r.blueprint?.planType?.domainId === IMS_DOMAIN_ID &&
            determineIfPlanPublished(r)
          )
      )
      .map((r: Plan) => {
        return { ...r, name: r.planVersion.name };
      });

    return plans;
  }, [selectedWorkspace]);

  const togglePrivate = React.useCallback(
    async (newVal: boolean) => {
      try {
        const response = await publishDocumentById(document.id, newVal);
        enqueueSnackbar(`Privacy set to ${response.private}`, {
          variant: 'success',
          autoHideDuration: 5000,
          preventDuplicate: true
        });
      } catch (e) {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          autoHideDuration: 5000,
          preventDuplicate: true
        });
      }
    },
    [document.id, enqueueSnackbar]
  );

  const save = async (data: any) => {
    if (!loading) {
      setLoading(true);
      try {
        const localItem = data;

        localItem.conductedDate = localItem.conductedDate ?? null;
        localItem.endDate = localItem.endDate ?? null;
        localItem.fileDocumentTypeId = localItem.fileDocumentType?.id;
        localItem.regionId = localItem.region?.id;
        localItem.startDate = localItem.startDate ?? null;
        localItem.status = localItem.status ?? null;
        localItem.url = localItem.url?.url || localItem.url;
        localItem.workspaceId = localItem.workspace?.id;
        localItem.planId = localItem.plan?.id;

        delete localItem.workspace;
        delete localItem.region;
        delete localItem.plan;

        if (document?.id) {
          if (localItem.planId) {
            await addPlanDocument({
              fileDocumentId: document?.id,
              planId: localItem.planId
            });
            enqueueSnackbar('Document linked to new plan', {
              variant: 'success',
              autoHideDuration: 5000,
              preventDuplicate: true
            });
          }
          delete localItem.planId;
          if (!lockedField) {
            const newDoc = await editDocument(document.id, localItem);
            setFilename(newDoc?.url?.match(/_.*$/)?.[0].substr(1));
            enqueueSnackbar('Document saved', {
              variant: 'success',
              autoHideDuration: 5000,
              preventDuplicate: true
            });
            setUpdatedAt(newDoc.updatedAt.substring(0, 10));
          }
        } else {
          const res = await addDocument(localItem);
          enqueueSnackbar('Document created', {
            variant: 'success',
            autoHideDuration: 5000,
            preventDuplicate: true
          });

          navigate(`/dashboard/admin/document/${res.data.id}`);
        }
      } catch (e) {
        console.error('Save error', e);
        enqueueSnackbar(
          `Error: ${(e as any)?.data?.message ?? (e as any)?.message}`,
          {
            variant: 'error',
            autoHideDuration: 5000,
            preventDuplicate: true
          }
        );
      } finally {
        reload();
        setLoading(false);
      }
    }
  };

  const onPlanDocumentDeleteClick = async (id: number) => {
    await deletePlanDocument(id);
    await reload();
  };

  const showConductedDate =
    fileDocumentType?.name === 'Common Country Analysis';

  const isInfographicDocument =
    fileDocumentType?.short === 'IR' ||
    fileDocumentType?.short === 'IF' ||
    fileDocumentType?.short === 'IC' ||
    fileDocumentType?.short === 'IG';
  const disabledForSomeInfographicDocuments =
    fileDocumentType?.short === 'IR' ||
    fileDocumentType?.short === 'IF' ||
    fileDocumentType?.short === 'IG';

  const showStartAndEndDates =
    [
      'Cooperation Framework',
      'United Nations Development Assistance Framework',
      'UN Country Results Report'
    ].indexOf(fileDocumentType?.name) !== -1;

  React.useEffect(() => {
    if (!showConductedDate) {
      setValue('conductedDate', null);
    }

    if (!showStartAndEndDates) {
      setValue('startDate', null);
      setValue('endDate', null);
    }
  }, [showConductedDate, showStartAndEndDates, setValue]);

  const changeStatusPopUp: SelectOnChangeType = async target => {
    if (target.value === 'Draft') {
      await confirm({
        title:
          "You have selected status 'Draft', do you also want to mark this document as private?",
        description:
          'Marking this document as private will hide it from the public',
        confirmationText: 'Set as private',
        cancellationText: 'Keep as is',
        confirmationButtonProps: { variant: 'outlined' }
      })
        .then(() => {
          setValue('private', true);
        })
        .catch(() => {
          // pass don't do anything
        });
    }
  };

  if (isInfographicDocument) {
    setValue('status', 'Final');
  }

  let permissionScope: Scopes = 'fileDocument_create';
  if (document?.id) {
    permissionScope = 'fileDocument_update';
  }

  return (
    <>
      <FormProvider {...methods}>
        <PaperWrapper component="form" onSubmit={handleSubmit(save)}>
          <Grid container spacing={2}>
            {createdAt && (
              <Grid item sx={{ mb: 1 }} xs={12}>
                <div>{`Created on ${createdAt} / Updated on ${updatedAt}`}</div>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Controller
                name="name"
                as={TextField}
                control={control}
                rules={{ required: true }}
                fullWidth
                placeholder="Full Name"
                required
                label="Full Name"
                variant="outlined"
                size="small"
                disabled={lockedField}
              />
              <FormError errors={errors} name="name" />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="fileDocumentType"
                control={control}
                onChange={([, value]: any[]) => value}
                render={({ onChange, ref, ...props }) => (
                  <Autocomplete
                    fetchOptionsCallback={fetchedFileDocumentType}
                    getOptionDisabled={(option: { disabled: boolean }) =>
                      option.disabled
                    }
                    onChange={(event: unknown, data: any) => {
                      // Avoid a region selected with a workspace and vice versa
                      if (fileDocumentType && selectedWorkspace) {
                        setValue('workspace', null);
                      }
                      if (fileDocumentType && selectedRegion) {
                        setValue('region', null);
                      }
                      onChange(data);
                    }}
                    required
                    fullWidth
                    label="Document Type"
                    disabled={lockedField}
                    {...props}
                  />
                )}
              />
              <FormError errors={errors} name="fileDocumentType" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="description"
                as={TextField}
                control={control}
                rules={{ required: false }}
                fullWidth
                placeholder="Description"
                label="Description"
                variant="outlined"
                disabled={lockedField}
              />
              <FormError errors={errors} name="description" />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="workspace"
                control={control}
                onChange={([, value]: Workspace[]) => value}
                render={({ onChange, ref, ...props }) => (
                  <Autocomplete
                    fetchOptionsCallback={fetchedWorkspaces}
                    getOptionDisabled={(option: any) => option.disabled}
                    onChange={(event: any, data: any) => {
                      if (selectedPlan) {
                        setValue('plan', null);
                      }
                      onChange(data);
                    }}
                    required={!disabledForSomeInfographicDocuments}
                    disabled={
                      disabledForSomeInfographicDocuments || lockedField
                    }
                    fullWidth
                    label="Workspace"
                    {...props}
                  />
                )}
              />
              <FormError errors={errors} name="workspace" />
            </Grid>
            {selectedWorkspace?.id && (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="plan"
                  control={control}
                  onChange={([, value]: any[]) => value}
                  render={({ onChange, ref, ...props }) => (
                    <Autocomplete
                      fetchOptionsCallback={fetchedPlans}
                      getOptionDisabled={(option: any) => option.disabled}
                      onChange={(event: any, data: any) => onChange(data)}
                      fullWidth
                      CustomOption={({ ...optionProps }) => (
                        <Option
                          {...optionProps}
                          existingPlanIds={document?.planDocuments?.map(
                            pd => pd.planId
                          )}
                        />
                      )}
                      label="Select the IMS survey or CF to link to"
                      {...props}
                    />
                  )}
                />
                <FormError errors={errors} name="plan" />
                <Alert severity="info" sx={{ marginTop: 1 }}>
                  <AlertTitle>
                    {(document?.planDocuments?.length ?? 0) > 0 && (
                      <>
                        This document is currently linked to the following
                        plans:
                      </>
                    )}
                    {(document?.planDocuments?.length ?? 0) === 0 && (
                      <>This document is not linked to any plans.</>
                    )}
                  </AlertTitle>
                  <ul>
                    {document.planDocuments?.map(pd => {
                      const { plan } = pd;
                      const isPublishedIMS =
                        plan &&
                        plan.blueprint?.planType.domainId === IMS_DOMAIN_ID &&
                        determineIfPlanPublished(plan);
                      return (
                        <li key={pd.id}>
                          {pd.planVersion.name}{' '}
                          {isPublishedIMS ? '(published)' : ''}
                          {(!isPublishedIMS || loggedInUser?.isAdmin) && (
                            <IconButton
                              onClick={() => onPlanDocumentDeleteClick(pd.id)}
                            >
                              <Delete />
                            </IconButton>
                          )}
                          {plan && (
                            <Link to={`/dashboard/plan/${plan.id}`}>
                              <IconButton>
                                <LinkIcon />
                              </IconButton>
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </Alert>
              </Grid>
            )}

            {loggedInUser?.isAdmin && (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="region"
                  control={control}
                  onChange={([, value]: Region[]) => value}
                  render={({ onChange, ref, ...props }) => (
                    <Autocomplete
                      fetchOptionsCallback={fetchedRegions}
                      getOptionDisabled={(option: any) => option.disabled}
                      onChange={(event: unknown, data: any) => onChange(data)}
                      fullWidth
                      label="Region"
                      disabled={fileDocumentType?.short !== 'IR' || lockedField}
                      {...props}
                    />
                  )}
                />
                <FormError errors={errors} name="region" />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Select
                withTitle
                name="status"
                label="Status"
                disabled={isInfographicDocument || lockedField}
                required
                options={[
                  { label: 'Draft', value: 'Draft' },
                  { label: 'Final', value: 'Final' },
                  { label: 'Final & Signed', value: 'Final & Signed' }
                ]}
                onChange={changeStatusPopUp}
              />
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {showStartAndEndDates && (
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                      <Typography variant="body1">
                        Please specify the time period which this document
                        covers
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: 0 }}>
                      <Controller
                        control={control}
                        name="startDate"
                        render={({ ref, ...props }) => (
                          <KeyboardDatePicker
                            {...props}
                            label="Start Date"
                            id="startDate"
                            inputRef={ref}
                            required
                            disabled={lockedField}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: 0 }}>
                      <Controller
                        control={control}
                        name="endDate"
                        render={({ ref, ...props }) => (
                          <KeyboardDatePicker
                            {...props}
                            id="endDate"
                            label="End Date"
                            inputRef={ref}
                            required
                            disabled={lockedField}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {showConductedDate && (
                <Grid item xs={6} style={{ alignSelf: 'flex-end' }}>
                  <Controller
                    control={control}
                    name="conductedDate"
                    render={({ ref, ...props }) => (
                      <KeyboardDatePicker
                        {...props}
                        id="conductedDate"
                        required
                        label="Conducted Date"
                        inputRef={ref}
                        disabled={lockedField}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={6} style={{ alignSelf: 'flex-end' }}>
                <Controller
                  control={control}
                  name="publishedDate"
                  rules={{ required: true }}
                  render={({ ref, ...props }) => (
                    <KeyboardDatePicker
                      {...props}
                      id="publishedDate"
                      required
                      label="Published Date"
                      inputRef={ref}
                      disabled={lockedField}
                    />
                  )}
                />
              </Grid>
            </LocalizationProvider>

            {!isInfographicDocument && (
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Controller
                      name="private"
                      control={control}
                      render={props => (
                        <Switch
                          onChange={e => {
                            props.onChange(e.target.checked);
                            togglePrivate(e.target.checked);
                          }}
                          checked={props.value}
                        />
                      )}
                    />
                  }
                  label="Private document"
                />
                <div>
                  <small>
                    (Click this if you want to hide the document on public
                    websites.)
                  </small>
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              <Controller
                name="checkbox"
                control={control}
                render={({ onChange, onBlur, value, name: renderName }) => (
                  <>
                    <FormControlLabel
                      control={
                        <MuiCheckbox
                          onBlur={onBlur}
                          onChange={e => onChange(e.target.checked)}
                          checked={value}
                          name={renderName}
                          disabled={lockedField}
                        />
                      }
                      label="The document is stored at an external web address"
                    />
                  </>
                )}
              />

              {!watchCheckbox && (
                <>
                  <DropzoneField
                    file={{
                      url: document.url,
                      name: filename,
                      thumbnail: document.thumbnail
                    }}
                    name="url"
                    multiple={false}
                    disabled={lockedField}
                  />
                </>
              )}
            </Grid>
            {watchCheckbox && (
              <Grid item xs={12}>
                <Controller
                  name="url"
                  as={TextField}
                  control={control}
                  rules={{ required: true }}
                  fullWidth
                  placeholder="Url of document"
                  required
                  label="Url of document"
                  variant="outlined"
                  disabled={lockedField}
                />

                <FormError errors={errors} name="url" />
              </Grid>
            )}

            <Grid item xs={12} />
            <PermissionGate scopes={permissionScope}>
              <Button
                type="submit"
                disabled={
                  // @ts-ignore
                  watchUrl?.path || !watchUrl || loading || !isEmpty(errors)
                }
                isLoading={loading}
                variant="contained"
                color="primary"
                className={classes.saveButton}
              >
                Save
              </Button>
            </PermissionGate>
            <Button
              color="inherit"
              variant="outlined"
              className={classes.saveButton}
            >
              <LinkButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
                Back
              </LinkButton>
            </Button>
          </Grid>
        </PaperWrapper>
      </FormProvider>
    </>
  );
};

const AdminDocumentBuilder: React.FC = () => {
  const [user] = useGlobal('user');
  const classes = useStyles();
  const { id } = useParams<{
    id: string;
  }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get('planId');
  const documentTypeId = queryParams.get('documentTypeId');

  const [loading, setLoading] = useState(false);

  const [document, setDocument] = useState({} as DocumentAPI);

  const fetchCb = useCallback(async () => {
    try {
      setLoading(true);
      if (id && id !== 'add') {
        const res = await fetchDocumentById(+id);
        if (planId) {
          const options: {
            limit: number;
            planIds: number[];
            workspaceIds?: number[];
            exclude: string[];
          } = {
            exclude: ['assessments', 'entityPrototypes'],
            limit: 1,
            planIds: [+planId]
          };
          if (res.workspace?.id) {
            options.workspaceIds = [res.workspace.id];
          }
          const { results: foundPlans } = await fetchPlans(options);
          if (foundPlans.length) {
            res.plan = {
              ...foundPlans[0],
              name: foundPlans[0].planVersion.name
            };
          }
        }

        const planDocuments = await Promise.all(
          res.planDocuments?.map(async pd => {
            const plan = await fetchPlanById(pd.planId);
            return {
              ...pd,
              plan
            };
          })
        );

        res.planDocuments = planDocuments;
        setDocument(res);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [id, planId]);

  const fetchPlanToAddDocument = useCallback(async () => {
    try {
      setLoading(true);
      const newDoc: Partial<DocumentAPI> = {};
      if (id && id === 'add' && planId) {
        const foundPlan = await fetchPlanById(+planId);
        newDoc.workspace = foundPlan.workspace;
        newDoc.plan = { ...foundPlan, name: foundPlan.planVersion.name };
      }
      if (id && id === 'add' && documentTypeId) {
        const documentType = await fetchDocumentType({
          fileDocumentTypeIds: [+documentTypeId]
        });
        // eslint-disable-next-line prefer-destructuring
        newDoc.fileDocumentType = documentType[0];
      }
      setDocument(doc => ({
        ...doc,
        ...newDoc
      }));
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [id, planId, documentTypeId]);

  React.useEffect(() => {
    fetchCb();
  }, [fetchCb]);

  React.useEffect(() => {
    fetchPlanToAddDocument();
  }, [fetchPlanToAddDocument]);

  const canEdit = hasPermission({ user, scopes: 'fileDocument_update' });

  return (
    <>
      {!loading && (id === 'add' || (id !== 'add' && isExisting(document))) && (
        <>
          <Typography variant="h4" className={classes.header}>
            {// eslint-disable-next-line no-nested-ternary
            id === 'add' ? 'Add' : canEdit ? 'Edit' : 'View'}{' '}
            Document
          </Typography>

          <DocumentForm document={document} reload={fetchCb} />
        </>
      )}
    </>
  );
};

export default AdminDocumentBuilder;

import React, { memo } from 'reactn';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import EnhancedTable from 'components/table/EnhancedTable';

import { columns as countryColumns } from './columns';

export interface NestedLocation extends LocationAPI {
  location: LocationAPI;
}

const AdminCountry = () => {
  const columns = React.useMemo(() => countryColumns, []);

  return (
    <Grid item xs={12}>
      <Paper>
        <EnhancedTable
          linkTo="/dashboard/admin/country"
          endpoint="country"
          columns={columns}
          // excludeFromQuery={['organizations', 'teams', 'workspaces']}
          noAdd
          hiddenColumns={[]}
        />
      </Paper>
    </Grid>
  );
};

export default memo(AdminCountry);

import React from 'reactn';

import { userFullName } from 'utils/user';

import Asynchronous from 'components/table/AsynchronousFilter';
import DateCell from 'components/table/DateCell';
import { FilterProps } from 'react-table';
import { Autocomplete } from 'components/common/AutoComplete';

function StatusFilter({
  column: { setFilter, filterValue, toggleHidden }
}: FilterProps<Contact>): React.ReactElement {
  const fetchedGroup = React.useCallback(async () => {
    return [
      { id: 'registered', name: 'Registered' },
      { id: 'active', name: 'Active' },
      { id: 'deactivated', name: 'Deactivated' }
    ];
  }, []);

  return (
    <Autocomplete
      style={{ width: 200 }}
      multiple
      label="User status"
      value={filterValue || []}
      onChange={(_e: any, value: any) => {
        setFilter(value);
        toggleHidden(!value?.[0]?.id);
      }}
      fetchOptionsCallback={fetchedGroup}
    />
  );
}

export const userRoleColumns = [
  {
    Header: 'ID',
    accessor: 'id',
    aggregate: 'uniquecount',
    sortType: 'basic',
    hide: true,
    disableFilters: true,
    Aggregated: ({ cell: { value } }: ReactTableCell<string, User>): string =>
      `${value} Unique ids`
  },
  {
    Header: 'Name',
    accessor: 'firstname',
    aggregate: 'count',
    sortType: 'basic',
    // eslint-disable-next-line react/display-name
    Cell: ({
      cell: { row }
    }: ReactTableCell<any, User>): React.ReactElement => (
      <>
        <div>{userFullName(row.original)}</div>
      </>
    ),
    disableFilters: true,
    Aggregated: ({ cell: { value } }: ReactTableCell<string, User>): string =>
      `${value} Names`
  },
  {
    Header: 'Email',
    accessor: 'email',
    aggregate: 'uniquecount',
    sortType: 'basic',
    disableFilters: true,
    Aggregated: ({ cell: { value } }: ReactTableCell<string, User>): string =>
      `${value} Unique Emails`
  },
  {
    Header: 'Username',
    accessor: 'username',
    aggregate: 'uniquecount',
    style: { wordBreak: 'break-word' },
    sortType: 'basic',
    disableFilters: true,
    Aggregated: ({ cell: { value } }: ReactTableCell<string, User>): string =>
      `${value} Unique usernames`
  },
  {
    Header: 'Position',
    accessor: 'position',
    aggregate: 'uniquecount',
    style: { wordBreak: 'break-word' },
    disableFilters: true,
    sortType: 'basic',
    Aggregated: ({ cell: { value } }: ReactTableCell<string, User>): string =>
      `${value} Unique positions`
  },
  {
    Header: 'Organization',
    id: 'organizationId',
    accessor: 'organization.abbreviation',
    sortType: 'basic',
    endpoint: 'organization',
    filterLabel: 'Organization',
    Filter: Asynchronous
  },
  {
    Header: 'Workspaces',
    id: 'workspaceId',
    accessor: 'workspaces',
    disableSortBy: true,
    endpoint: 'workspace',
    filterLabel: 'Workspace',
    Filter: Asynchronous,
    filterByUserWorkspaces: true,
    permission: 'user_search'
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableFilters: false,
    sortType: 'basic',
    Filter: StatusFilter
  },
  {
    Header: 'User Roles',
    id: 'userRoles',
    disableSortBy: true,
    disableFilters: true
  },
  {
    Header: 'Role',
    id: 'roleId',
    accessor: 'roleId',
    disableSortBy: true,
    endpoint: 'role',
    filterLabel: 'Role',
    Filter: Asynchronous,
    hideToggleHide: true
  },
  {
    Header: 'Archived at ',
    accessor: 'deletedAt',
    disableFilters: true,
    sortType: 'basic',
    Cell: DateCell,
    hideToggleHide: true
  },
  {
    Header: 'Updated at ',
    accessor: 'updatedAt',
    disableFilters: true,
    sortType: 'basic',
    Cell: DateCell
  }
];

export default {
  userRoleColumns
};

import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: '2px dashed',
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1)
  }
}));

const AddRowDialog: React.FC<{
  linkTo?: string;
  setOpen?: (arg0: boolean) => void;
}> = ({ ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    if (props.linkTo) {
      navigate(`${props.linkTo}/add`);
    } else {
      props.setOpen?.(true);
    }
  };

  return (
    <Tooltip title="Add">
      <IconButton
        aria-label="add"
        onClick={handleClickOpen}
        className={classes.button}
        size="large"
      >
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AddRowDialog;

import React, { createContext, useReducer, useContext } from 'react';

interface State {
  shouldRefresh?: boolean;
  isDialogOpen?: boolean;
}

type ToggleShouldRefresh = {
  type: 'setShouldRefresh';
  shouldRefresh: boolean;
};

type IsDialogOpen = {
  type: 'setIsDialogOpen';
  isDialogOpen: boolean;
};

type Actions = ToggleShouldRefresh | IsDialogOpen;

const tableReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'setShouldRefresh':
      return {
        ...state,
        shouldRefresh: action.shouldRefresh
      };
    case 'setIsDialogOpen':
      return {
        ...state,
        isDialogOpen: action.isDialogOpen
      };
    default:
      return state;
  }
};

const TableRefreshContext = createContext(
  {} as [State, React.Dispatch<Actions>]
);

const initialState = {
  shouldRefresh: false,
  isDialogOpen: false
};

export const TableRefreshProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);
  return (
    <TableRefreshContext.Provider value={[state, dispatch]}>
      {children}
    </TableRefreshContext.Provider>
  );
};

export const useTableRefreshContext = () => {
  const [state, dispatch] = useContext(TableRefreshContext);
  return { state, dispatch };
};

import settings from 'config';
import Api from '../utils/Api';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

const DOCUMENT_API_BASE_URL = `${BASE_URL}/fileDocument/`;
const DOCUMENT_TYPE_API_BASE_URL = `${BASE_URL}/fileDocumentType/`;

interface FetchDocumentOptions extends APIQueryOptions {
  workspaceRoleIds?: number[];
  workspaceIds?: number[];
  fileDocumentTypeIds?: number[];
  limit?: number;
  planIds?: number[];
}

export const fetchDocuments = async (
  options: FetchDocumentOptions = {}
): Promise<DocumentAPI[]> => {
  const data = {
    ...options
  };

  return Api.post(`${DOCUMENT_API_BASE_URL}search`, data, getAuthHeader()).then(
    res => res.data.results
  );
};

interface FetchDocumentTypeOptions extends APIQueryOptions {
  fileDocumentTypeIds?: number[];
}

export const fetchDocumentType = async (
  options: FetchDocumentTypeOptions = {}
): Promise<DocumentAPI['fileDocumentType'][]> => {
  const data = {
    ...options
  };

  return Api.post(
    `${DOCUMENT_TYPE_API_BASE_URL}search`,
    data,
    getAuthHeader()
  ).then(res => res.data.results);
};

export const fetchDocumentById = (documentId: number): Promise<DocumentAPI> => {
  return Api.get(`${DOCUMENT_API_BASE_URL}${documentId}`, getAuthHeader()).then(
    res => res.data
  );
};

export const addDocument = (document: Partial<DocumentAPI>): Promise<any> => {
  return Api.post(DOCUMENT_API_BASE_URL, document, getAuthHeader());
};
export const editDocument = (
  id: number | string,
  document: Partial<DocumentAPI>
): Promise<DocumentAPI> => {
  return Api.put(
    `${DOCUMENT_API_BASE_URL}${id}`,
    document,
    getAuthHeader()
  ).then(res => res.data);
};

export const deleteDocumentById = (documentId: number): Promise<void> => {
  return Api.delete(
    `${DOCUMENT_API_BASE_URL}${documentId}`,
    getAuthHeader()
  ).then(res => res.data);
};

export const publishDocumentById = (
  documentId: number,
  value: boolean
): Promise<DocumentAPI> => {
  return Api.put(
    `${DOCUMENT_API_BASE_URL}${documentId}/publish`,
    { private: value },
    getAuthHeader()
  ).then(res => res.data);
};

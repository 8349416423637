import React from 'react';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import pkg from '../../../package.json';

const useStyles = makeStyles((theme: Theme) => ({
  inline: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    textAlign: 'right',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0
  },
  listItem: {
    margin: 0,
    padding: 0,
    justifyContent: 'right'
  },
  link: {
    textDecoration: 'none',
    fontSize: '1.2rem',
    color: 'inherit'
  },
  footer: {
    bottom: 0,
    padding: theme.spacing(1, 3),
    backgroundColor: 'white',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3)
    }
  }
}));

const footer = {
  title: 'UN-Info',
  description: [
    {
      id: 1,
      title: 'Privacy Notice',
      link:
        'https://www.un.org/en/sections/about-website/privacy-notice/index.html'
    },
    {
      id: 2,
      title: 'Terms of service',
      link: 'https://www.un.org/en/sections/about-website/terms-use/index.html'
    }
  ]
};
const Footer = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      data-testid="uninfo-test"
      component="footer"
      className={classes.footer}
    >
      <Grid item xs={8} />
      <Grid item xs={4}>
        <List dense className={classes.inline}>
          {footer.description.map(item => (
            <ListItem className={classes.listItem} key={item.id}>
              <Link
                className={classes.listItem}
                href={item.link}
                color="textSecondary"
                underline="none"
              >
                {item.title}
              </Link>
            </ListItem>
          ))}
          <ListItem className={classes.listItem}>
            <Link
              className={classes.listItem}
              href="/"
              color="textSecondary"
              underline="none"
            >
              {`v${pkg.version}`}
            </Link>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};
export default Footer;

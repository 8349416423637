import { cloneDeep, omit } from 'lodash';
import Api from 'utils/Api';
import settings from 'config';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

const SUBMISSION_API_BASE_URL = `${BASE_URL}/plan/submission/`;

export const addSubmission = (submission: any, entity: any) => {
  const formattedSubmission = submission;
  if (entity.planVersion) {
    formattedSubmission.planId = entity.id;
  } else {
    formattedSubmission.planId = entity.planId;
    formattedSubmission.planEntityId = entity.id;
  }
  formattedSubmission.data = submission.currentSubmission.data;
  delete formattedSubmission.currentSubmission;
  return Api.post(
    SUBMISSION_API_BASE_URL,
    formattedSubmission,
    getAuthHeader()
  );
};

export const editSubmission = (submission: any, entity: any) => {
  let formattedSubmission: any = {};
  // TODO : cleanup
  formattedSubmission = cloneDeep(submission);
  formattedSubmission.data = submission.currentSubmission.data;
  if (entity.planVersion) {
    formattedSubmission.planId = entity.id;
  } else {
    formattedSubmission.planId = entity.planId;
    formattedSubmission.planEntityId = entity.id;
  }
  // formattedSubmission.id = submission.currentSubmission.data?.planSubmissionId;
  formattedSubmission.id = submission.id;

  return Api.put(
    `${SUBMISSION_API_BASE_URL}${formattedSubmission.id}`,
    omit(formattedSubmission, ['currentSubmission', 'planSubmissionFields']),
    getAuthHeader()
  );
};

export const fetchSubmissions = (options: any) => {
  const data: any = getAuthHeader();
  if (options) {
    if (options.sort && options.sort.length) {
      data.sort = options.sort[0].id;
      data.order = options.sort[0].desc ? 'DESC' : 'ASC';
    }
    data.submissionIds = options.submissionIds;
  }
  return Api.post(`${SUBMISSION_API_BASE_URL}search`, data, getAuthHeader());
};

export const fetchDigest = (options: any) => {
  const data = options;
  return Api.post(
    `${SUBMISSION_API_BASE_URL}digest/search`,
    data,
    getAuthHeader()
  ).then(res => ({
    data: {
      ...res.data,
      results: res.data.results.map((obj: any) => {
        const fields: any = {};
        Object.keys(obj).forEach(keyName => {
          if (keyName.indexOf('field') !== -1) {
            const fieldId = +keyName.replace('field', '');
            fields[fieldId] = obj[keyName];
          }
        });
        return {
          id: obj.id,
          planId: obj.planId,
          planEntityId: obj.planEntityId,
          planSubmission: obj.planSubmission,
          plan: obj.plan,
          planEntity: obj.planEntity,
          fields
        };
      })
    }
  }));
};

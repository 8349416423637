import React, { memo } from 'reactn';
import { useLocation, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import {
  TableRefreshProvider,
  useTableRefreshContext
} from 'components/table/TableRefreshProvider';
import { BaseDialog } from 'uninfo-components';

import UserTable from 'components/table/UserTable';
import { userRoleColumns } from './UserRoleColumns';
import UserBuilderDialogue from './UserBuilderDialogue';

// FIXME Just update Base Dialog
const useStyles = makeStyles(theme => ({
  customBorderColor: {
    '&  > div:nth-child(3) > div ': {
      border: `2px solid ${theme.palette.primary.main}`
    }
  }
}));

const BaseUserRoleTable = () => {
  const classes = useStyles();
  const { dispatch } = useTableRefreshContext();
  const columns = React.useMemo(() => userRoleColumns, []);
  const [open, setOpen] = React.useState(false);
  const [editItem, setEditItem] = React.useState<User | undefined>(undefined);

  const [searchParams, setSearchParams] = useSearchParams();
  const userIdSearchParam = searchParams.get('id');
  const userIdParam =
    userIdSearchParam && !Number.isNaN(+userIdSearchParam)
      ? +userIdSearchParam
      : undefined;

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setEditItem(undefined);
    setSearchParams({});
  }, []);

  React.useEffect(() => {
    dispatch({ type: 'setIsDialogOpen', isDialogOpen: open });
  }, [open]);

  React.useEffect(() => {
    if (editItem?.id) {
      setSearchParams({ id: `${editItem?.id}` });
    } else if (userIdParam && !editItem?.id) {
      setOpen(true);
    }
  }, [editItem?.id, userIdParam]);

  return (
    <Grid item xs={12} style={{ overflowX: 'scroll' }}>
      <UserTable
        endpoint="user"
        setEditItem={setEditItem}
        setOpen={setOpen}
        columns={columns}
      />
      <BaseDialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        title="User details"
        description={
          <i style={{ textAlign: 'start', fontSize: '0.9rem' }}>
            ID: {editItem?.id ?? userIdParam ?? 'N/A'}
          </i>
        }
        className={classes.customBorderColor}
      >
        {editItem && (
          <UserBuilderDialogue id={editItem.id} onClose={handleClose} />
        )}
        {userIdParam && !editItem && (
          <UserBuilderDialogue id={userIdParam} onClose={handleClose} />
        )}
      </BaseDialog>
    </Grid>
  );
};

const UserManagementTable = () => {
  return (
    <TableRefreshProvider>
      <BaseUserRoleTable />
    </TableRefreshProvider>
  );
};

export default memo(UserManagementTable);

import React, { useGlobal, setGlobal } from 'reactn';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { Theme } from '@mui/material';

import {
  dashboardLink,
  MainListItems,
  AdminListItems,
  WorkspaceListItems
} from './SidebarLists';

import pkg from '../../../../package.json';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    // marginTop: 100,
    overflowX: 'hidden',
    overflowY: 'auto',
    '& .MuiListSubheader-sticky': {
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.grey[300]}`
    }
  },
  drawer: {
    whiteSpace: 'nowrap',
    flexShrink: 0,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(9),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  hide: {
    display: 'none'
  },
  bottomPush: {
    position: 'fixed',
    bottom: 0,
    textAlign: 'center',
    paddingBottom: 10
  }
}));

export default function Sidebar() {
  const [user] = useGlobal('user');
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
    setGlobal({ drawer: 'full' });
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setGlobal({ drawer: 'small' });
  };

  return (
    <>
      {user && user.id && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          <div style={{ marginTop: '100px' }}>
            <IconButton
              onClick={handleDrawerOpen}
              className={open ? classes.hide : ''}
              size="large"
            >
              <ChevronRightIcon />
            </IconButton>
            <IconButton
              className={!open ? classes.hide : ''}
              onClick={handleDrawerClose}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
            <div />
            <Divider />
            <List>{dashboardLink}</List>

            <List>
              <MainListItems user={user} />
            </List>
          </div>
          <div className={classes.drawerContainer}>
            {user?.userRoles?.find((uR: any) => uR.contextWorkspaceId) && (
              <List>
                <WorkspaceListItems user={user} />
              </List>
            )}
            <Divider />
            <List>
              <AdminListItems user={user} />
            </List>
          </div>
          <Divider />

          <ListItem button>
            <ListItemIcon />
            <ListItemText primary={`v${pkg.version}`} />
          </ListItem>
        </Drawer>
      )}
    </>
  );
}

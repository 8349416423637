export const customFormColumns = [
  {
    Header: 'Name',
    accessor: 'formVersion.name',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Context',
    accessor: 'context',
    filter: 'fuzzyText',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Custom Form ID',
    accessor: 'formVersion.customFormId',
    disableFilters: true,
    filter: 'fuzzyText',
    sortType: 'basic'
  }
];

export default {
  customFormColumns
};

import settings from 'config';

export const PLAN = 3;
export const INDICATOR = 7;
export const JWP = 6;
export const SURVEY_ENTITY_ID = 12;
export const OUTPUT_ENTITY_ID = 8;
export const SUBOUTPUT_ENTITY_ID = 9;
export const OUTCOME_ENTITY_ID = 5;
export const OCCUPANT_ENTITY_ID = 17;
export const PREMISE_ENTITY_ID = 16;
export const NON_MONETARY_ASSISTANCE_CATEGORY_ID = 284;
export const NMA_TYPECATEGORY_ID = 330;
export const AGENCY_TYPE_ID = 2;

export const IMS_DOMAIN_ID = 5;
export const IMS_ANNUAL_PROGRAMMATIC_ID = 9;
export const CFR_TYPE_ID = 3;
export const CFR_DOMAIN_ID = 3;
export const CPR_TYPE_ID = 4;
export const UNDAF_TYPE_ID = 7;

export const SERP_BLUEPRINT_ID = 76;
export const IMS_MONTHLY_SURVEY_BLUEPRINT_ID = 83;

export const DATE_FORMAT = 'yyyy-MM-dd';

export const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

export const REGIONS = [
  { id: 0, name: 'Africa' },
  { id: 1, name: 'Arab State' },
  { id: 2, name: 'Asia Pacific' },
  { id: 3, name: 'Europe & Central Asia' },
  { id: 4, name: 'Latin America and the Caribbean' }
];

export const REGIONS_WITH_ALL = [
  { id: 5, name: 'All' },
  { id: 0, name: 'Africa' },
  { id: 1, name: 'Arab State' },
  { id: 2, name: 'Asia Pacific' },
  { id: 3, name: 'Europe & Central Asia' },
  { id: 4, name: 'Latin America and the Caribbean' }
];

export const REGIONS_WORKSPACES = [
  [
    177,
    221,
    209,
    193,
    208,
    211,
    28,
    188,
    192,
    52,
    185,
    187,
    189,
    251,
    180,
    175,
    159,
    174,
    55,
    170,
    166,
    161,
    20,
    162,
    160,
    135,
    122,
    127,
    126,
    115,
    27,
    111,
    106,
    105,
    23,
    31,
    104,
    102,
    22,
    75,
    60,
    72,
    66,
    63,
    32,
    61,
    73,
    36,
    51,
    29,
    15,
    30,
    14
  ],

  [205, 143, 137, 130, 128, 233, 74, 53, 218, 33],
  [
    21,
    207,
    195,
    133,
    24,
    168,
    146,
    147,
    144,
    227,
    129,
    26,
    114,
    107,
    109,
    96,
    92,
    86,
    88,
    248,
    123,
    50,
    46,
    37
  ],
  [
    220,
    216,
    212,
    202,
    203,
    165,
    18,
    230,
    134,
    116,
    108,
    19,
    62,
    49,
    43,
    47,
    35,
    34
  ],
  [
    217,
    197,
    247,
    199,
    198,
    190,
    186,
    184,
    183,
    178,
    176,
    228,
    155,
    153,
    149,
    151,
    139,
    113,
    91,
    81,
    89,
    59,
    44,
    41,
    40
  ]
];
export const CPSCOPE_OFFICE_TYPE = [1, 2, 10, 12, 3, 13, 9, 4, 6, 5, 8, 7];
export const SURVEY_LIST = [
  { id: 0, name: 'Evaluation', value: 'evaluation' },
  { id: 1, name: 'Funding', value: 'funding' },
  { id: 2, name: 'Preparation', value: 'preparation' },
  { id: 3, name: 'Signatory', value: 'signatory' }
];

export const PLAN_SURVEY_VERSIONS = [
  { id: 0, name: 'Published', value: 'latestTagged' },
  { id: 1, name: 'Latest', value: 'latest' }
];
export const CP_HOME_WORK_RATIO = 0.8;

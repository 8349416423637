import React, { memo } from 'reactn';
import Grid from '@mui/material/Grid';

import UserManagementTable from './user/UserManagementTable';

const AdminUser = () => {
  return (
    <Grid item xs={12} style={{ overflowX: 'scroll' }}>
      <UserManagementTable />
    </Grid>
  );
};

export default memo(AdminUser);

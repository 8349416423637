import React, { setGlobal, useGlobal } from 'reactn';
import { QueryClient, QueryClientProvider } from 'react-query';

import { BrowserRouter } from 'react-router-dom';

import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import { ThemeProvider, Theme } from '@mui/material/styles';

import './App.css';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { fetchProfile } from 'services/UserService';
import { currentSession, getUserInfo } from 'services/AuthService';
import Topbar from 'components/layout/Topbar';
import Footer from 'components/layout/Footer';
import GlobalStyles from 'styles/global';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import Sidebar from 'components/layout/Sidebar/Main';
import { SnackbarUtilsConfigurator } from 'utils/SnackbarUtils';

import ErrorBoundary from 'components/layout/ErrorBoundary';
import theme, { confirmDefaultOptions } from 'theme';
import MainBody from 'MainBody';
import { isFuture, isValid } from 'date-fns';
import pkg from '../package.json';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const { useEffect, useState } = React;

const queryClient = new QueryClient();

// Need to do that because emotion on prod use the insertRule
// https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleSheet/insertRule
// and for PDF export we need to have the CSS available in the html head
const header = document.querySelector('head');
const emotionCache = createCache({
  key: 'css',
  container: header ?? undefined,
  prepend: true,
  speedy: false
});

const App: React.FC = () => {
  const [user] = useGlobal('user');
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const userId = user && user.id;
  // Function to clear complete cache data
  const clearCacheData = () => {
    const version = localStorage.getItem('version');
    if (version !== pkg.version) {
      caches
        .keys()
        .then(names => {
          names.forEach(name => {
            caches.delete(name);
          });
        })
        .catch(e => {
          console.error(e);
        });
    }
  };
  clearCacheData();
  setGlobal({ version: pkg.version });

  const onLoad = React.useCallback(async () => {
    try {
      const session = await currentSession();
      if (userId || session) {
        const expirationDate = new Date(getUserInfo().expirationDateTime);
        if (isFuture(expirationDate) && isValid(expirationDate)) {
          // TODO: api call to get fresh data
          const userProfile = await fetchProfile();

          // wip temp fix if api doesnt have userRoles or change to not mandatory on the User type
          if (!userProfile.data.userRoles) {
            userProfile.data.userRoles = [];
          }
          setGlobal({ user: userProfile.data });
        }
      }

      setIsAuthenticating(false);
    } catch (e) {
      setIsAuthenticating(false);
      if (e !== 'No current user') {
        console.error(e);
      }
    }
    setIsAuthenticating(false);
  }, [userId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  if (isAuthenticating) {
    return null;
  }

  return (
    !isAuthenticating && (
      <CacheProvider value={emotionCache}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <SnackbarUtilsConfigurator />
              <ConfirmProvider defaultOptions={confirmDefaultOptions}>
                <DndProvider backend={Backend}>
                  <BrowserRouter>
                    <ErrorBoundary>
                      <Topbar />
                    </ErrorBoundary>
                    <ErrorBoundary>
                      <Sidebar />
                    </ErrorBoundary>
                    <MainBody user={user} />
                  </BrowserRouter>
                  <Footer />
                  <GlobalStyles />
                </DndProvider>
              </ConfirmProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </CacheProvider>
    )
  );
};

export default App;

import { Edit, Check } from '@mui/icons-material';
import { Alert, IconButton, Typography } from '@mui/material';
import React from 'react';
import { groupBy } from 'lodash';

import { BaseDialog, Table } from 'uninfo-components';
import {
  searchPlanSurveys,
  SurveySignatoryResponse
} from 'services/PlanSurveyService';
import { useParams, Link } from 'react-router-dom';
import { fetchPlanById } from 'services/PlanService';
import {
  fetchWorkspaceOrganizations,
  fetchWorkspaces
} from 'services/WorkspaceService';
import theme from 'theme';

import CFSignatoryForm from './CFSignatoryForm';

const Signatories: React.FC = () => {
  const params = useParams();
  const [open, setOpen] = React.useState(false);
  const [plan, setPlan] = React.useState<Plan | undefined>(undefined);
  const [workspaceOrg, setWorkspaceOrg] = React.useState<
    WorkspaceOrganization
  >();
  const [workspaceOrgs, setWorkspaceOrgs] = React.useState<
    WorkspaceOrganization[]
  >([]);
  const [surveyMap, setSurveyMap] = React.useState<{
    [key: number]: SurveySignatoryResponse[];
  }>({});

  const fetchPlanCallback = React.useCallback(async () => {
    if (params.id) {
      const fetchedPlan = await fetchPlanById(+params.id);
      setPlan(fetchedPlan);
      const planSurveyId = fetchedPlan?.planSurveys?.[0]?.id;
      let tempSurveyMap: { [key: number]: any } = {};

      if (planSurveyId) {
        const fetchedSurveyResponses = await searchPlanSurveys<
          SurveySignatoryResponse
        >({
          surveyName: 'signatory',
          planSurveyId: +planSurveyId,
          limit: null
        });
        tempSurveyMap = groupBy(
          fetchedSurveyResponses.results,
          'organizationId'
        );
      }

      const children = await fetchWorkspaces({
        parentIds: [fetchedPlan.workspaceId]
      });

      const fetchedWorkspaceOrgs = await fetchWorkspaceOrganizations({
        workspaceIds: [fetchedPlan.workspaceId]
      });

      fetchedWorkspaceOrgs.forEach(wo => {
        if (wo.organizationId && !wo.organization?.isRCO) {
          const workspaces =
            children.length > 0
              ? [fetchedPlan.workspace, ...children]
              : [fetchedPlan.workspace];

          workspaces.forEach(workspace => {
            const hasSurvey =
              wo.organizationId &&
              tempSurveyMap[wo.organizationId]?.find(
                (survey: SurveySignatoryResponse) =>
                  survey.workspaceId === workspace?.id
              );

            if (hasSurvey) {
              hasSurvey.workspace = workspace;
            }
            if (!hasSurvey && wo.organizationId && workspace) {
              if (tempSurveyMap[wo.organizationId]) {
                tempSurveyMap[wo.organizationId].push({
                  workspace,
                  workspaceId: workspace.id
                });
              } else if (wo.organizationId) {
                tempSurveyMap[wo.organizationId] = [
                  { workspace, workspaceId: workspace.id }
                ];
              }
            }
          });

          tempSurveyMap[wo.organizationId] = tempSurveyMap[
            wo.organizationId
          ]?.sort((a: any, b: any) => (a.workspace.parentId === null ? -1 : 1));
        }
      });
      setWorkspaceOrgs(
        fetchedWorkspaceOrgs.filter(wo => !wo.organization?.isRCO)
      );
      setSurveyMap(tempSurveyMap);
    }
  }, [params.id]);

  React.useEffect(() => {
    fetchPlanCallback();
  }, [fetchPlanCallback]);

  let signedOnBehalf = 0;
  let signedDirectly = 0;
  let notFilledIn = 0;

  Object.keys(surveyMap).forEach(key => {
    const isSignatoryDataItem =
      surveyMap[+key]?.[0]?.surveySignatoryVersion
        ?.isEntitySignatoryToFramework;

    if (isSignatoryDataItem === undefined) {
      notFilledIn += 1;
    } else if (isSignatoryDataItem.name.includes('behalf')) {
      signedOnBehalf += 1;
    } else if (isSignatoryDataItem.name.includes('signed')) {
      signedDirectly += 1;
    }
  });

  return (
    <>
      {/* <TextField label="Filter" /> */}
      <Typography variant="h2" style={{ marginBottom: '.5rem' }}>
        C.1.3.8 - Signatories and derivation of UN Entity&apos;s country level
        programming
      </Typography>
      <Typography variant="h3" style={{ marginBottom: '2rem' }}>
        Note: The data in this section is used for UNSDG cost-sharing purposes.
        Please ensure the resident co-ordinator signs off on this data
      </Typography>
      <Typography variant="h3">
        To edit, click on the <Edit />
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography variant="subtitle1">
          Don&apos;t see an Entity? You must first add it to your{' '}
          <Link
            to={`/dashboard/workspace/${plan?.workspaceId}/contacts/unEntity`}
          >
            RCO/UNCT Composition
          </Link>
        </Typography>
      </div>
      {notFilledIn > 0 && (
        <Alert color="warning" variant="filled" style={{ marginTop: '1rem' }}>
          <div>
            <strong>{notFilledIn} </strong> UN Entities have not yet been
            verified as signatories/non-signatories to the plan
          </div>
          <div>
            <strong>{signedDirectly}</strong> UN Entities signed directly
          </div>
          <div>
            <strong>{signedOnBehalf}</strong> RC signed on behalf of UN Entity
          </div>
        </Alert>
      )}
      <Table style={{ marginTop: '1rem' }}>
        <thead>
          <tr>
            <th>UN entity</th>
            <th className="alignRight">Signatory?</th>
            <th className="alignRight">UNCT member?</th>
            <th className="alignRight">Physical presence?</th>
            {/* <th>Entity&apos;s country programming</th> */}
            <th className="alignRight">Edit signatories</th>
          </tr>
        </thead>
        <tbody>
          {workspaceOrgs.map(wo => {
            if (!wo.organizationId) {
              return null;
            }
            const version =
              surveyMap[wo.organizationId]?.[0]?.surveySignatoryVersion;
            return (
              <tr
                key={wo.id}
                style={
                  version === undefined
                    ? { background: theme.palette.warning.light }
                    : {}
                }
              >
                <td>{wo.organization?.name}</td>
                <td className="alignRight">
                  {!version?.isEntitySignatoryToFramework?.name?.startsWith(
                    'No'
                  ) &&
                    version !== undefined && <Check />}
                </td>
                <td className="alignRight">{wo.unctMember && <Check />}</td>
                <td className="alignRight">
                  {wo.inCountryPresence && <Check />}
                </td>
                {/* <td /> */}
                <td className="alignRight">
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setWorkspaceOrg(wo);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {workspaceOrg?.organizationId && plan && (
        <BaseDialog
          open={open}
          onClose={() => {
            setOpen(false);
            setWorkspaceOrg(undefined);
          }}
          title={workspaceOrg?.organization?.name ?? ''}
          fullWidth
          maxWidth="xl"
        >
          <CFSignatoryForm
            plan={plan}
            orgWorkspace={workspaceOrg}
            workspaceSurveys={surveyMap[workspaceOrg.organizationId]}
            reload={() => fetchPlanCallback()}
          />
        </BaseDialog>
      )}
    </>
  );
};

export default Signatories;

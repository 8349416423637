/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line import/prefer-default-export
export function capitalize(word: string) {
  const wordToLower = word.toLowerCase();
  return wordToLower.charAt(0).toUpperCase() + wordToLower.slice(1);
}

export function capitalizeSentence(sentence?: string) {
  if (sentence) {
    return sentence
      .split(' ')
      .map(s => capitalize(s))
      .join(' ');
  }
  return undefined;
}

import React, { useCallback } from 'reactn';
import { Controller, useFormContext } from 'react-hook-form';
import FormError from 'components/common/form/FormError';
import { Grid, MenuItem, Select, FormLabel } from '@mui/material';
import { TextField } from 'uninfo-components';
import NumberFormat from 'react-number-format';

import { Checkbox } from 'components/common/form/Checkbox';
import { Autocomplete } from 'components/common/AutoComplete';
import { autocomplete } from 'services/AutocompleteService';

const officeTypes = ['Main', 'Subnational', 'Outposted MCO'];

export const RcoForm: React.FC<{
  jobTitleDataSourceId?: number;
  mainRcoContact: WorkspaceOrganizationContact | null;
}> = ({ jobTitleDataSourceId, mainRcoContact }) => {
  const { watch, errors, setValue } = useFormContext();
  const watchContact = watch('contact');
  const watchGroup = watch('type');
  const watchJobTitle = watch('jobTitle');
  const watchFinancing = watch('financings');
  const watchContributionKind = watch('contributionKind');
  const watchArea = watch('areas');
  const watchOfficeType = watch('officeType');
  const watchMainRCOContact = watch('mainRCOContact');

  const fetchedDataitem = useCallback(
    async (inputValue, id: number) => {
      return autocomplete('dataitem', {
        parentId: id,
        simple: true,
        q: inputValue
      });
    },
    [jobTitleDataSourceId]
  );

  const checkValidity = (value: any): string | boolean => {
    if (value === true) {
      // FIXME: https://gitlab.tools.uninfo.org/dco/uninfo-mvp/-/issues/1133#note_28955
      // const mainRCOContact = contacts.find(
      //   contact =>
      //     (contact.mainRCOContact && contact.id !== contactId) ||
      //     (!contactId && contact.mainRCOContact)
      // );
      // if (mainRCOContact) {
      //   return `${mainRCOContact.name} is currently marked as the Head of RCO or Main RCO contact.`;
      // }
    }
    return true;
  };

  const isRcoHead =
    watchJobTitle?.name === "Head of Resident Coordinator's Office";
  if (isRcoHead && !watchMainRCOContact) {
    setValue('mainRCOContact', true);
  }
  const hasHead =
    mainRcoContact?.organizationContact.jobTitle?.dataitemVersion?.name ===
    "Head of Resident Coordinator's Office";

  const isThisMainContact =
    mainRcoContact && watchContact.id === mainRcoContact?.id;

  return (
    <>
      <Grid item xs={12} />
      {watchGroup === 'RCO' && (
        <>
          <Grid item xs={12} sm={6}>
            <Checkbox
              name="mainRCOContact"
              disabled={
                isRcoHead ||
                (!!mainRcoContact &&
                  ((!hasHead && !isThisMainContact) ||
                    (hasHead && !isThisMainContact && !watchMainRCOContact)))
              }
              label={
                jobTitleDataSourceId === 142
                  ? `Team leader/Head of DCO?`
                  : `Team leader/Head of RCO?`
              }
              rules={{ validate: value => checkValidity(value) }}
              {...(mainRcoContact && !isRcoHead
                ? {
                    moreInfo: `Team leader/Head is already present: ${mainRcoContact.name}`
                  }
                : {})}
            />
          </Grid>
          <Grid item sm={6} />
        </>
      )}

      <Grid item xs={12} sm={6}>
        <Controller
          name="jobTitle"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ref, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) =>
                fetchedDataitem(
                  e,
                  jobTitleDataSourceId ?? (watchGroup === 'RCO' ? 18 : 142)
                )
              }
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="Job Title"
              required
              {...props}
            />
          )}
          rules={{ required: true }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {watchJobTitle?.name === 'Other' && (
          <>
            <Controller
              name="jobTitleOther"
              as={TextField}
              rules={{ required: true }}
              fullWidth
              placeholder="Please specify"
              required
              size="small"
              label="Please specify"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <FormError errors={errors} name="jobTitleOther" />
          </>
        )}
      </Grid>

      {watchGroup && watchGroup === 'RCO' && (
        <>
          <Grid item xs={12} sm={6}>
            <FormLabel style={{ display: 'block' }}>
              Does this person work at the main office, the subnational office,
              or an outposted MCO? *
            </FormLabel>

            <Controller
              name="officeType"
              render={({ onChange, ref, ...props }) => (
                <Select
                  onChange={onChange}
                  {...props}
                  size="small"
                  style={{ width: '100%' }}
                  required
                >
                  {officeTypes.map(ct => (
                    <MenuItem key={ct} value={ct}>
                      {ct}
                    </MenuItem>
                  ))}
                </Select>
              )}
              rules={{ required: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {watchOfficeType !== 'Main' && (
              <>
                <Controller
                  name="officeCity"
                  as={TextField}
                  rules={{ required: true }}
                  fullWidth
                  placeholder="Please provide name of the city/town and country where applicable"
                  required
                  size="small"
                  label="Please provide name of the city/town and country where applicable"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <FormError errors={errors} name="officeCity" />
              </>
            )}
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <Controller
          name="postLevel"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ref, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) => fetchedDataitem(e, 20)}
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="Post Level"
              required
              {...props}
            />
          )}
          rules={{ required: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="contractModality"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ref, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) => fetchedDataitem(e, 19)}
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="Contract Modality"
              required
              {...props}
            />
          )}
          rules={{ required: true }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="financings"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ref, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) =>
                fetchedDataitem(e, watchGroup === 'RCO' ? 53 : 143)
              }
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="Source of Financing"
              multiple
              {...props}
            />
          )}
          rules={{
            validate: value => {
              if (Array.isArray(value) && value.length > 0) {
                return true;
              }
              return 'Please select a least one source of financing for this contact';
            }
          }}
        />
        <FormError errors={errors} name="financings" />
      </Grid>
      {watchFinancing && watchFinancing.find((f: any) => f.name === 'Other') && (
        <>
          <Grid item xs={12} sm={6}>
            <Controller
              name="financingOther"
              as={TextField}
              rules={{ required: true }}
              fullWidth
              placeholder="Please specify"
              required
              size="small"
              label="Please specify"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </>
      )}
      {watchFinancing &&
        watchFinancing.length > 0 &&
        !watchFinancing.find(
          (f: any) => f.name === 'Special Purpose Trust Fund (DCO)'
        ) && (
          <>
            <Grid item xs={12} sm={3}>
              <Controller
                name="contributionKind"
                onChange={([, value]: DataItem[]) => value}
                render={({ onChange, ref, ...props }) => (
                  <Autocomplete
                    fetchOptionsCallback={(e: any) => fetchedDataitem(e, 144)}
                    onChange={(_event: any, data: DataItem) => onChange(data)}
                    fullWidth
                    label="Contribition Kind"
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {watchContributionKind?.name ===
                'In-kind personnel contribution' && (
                <>
                  <Controller
                    name="inKindPersonnelContribution"
                    as={
                      <NumberFormat
                        customInput={TextField}
                        thousandSeparator
                        prefix="$ "
                      />
                    }
                    fullWidth
                    label="What is the estimated value of annual in-kind personnel contribution ?"
                    variant="outlined"
                  />
                </>
              )}
            </Grid>
          </>
        )}
      <Grid item xs={12} />
      <Grid item xs={12} sm={6}>
        <Controller
          name="areas"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ref, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) => fetchedDataitem(e, 145)}
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="Main areas of responsibility"
              multiple
              {...props}
            />
          )}
          rules={{
            validate: value => {
              if (Array.isArray(value) && value.length > 0) {
                return true;
              }
              return 'Please select a least one main areas of responsibility for this contact';
            }
          }}
        />
        <FormError errors={errors} name="areas" />
      </Grid>
      {watchArea && watchArea.find((f: any) => f.name === 'Other') && (
        <>
          <Grid item xs={12} sm={6}>
            <Controller
              name="areaOther"
              as={TextField}
              rules={{ required: true }}
              fullWidth
              placeholder="Please specify"
              required
              size="small"
              label="Please specify"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <FormError errors={errors} name="areaOther" />
          </Grid>
        </>
      )}
    </>
  );
};

export default RcoForm;

import React from 'react';
import { CircularProgress as MuiCircularProgress } from '@mui/material';

/**
 * A component that centers and blocks clicking in dialogs
 * @returns
 */
const CircularProgress: React.FC = () => {
  return (
    <div
      style={{
        background: 'rgba(0, 0, 0, .25)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '99999',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0
      }}
    >
      <MuiCircularProgress />
    </div>
  );
};

export default CircularProgress;

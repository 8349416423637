import React, { useCallback } from 'reactn';
import { Controller, useFormContext } from 'react-hook-form';
import FormError from 'components/common/form/FormError';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Checkbox } from 'components/common/form/Checkbox';

import { Autocomplete } from 'components/common/AutoComplete';
import { autocomplete } from 'services/AutocompleteService';

export const UncgMemberForm = (): React.ReactElement => {
  const { control, errors } = useFormContext();

  const fetchedDataitem = useCallback(async (inputValue, id: number) => {
    return autocomplete('dataitem', {
      parentId: id,
      simple: true,
      q: inputValue
    });
  }, []);

  return (
    <>
      <Grid item xs={12} />
      <Grid item xs={12} sm={6}>
        <Controller
          name="postLevel"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) => fetchedDataitem(e, 20)}
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="Post Level"
              {...props}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="jobTitleOther"
          as={TextField}
          control={control}
          rules={{ required: true }}
          fullWidth
          style={{ backgroundColor: 'white' }}
          placeholder="Job Title"
          required
          size="small"
          label="Job Title"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
        <FormError errors={errors} name="jobTitleOther" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Checkbox name="uncgLead" label="UNCG Chair" />
      </Grid>
    </>
  );
};

export default UncgMemberForm;

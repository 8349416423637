/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useState, useGlobal } from 'reactn';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext
} from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { Button, BaseDialog } from 'uninfo-components';
import PermissionGate, {
  hasPermission
} from 'components/common/PermissionGate';
import { Helmet } from 'react-helmet';

import {
  Grid,
  TextField,
  Typography,
  Theme,
  Tooltip,
  IconButton
} from '@mui/material';

import { Autocomplete } from 'components/common/AutoComplete';
import PaperWrapper from 'components/common/PaperWrapper';

import { autocomplete } from 'services/AutocompleteService';
import {
  fetchContactById,
  editContact,
  addContact,
  fetchContacts,
  deleteOrganizationContact
} from 'services/ContactService';
import FormError from 'components/common/form/FormError';
import { ArrowBack as ArrowBackIcon, Delete, Edit } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';

import { isExisting } from 'utils/typeguards';
import ContactForm from 'pages/UNEntitiesAndRCO/ContactForm';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(2)
  }
}));

const buildDefaultValues = (
  contact?: Contact,
  search?: { [key: string]: string }
) => ({
  email: contact?.email ?? '',
  name: contact?.name ?? search?.name ?? '',

  gender: {
    id: contact?.gender?.id,
    name: contact?.gender?.dataitemVersion?.name
  },

  nationality: {
    id: contact?.nationality?.id,
    name: contact?.nationality?.name
  }
});

const useContactFormStyles = makeStyles((theme: Theme) => ({
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  backButton: {
    marginBottom: theme.spacing(3)
  }
}));

export const ContactBasicForm: React.FC = () => {
  const { errors } = useFormContext();

  const fetchedDataitem = useCallback(async (inputValue, id: number) => {
    return autocomplete('dataitem', {
      parentId: id,
      simple: true,
      q: inputValue
    });
  }, []);

  const fetchedNationality = useCallback(async inputValue => {
    return autocomplete('country', {
      q: inputValue
    });
  }, []);
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Controller
          name="name"
          as={TextField}
          rules={{ required: true }}
          fullWidth
          placeholder="Full Name"
          required
          label="Full Name"
          size="small"
          variant="outlined"
          sx={{ backgroundColor: 'field' }}
          InputLabelProps={{ shrink: true }}
        />
        <FormError errors={errors} name="name" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="gender"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ref, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={(e: any) => fetchedDataitem(e, 101)}
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="Gender"
              required
              {...props}
            />
          )}
          rules={{ required: true }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="email"
          size="small"
          as={TextField}
          rules={{
            pattern: {
              message: 'Invalid email address',
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
            },
            required: true
          }}
          fullWidth
          placeholder="Email"
          required
          label="Email"
          variant="outlined"
          sx={{ backgroundColor: 'field' }}
          InputLabelProps={{ shrink: true }}
        />
        <FormError errors={errors} name="email" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="nationality"
          onChange={([, value]: DataItem[]) => value}
          render={({ onChange, ref, ...props }) => (
            <Autocomplete
              fetchOptionsCallback={fetchedNationality}
              onChange={(_event: any, data: DataItem) => onChange(data)}
              fullWidth
              label="Nationality"
              required
              data-cy="nationality"
              {...props}
            />
          )}
          rules={{ required: true }}
        />
      </Grid>
    </>
  );
};

interface ContactBasicFormBuilderProps {
  contact?: Contact;
  hasOrganizationContact?: boolean;
  isLoading?: boolean;
  setLoading?: (value: boolean) => void;
  disabled?: boolean;
  setContact?: (contact: Contact) => void;
  reload?: () => Promise<void>;
}

export const ContactBasicFormBuilder: React.FC<ContactBasicFormBuilderProps> = ({
  contact,
  hasOrganizationContact,
  isLoading: isParentLoading,
  setLoading: setParentLoading,
  disabled,
  setContact,
  reload
}) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const methods = useForm({
    defaultValues: buildDefaultValues(contact),
    mode: 'onChange'
  });
  const { handleSubmit, reset } = methods;

  React.useEffect(() => {
    reset(buildDefaultValues(contact));
  }, [reset, contact]);

  React.useEffect(() => {
    setParentLoading?.(loading);
  }, [loading, setParentLoading]);

  const onSubmit = async (data: any) => {
    if (!loading) {
      try {
        await confirm({
          description: hasOrganizationContact
            ? `Click 'Confirm' only if the same person is continuing in this role. If a different person is now in the role, please click 'Cancel', and then click the 'Contact has left position' button instead.`
            : `Click 'Confirm' only if you are updating the same person.`
        });

        setLoading(true);
        const localItem = data;
        localItem.id = contact?.id;
        localItem.email = data?.email;
        localItem.genderId = localItem.gender?.id;
        localItem.nationalityId = localItem.nationality?.id;

        if (localItem.id) {
          await editContact(localItem);
        } else {
          const exists = await fetchContacts({
            email: localItem.email
          });

          if (exists.length > 0) {
            await confirm({
              description: `There is another contact record (id ${exists[0].id}, ${exists[0].name}) 
              with the same email address.  Contacts should 
              not normally be duplicated unless they represent multiple 
              entities.  Do you want to proceed with saving this record?`
            });
          }
          await addContact(localItem);
        }

        await reload?.();
        setContact?.(localItem);
        enqueueSnackbar('Contact updated', {
          variant: 'success',
          autoHideDuration: 3000,
          preventDuplicate: true
        });
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} style={{ marginTop: '0.5rem' }}>
          <ContactBasicForm />
          <PermissionGate scopes={['contact_update', 'contact_create']}>
            <Grid
              item
              xs={12}
              style={{
                marginLeft: '0rem',
                marginBottom: '.75rem',
                marginTop: '.25rem'
              }}
            >
              <Button
                type="submit"
                disabled={disabled}
                isLoading={isParentLoading || loading}
                variant="outlined"
                color="primary"
              >
                Save
              </Button>
            </Grid>
          </PermissionGate>
        </Grid>
      </form>
    </FormProvider>
  );
};

const ContactOrganizationRow: React.FC<{
  contact: Contact;
  oc: OrganizationContact;
  reload: () => void;
}> = ({ oc, contact, reload }) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation('common', { keyPrefix: 'unEntities' });
  const confirm = useConfirm();

  const deleteContactFromEntity = React.useCallback(async () => {
    try {
      if (oc.workspaceOrganizationId) {
        await confirm({
          description: t('areYouSureDelete')
        });
        await deleteOrganizationContact(oc.id);
        if (reload) {
          await reload();
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [reload, oc]);

  return (
    <Grid container key={oc.id} style={{ padding: '1rem 0' }}>
      <Grid item xs={4}>
        {oc.workspaceOrganization?.workspace?.name}
      </Grid>
      <Grid item xs={6}>
        {oc.workspaceOrganization?.organization?.name}
      </Grid>
      <Grid item xs={2} sx={{ textAlign: 'right' }}>
        {oc.workspaceOrganization && (
          <>
            <Tooltip title="Manage contacts on organization and workspace">
              <IconButton size="small" onClick={() => setOpen(true)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove contact from organization and workspace">
              <IconButton size="small" onClick={deleteContactFromEntity}>
                <Delete />
              </IconButton>
            </Tooltip>
            <BaseDialog
              maxWidth="xl"
              fullWidth
              open={open}
              onClose={() => setOpen(false)}
              title="Contact management"
            >
              <ContactForm
                existingContact={{
                  ...contact,
                  organizationContact: oc
                }}
                workspaceOrganizationId={oc.workspaceOrganization.id}
                workspaceOrganization={oc.workspaceOrganization}
                workspace={oc.workspaceOrganization.workspace}
                contactTypes={[
                  'Entity representative',
                  'UNCG member',
                  'RC',
                  'RCO',
                  'RO',
                  'RD'
                ]}
                onDeleteContactClick={deleteContactFromEntity}
              />
            </BaseDialog>
          </>
        )}
      </Grid>
    </Grid>
  );
};
interface ContactFormProps {
  contact?: Contact;
  setContact: any;
  reload: () => void;
}
export const ContactBuilder: React.FC<ContactFormProps> = ({
  contact,
  setContact,
  reload
}) => {
  const [loading, setLoading] = useState(false);
  const [loggedInUser] = useGlobal('user');
  const location = useLocation();
  const classes = useContactFormStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const search = new URLSearchParams(location.search);

  const methods = useForm({
    defaultValues: buildDefaultValues(contact, Object.fromEntries(search)),
    mode: 'onChange'
  });

  const { handleSubmit, errors } = methods;

  const save = async (data: any) => {
    if (!loading) {
      setLoading(true);
      try {
        const localItem = data;
        localItem.id = contact?.id;
        localItem.email = data?.email;
        localItem.genderId = localItem.gender?.id;
        localItem.nationalityId = localItem.nationality?.id;

        if (localItem.id) {
          const savedContact = await editContact(localItem);
          setContact(savedContact);
        } else {
          const exists = await fetchContacts({
            email: localItem.email
          });

          if (exists.length > 0) {
            await confirm({
              description: `There is another contact record (id ${exists[0].id}) 
              with the same email address and country.  Contacts should 
              not normally be duplicated unless they represent multiple 
              entities.  Do you want to proceed with saving this record?`
            });
          }
          const res = await addContact(localItem);
          navigate(`/dashboard/admin/contact/${res.id}`);
        }

        enqueueSnackbar('Contact updated', {
          variant: 'success',
          autoHideDuration: 3000,
          preventDuplicate: true
        });

        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }
  };

  const canEdit = hasPermission({
    user: loggedInUser,
    scopes: ['contact_update']
  });

  const memoHistory = React.useCallback(() => navigate(-1), [navigate]);

  return (
    <>
      <Helmet>
        <title>{`UN Info - Contacts: ${contact?.name ?? 'Add New'}`}</title>
      </Helmet>
      <FormProvider {...methods}>
        <PaperWrapper component="form" onSubmit={handleSubmit(save)}>
          <Button
            color="primary"
            onClick={memoHistory}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            className={classes.backButton}
          >
            Back
          </Button>
          <Grid container spacing={2}>
            <ContactBasicForm />
            {(contact?.organizationContacts?.length ?? 0) > 0 && (
              <Grid item xs={12}>
                <Typography variant="h2">
                  Contact is linked to these workspaces and organizations
                </Typography>
                {contact?.organizationContacts.map(oc => (
                  <ContactOrganizationRow
                    oc={oc}
                    key={oc.id}
                    contact={contact}
                    reload={reload}
                  />
                ))}
              </Grid>
            )}
            <Grid item xs={12} style={{ marginTop: '1rem' }}>
              <Button
                type="submit"
                disabled={!isEmpty(errors) || !canEdit}
                isLoading={loading}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </PaperWrapper>
      </FormProvider>
    </>
  );
};

const AdminContactBuilder = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);

  const [contact, setContact] = useState<Contact>();

  const fetchCb = useCallback(async () => {
    try {
      setLoading(true);
      if (id && id !== 'add') {
        const res = await fetchContactById(+id);
        setContact(res);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [id]);

  React.useEffect(() => {
    fetchCb();
  }, [fetchCb]);

  return (
    <>
      {!loading && (id === 'add' || (id !== 'add' && isExisting(contact))) && (
        <>
          <Typography variant="h4" className={classes.header}>
            {id === 'add' ? 'Add' : 'Edit'} Contact
          </Typography>

          <ContactBuilder
            contact={contact}
            setContact={setContact}
            reload={fetchCb}
          />
        </>
      )}
    </>
  );
};

export default AdminContactBuilder;

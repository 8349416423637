import Asynchronous from 'components/table/AsynchronousFilter';

export const organizationColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Abbreviation',
    accessor: 'abbreviation',
    disableFilters: true,
    filter: 'fuzzyText',
    sortType: 'basic'
  },
  {
    Header: 'Type',
    id: 'typeId',
    // id: 'organizationType.name',
    accessor: 'organizationType.name',
    endpoint: 'organizationType',
    sortType: 'basic',
    filterLabel: 'Organization Type',
    Filter: Asynchronous
  },
  {
    Header: 'IATI Code',
    accessor: 'iatiCode',
    disableFilters: true,
    filter: 'fuzzyText',
    sortType: 'basic'
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableFilters: true,
    filter: 'fuzzyText',
    sortType: 'basic'
  },
  {
    Header: 'Archived At',
    accessor: 'archivedAt',
    disableFilters: true,
    filter: 'fuzzyText',
    sortType: 'basic'
  }
];

export default {
  organizationColumns
};

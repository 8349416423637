import Api from 'utils/Api';
import settings from 'config';

import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;
const UNIT_API_BASE_URL = `${BASE_URL}/unit`;

export const fetchUnits = (
  options: APIQueryOptions
): Promise<APIResults<Unit>> => {
  const data: APIQueryOptions = {};
  if (options) {
    data.limit = 100;
    data.q = options.q;
    data.sort = options.sort ?? [['name', 'ASC']];
  }

  return Api.post(`${UNIT_API_BASE_URL}/search`, data, getAuthHeader()).then(
    res => res.data.results
  );
};

export const addUnit = (unit: Unit) => {
  return Api.post(`${UNIT_API_BASE_URL}`, unit, getAuthHeader()).then(
    res => res.data
  );
};

export const updateUnit = (unit: Unit) => {
  return Api.put(`${UNIT_API_BASE_URL}/${unit.id}`, unit, getAuthHeader()).then(
    res => res.data
  );
};

import React from 'react';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

const useAddMultipleToggle = (): {
  Toggle: React.FC;
  ToggleInFormDescription: React.FC;
  resetSwitch: () => void;
  switchState: { addMultiple: boolean };
} => {
  const [switchState, setSwitchState] = React.useState({
    addMultiple: false
  });

  const handleSwitchChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const resetSwitch = () => {
    setSwitchState({ addMultiple: false });
  };

  const Toggle = () => (
    <Tooltip title="Add multiple">
      <div>
        Add multiple
        <Switch
          checked={switchState.addMultiple}
          onChange={handleSwitchChange('addMultiple')}
          value="addMultiple"
          inputProps={{ 'aria-label': 'add multiple of this value' }}
        />
      </div>
    </Tooltip>
  );

  const ToggleInFormDescription = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <span style={{ flexGrow: 1, textAlign: 'left' }}>
          Add a new value after this one?{' '}
        </span>
        <Toggle />
      </div>
    );
  };

  return {
    Toggle,
    ToggleInFormDescription,
    resetSwitch,
    switchState
  };
};

export default useAddMultipleToggle;

import React from 'react';
import { debounce } from 'lodash';

import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { IconButton, Theme, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useTableContext } from './TableProvider';

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.65),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 1)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  }
}));

interface GlobalFilterProps {
  totalRecords: number;
}

const GlobalFilter: React.FC<GlobalFilterProps> = ({ totalRecords }) => {
  const { state, dispatch } = useTableContext();
  const [qString, setQString] = React.useState(state.queryString ?? undefined);
  const classes = useStyles();

  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.

  const debounceSetFilterCallback = React.useCallback(
    debounce(q => {
      dispatch({ type: 'setQueryString', queryString: q });
    }, 500),
    []
  );

  React.useEffect(() => {
    debounceSetFilterCallback(qString);
  }, [debounceSetFilterCallback, qString]);

  const handleClearClick = () => {
    setQString('');
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>

      <InputBase
        onChange={e => {
          setQString(e.target.value ?? '');
        }}
        value={qString}
        placeholder={`${totalRecords} records...`}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{
          'aria-label': 'search'
        }}
        endAdornment={
          <Tooltip title="Clear search input" aria-label="clear search input">
            <IconButton
              sx={{ visibility: qString ? 'visible' : 'hidden' }}
              onClick={handleClearClick}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        }
      />
    </div>
  );
};

export default GlobalFilter;

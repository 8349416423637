import React, { useCallback } from 'react';

import { Popover, TableSortLabel, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Button } from 'uninfo-components';
import { TableInstance } from 'react-table';

const useStyles = makeStyles(
  createStyles({
    columnsPopOver: {
      padding: 24
    },
    filtersResetButton: {
      marginTop: 18
    },
    popoverTitle: {
      fontWeight: 500,
      padding: '0 24px 24px 0',
      textTransform: 'uppercase'
    },
    grid: {
      display: 'grid',
      gridRowGap: 18
    },
    cell: {
      width: '100%',
      display: 'inline-flex',
      flexDirection: 'column'
    },
    hidden: {
      display: 'none'
    }
  })
);

const hideColumn = ({
  archivable,
  columnId,
  includeSoftDeleted
}: {
  archivable?: boolean;
  columnId: any;
  includeSoftDeleted?: boolean;
}): boolean => {
  if (archivable && columnId === 'deletedAt') {
    return true;
  }
  if (
    (columnId === 'deletedAt' || columnId === 'archivedAt') &&
    !includeSoftDeleted
  ) {
    return true;
  }
  return false;
};

interface Props {
  instance: TableInstance;
  anchorEl: any;
  onClose: () => void;
  show: boolean;
  archivable?: boolean;
  includeSoftDeleted?: boolean;
}

export default function SortingPopUp({
  instance,
  anchorEl,
  onClose,
  show,
  archivable,
  includeSoftDeleted
}: Props) {
  const classes = useStyles({});
  const { setSortBy } = instance;
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      onClose();
    },
    [onClose]
  );

  const resetSorting = useCallback(() => {
    setSortBy([{ id: 'id', desc: true }]);
  }, [setSortBy]);

  return (
    <div>
      <Popover
        anchorEl={anchorEl}
        id="popover-filters"
        onClose={onClose}
        open={show}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className={classes.columnsPopOver}>
          <Typography className={classes.popoverTitle}>Sort by</Typography>
          <form onSubmit={onSubmit}>
            <div>
              <div className={classes.grid} key={instance.id}>
                {instance.headerGroups.map(headerGroup => (
                  <>
                    {headerGroup.headers
                      .filter(c => c.id !== 'selection' && !c.disableSortBy)
                      .map(column => {
                        if (
                          hideColumn({
                            archivable,
                            includeSoftDeleted,
                            columnId: column.id
                          })
                        ) {
                          return true;
                        }
                        return (
                          <div
                            {...(column.id === 'selection'
                              ? column.getHeaderProps()
                              : column.getHeaderProps(
                                  column.getSortByToggleProps()
                                ))}
                            key={column.id}
                          >
                            {column.render('Header')}
                            {column.id !== 'actions' &&
                              column.id !== 'selection' && (
                                <TableSortLabel
                                  active={column.isSorted}
                                  // react-table has a unsorted state which is not treated here
                                  direction={
                                    column.isSortedDesc ? 'desc' : 'asc'
                                  }
                                />
                              )}
                          </div>
                        );
                      })}
                  </>
                ))}
              </div>
            </div>
            <div>
              <Button
                className={classes.filtersResetButton}
                color="primary"
                variant="outlined"
                onClick={resetSorting}
              >
                Reset
              </Button>
            </div>
            <Button className={classes.hidden} type="submit">
              {'\u00A0'}
            </Button>
          </form>
        </div>
      </Popover>
    </div>
  );
}

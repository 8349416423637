import { withStyles } from '@mui/styles';

import Paper from '@mui/material/Paper';

export const PaperWrapper = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    width: '100%',
    marginBottom: theme.spacing(4),

    '& h4': {
      margin: theme.spacing(1, 0, 2)
    }
  }
}))(Paper) as typeof Paper;

export default PaperWrapper;

import React from 'react';
import { useSnackbar } from 'notistack';

import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import CircularProgress from 'components/dialogs/CircularProgress';

import { SurveyEvaluationResponse } from 'services/PlanSurveyService';
import {
  createDefaultValues,
  createOrUpdate,
  Datum,
  fetchPlanAndBuildSurveyForm,
  Question
} from './utils';
import { QuestionForm } from './QuestionForm';
import LoadingBlocks from './LoadingBlocks';

export const questions: Question[] = [
  {
    question: `C.1.7.1 - What is the planned date when the evaluation will be initiated for this framework? *`,
    type: 'datetime',
    required: true,
    slug: 'expectedDateForEvaluation'
  },
  {
    question: `C.1.7.2 - What is the planned date when the evaluation will be finalised for this framework? * `,
    type: 'datetime',
    required: true,
    slug: 'expectedDateForEvaluationFinalised'
  },
  {
    question: `C.1.7.3 - When was the evaluation for this framework initiated?`,
    type: 'datetime',
    slug: 'whenEvaluationInitiated'
  },
  {
    question: `C.1.7.4 - When was the evaluation for this framework finalised?`,
    type: 'datetime',
    slug: 'whenEvaluationFinalised'
  },
  {
    question: `C.1.7.4.1 - Does the evaluation include actionable
    recommendations, with a clear target audience and time
    frame for implementation`,
    type: 'boolean',
    required: true,
    slug: 'includeActionableRecommendation',
    dependsOn: 'whenEvaluationFinalised'
  },
  {
    question: `C.1.7.4.2 - Was a management response prepared * `,
    type: 'boolean',
    required: true,
    slug: 'wasManagementResponsePrepared',
    dependsOn: 'whenEvaluationFinalised'
  },
  {
    question: `C.1.7.4.2.1 - If a management response was prepared, how was the response led * `,
    type: 'dataitem',
    slug: 'managementResponse',
    dependsOn: 'wasManagementResponsePrepared',
    filterId: 433,
    required: true
  },
  {
    question: `C.1.7.4.2.1.1 - If other, please specify`,
    type: 'textField',
    slug: 'otherResponse',
    dependsOn: 'managementResponse',
    dependsOnFnc: (field: { name: string }): boolean => {
      return !!field?.name.startsWith('Other');
    }
  },
  {
    question: `C.1.7.4.3 - Did the process bring together key UN agencies with comparative advantage  value to addressing the recommendations* `,
    type: 'boolean',
    slug: 'didProcessBringUNAgencies',
    dependsOn: 'whenEvaluationFinalised',
    required: true
  },
  {
    question: `C.1.7.4.4 - Was the <a target="_blank" href="https://unitednations.sharepoint.com/:x:/r/sites/DCO-WG-UNSDG_CF/_layouts/15/Doc.aspx?sourcedoc=%7B4CEB9C03-D424-461D-94B0-F866F59A4CA0%7D&file=Template_%20Evaluation%20Management%20Response%20matrix_DCO-June%202021.xlsx&action=default&mobileredirect=true">DCO Template Evaluation Management Response
    matrix_DCO</a> template used? *`,
    type: 'boolean',
    slug: 'wasDCOTemplateUsed',
    dependsOn: 'whenEvaluationFinalised',
    required: true
  }
];
const surveyName = 'evaluation';

const Evaluation: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [evaluation, setEvaluation] = React.useState<
    SurveyEvaluationResponse['surveyEvaluationVersion']
  >();
  const [plan, setPlan] = React.useState<Plan>();

  const fetchPlanCallback = React.useCallback(async () => {
    const {
      form: surveyMapped,
      plan: fetchedPlan
    } = await fetchPlanAndBuildSurveyForm<SurveyEvaluationResponse>({
      id: params.id,
      questions,
      surveyName
    });
    setPlan(fetchedPlan);
    setEvaluation(surveyMapped);
    setIsLoading(false);
  }, [params.id]);

  React.useEffect(() => {
    fetchPlanCallback();
  }, [fetchPlanCallback, params.id]);

  const methods = useForm({
    defaultValues: createDefaultValues(questions)
  });
  const { reset } = methods;

  React.useEffect(() => {
    if (evaluation) {
      reset(createDefaultValues(questions, evaluation));
    }
  }, [evaluation, reset]);

  const onSubmit = React.useCallback(
    async (data: { [key: string]: Datum }) => {
      if (params.id) {
        setIsSaving(true);
        const msg = await createOrUpdate({
          questions,
          planId: +params.id,
          data,
          surveyName,
          survey: evaluation
        });
        await fetchPlanCallback();
        setIsSaving(false);
        enqueueSnackbar(msg, {
          variant: 'success',
          autoHideDuration: 5000,
          preventDuplicate: true
        });
      }
    },
    [enqueueSnackbar, evaluation, fetchPlanCallback, params.id]
  );

  return (
    <FormProvider {...methods}>
      {isLoading && <LoadingBlocks />}
      {isSaving && <CircularProgress />}
      {!isLoading && plan && (
        <QuestionForm
          questions={questions}
          onSubmit={onSubmit}
          disabled={isSaving}
          surveyVersion={evaluation}
          plan={plan}
        />
      )}
    </FormProvider>
  );
};

export default Evaluation;

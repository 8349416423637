import React, { useGlobal } from 'reactn';
import { truncate } from 'lodash';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material';
import { format } from 'date-fns';

import { IndeterminateCheckbox } from 'components/table/IndeterminateCheckbox';
import { DATE_FORMAT } from 'utils/constants';

import { Row } from 'react-table';
import { useTableContext } from 'components/table/TableProvider';

import ActionButtons from 'components/table/UserActionButtons';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(1)
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'start'
  },
  paper: {
    padding: 20,
    margin: 4,
    transition: '0.3s',
    '&:hover': {
      boxShadow: '5px 5px 0px #dfdfdf'
    }
  },
  title: {
    fontSize: 26,
    fontWeight: 500
  },
  checkbox: {
    alignSelf: 'flex-start'
  },
  tooltip: {
    maxWidth: 800,
    background: 'var(--color-white)',
    border: '1px solid var(--color-grey)',
    padding: theme.spacing(2),
    color: 'var(--color-black)',
    boxShadow: '0 0 8px rgba(0, 0, 0, .2)'
  }
}));

type WorkspaceNameLookupType = {
  [key: number]: string;
};

const CardUserInfo = ({
  row,
  columns,
  workspaceNameLookup
}: {
  row: Row<any>;
  columns: any;
  workspaceNameLookup?: WorkspaceNameLookupType;
}) => {
  const {
    id: displayId,
    firstname: displayFirstname,
    email: displayEmail,
    username: displayUsername,
    userRoles: displayUserRoles,
    position: displayPosition,
    organizationId: displayOrganizationId,
    workspaceId: displayWorkspaces,
    status: displayStatus
  } = columns;

  const {
    original: {
      id,
      firstname,
      lastname,
      email,
      username,
      userRoles,
      position,
      organization,
      status
    }
  } = row;

  return (
    <Grid container justifyContent="space-between" spacing={1}>
      {(displayFirstname || displayEmail || displayUsername) && (
        <Grid item xs={3}>
          {displayId && (
            <>
              <Typography component="div">
                <strong>ID:</strong> {id}
              </Typography>
            </>
          )}
          {displayFirstname && (
            <Typography component="div">
              <b>Name: </b>
              {firstname} {lastname}
            </Typography>
          )}
          {displayEmail && (
            <Typography component="div">
              <strong>Email:</strong> {email}
            </Typography>
          )}
          {displayUsername && (
            <Typography component="div">
              <strong>Username:</strong> {username}
            </Typography>
          )}
        </Grid>
      )}
      {(displayUserRoles || displayPosition) && (
        <Grid item xs={3}>
          {displayOrganizationId && (
            <Typography component="div">
              <strong>Organization:</strong> {organization?.name}
            </Typography>
          )}
          {displayPosition && (
            <>
              <Typography component="div">
                <strong>Positon:</strong> {position}
              </Typography>
            </>
          )}
          {displayStatus && (
            <Typography component="div">
              <strong>Status:</strong> {status}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={6}>
        {displayUserRoles && displayWorkspaces && (
          <Typography component="div" style={{ whiteSpace: 'pre-wrap' }}>
            <strong>User Roles:</strong>{' '}
            {userRoles
              ?.map((ur: UserRole) =>
                [
                  ur.role.name,
                  ur.contextWorkspaceId &&
                    workspaceNameLookup &&
                    workspaceNameLookup[ur.contextWorkspaceId]
                ]
                  .filter(n => n)
                  .join(' - ')
              )
              .filter(
                (r: string, i: number, ra: string[]) => ra.indexOf(r) === i
              )
              .sort()
              .join(',  ') || '-'}
          </Typography>
        )}
        {displayUserRoles && !displayWorkspaces && (
          <Typography component="div" style={{ whiteSpace: 'pre-wrap' }}>
            <strong>User Roles:</strong>{' '}
            {userRoles
              ?.map((ur: UserRole) => ur.role.name)
              .filter(
                (r: string, i: number, ra: string[]) => ra.indexOf(r) === i
              )
              .join(', ') || '-'}
          </Typography>
        )}
        {!displayUserRoles && displayWorkspaces && workspaceNameLookup && (
          <Typography component="div" style={{ whiteSpace: 'pre-wrap' }}>
            <strong>Workspaces:</strong>{' '}
            {userRoles
              ?.map((ur: UserRole) => ur.contextWorkspaceId)
              .map((n: number | null) => n)
              .filter(
                (r: number, i: number, ra: number[]) => ra.indexOf(r) === i
              )
              .map((wi: number) => workspaceNameLookup[wi])
              .sort()
              .join(', ') || '-'}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

interface CustomCard {
  user: User;
  row: Row<any>;
  endpoint: string;
  linkTo?: string;
  canDelete?: boolean;
  preDeleteCheck?: (ids: number[]) => Promise<boolean>;
  includeSoftDeleted?: boolean;
  visibleColumns?: any[];
  setDialogData?: (data: any) => void;
  workspaceNameLookup?: WorkspaceNameLookupType;
}

const CustomCard: React.FC<CustomCard> = ({
  user,
  row,
  endpoint,
  canDelete,
  preDeleteCheck,
  includeSoftDeleted,
  visibleColumns,
  setDialogData,
  workspaceNameLookup
}) => {
  const [loggedInUser] = useGlobal('user');
  const { t } = useTranslation('common', { keyPrefix: 'userTable' });

  const classes = useStyles();
  const { state } = useTableContext();

  if (!loggedInUser) {
    return null;
  }

  const textTrunc = 400;

  const description = '';

  const columns = visibleColumns
    ?.map(c => c.id)
    .reduce((a, v) => {
      return { ...a, [v]: true };
    }, {});

  const isSoftDeleted =
    includeSoftDeleted && (row.values.deletedAt || row.values.archivedAt);

  return (
    <Paper
      elevation={0}
      className={classes.paper}
      style={{
        borderLeft: `5px var(--color-blue-background) solid`,
        backgroundColor: isSoftDeleted
          ? `var(--color-blue-background)`
          : undefined
      }}
    >
      <Grid container>
        {state.canSelect && (
          <Grid item xs={0.5}>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              className={classes.checkbox}
            />
          </Grid>
        )}
        <Grid item xs={state.canSelect ? 11 : 12}>
          <Grid container justifyContent="space-between">
            <Grid item md={10}>
              <CardUserInfo
                row={row}
                columns={columns}
                workspaceNameLookup={workspaceNameLookup}
              />
            </Grid>
            <Grid item md={2} className={classes.headerRight}>
              <ActionButtons
                row={row}
                endpoint={endpoint}
                archivable={false}
                preDeleteCheck={preDeleteCheck}
                canDelete={canDelete}
                setDialogData={setDialogData}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              {description?.length > textTrunc && (
                <Tooltip
                  arrow
                  placement="bottom"
                  classes={{
                    tooltip: classes.tooltip
                  }}
                  title={
                    <>
                      <Typography style={{ fontSize: '0.875rem' }}>
                        {description}
                      </Typography>
                    </>
                  }
                >
                  <Typography variant="subtitle2">
                    {truncate(description, { length: textTrunc })}
                  </Typography>
                </Tooltip>
              )}
              {description?.length <= textTrunc && (
                <Typography variant="subtitle2">{description}</Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row-reverse"
            className={classes.container}
            alignItems="center"
          >
            {columns.updatedAt && (
              <Grid
                item
                xs={2}
                className={classes.headerRight}
                sx={{ marginRight: 2 }}
              >
                {t('lastUpdatedOn', {
                  time:
                    format(new Date(user.updatedAt), DATE_FORMAT) ||
                    '2020-01-01 10:00'
                })}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

interface UserItemProps {
  endpoint: string;
  linkTo?: string;
  user: any; // User;
  row: Row<any>; // FIXME: type this
  includeSoftDeleted?: boolean;
  visibleColumns?: any[];
  preDeleteCheck?: (ids: number[]) => Promise<boolean>;
  canDelete?: boolean;
  setDialogData?: (data: any) => void;
  workspaceNameLookup?: WorkspaceNameLookupType;
}

export const UserItem: React.FC<UserItemProps> = function UserCard({
  endpoint,
  user,
  row,
  includeSoftDeleted,
  visibleColumns,
  preDeleteCheck,
  canDelete,
  setDialogData,
  workspaceNameLookup
}) {
  return (
    <>
      {(!user.deletedAt || includeSoftDeleted) && (
        <tr>
          <td>
            <CustomCard
              row={row}
              endpoint={endpoint}
              user={user}
              canDelete={canDelete}
              preDeleteCheck={preDeleteCheck}
              visibleColumns={visibleColumns}
              includeSoftDeleted={includeSoftDeleted}
              setDialogData={setDialogData}
              workspaceNameLookup={workspaceNameLookup}
            />
          </td>
        </tr>
      )}
    </>
  );
};
export default UserItem;

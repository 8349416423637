import React, { memo } from 'reactn';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import EnhancedTable from 'components/table/EnhancedTable';
import { organizationColumns } from './OrganizationColumns';

const AdminAgency = () => {
  const columns = React.useMemo(() => organizationColumns, []);

  return (
    <Grid item xs={12}>
      <Paper>
        <EnhancedTable
          linkTo="/dashboard/admin/organization"
          endpoint="organization"
          allowMerge
          columns={columns}
          hiddenColumns={[]}
          archivable
        />
      </Paper>
    </Grid>
  );
};

export default memo(AdminAgency);

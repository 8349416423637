import React, { useGlobal } from 'reactn';
import { IMS_DOMAIN_ID } from 'utils/constants';

type AdditionalVariables = {
  allStepsCompleted?: boolean;
};

const scopeDetailsError = (scope: string, detailsName: string) =>
  `${scope} requires ${detailsName} to be passed to PermissionGate or hasPermissions`;

const determineIfCanDoScope = (
  scope: string,
  options: {
    user: User;
    plan?: Plan;
    additionalVariables?: AdditionalVariables;
    entityPrototype?: EntityPrototype;
    workspaceIds?: number[];
    regionIds?: number[];
    domainId?: number;
  }
): boolean => {
  const {
    user,
    plan,
    additionalVariables,
    workspaceIds,
    regionIds,
    domainId: passedDomainId
  } = options;

  const isIMS = plan?.planType?.domainId === IMS_DOMAIN_ID;
  const domainId = passedDomainId ?? plan?.planType?.domainId;

  if (
    [
      'plan_publishTag',
      'plan_updateSubmission',
      'plan_update',
      'plan_updateEntity',
      'plan_softDelete',
      'plan_softDeleteEntity',
      'comment_create',
      'comment_update',
      'entityPrototype_completeFlag'
    ].includes(scope)
  ) {
    if (!plan) {
      console.error(scopeDetailsError(scope, 'plan'));
    }

    const hasPermission = !!user.userRoles.find(userRole => {
      const isForCorrectDomain = userRole.contextDomainId === domainId;

      const isForPlanWorkspace =
        userRole.contextWorkspaceId === plan?.workspaceId;
      const isForPlanRegion =
        userRole.contextRegionId === plan?.workspace?.regionId;
      const isForAllWorkspaces =
        !userRole.contextRegionId && !userRole.contextWorkspaceId;
      const foundAPermission = userRole.role.rolePermissions.find(
        rolePermission => rolePermission.permission?.key === scope
      );

      return (
        isForCorrectDomain &&
        (isForPlanWorkspace || isForPlanRegion || isForAllWorkspaces) &&
        foundAPermission
      );
    });

    if (!hasPermission) {
      return false;
    }

    if (
      scope === 'plan_update' &&
      isIMS &&
      !additionalVariables?.allStepsCompleted
    ) {
      return false;
    }

    return true;
  }

  if (
    domainId &&
    ['report_exportPlan', 'report_exportCrossPlans'].includes(scope)
  ) {
    const hasPermission = !!user.userRoles.find(userRole => {
      const isForCorrectDomain = userRole.contextDomainId === domainId;
      const foundAPermission = userRole.role.rolePermissions.find(
        rolePermission => rolePermission.permission?.key === scope
      );
      return isForCorrectDomain && foundAPermission;
    });

    if (!hasPermission) {
      return false;
    }

    return true;
  }

  // These don't have domain specific information
  if (
    [
      'user_update',
      'user_search',
      'user_update',
      'user_softDelete',
      'contact_update',
      'contact_view',
      'contact_softDelete',
      'contact_create',
      'organization_archive',
      'organization_batchCreate',
      'organization_create',
      'organization_getEntities',
      'organization_merge',
      'organization_softDelete',
      'organization_unarchive',
      'organization_update',
      'organizationContact_create',
      'organizationContact_update',
      'organizationContact_softDelete',
      'blueprint_update',
      'userRole_batchAssign',
      'userRole_assign',
      'fileDocument_create',
      'fileDocument_update',
      'fileDocument_softDelete',
      'fileDocument_view',
      'location_archive',
      'location_batchCreate',
      'location_create',
      'location_csvExport',
      'location_csvImport',
      'location_getEntities',
      'location_merge',
      'location_softDelete',
      'location_unarchive',
      'location_update',
      'report_exportCrossPlans',
      'report_exportOccupant',
      'report_exportPlan',
      'report_exportPremise',
      'report_userAuditPlanEntity',
      'planSurvey_getFundingReport',
      'planSurvey_search',
      'planSurvey_create',
      'planSurvey_update',
      'workspaceOrganization_create',
      'workspaceOrganization_update',
      'workspaceOrganization_softDelete'
    ].includes(scope)
  ) {
    if (
      workspaceIds &&
      [
        'blueprint_update',
        'contact_update',
        'contact_create',
        'organizationContact_create',
        'organizationContact_update',
        'organizationContact_softDelete',
        'fileDocument_update',
        'fileDocument_softDelete',
        'planSurvey_getFundingReport',
        'planSurvey_search',
        'planSurvey_create',
        'planSurvey_update',
        'workspaceOrganization_create',
        'workspaceOrganization_update',
        'workspaceOrganization_softDelete'
      ].includes(scope)
    ) {
      return !!user.userRoles.find(
        userRole =>
          ((userRole.contextWorkspaceId &&
            workspaceIds.includes(userRole.contextWorkspaceId)) ||
            (!userRole.contextWorkspaceId && !userRole.contextRegionId) ||
            // TODO: check if contextRegionId contains workspaceIds
            (userRole.contextRegionId &&
              regionIds?.length &&
              regionIds.includes(userRole.contextRegionId))) &&
          userRole.role.rolePermissions.find(
            rolePermission => rolePermission.permission?.key === scope
          )
      );
    }

    if (user?.userRoles.length) {
      return !!user.userRoles.find(userRole =>
        userRole.role.rolePermissions.find(
          rolePermission => rolePermission.permission?.key === scope
        )
      );
    }
  }

  return false;
};

export const hasPermission = ({
  user,
  plan,
  entityPrototype,
  workspaceIds,
  regionIds,
  scopes,
  additionalVariables,
  domainId
}: {
  user?: User;
  plan?: Plan;
  workspaceIds?: number[];
  regionIds?: number[];
  entityPrototype?: EntityPrototype;
  scopes: Scopes[] | Scopes;
  additionalVariables?: AdditionalVariables;
  domainId?: number;
}): boolean => {
  if (user?.isAdmin) {
    return true;
  }
  if (!user) {
    return false;
  }
  if (user?.userRoles?.length === 0 && !user.isAdmin) {
    return false;
  }
  if (!Array.isArray(scopes)) {
    return determineIfCanDoScope(scopes, {
      user,
      plan,
      entityPrototype,
      workspaceIds,
      regionIds,
      domainId,
      additionalVariables
    });
  }
  // If the scopes is an array we do an "or" by default.
  return scopes.some(scope => {
    return determineIfCanDoScope(scope, {
      user,
      plan,
      entityPrototype,
      workspaceIds,
      regionIds,
      domainId,
      additionalVariables
    });
  });
};

export const hasRole = ({
  user,
  workspace,
  organizationId,
  roles
}: {
  user?: User;
  workspace?: Workspace;
  organizationId?: number;
  roles: string[];
}): boolean => {
  if (user?.isAdmin) {
    return true;
  }
  if (!user) {
    return false;
  }
  if (user.userRoles?.length === 0) {
    return false;
  }
  const role = user?.userRoles?.find(
    (r: any) =>
      (r.contextWorkspaceId === workspace?.id ||
        r.contextRegionId === workspace?.regionId ||
        (!r.contextWorkspaceId && !r.contextRegionId)) &&
      roles.indexOf(r.role.short) !== -1
  );
  if (
    organizationId &&
    role?.role.short === 'AFP' &&
    (user.organization?.id === organizationId || !role.contextOrganizationId)
  ) {
    return true;
  }

  if (!organizationId && role) {
    return true;
  }
  return false;
};

interface MatchUserRoles {
  user?: User;
  domainId?: number;
  permission?: Scopes;
  or?: boolean;
  context?: 'contextRegionId' | 'contextWorkspaceId' | 'global';
}

const matchUserRoles = ({
  domainId,
  permission,
  or = false,
  context = 'global'
}: MatchUserRoles) => (r: UserRole) => {
  const skip = !or;
  const isContextMached =
    context === 'global'
      ? !r.contextWorkspaceId && !r.contextRegionId
      : !!r[context];
  const isDomainMatched = domainId ? r.contextDomainId === domainId : skip;
  const isPermissionMatched = permission
    ? !!r.role.rolePermissions.find(rP => rP.permission?.key === permission)
    : skip;
  if (or) {
    return isContextMached && (isPermissionMatched || isDomainMatched);
  }
  return isContextMached && isPermissionMatched && isDomainMatched;
};

export const hasUserRole = ({
  user,
  domainId,
  permission,
  or = false,
  context = 'global'
}: MatchUserRoles): boolean => {
  if (user?.isAdmin) {
    return true;
  }
  if (!user) {
    return false;
  }
  if (!user.userRoles || user.userRoles?.length === 0) {
    return false;
  }
  const matchUserRolesFn = matchUserRoles({
    domainId,
    permission,
    or,
    context
  });
  return !!user.userRoles.find(matchUserRolesFn);
};

export const filterUserRoles = ({
  user,
  domainId,
  permission,
  or = false,
  context = 'global'
}: MatchUserRoles): UserRole[] => {
  if (user?.isAdmin) {
    return user.userRoles;
  }
  if (!user) {
    return [];
  }
  if (!user.userRoles || user.userRoles?.length === 0) {
    return [];
  }
  const matchUserRolesFn = matchUserRoles({
    domainId,
    permission,
    or,
    context
  });
  return user.userRoles.filter(matchUserRolesFn);
};

const PermissionGate: React.FC<{
  RenderError?: () => React.ReactElement;
  errorProps?: any;
  scopes: Scopes[] | Scopes;
  plan?: Plan;
  entityPrototype?: EntityPrototype;
  workspaceIds?: number[];
  regionIds?: number[];
  additionalVariables?: AdditionalVariables;
  domainId?: number;
}> = ({
  children,
  RenderError = () => <></>,
  errorProps = null,
  plan,
  entityPrototype,
  workspaceIds,
  regionIds,
  domainId,
  additionalVariables = {},
  scopes = []
}) => {
  const [user] = useGlobal('user');

  const permissions: string[] = [];

  if (!user) {
    return <RenderError />;
  }

  const permissionGranted = hasPermission({
    user,
    plan,
    entityPrototype,
    workspaceIds,
    regionIds,
    additionalVariables,
    domainId,
    scopes
  });

  if (!permissionGranted && !errorProps) return <RenderError />;

  if (!permissionGranted && errorProps) {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, { ...errorProps });
    }
  }

  if (React.isValidElement(children)) {
    return React.cloneElement(children, { ...permissions });
  }

  return <>{children}</>;
};

export default PermissionGate;

import React, { useGlobal } from 'reactn';
import { useFormContext, Controller } from 'react-hook-form';
import { unionBy } from 'lodash';

import { Autocomplete } from 'components/common/AutoComplete';
import { fetchWorkspaces } from 'services/WorkspaceService';

interface WorkspaceAutocompleteProps {
  workspace?: Workspace;
  exclude?: ('countries' | 'contacts' | 'region')[];
  variant?: 'filled';
  multiple?: boolean;
  rules?: any;
  validate?: (d: any) => boolean | string;
  fullWidth?: boolean;
  onWorkspaceChange?: () => void; // useful for form submission or validation in parent component
}

/**
 * This is a WorkspaceAutocomplete component to be used specifically with a
 * react-hook-form form, inside of its context.
 * @param props
 */
export const WorkspaceAutocomplete: React.FC<WorkspaceAutocompleteProps &
  React.HTMLAttributes<HTMLDivElement>> = ({
  workspace,
  exclude,
  onWorkspaceChange,
  multiple = false,
  rules,
  validate,
  fullWidth,
  ...parentProps
}) => {
  const [user] = useGlobal('user');
  const [currentWorkspace] = useGlobal('currentWorkspace');
  const { control } = useFormContext();

  const fetchedWorkspaceOptions = React.useCallback(
    async inputValue => {
      const workspaceIds =
        user?.isAdmin ||
        user?.userRoles?.find(
          (uR: any) => !uR.contextWorkspaceId && !uR.contextRegionId
        )
          ? undefined
          : user?.userRoles
              ?.filter((uR: any) => uR.contextWorkspaceId !== null)
              .map(w => w.contextWorkspaceId)
              .filter(w => w !== null);
      let workspaces = await fetchWorkspaces({
        globalFilter: inputValue,
        workspaceIds,
        exclude
      });
      if (
        user?.userRoles?.find((uR: any) => uR.contextRegionId) &&
        !user?.userRoles?.find(
          (uR: any) => !uR.contextWorkspaceId && !uR.contextRegionId
        )
      ) {
        const regionalWorkspaces = await fetchWorkspaces({
          globalFilter: inputValue,
          regionIds: user?.userRoles?.map(r => r.contextRegionId)
        });
        workspaces = [...workspaces, ...regionalWorkspaces];
      }
      return workspaces;
    },
    [user, exclude]
  );

  let defaultValue: Workspace[] | Workspace | null =
    (workspace || currentWorkspace) ?? null;

  if (multiple) {
    defaultValue = defaultValue ? [defaultValue] : [];
  }

  return (
    <Controller
      data-cy="workspace-controller"
      control={control}
      name="workspace"
      defaultValue={defaultValue}
      rules={{ validate }}
      render={({ onChange, ref, ...props }) => (
        <Autocomplete
          dataCy="workspace-controller"
          fullWidth={fullWidth ?? true}
          label="Workspace"
          multiple={multiple}
          fetchOptionsCallback={fetchedWorkspaceOptions}
          onChange={(event: any, data: any) => {
            onChange(data);
            if (onWorkspaceChange) {
              onWorkspaceChange();
            }
            return data;
          }}
          {...(rules?.required ? { required: true } : {})}
          {...props}
          {...parentProps}
        />
      )}
    />
  );
};

export default WorkspaceAutocomplete;

import React from 'react';
import { makeStyles } from '@mui/styles';
import { ErrorMessage } from '@hookform/error-message';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red'
  }
}));

interface FormErrorProps {
  errors: any;
  message?: string;
  name: string;
}

const FormError: React.FC<FormErrorProps> = ({
  errors,
  message = 'There is a problem with this field',
  name
}) => {
  const classes = useStyles();
  const msg = message ?? errors[name]?.message;
  return (
    <>
      {name && errors[name] && (
        <span className={classes.error}>
          <ErrorMessage errors={errors} name={name} message={msg} />
        </span>
      )}
    </>
  );
};

export default FormError;

import React from 'react';

import { ParseResult } from 'papaparse';
import { CSVReader } from 'react-papaparse';

import { Button } from 'uninfo-components';
import Tooltip from '@mui/material/Tooltip';
import Check from '@mui/icons-material/Check';
import Warning from '@mui/icons-material/Warning';

export const mapCSVDataToJson = (
  data: any[],
  titleData: { data: any[] },
  allowedBlanks: string[]
): any[] => {
  return data.map(d => {
    const obj: { [key: string]: any } = {
      status: 'upload'
    };
    obj.missing = [];
    titleData.data.forEach((t, i) => {
      obj[t] = d.data[i]?.trim();
      if (d.data[i] === '' && allowedBlanks.indexOf(t) === -1) {
        obj.missing.push(t);
      }
    });
    obj.status = obj.missing.length > 0 ? 'missingData' : obj.status;
    return obj;
  });
};

export const BulkCSVUpload: React.FC<{
  handleOnFileLoad: (data: ParseResult<any>[], file?: any) => void;
  setImports: (imports: any[]) => void;
}> = ({ handleOnFileLoad, setImports }) => {
  const buttonRef = React.useRef();

  const handleOnError = (data: unknown) => {
    console.error(data);
  };

  const handleOnRemoveFile = () => {
    setImports([]);
  };

  const handleRemoveFile = (e: unknown) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      // @ts-ignore
      buttonRef.current.removeFile(e);
    }
  };

  const handleOpenDialog = (e: unknown) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      // @ts-ignore
      buttonRef.current.open(e);
    }
  };

  return (
    <CSVReader
      onFileLoad={handleOnFileLoad}
      onError={handleOnError}
      // @ts-ignore
      ref={buttonRef}
      noClick
      noDrag
      onRemoveFile={handleOnRemoveFile}
    >
      {(
        { file }: { file: { name: string } } // eslint-disable-line react/no-unused-prop-types
      ) => (
        <aside>
          <Button type="button" onClick={handleOpenDialog} variant="outlined">
            Browse file
          </Button>
          <Button onClick={handleRemoveFile} type="button" variant="outlined">
            Remove
          </Button>
          <div style={{ margin: '1rem 0' }}>{file && file.name}</div>
        </aside>
      )}
    </CSVReader>
  );
};

export const StatusTd: React.FC<{
  i: {
    status: 'upload' | 'exists' | 'conflict' | 'missingData';
    existingMatches?: any[];
    missing?: string[];
  };
}> = ({ i }) => {
  return (
    <td style={{ textAlign: 'right' }}>
      {i.status === 'upload' && <>Will upload</>}
      {i.status === 'exists' && (
        <>
          <Check style={{ verticalAlign: 'middle' }} />
          Exists, Won&lsquo;t Upload
        </>
      )}
      {i.status === 'conflict' && (
        <Tooltip
          title={`Conflict with ${i.existingMatches
            ?.map((o: Organization) => `${o.id}: ${o.name}`)
            .join(', ')}`}
        >
          <div>
            <Warning style={{ verticalAlign: 'middle' }} /> Conflict with
            existing
          </div>
        </Tooltip>
      )}
      {i.status === 'missingData' && (
        <>
          <Warning style={{ verticalAlign: 'middle' }} /> Missing{' '}
          {i.missing?.join(', ')} Info
        </>
      )}
    </td>
  );
};

export default BulkCSVUpload;

import React from 'react';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';

import PaperWrapper from 'components/common/PaperWrapper';
import { Button } from 'uninfo-components';

import TextField from '@mui/material/TextField';

const TokenTime: React.FC = () => {
  const [token, setToken] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();

  const on = async () => {
    try {
      enqueueSnackbar('👻 Turning into ghost...', {
        variant: 'success',
        preventDuplicate: true
      });
      const parsed = JSON.parse(token);
      localStorage.setItem('userInfo', token);
      localStorage.setItem('token', parsed.token);
      window.location.reload();
    } catch (e) {
      enqueueSnackbar('☹️ Something went wrong', {
        variant: 'error',
        preventDuplicate: true
      });
    }
  };

  return (
    <PaperWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="time"
            variant="outlined"
            multiline
            fullWidth
            minRows={3}
            value={token}
            onChange={e => setToken(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={on}>Go!</Button>
        </Grid>
      </Grid>
    </PaperWrapper>
  );
};

export default TokenTime;

import React from 'react';

export const PlanDateSpan: React.FC<{
  obj?: {
    endDate: string | null;
    startDate: string | null;
  };
}> = ({ obj }) => {
  if (!obj) {
    return null;
  }
  return (
    <>
      {obj.startDate} → {obj.endDate}
    </>
  );
};

export default PlanDateSpan;

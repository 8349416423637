import React from 'react';
import { uniqWith } from 'lodash';
import { NavLink } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { fetchWorkspacesForUser } from 'services/WorkspaceService';

import {
  Dashboard,
  People,
  ContactMail,
  AccountBalance,
  Layers,
  Language,
  Dns,
  ListAlt,
  PieChart,
  GroupWork,
  ViewWeek,
  BorderClear,
  Functions,
  ImportExport,
  Description,
  Assessment,
  Comment,
  Article
} from '@mui/icons-material';
import styled from 'styled-components';
import { canUserViewPlanOfDomain } from 'utils/access';
import { IMS_DOMAIN_ID } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { hasPermission } from 'components/common/PermissionGate';

// FIXME: there's an issue with passing components to ListItems that TypeScript doesn't
// like. See https://github.com/mui-org/material-ui/issues/9106#issuecomment-409882433
// for more details. Hence all the ignores in this file

export const StyledListItem = styled(ListItemButton)`
  span {
    font-size: 0.875rem;
  }

  &.active {
    background-color: var(--color-blue-background);
    border-right: 3px solid var(--color-sdg-blue);
    color: var(--color-sdg-blue);

    svg {
      color: var(--color-sdg-blue);
    }
  }
`;

export const dashboardLink = (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore see top of file for description
  <StyledListItem component={NavLink} to="/dashboard" exact>
    <ListItemIcon>
      <Dashboard />
    </ListItemIcon>
    <ListItemText primary="Dashboard" />
  </StyledListItem>
);

export const MainListItems: React.FC<{
  user: User;
}> = ({ user }): React.ReactElement | null => {
  const { t } = useTranslation('common', { keyPrefix: 'sidebar' });
  const IMSUser = canUserViewPlanOfDomain(user, IMS_DOMAIN_ID);
  return (
    <>
      {IMSUser && (
        <>
          {!user?.userRoles?.find(
            (uR: any) =>
              uR.contextDomainId === IMS_DOMAIN_ID && uR.contextWorkspaceId
          ) && (
            <>
              <StyledListItem
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore see top of file for description
                component={NavLink}
                to="/dashboard/admin/contacts"
              >
                <ListItemIcon>
                  <Layers />
                </ListItemIcon>
                <ListItemText primary={t('contacts')} />
              </StyledListItem>
              <StyledListItem
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore see top of file for description
                component={NavLink}
                to="/dashboard/admin/documents"
              >
                <ListItemIcon>
                  <Article />
                </ListItemIcon>
                <ListItemText primary={t('documents')} />
              </StyledListItem>
            </>
          )}
        </>
      )}
    </>
  );
};

export const WorkspaceListItems: React.FC<{
  user: User;
}> = ({ user }): React.ReactElement | null => {
  const { t } = useTranslation('common', { keyPrefix: 'sidebar' });
  const [workspaces, setWorkspaces] = React.useState<Workspace[]>([]);

  React.useEffect(() => {
    const fetchWorkspaces = async (): Promise<void> => {
      const res = await fetchWorkspacesForUser(user);
      setWorkspaces(res);
    };
    fetchWorkspaces();
  }, [user]);

  const getDomainFromWorkspace = (workspaceId: number): number => {
    if (!user) {
      return 3;
    }
    return (
      user?.userRoles?.find((uR: any) => uR.contextWorkspaceId === workspaceId)
        ?.contextDomainId || 3
    );
  };
  const IMSUser = canUserViewPlanOfDomain(user, IMS_DOMAIN_ID);

  const displayContacts =
    IMSUser &&
    (uniqWith(
      user.userRoles,
      (r1, r2) =>
        r1.contextWorkspaceId === r2.contextWorkspaceId &&
        r1.contextRegionId === r2.contextRegionId &&
        r1.contextDomainId === r2.contextDomainId
    ).filter(
      (uR: any) => uR.contextDomainId === IMS_DOMAIN_ID && uR.contextWorkspaceId
    ).length > 2 ||
      user.userRoles?.filter(
        (uR: any) => uR.contextDomainId === IMS_DOMAIN_ID && uR.contextRegionId
      ));

  if (workspaces.length) {
    return (
      <>
        {IMSUser && (
          <>
            {displayContacts && (
              <StyledListItem
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore see top of file for description
                component={NavLink}
                to="/dashboard/admin/contacts"
              >
                <ListItemIcon>
                  <Layers />
                </ListItemIcon>
                <ListItemText primary={t('contacts')} />
              </StyledListItem>
            )}
            <StyledListItem
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore see top of file for description
              component={NavLink}
              to="/dashboard/admin/documents"
            >
              <ListItemIcon>
                <Article />
              </ListItemIcon>
              <ListItemText primary={t('documents')} />
            </StyledListItem>
          </>
        )}
        <ListSubheader inset>{t('myWorkspaces')}</ListSubheader>
        {workspaces?.map(workspace => {
          return (
            <React.Fragment key={workspace.id}>
              <ListSubheader>{workspace.name}</ListSubheader>
              {IMSUser && (
                <StyledListItem
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore see top of file for description
                  component={NavLink}
                  to={`/dashboard/workspace/${workspace.id}/contacts/rcoStaff`}
                >
                  <ListItemIcon>
                    <ContactMail />
                  </ListItemIcon>
                  <ListItemText primary={t('rcounct')} />
                </StyledListItem>
              )}
              <StyledListItem
                key={workspace.id}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore see top of file for description
                component={NavLink}
                to={`/dashboard/workspace/${
                  workspace.id
                }/domain/${getDomainFromWorkspace(workspace.id)}`}
              >
                <ListItemIcon>
                  <Layers />
                </ListItemIcon>
                <ListItemText primary={t('plans')} />
              </StyledListItem>
            </React.Fragment>
          );
        })}
      </>
    );
  }
  return null;
};

export const AdminListItems: React.FC<{ user: User }> = ({ user }) => {
  const { t } = useTranslation('common', { keyPrefix: 'sidebar' });

  const adminLinks = [
    {
      show: user.isAdmin,
      to: '/dashboard/admin/workspaces',
      text: t('workspaces'),
      icon: <Layers />
    },
    {
      to: '/dashboard/admin/contacts',
      text: t('contacts'),
      show: user.isAdmin,
      icon: <ContactMail />
    },
    {
      to: '/dashboard/admin/documents',
      text: t('documents'),
      show: user.isAdmin,
      icon: <Description />
    },

    {
      show: user.isAdmin,
      to: '/dashboard/admin/blueprints',
      text: t('blueprints'),
      icon: <BorderClear />
    },
    {
      show: hasPermission({
        user,
        scopes: ['report_exportCrossPlans', 'report_exportPlan']
      }),
      to: '/dashboard/admin/export',
      text: t('fullDownloads'),
      icon: <ImportExport />
    },
    {
      show: hasPermission({
        user,
        domainId: 3,
        scopes: ['report_exportCrossPlans', 'report_exportPlan']
      }),
      to: '/dashboard/admin/new-export',
      text: t('dataExporter'),
      icon: <ImportExport />
    },
    { to: '/user/comments', text: 'View All Comments', icon: <Comment /> },
    {
      show: user.isAdmin,
      to: '/dashboard/admin/forms',
      text: t('customForms'),
      icon: <Dns />
    },
    {
      show: user.isAdmin,
      to: '/dashboard/admin/links',
      text: t('links'),
      icon: <ViewWeek />
    },
    {
      show: user.isAdmin,
      to: '/dashboard/admin/datasources',
      text: t('dataSources'),
      icon: <ListAlt />
    },
    {
      show: user.isAdmin,
      to: '/dashboard/admin/dataitems',
      text: t('dataItems'),
      icon: <PieChart />
    },
    {
      show: hasPermission({ user, scopes: 'userRole_batchAssign' }),
      to: '/dashboard/admin/users',
      text: t('users'),
      icon: <People />
    },
    {
      show: hasPermission({
        user,
        scopes: ['organization_update']
      }),
      to: '/dashboard/admin/organizations',
      text: t('organizations'),
      icon: <AccountBalance />
    },
    {
      show: hasPermission({
        user,
        scopes: ['location_update']
      }),
      to: '/dashboard/admin/countries',
      text: t('countries'),
      icon: <Language />
    },
    {
      show: user.isAdmin,
      to: '/dashboard/admin/units',
      text: t('units'),
      icon: <Functions />
    },
    {
      show: user.isAdmin,
      to: '/dashboard/admin/metrics',
      text: t('metrics'),
      icon: <Assessment />
    },
    {
      show: user.isAdmin,
      to: '/dashboard/admin/roles',
      text: t('roles'),
      icon: <Assessment />
    }
  ];

  const filteredLinks = adminLinks.filter(link => link.show);

  return (
    <>
      {filteredLinks.length > 0 && <ListSubheader>Admin</ListSubheader>}
      {filteredLinks.map(link => (
        <React.Fragment key={link.to}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore see top of file for description */}
          <StyledListItem component={NavLink} to={link.to}>
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={link.text} />
          </StyledListItem>
        </React.Fragment>
      ))}
    </>
  );
};

import React, { MutableRefObject } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface NumberFormatCustomProps extends NumberFormatProps<any> {
  inputRef: MutableRefObject<any>;
  onChange: (event: {
    target: { name: string; value: number | undefined };
  }) => void;
  name: string;
  decimal?: number;
}

export const NumberFormatCustom: React.FC<NumberFormatCustomProps> = React.forwardRef(
  (props, ref) => {
    const { inputRef, onChange, name, ...other } = props;

    return (
      <NumberFormat
        {...other}
        decimalScale={other.decimalScale || 0}
        getInputRef={inputRef ?? ref}
        onValueChange={values => {
          onChange({
            target: {
              name,
              value: values.floatValue
            }
          });
        }}
        style={{
          textAlign: 'right'
        }}
        thousandSeparator
        isNumericString
      />
    );
  }
);
NumberFormatCustom.displayName = 'NumberFormatCustom';

export const MonetaryFormatCustom: React.FC<NumberFormatCustomProps> = React.forwardRef(
  (props, ref) => {
    return (
      <NumberFormatCustom
        {...props}
        thousandSeparator
        isNumericString
        ref={ref}
        prefix="$"
      />
    );
  }
);
MonetaryFormatCustom.displayName = 'MonetaryFormatCustom';

export const FloatFormatCustom: React.FC<NumberFormatCustomProps> = props => {
  return (
    <NumberFormatCustom
      {...props}
      decimalScale={2}
      thousandSeparator
      isNumericString
    />
  );
};
export default {
  FloatFormatCustom,
  MonetaryFormatCustom,
  NumberFormatCustom
};

/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useState, useGlobal } from 'reactn';
import { makeStyles } from '@mui/styles';
import { Typography, Theme, Grid, Link } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { BaseDialog } from 'uninfo-components';
import * as FileSaver from 'file-saver';

import { fetchById } from 'services/AdminService';
import { isExisting } from 'utils/typeguards';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import { downloadLocationCSV } from 'services/LocationService';
import format from 'date-fns/format';
import PermissionGate from 'components/common/PermissionGate';
import BulkLocationForm from './BulkLocationForm';
import LocationsList from './LocationsList';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(2)
  }
}));

export const CountryRedirect: React.FC = () => {
  const { id } = useParams<{ id?: string }>();

  return <Navigate to={`/dashboard/admin/country/${id}/locations/`} />;
};

const CountryBuilder: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [user] = useGlobal('user');
  const [openBulk, setOpenBulk] = useState(false);

  const [country, setCountry] = useState<Country | undefined>(undefined);

  const fetchCb = useCallback(async () => {
    try {
      setLoading(true);
      if (id) {
        const res = await fetchById<Country>('country', +id);
        setCountry(res.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [id]);

  React.useEffect(() => {
    fetchCb();
  }, [fetchCb]);

  const downloadLocationModelFile = async () => {
    try {
      if (id) {
        const options = { countryId: +id };
        const csv = await downloadLocationCSV(options);
        const blob = new Blob([csv], {
          type: 'text/csv;charset=utf-8;'
        });
        const currentDate = format(new Date(), 'yyyy-MM-dd');
        FileSaver.saveAs(
          blob,
          `model_file_${country?.name}_${currentDate}.csv`
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!loading && isExisting(country) && (
        <>
          <Typography variant="h4" className={classes.header}>
            {country.name}
          </Typography>
          <Grid container>
            <PermissionGate scopes="location_batchCreate">
              <Grid item xs={12} style={{ marginBottom: '2rem' }}>
                Want to create locations in bulk?{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link onClick={() => setOpenBulk(true)} component="button">
                  click here
                </Link>
              </Grid>
            </PermissionGate>
            <Grid item xs={4}>
              <strong>ISO3</strong>: {country.iso3}
            </Grid>
            <Grid item xs={4}>
              <strong>ISO2</strong>: {country.iso2}
            </Grid>
            <Grid item xs={4}>
              <strong>Note</strong>: {country.description}
            </Grid>
            <Grid item xs={4}>
              <strong>Lat</strong>: {country.latitude}
            </Grid>
            <Grid item xs={4}>
              <strong>Lon</strong>: {country.longitude}
            </Grid>
          </Grid>
          <LocationsList country={country} refreshCountry={fetchCb} />
          <BaseDialog
            maxWidth="lg"
            open={openBulk}
            onClose={() => setOpenBulk(false)}
            title="Bulk Create Locations"
          >
            <Alert severity="warning" sx={{ marginBottom: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2">
                  Please download and use the <b>CSV model file</b> to update
                  any location:
                </Typography>
                <IconButton aria-label="FileDownload">
                  <FileDownloadIcon
                    onClick={() => downloadLocationModelFile()}
                  />
                </IconButton>
              </div>
            </Alert>

            <BulkLocationForm country={country} />
          </BaseDialog>
        </>
      )}
    </>
  );
};

export default CountryBuilder;

/* eslint-disable import/prefer-default-export */
import settings from 'config';
import Api from '../utils/Api';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

const ORGANIZATION_API_BASE_URL = `${BASE_URL}/organization/`;
const ORGANIZATION_TYPE_API_BASE_URL = `${BASE_URL}/organizationType/`;

export const addOrganization = (
  organization: OrganizationNew
): Promise<APISingleResult<Organization>> => {
  const formattedItem = organization;
  // TODO: clone organization before deleting countries
  /* if (formattedItem.countries) {
    delete formattedItem.countries;
  } */
  return Api.post(
    `${ORGANIZATION_API_BASE_URL}`,
    formattedItem,
    getAuthHeader()
  );
};

export const batchCreateOrganizations = (
  organizations: OrganizationNew[]
): Promise<Organization[]> => {
  const formattedItem = { organizations };

  return Api.post(
    `${ORGANIZATION_API_BASE_URL}batchCreate`,
    formattedItem,
    getAuthHeader()
  );
};

export const editOrganization = (
  organization: Organization
): Promise<Organization> => {
  const formattedItem = organization;
  // TODO: clone organization before deleting countries
  /* if (formattedItem.countries) {
    delete formattedItem.countries;
  } */
  return Api.put(
    `${ORGANIZATION_API_BASE_URL}${organization.id}`,
    formattedItem,
    getAuthHeader()
  );
};

interface OrganizationFilters extends APIQueryOptions {
  unsdg?: boolean;
  ttcp?: boolean;
  typeIds?: number[];
  planIds?: number[];
  entityPrototypeIds?: number[];
  exclude?: string[];
  statuses?: string[];
}

export const fetchUNSdgOrganizations = (
  options: OrganizationFilters
): Promise<Organization[]> => {
  const data = { ...options, statuses: ['active'] };
  data.unsdg = true;
  data.sort = options.sort || [['name', 'ASC']];
  return Api.post(
    `${ORGANIZATION_API_BASE_URL}search`,
    data,
    getAuthHeader()
  ).then(res => res.data.results);
};

export const fetchTtcpOrganizations = (
  options: OrganizationFilters
): Promise<Organization[]> => {
  const data = { ...options, statuses: ['active'] };
  data.ttcp = true;

  data.exclude = [
    'contributingPartnerCells',
    'implementingPartnerCells',
    'agencyCells',
    'surveyPreparationEJVersions',
    'surveyPreparationELVersions',
    'surveySignatories',
    'occupants'
  ];
  data.sort = options.sort || [['name', 'ASC']];
  return Api.post(
    `${ORGANIZATION_API_BASE_URL}search`,
    data,
    getAuthHeader()
  ).then(res => res.data.results);
};

export const fetchOrganizations = (
  options: OrganizationFilters
): Promise<Organization[]> => {
  let data: OrganizationFilters = { statuses: ['active'] };
  if (options) {
    data = {
      ...options,
      limit: options.limit ?? 500,
      statuses: data.statuses
    };
    data.exclude = [
      'contributingPartnerCells',
      'implementingPartnerCells',
      'agencyCells',
      'surveyPreparationEJVersions',
      'surveyPreparationELVersions',
      'surveySignatories',
      'occupants'
    ];
    if (options.statuses) {
      data.statuses = options.statuses;
    }
    if (options.sort) {
      data.sort = options.sort;
    } else {
      data.sort = [['name', 'ASC']];
    }
  } else {
    data.limit = 2000;
    data.sort = [['name', 'ASC']];
  }
  return Api.post(
    `${ORGANIZATION_API_BASE_URL}search`,
    data,
    getAuthHeader()
  ).then(res => res.data.results);
};

export const fetchOrganizationById = (
  organizationId: number
): Promise<APISingleResult<Organization>> => {
  return Api.get(
    `${ORGANIZATION_API_BASE_URL}${organizationId}`,
    getAuthHeader()
  );
};

export const fetchOrganizationTypes = (): Promise<OrganizationType[]> => {
  return Api.post(
    `${ORGANIZATION_TYPE_API_BASE_URL}search`,
    {},
    getAuthHeader()
  ).then(res => res.data.results);
};

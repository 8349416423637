import React from 'reactn';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { TableInstance } from 'react-table';

import { unarchiveById } from 'services/AdminService';
import { useTableContext } from './TableProvider';

const RestoreFromTrashButton: React.FC<{
  tableInstance: TableInstance;
  endpoint: string;
}> = ({ tableInstance, endpoint }) => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useTableContext();

  const restoreRowHandler = async () => {
    try {
      await confirm({
        description: `Are you sure you want to unarchive these ${endpoint}s?`
      });

      try {
        if (state.tableData) {
          const ids = Object.keys(tableInstance.state.selectedRowIds)
            .map(x => state.tableData?.[parseInt(x, 10)].id)
            .filter(id => !!id);

          const promises: any[] = [];
          ids.forEach((id: number) => {
            promises.push(unarchiveById(endpoint, id));
          });
          await Promise.all(promises);
          enqueueSnackbar(`${endpoint} restored`, {
            preventDuplicate: true
          });
        }
      } catch (e) {
        console.error(e);
      }

      dispatch({ type: 'toggleRefresh' });
      return null;
    } catch (e) {
      return null;
    }
  };

  return (
    <Tooltip title="Un-archive">
      <IconButton
        aria-label="unarchive"
        onClick={restoreRowHandler}
        size="large"
      >
        <RestoreFromTrashIcon />
      </IconButton>
    </Tooltip>
  );
};

export default RestoreFromTrashButton;

export const metricColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableFilters: true,
    sortType: 'basic'
  }
];

export default {
  metricColumns
};

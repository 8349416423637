import { cloneDeep, isNil, isNumber, isEmpty, values } from 'lodash';
import {
  PLAN,
  IMS_DOMAIN_ID,
  OCCUPANT_ENTITY_ID,
  PREMISE_ENTITY_ID
} from 'utils/constants';
import { hasRole } from 'components/common/PermissionGate';
import {
  autocomplete,
  AutocompleteOptions,
  userAutocomplete
} from 'services/AutocompleteService';
import { fetchOrganizations } from 'services/OrganizationService';
import { fetchLocations } from 'services/LocationService';
import { manyToManyUpdate } from 'utils/relations';
import { editSubmission, addSubmission } from 'services/SubmissionService';
import { deleteSupport, addSupport } from 'services/SupportService';

import { buildCells } from 'utils/cells';
import { addEntity, fetchPlanEntityById } from 'services/PlanService';
import { entityIsPlan, entityIsPlanEntity } from 'utils/typeguards';

export interface EditItem {
  id?: number;
  name: string;
  code: string;
  value?: {
    periodicity: string;
    unit: any;
    locked?: boolean;
    calculated: 'incremental' | 'cumulative';
  };
  description: string;
  plan?: Plan;
  parent?: any;
  startDate: string | null;
  endDate: string | null;
  aggregation?: any;
  parentId?: number;
  cells?: Cell[];
  disaggregation?: any[];
  asDraft?: boolean;
  planEntityVersion?: {
    name: string;
    code: string;
    description: string;
    startDate: string | null;
    endDate: string | null;
    value?: {
      periodicity?: string;
      locked?: boolean;
      unit?: any;
    };
  };
  planVersion?: {
    name: string;
    code: string;
    description: string;
    startDate: string | null;
    endDate: string | null;
    value?: {
      periodicity?: string;
      unit?: any;
    };
  };
  planSubmissions?: Submission[];
  supporting?: Support[];
  metrics?: any[];
  planEntityOrganizations?: PlanEntityOrganization[];
  organizations?: PlanEntityOrganization[];
  planEntitySdgs?: any[];
  planEntityDataitems?: any[];
  dataitems?: any[];
  planEntityTeams?: any[];
  planEntityLocations?: any[];
  locations?: LocationAPI[];
  planEntityUsers?: any[];
  focalUsers?: PlanUser[];
  planEntityIndicators?: any[];
  indicators?: any[];
}

/**
 * looks at the type of the link, and determines what
 * options it should get. Then it fetches those options
 * and returns a promise with them.
 *
 * @param {*} link
 * @param {*} inputValue
 * @param {*} organizationFilters
 * @param {*} workspaceId
 */
export const fetchAppropriateOptions = async (
  link: Link,
  inputValue: string,
  filters: {
    id: number;
  },
  workspaceId: number,
  planId?: number
): Promise<any[]> => {
  let endpoint: string = link.entityName;
  if (link.entityName === 'dataitem') {
    endpoint = `${link.entityName}/${link.properties.id}`;
  }
  // TODO: add throttle
  const autocompleteOptions: AutocompleteOptions = {
    ...filters,
    q: inputValue,
    limit: 100
  };
  if (filters?.id) {
    autocompleteOptions.typeIds = [filters.id];
  }

  let items: any = [];
  let formattedOptions = [];
  if (link.entityName === 'location') {
    items = await fetchLocations(autocompleteOptions);
  } else if (link.entityName === 'user' && planId) {
    const users = await userAutocomplete<PlanUser>(planId, {
      ...autocompleteOptions
    });

    formattedOptions = users.map((item: any) => {
      return {
        name: `${item.firstname} ${item.lastname}`,
        id: item.id,
        position: item.position,
        status: item.status,
        organizationId: item.organizationId,
        linkedTableName: link.linkedTableName
      };
    });
    return formattedOptions.sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    );
  } else if (link.entityName === 'organization') {
    const response = await fetchOrganizations(autocompleteOptions);
    items = response;
    formattedOptions = items.map((item: any) => {
      return {
        name: item.name,
        organizationId: item.id,
        id: item.id,
        type: link.linkedKey.replace('Id', ''),
        linkedTableName: link.linkedTableName
      };
    });
    return formattedOptions;
  } else {
    items = await autocomplete(endpoint, autocompleteOptions);
  }

  // FIXME: How do we type all the possible options here while still doing the
  // mapping in this function?
  formattedOptions = items.map((item: any) => {
    return {
      linkId: link.id,
      name: item.name || item.dataitemVersion?.name,
      id: item.id,
      goalId: item.goalId || null,
      indicatorId: item.indicatorId || null,
      abbreviation: item.abbreviation ?? item.ref ?? null,
      targetId: item.targetId || null
    };
  });
  return formattedOptions;
};

export const makeSubmissions = async (
  formTags: FormTag[],
  inputRefs: any, // references to formIO forms
  submissions: any[], // submissions as they're currently stored
  asDraft: boolean
): Promise<{
  formOk: boolean;
  formSubmissions: {
    currentSubmission: Submission;
    formTagId: number | string;
  }[];
}> => {
  let formOk = true;

  const formSubmissions: { currentSubmission: any; formTagId: number }[] = [];
  const refs = inputRefs.current;
  // submits the input refs
  const promises = [];
  for (let index = 0; index < refs.length; index += 1) {
    const ref = refs[index];
    if (ref) {
      if (asDraft === true) {
        promises.push(
          // eslint-disable-next-line no-underscore-dangle
          Promise.resolve({ submission: cloneDeep(ref?.formio._submission) })
        );
      } else {
        promises.push(
          ref.formio.submitForm().catch((error: any) => {
            console.error('error with form submission', error);
            return null;
          })
        );
      }
    }
  }

  await Promise.all(promises).then(results => {
    let index = 0;
    // FIXME
    // eslint-disable-next-line no-restricted-syntax
    for (const result of results) {
      if (!result) {
        formOk = false;
      }

      if (result?.submission) {
        const localSubmission =
          cloneDeep(
            submissions.find((s: any) => s.id === result.submission.name)
          ) || {};
        const ref = refs[index];

        const emptySubmission = values(result.submission.data).every(isEmpty);
        if (!emptySubmission) {
          localSubmission.currentSubmission = result.submission;
          localSubmission.formTagId =
            formTags.find(
              // eslint-disable-next-line no-underscore-dangle
              formTag => formTag.formId === ref?.formio?._form?.formId
            )?.id ?? 0;
          formSubmissions.push(localSubmission);
        }
      } else {
        formOk = false;
      }
      index += 1;
    }
  });
  return { formOk, formSubmissions };
};

export const updateSubmissions = async (
  localEntity: EditItem,
  submissions: Submission[]
): Promise<any[]> => {
  const promises: Promise<any>[] = [];
  submissions.forEach(submission => {
    if (submission.id) {
      promises.push(editSubmission(submission, localEntity));
    } else {
      promises.push(addSubmission(submission, localEntity));
    }
  });
  return Promise.all(promises);
};

export const updateSupports = async (
  localEntity: EditItem,
  supports: Support[]
): Promise<any[]> => {
  const promises: Promise<any>[] = [];

  if (!localEntity.planVersion) {
    const resultObj = manyToManyUpdate({
      modifiedList: supports,
      originalList: localEntity.supporting
    });
    resultObj.toCreate.forEach(item => {
      promises.push(addSupport(item, localEntity));
    });
    resultObj.toDelete.forEach(item => {
      promises.push(deleteSupport(item.support.id));
    });
  }

  return Promise.all(promises);
};

export const submitAllPartsOfEntity = async ({
  entity,
  eP,
  currentParent,
  parentId,
  submissions,
  customCategories,
  disaggregationDimension,
  supports
}: {
  entity: EditItem;
  eP: EntityPrototype;
  currentParent?: PlanEntity;
  parentId?: number;
  submissions: Submission[];
  customCategories: DesignedCategory[];
  links: Link[];
  disaggregationDimension: any[];
  supports: Support[];
}): Promise<{ newEntity: any; localEntity: any }> => {
  const localEntity = entity;
  localEntity.supporting = localEntity.supporting?.filter(s => !!s);

  if (eP.potentialParents && eP.potentialParents[0].coreentityTypeId !== PLAN) {
    if (currentParent && currentParent.id) {
      localEntity.parentId = currentParent.id;
    } else {
      localEntity.parentId = parentId;
    }
  }

  // cells will be saved as part of the saving of localEntity
  localEntity.cells = buildCells(customCategories);
  localEntity.disaggregation = disaggregationDimension;
  const { asDraft } = localEntity;
  const res = await addEntity(localEntity, eP.id);

  const newLocalEntity = {
    ...res.data,
    asDraft,
    planSubmissions: []
  };

  const promises: Promise<any>[] = [];
  submissions.forEach(submission => {
    promises.push(addSubmission(submission, newLocalEntity));
  });
  const submissionsResults = await Promise.all(promises);

  submissionsResults.forEach(resSubmission => {
    newLocalEntity.planSubmissions.push(resSubmission.data);
  });

  if (!newLocalEntity.supporting) {
    newLocalEntity.supporting = [];
  }

  if (supports) {
    const supportPromises = supports.map(async support => {
      if (support) {
        const resSupport = await addSupport(
          { supportedId: support.id },
          newLocalEntity
        );
        newLocalEntity.supporting.push({ ...support, id: resSupport.data.id });
      }
    });

    await Promise.all(supportPromises).catch(e => {
      console.error(e);
    });
  }

  const newEntity = await fetchPlanEntityById(newLocalEntity.id);

  return { newEntity, localEntity: newLocalEntity };
};

export const determineEntityAddingOrDeletion = (
  eP: EntityPrototype,
  plan: Plan,
  parentChildren: PlanEntity[],
  noParentSelected?: boolean,
  user?: User
): {
  min?: number;
  max?: number;
  allowCreationOfNewEntity: boolean;
  allowDeletionOfEntity: boolean;
} => {
  const max =
    eP.properties?.cardinality?.max ??
    (plan.planType?.domainId === IMS_DOMAIN_ID ? 1 : undefined); // This is just a catch for if the cardinality isn't set on an IMS plan. User error but :shrug:
  const min =
    eP.properties?.cardinality?.min ??
    (plan.planType?.domainId === IMS_DOMAIN_ID ? 1 : undefined);

  const allowDeletionOfEntity = parentChildren.length > min;
  if (noParentSelected) {
    return { min, max, allowCreationOfNewEntity: false, allowDeletionOfEntity };
  }

  let allowCreationOfNewEntity = false;
  if (eP.coreentityTypeId === OCCUPANT_ENTITY_ID) {
    const userIsCPCOrCPWG = hasRole({
      user,
      workspace: plan?.workspace,
      roles: ['CPDCO', 'CPC', 'AFP']
    });

    allowCreationOfNewEntity =
      (user?.isAdmin || !!userIsCPCOrCPWG) &&
      (isNil(max) || (isNumber(max) && parentChildren.length < max));
  } else {
    allowCreationOfNewEntity =
      eP.coreentityTypeId !== PLAN &&
      (isNil(max) || (isNumber(max) && parentChildren.length < max));
  }
  return { min, max, allowCreationOfNewEntity, allowDeletionOfEntity };
};

export const determineCPRUserPermission = (
  eP: EntityPrototype,
  editItem: PlanEntity | Plan | undefined,
  plan: Plan,
  user: User | undefined,
  displayedChildren?: PlanEntity[]
): {
  cPRAllowEdit: boolean;
  cPRAllowDeletion: boolean;
} => {
  let cPRAllowEdit = false;
  let cPRAllowDeletion = false;
  if (entityIsPlanEntity(editItem)) {
    if (eP.coreentityTypeId === OCCUPANT_ENTITY_ID) {
      if (
        hasRole({
          user,
          workspace: plan?.workspace,
          roles: ['CPDCO', 'CPC', 'AFP']
        })
      ) {
        cPRAllowEdit = true;
        cPRAllowDeletion = true;
        if (
          hasRole({
            user,
            workspace: plan?.workspace,
            roles: ['AFP']
          }) &&
          !hasRole({
            user,
            workspace: plan?.workspace,
            roles: ['CPDCO', 'CPC']
          }) &&
          editItem?.id
        ) {
          cPRAllowDeletion = true;
          if (
            user?.userRoles?.find(
              ur =>
                ur.contextWorkspaceId === plan?.workspace?.id &&
                ur.role.short === 'AFP' &&
                !ur.contextOrganizationId
            ) &&
            !hasRole({
              user,
              workspace: plan?.workspace,
              roles: ['CPDCO', 'CPC']
            }) &&
            editItem?.id &&
            editItem?.organizations?.length &&
            !displayedChildren?.find(
              (child: any) =>
                child?.organizations?.[0]?.id === user?.organization?.id &&
                child?.planEntityVersion?.value?.isCommonServiceManager
                  ?.name === 'Yes'
            ) &&
            !user?.isAdmin
          ) {
            cPRAllowDeletion = false;
            cPRAllowEdit = false;
          }
          if (
            editItem?.organizations?.length &&
            user?.userRoles?.find(
              ur =>
                ur.contextWorkspaceId === plan?.workspace?.id &&
                ur.role.short === 'AFP' &&
                ur.contextOrganizationId
            ) &&
            !hasRole({
              user,
              workspace: plan?.workspace,
              roles: ['CPDCO', 'CPC']
            }) &&
            user?.organization.id !== editItem?.organizations[0].id
          ) {
            cPRAllowEdit = false;
            cPRAllowDeletion = false;
          }
        }
      }
    }
    if (eP.coreentityTypeId === PREMISE_ENTITY_ID) {
      if (
        hasRole({
          user,
          workspace: plan?.workspace,
          roles: ['CPDCO', 'CPC', 'AFP']
        }) &&
        (!editItem?.id || editItem?.planEntityVersion?.value?.phaseId !== 1)
      ) {
        cPRAllowEdit = true;
      }
      if (
        hasRole({
          user,
          workspace: plan?.workspace,
          roles: ['PVFP', 'CPDCO']
        }) &&
        (!editItem?.id || editItem?.planEntityVersion?.value?.phaseId === 1)
      ) {
        cPRAllowEdit = true;
      }
    }
  } else if (
    entityIsPlan(editItem) &&
    hasRole({
      user,
      workspace: plan?.workspace,
      roles: ['CPC', 'CPDCO']
    })
  ) {
    cPRAllowEdit = true;
  } else {
    if (eP.coreentityTypeId === OCCUPANT_ENTITY_ID) {
      if (
        hasRole({
          user,
          workspace: plan?.workspace,
          roles: ['AFP']
        })
      ) {
        cPRAllowEdit = true;
      }
      if (
        hasRole({
          user,
          workspace: plan?.workspace,
          roles: ['CPDCO', 'CPC']
        })
      ) {
        cPRAllowEdit = true;
      }
    }
    if (eP.coreentityTypeId === PREMISE_ENTITY_ID) {
      if (
        hasRole({
          user,
          workspace: plan?.workspace,
          roles: ['CPDCO', 'CPC', 'AFP']
        }) &&
        !editItem?.id
      ) {
        cPRAllowEdit = true;
      }
      if (
        hasRole({
          user,
          workspace: plan?.workspace,
          roles: ['PVFP', 'CPDCO']
        }) &&
        !editItem?.id
      ) {
        cPRAllowEdit = true;
      }
    }
  }
  return { cPRAllowEdit, cPRAllowDeletion };
};

export const getNextCode = (currentChildren: PlanEntity[]): number => {
  const max = currentChildren.reduce((currentMax, child) => {
    if (+(child.planEntityVersion?.code ?? 0) > currentMax) {
      return +(child.planEntityVersion?.code ?? 0);
    }
    return currentMax;
  }, 0);
  return max + 1;
};

export const determineIfPlanPublished = (plan: Plan): boolean => {
  const planTag = plan?.planTags.find(pt => pt.public);
  return planTag?.public ?? false;
};

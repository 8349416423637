import React from 'reactn';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query';
import { getAuthHeader } from 'services/AuthService';
import Api from 'utils/Api';
import { BASE_URL } from 'utils/constants';
import {
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Paper,
  TextField,
  Tooltip
} from '@mui/material';
import { DataObject } from '@mui/icons-material';
import { Table } from 'uninfo-components';
import { debounce } from 'lodash';

interface LogData {
  _id: string;
  level: string;
  timestamp: string;
  message: string;
  meta?: {
    meta?: {
      req?: unknown;
      res?: unknown;
      responseTime?: number;
      userId?: number;
    };
  };
}

const LogRow: React.FC<{ d: LogData }> = ({ d }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <tr style={{ backgroundColor: d.level === 'error' ? 'red' : '#f6f6f6' }}>
      <td>{d.level}</td>
      <td>{d.timestamp}</td>
      <td>{d.message}</td>
      <td style={{ textAlign: 'right' }}>{d.meta?.meta?.responseTime}</td>
      <td style={{ textAlign: 'right' }}>{d.meta?.meta?.userId}</td>

      <td style={{ textAlign: 'right' }}>
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Tooltip
            PopperProps={{
              disablePortal: true
            }}
            onClose={() => setOpen(false)}
            open={open}
            disableFocusListener
            disableTouchListener
            title={
              <pre style={{ textAlign: 'left' }}>
                {JSON.stringify(d.meta, undefined, 2)}
              </pre>
            }
            disableHoverListener
          >
            <IconButton onClick={() => setOpen(true)}>
              <DataObject />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      </td>
    </tr>
  );
};

interface AuditReportQuery {
  userId?: number;
  message?: string;
}

const AdminLogList: React.FC = () => {
  const [userId, setUserId] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [query, setQuery] = React.useState<AuditReportQuery>({});

  const debounceSetFilterCallback = React.useCallback(
    debounce(
      ({ userId: userIdQuery, message: messageQuery }) =>
        setQuery(localQuery => ({
          ...localQuery,
          ...(userIdQuery ? { userId: +userIdQuery } : { userId: undefined }),
          ...(messageQuery ? { message: messageQuery } : { message: undefined })
        })),
      500
    ),
    []
  );

  React.useEffect(() => {
    debounceSetFilterCallback({ userId, message });
  }, [debounceSetFilterCallback, userId, message]);

  const { isLoading, isError, error, data, isFetching } = useQuery(
    ['auditReport', query],
    vars => {
      return Api.post(
        `${BASE_URL}/auditReport/search`,
        vars.queryKey[1],
        getAuthHeader()
      ).then(res => {
        return res.data;
      });
    }
  );

  return (
    <Grid item xs={12} style={{ overflowX: 'scroll' }}>
      <Paper style={{ padding: '1rem' }}>
        <div
          style={{
            marginBottom: '1rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <TextField
            size="small"
            title="userId"
            placeholder="userId"
            value={userId}
            onChange={e => setUserId(e.target.value)}
          />
          <TextField
            size="small"
            title="message"
            placeholder="message"
            value={message}
            style={{ marginLeft: '1rem ' }}
            onChange={e => setMessage(e.target.value)}
          />
          <div style={{ flexGrow: '1' }} />
          {(isFetching || isLoading) && (
            <CircularProgress style={{ marginRight: '.5rem' }} size={20} />
          )}
        </div>

        {isError ? JSON.stringify(error) : ''}

        <Table style={{ width: '100%' }}>
          <tr>
            <th style={{ textAlign: 'left' }}>level</th>
            <th style={{ textAlign: 'left' }}>timestamp</th>
            <th style={{ textAlign: 'left' }}>message</th>
            <th style={{ textAlign: 'right' }}>response time (ms)</th>
            <th style={{ textAlign: 'right' }}>userId</th>
            <th style={{ textAlign: 'right' }}>data</th>
          </tr>
          {data?.map((d: LogData) => (
            // eslint-disable-next-line no-underscore-dangle
            <LogRow key={d._id} d={d} />
          ))}
        </Table>
      </Paper>
    </Grid>
  );
};

export default AdminLogList;

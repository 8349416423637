import React from 'react';

import { Chip } from '@mui/material';

interface CountriesProps {
  values: Country[];
}

const Countries: React.FC<CountriesProps> = ({ values }) => {
  if (values && values.length) {
    return (
      <>
        {values.map(country => {
          return <Chip key={country.id} label={country.iso3} />;
        })}
      </>
    );
  }
  return null;
};

export const workspaceColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Workspace Type',
    id: 'workspaceTypeId',
    accessor: 'workspaceType.name',
    sortType: 'basic',
    disableFilters: true
  },
  {
    Header: 'Countries',
    id: 'countryId',
    accessor: 'countries',
    // eslint-disable-next-line react/display-name
    Cell: ({ cell: { value } }: ReactTableCell<any, Workspace>) => (
      <>
        <Countries values={value} />
      </>
    ),
    disableSortBy: true
  }
];

export default {
  workspaceColumns
};

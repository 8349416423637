import Asynchronous from 'components/table/AsynchronousFilter';

export const dataItemColumns = [
  {
    Header: 'Id',
    accessor: 'id',
    aggregate: 'count',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Name',
    accessor: 'dataitemVersion.name',
    sortType: 'basic',
    disableFilters: true
  },
  {
    Header: 'Datasource',
    id: 'datasourceIds',
    accessor: 'datasource.datasourceVersion.name',
    endpoint: 'datasource',
    filterAccessor: 'datasourceVersion.name',
    filterLabel: 'Data Source',
    Filter: (props: any) => Asynchronous<DataSource>(props)
  },
  {
    Header: 'Datasource ID',
    id: 'datasourceId',
    accessor: 'datasource.id',
    endpoint: 'datasource',
    disableFilters: true
  }
];

export default {
  dataItemColumns
};

import React, { useGlobal, setGlobal, useState } from 'reactn';

import { BaseDialog, Button } from 'uninfo-components';
import { EmailInput, PasswordInput } from 'pages/Authentication/common';
import { getUserInfo, login, logOut } from 'services/AuthService';
import { fetchProfile } from 'services/UserService';
import { clearSessionExpireTimer } from 'utils/Api';
import DialogActions from '@mui/material/DialogActions';
import { isPast, subHours, isValid } from 'date-fns/esm';
import { isEmpty } from 'lodash';

const SessionExpirePopUp: React.FC = () => {
  const [user] = useGlobal('user');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [sessionExpiredBecauseOf] = useGlobal('sessionExpiredBecauseOf');
  const [userExpires, setUserExpires] = useState(false);

  React.useEffect(() => {
    if (sessionExpiredBecauseOf) {
      setUserExpires(true);
    }
  }, [sessionExpiredBecauseOf]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const credentials = {
      email,
      password
    };
    try {
      const res = await login(credentials);
      localStorage.setItem('userInfo', JSON.stringify(res.data));
      localStorage.setItem('token', res.data.token);

      // If first most recent request is a PUT, we don't cause
      // a reload
      if (sessionExpiredBecauseOf !== 'put') {
        const userProfile = await fetchProfile();
        setGlobal({ user: userProfile.data });
      }
      setUserExpires(false);
      setGlobal({ sessionExpiredBecauseOf: false });
      clearSessionExpireTimer();
    } catch (e) {
      console.error('error: ', e);
    }
  }

  React.useEffect(() => {
    let interval: any = null;
    interval = setInterval(() => {
      if (!user || isEmpty(user)) {
        setUserExpires(false);
        return;
      }
      const expirationDate = new Date(getUserInfo().expirationDateTime);
      const isPastWarning = isPast(subHours(expirationDate, 2));
      const isPastExpiration = isPast(expirationDate);
      if (isPastWarning && isValid(expirationDate)) {
        setUserExpires(true);
      } else {
        if (isPastExpiration) {
          logOut();
        }
        setUserExpires(false);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [user]);

  if (window.location.href.includes('?next=')) {
    return null;
  }
  if (!window.location.href.includes('dashboard')) {
    return null;
  }

  if (userExpires || sessionExpiredBecauseOf) {
    return (
      <BaseDialog
        fullWidth
        open={userExpires || !!sessionExpiredBecauseOf}
        title="Session about to expire!"
      >
        {sessionExpiredBecauseOf && (
          <>
            Session has expired, you need to log in again to continue.
            You&quot;ll be redirected within 2 minutes if you don&quot;t sign in
            again
          </>
        )}
        {userExpires && (
          <>
            Your session will expire soon. Please enter your username / email
            and password to prevent getting logged out.
          </>
        )}
        <form onSubmit={handleSubmit} noValidate>
          <EmailInput email={email} onChange={e => setEmail(e.target.value)} />
          <PasswordInput
            onChange={e => setPassword(e.target.value)}
            password={password}
          />
          <DialogActions>
            <Button type="submit">Sign in</Button>
          </DialogActions>
        </form>
      </BaseDialog>
    );
  }
  return null;
};

export default SessionExpirePopUp;

import React, { Suspense, lazy, useGlobal } from 'reactn';
import { Navigate, Routes, Route } from 'react-router-dom';

import {
  AdminBlueprint,
  AdminCustomForm,
  AdminDataItem,
  AdminDataSource,
  AdminLink,
  AdminCountry,
  CountryBuilder,
  AdminOrganization,
  AdminOrganizationBuilder,
  AdminUser,
  AdminContact,
  AdminContactBuilder,
  AdminDocument,
  AdminDocumentBuilder,
  AdminWorkspace,
  AdminWorkspaceBuilder,
  AdminUnits,
  AdminMetrics,
  TokenTime,
  RoleTable,
  RoleEditor,
  AdminLogList
} from 'pages/admin/';

import { CountryRedirect } from 'pages/admin/country/CountryBuilder';
import Settings from 'pages/admin/Settings';
import { hasPermission } from 'components/common/PermissionGate';

import NotFound from 'pages/NotFound';
import Loading from 'components/common/Loading';

import CooperationFrameworkDetails from 'pages/PlanBuilder/cfDetails/CooperationFrameworkDetails';
import Documents from 'pages/PlanBuilder/cfDetails/Documents';
import Evaluation from 'pages/PlanBuilder/cfDetails/Evaluation';
import FundingFrameworkDetails from 'pages/PlanBuilder/cfDetails/FundingFrameworkDetails';
import Preparation from 'pages/PlanBuilder/cfDetails/Preparation';
import Signatories from 'pages/PlanBuilder/cfDetails/Signatories';
import AuthWrapper from './AuthWrapper';

// FIXME: uncommenting this for now because it seems to conflict with
// loading of forms.io somehow.
const BlueprintBuilderComponent = lazy(() => import('pages/BlueprintBuilder'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const EntityPrototypeComponent = lazy(() => import('pages/EntityPrototype'));
const DataExporter = lazy(() => import('pages/DataExporter/index'));
const NewDataExporter = lazy(() => import('pages/NewDataExporter/index'));
const FormBuilderComponent = lazy(() => import('pages/FormBuilder'));
const Main = lazy(() => import('pages/Main'));
const PlanBuilderComponent = lazy(() => import('pages/PlanBuilder'));
const MonitoringComponent = lazy(() => import('pages/Monitoring'));
const Profile = lazy(() => import('pages/Profile'));
const PlanViewerComponent = lazy(() => import('pages/PlanViewer'));
const Comments = lazy(() => import('pages/comments/Comments'));
const FundingFrameworkPage = lazy(() => import('pages/FundingFramework'));
const UNEntitiesAndRCO = lazy(() => import('pages/UNEntitiesAndRCO/index'));

const CpOptionIdentificationPage = lazy(() =>
  import('pages/CpOptionIdentification')
);

export default function RouteWrapper(): React.ReactElement | null {
  const [user] = useGlobal('user');

  if (!user) {
    return null;
  }

  // TODO: remove old route for plan at beginning of april 2022
  return (
    <Suspense fallback={<Loading loading />}>
      <Routes>
        <Route
          path="/user/profile"
          element={
            <AuthWrapper>
              <Profile />
            </AuthWrapper>
          }
        />
        <Route
          path="/user/comments"
          element={
            <AuthWrapper>
              <Comments />
            </AuthWrapper>
          }
        />

        <Route path="/dashboard/workspace/:workspaceId">
          <Route
            path="contacts/:tab"
            element={
              <AuthWrapper>
                <UNEntitiesAndRCO />
              </AuthWrapper>
            }
          />
          <Route
            path="domain/:domainId"
            element={
              <AuthWrapper>
                <Dashboard />
              </AuthWrapper>
            }
          />
        </Route>
        <Route
          path="/dashboard/domain"
          element={
            <AuthWrapper>
              <Dashboard />
            </AuthWrapper>
          }
        >
          <Route
            path=":domainId"
            element={
              <AuthWrapper>
                <Dashboard />
              </AuthWrapper>
            }
          />
        </Route>

        <Route path="/dashboard/admin">
          <Route
            path="organizations"
            element={
              <AuthWrapper>
                <AdminOrganization />
              </AuthWrapper>
            }
          />
          <Route
            path="organization/:id"
            element={
              <AuthWrapper>
                <AdminOrganizationBuilder />
              </AuthWrapper>
            }
          />
          <Route
            path="links"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !user?.isAdmin;
                }}
              >
                <AdminLink />
              </AuthWrapper>
            }
          />
          <Route
            path="countries"
            element={
              <AuthWrapper>
                <AdminCountry />
              </AuthWrapper>
            }
          />

          <Route
            path="country/:id/locations"
            element={
              <AuthWrapper>
                <CountryBuilder />
              </AuthWrapper>
            }
          />

          <Route
            path="country/:id"
            element={
              <AuthWrapper>
                <CountryRedirect />
              </AuthWrapper>
            }
          />

          <Route
            path="blueprints"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !user?.isAdmin;
                }}
              >
                <AdminBlueprint />
              </AuthWrapper>
            }
          />

          <Route
            path="blueprintbuilder/:id"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !user?.isAdmin;
                }}
              >
                <BlueprintBuilderComponent />
              </AuthWrapper>
            }
          />

          <Route
            path="datasources"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !user?.isAdmin;
                }}
              >
                <AdminDataSource />
              </AuthWrapper>
            }
          />
          <Route
            path="dataitems"
            element={
              <AuthWrapper>
                <AdminDataItem />
              </AuthWrapper>
            }
          />
          <Route
            path="forms"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !user?.isAdmin;
                }}
              >
                <AdminCustomForm />
              </AuthWrapper>
            }
          />
          <Route
            path="units"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !user?.isAdmin;
                }}
              >
                <AdminUnits />
              </AuthWrapper>
            }
          />
          <Route
            path="metrics"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !user?.isAdmin;
                }}
              >
                <AdminMetrics />
              </AuthWrapper>
            }
          />
          <Route
            path="logs"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !user?.isAdmin;
                }}
              >
                <AdminLogList />
              </AuthWrapper>
            }
          />
          <Route
            path="formbuilder/:id"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !user?.isAdmin;
                }}
              >
                <FormBuilderComponent />
              </AuthWrapper>
            }
          />
          <Route
            path="planviewer/:id"
            element={
              <AuthWrapper>
                <PlanViewerComponent />
              </AuthWrapper>
            }
          />
          <Route
            path="users"
            element={
              <AuthWrapper
                denyUserAccess={() =>
                  !hasPermission({ user, scopes: 'userRole_batchAssign' })
                }
              >
                <AdminUser />
              </AuthWrapper>
            }
          />

          <Route
            path="contacts"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !hasPermission({
                    user,
                    scopes: ['contact_view', 'contact_create', 'contact_update']
                  });
                }}
              >
                <AdminContact />
              </AuthWrapper>
            }
          />
          <Route
            path="contact/:id"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !hasPermission({
                    user,
                    scopes: ['contact_view', 'contact_create', 'contact_update']
                  });
                }}
              >
                <AdminContactBuilder />
              </AuthWrapper>
            }
          />
          <Route
            path="documents"
            element={
              <AuthWrapper>
                <AdminDocument />
              </AuthWrapper>
            }
          />
          <Route
            path="document/:id"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !hasPermission({
                    user,
                    scopes: [
                      'fileDocument_view',
                      'fileDocument_update',
                      'fileDocument_create',
                      'fileDocument_softDelete'
                    ]
                  });
                }}
              >
                <AdminDocumentBuilder />
              </AuthWrapper>
            }
          />
          <Route
            path="workspaces"
            element={
              <AuthWrapper>
                <AdminWorkspace />
              </AuthWrapper>
            }
          />
          <Route
            path="workspace/:id"
            element={
              <AuthWrapper>
                <AdminWorkspaceBuilder />
              </AuthWrapper>
            }
          />

          <Route
            path="entityprototype/:planId"
            element={
              <AuthWrapper>
                <EntityPrototypeComponent />
              </AuthWrapper>
            }
          />

          <Route
            path="export"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !hasPermission({
                    user,
                    scopes: ['report_exportCrossPlans', 'report_exportPlan']
                  });
                }}
              >
                <DataExporter />
              </AuthWrapper>
            }
          />
          <Route
            path="new-export"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !hasPermission({
                    user,
                    domainId: 3,
                    scopes: ['report_exportCrossPlans', 'report_exportPlan']
                  });
                }}
              >
                <NewDataExporter />
              </AuthWrapper>
            }
          />
          <Route
            path="settings"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !user?.isAdmin;
                }}
              >
                <Settings />
              </AuthWrapper>
            }
          />
          <Route path="roles">
            <Route
              path=":id"
              element={
                <AuthWrapper
                  denyUserAccess={() => {
                    return !user?.isAdmin;
                  }}
                >
                  <RoleEditor />
                </AuthWrapper>
              }
            />
            <Route
              index
              element={
                <AuthWrapper
                  denyUserAccess={() => {
                    return !user?.isAdmin;
                  }}
                >
                  <RoleTable />
                </AuthWrapper>
              }
            />
          </Route>
          <Route
            path="tokentime"
            element={
              <AuthWrapper
                denyUserAccess={() => {
                  return !user?.isAdmin;
                }}
              >
                <TokenTime />
              </AuthWrapper>
            }
          />
        </Route>
        <Route
          path="/dashboard/plan/:id/monitoring/prototype/:entityPrototypeId"
          element={
            <AuthWrapper>
              <MonitoringComponent />
            </AuthWrapper>
          }
        />
        <Route path="/dashboard/plan/:id/build">
          <Route
            path="cooperation-framework"
            element={<CooperationFrameworkDetails />}
          >
            <Route path="signatories" element={<Signatories />} />
            <Route path="preparation" element={<Preparation />} />
            <Route path="funding" element={<FundingFrameworkDetails />} />
            <Route path="documents" element={<Documents />} />
            <Route path="evaluation" element={<Evaluation />} />
          </Route>
          <Route path="funding-framework" element={<FundingFrameworkPage />} />
          <Route
            path="cp-option-identification/:stepIndex"
            element={
              <AuthWrapper>
                <CpOptionIdentificationPage />
              </AuthWrapper>
            }
          />
          <Route
            path="prototype/:entityPrototypeId"
            element={
              <AuthWrapper>
                <PlanBuilderComponent />
              </AuthWrapper>
            }
          />
          <Route
            path="prototype"
            element={
              <AuthWrapper>
                <PlanBuilderComponent />
              </AuthWrapper>
            }
          />
          <Route
            index
            element={
              <AuthWrapper>
                <Navigate to="prototype" />
              </AuthWrapper>
            }
          />
        </Route>

        <Route
          path="/dashboard/plan/:id"
          element={
            <AuthWrapper>
              <PlanViewerComponent />
            </AuthWrapper>
          }
        />

        <Route
          path="/dashboard"
          element={<Navigate to="/dashboard/domain" />}
        />
        <Route path="/*" element={<Main />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

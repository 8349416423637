export function entityIsPlan(entity: unknown): entity is Plan {
  if (!entity) {
    return false;
  }
  return (entity as Plan).planVersion !== undefined;
}

interface ExistsInDatabase {
  id: number;
}

export function isExisting<T extends ExistsInDatabase>(
  entity: unknown
): entity is T {
  if (!entity) {
    return false;
  }
  return (entity as T).id !== undefined;
}

export function filterExisting<T>(entities: (T | undefined)[]): T[] {
  return entities.filter(o => !!o) as T[];
}

export function entityIsPlanEntity(entity: unknown): entity is PlanEntity {
  if (!entity) {
    return false;
  }
  return (entity as PlanEntity).planEntityVersion !== undefined;
}

export function entityIsEntityPrototype(
  entity: Plan | EntityPrototype
): entity is EntityPrototype {
  return (entity as EntityPrototype).coreentityTypeId !== undefined;
}

export function entityIsOrganization(entity: unknown): entity is Organization {
  return (entity as Organization).organizationType !== undefined;
}

export function entityIsArchivable(entity: unknown): entity is IsArchivable {
  return (entity as IsArchivable).archivedAt !== undefined;
}

export function entityIsUser(entity: unknown): entity is User {
  return (entity as User).firstname !== undefined;
}

export function isWorkspaceOrganizationContact(
  contact: unknown
): contact is WorkspaceOrganizationContact {
  return (
    (contact as WorkspaceOrganizationContact)?.organizationContact?.id !==
    undefined
  );
}

// export function objectIsCellMetric(object: any): object is CellMetric {
//   return (object as CellMetric).metricId !== undefined;
// }

export function isNumberArray(value: unknown): value is number[] {
  if (!Array.isArray(value)) return false;
  if (value.some(v => typeof v !== 'number')) return false;
  return true;
}

export default {
  entityIsPlan
};

import React, { useState, useCallback, useGlobal } from 'reactn';
import clsx from 'clsx';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableInstance } from 'react-table';

import ViewColumnsIcon from '@mui/icons-material/ViewColumn';
import { makeStyles } from '@mui/styles';
import { lighten, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

import Tooltip from '@mui/material/Tooltip';

import ExportExcel from 'components/common/export/exportExcel';

import PermissionGate from 'components/common/PermissionGate';
import GlobalFilter from './GlobalFilter';
import AddRowDialog from './AddRowDialog';
import Filters from './Filters';
import ColumnHide from './ColumnHide';
import { useTableContext } from './TableProvider';
import RestoreFromTrashButton from './RestoreFromTrashButton';
import MergeButton from './MergeButton';

const useToolbarStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between'
  },
  highlight: {
    ...(theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        })
  },
  filters: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    flex: '1 1 100%',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    textTransform: 'capitalize'
  }
}));

interface Props {
  numSelected: number;
  deleteRowHandler: () => void;
  totalRecords: number;
  tableInstance: TableInstance;
  endpoint: string;
  noAdd?: boolean;
  setOpen?: (arg0: boolean) => void;
  linkTo?: string;
  archivable?: boolean;
}

const TableToolbar: React.FC<Props> = ({
  numSelected,
  deleteRowHandler,
  totalRecords,
  tableInstance,
  endpoint,
  noAdd,
  setOpen,
  archivable,
  linkTo
}) => {
  const classes = useToolbarStyles();

  const { state, dispatch } = useTableContext();
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(undefined);

  const displayFilter = tableInstance.allColumns.find(
    // @ts-ignore FIXME
    column => column.Filter?.name
  );

  const hideableColumns = tableInstance.allColumns.filter(
    (column: { id: string }) =>
      !(column.id === 'selection' || column.id === 'actions')
  );

  const handleColumnsClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
      setColumnsOpen(true);
    },
    [setAnchorEl, setColumnsOpen]
  );

  const handleFilterClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
      setFilterOpen(true);
    },
    [setAnchorEl, setFilterOpen]
  );

  const handleShowArchivedClick = useCallback(() => {
    dispatch({ type: 'toggleIncludeSoftDeleted' });
  }, [dispatch]);

  const handleClose = useCallback(() => {
    setColumnsOpen(false);
    setFilterOpen(false);
    setAnchorEl(undefined);
  }, []);

  const displaySelectedToolbar = numSelected !== 0;

  const containsArchived = state?.tableData?.find(
    (t: any) => t.archivedAt !== null
  );

  const createScope = `${endpoint}_create` as Scopes;
  const updateScope = `${endpoint}_update` as Scopes;
  const deleteScope = `${endpoint}_softDelete` as Scopes;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {!displaySelectedToolbar && (
        <>
          <PermissionGate scopes={createScope}>
            {!noAdd && <AddRowDialog setOpen={setOpen} linkTo={linkTo} />}
          </PermissionGate>

          <Typography className={classes.title} variant="h6" id="tableTitle">
            {endpoint === 'contact' && 'RCO / UNCT Composition'}
            {endpoint === 'fileDocument' && 'Document Library'}
            {endpoint !== 'fileDocument' &&
              endpoint !== 'contact' &&
              `${endpoint} management`}
          </Typography>
        </>
      )}
      {displaySelectedToolbar && (
        <>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} selected
          </Typography>
          {state.allowMerge && (
            <PermissionGate scopes={updateScope}>
              <MergeButton tableInstance={tableInstance} endpoint={endpoint} />
            </PermissionGate>
          )}
          {containsArchived && (
            <PermissionGate scopes={updateScope}>
              <RestoreFromTrashButton
                tableInstance={tableInstance}
                endpoint={endpoint}
              />
            </PermissionGate>
          )}

          <PermissionGate scopes={deleteScope}>
            <Tooltip title={archivable ? 'Archive' : 'Delete'}>
              <IconButton
                aria-label="archive"
                onClick={deleteRowHandler}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </PermissionGate>
        </>
      )}
      <div className={classes.filters}>
        {!displaySelectedToolbar && !state.canSelect && (
          <>
            <GlobalFilter totalRecords={totalRecords} />
            <Tooltip title="Export">
              <ExportExcel
                table={tableInstance}
                endpoint={endpoint}
                {...(endpoint === 'contact' ? { current: false } : {})}
              />
            </Tooltip>
            {hideableColumns?.length > 1 && (
              <>
                <ColumnHide
                  instance={tableInstance}
                  onClose={handleClose}
                  show={columnsOpen}
                  anchorEl={anchorEl}
                />
                <Tooltip title="Columns" aria-label="Columns">
                  <span>
                    <IconButton onClick={handleColumnsClick} size="large">
                      <ViewColumnsIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )}
            <Tooltip
              title={`Include ${archivable ? 'archived' : 'deleted'} entities`}
              aria-label="Filters"
            >
              <span>
                <IconButton
                  onClick={handleShowArchivedClick}
                  color={state.includeSoftDeleted ? 'primary' : undefined}
                  size="large"
                >
                  <RestoreFromTrashIcon />
                </IconButton>
              </span>
            </Tooltip>
            {displayFilter && (
              <>
                <Filters
                  instance={tableInstance}
                  onClose={handleClose}
                  show={filterOpen}
                  anchorEl={anchorEl}
                />

                <Tooltip title="Filters" aria-label="Filters">
                  <span>
                    <IconButton onClick={handleFilterClick} size="large">
                      <FilterListIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )}
          </>
        )}
      </div>
    </Toolbar>
  );
};

export default TableToolbar;

import React, {
  useEffect,
  useState,
  useCallback,
  setGlobal,
  useGlobal
} from 'reactn';

import { useNavigate, useLocation } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';

import PaperWrapper from 'components/common/PaperWrapper';
import MaUTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';

import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useFilters,
  useTable
} from 'react-table';

import { fetchAll, deleteById } from 'services/AdminService';
import {
  fetchWorkspaces,
  getRegionWorkspaceMap
} from 'services/WorkspaceService';

import { hasUserRole, filterUserRoles } from 'components/common/PermissionGate';

import UserItem from 'components/common/UserItem';

import { TableProvider, useTableContext, Filter } from './TableProvider';
import { useTableRefreshContext } from './TableRefreshProvider';

import TablePaginationActions from './TablePaginationActions';
import { IndeterminateCheckbox } from './IndeterminateCheckbox';

import TableToolbar from './UserTableToolbar';
import FilterChipBar from './FilterChipBar';

// const hideColumn = ({
//   archivable,
//   columnId,
//   includeSoftDeleted
// }: {
//   archivable?: boolean;
//   columnId: any;
//   includeSoftDeleted?: boolean;
// }): boolean => {
//   if (archivable && columnId === 'deletedAt') {
//     return true;
//   }
//   if (
//     (columnId === 'deletedAt' || columnId === 'archivedAt') &&
//     !includeSoftDeleted
//   ) {
//     return true;
//   }
//   return false;
// };

interface QueryOptions {
  limit: number;
  apiPage: number;
  sort: any;
  includeSoftDeleted?: boolean;
  includeArchived?: boolean;
  filters: Filter[];
  globalFilter: string;
  exclude?: string[];
  extraParams: any;
}

interface TableParams {
  columns: any[];
  endpoint: string;
  archivable?: boolean;
  pageSize?: number;
  setEditItem?: (item: any) => void;
  setOpen?: (val: boolean) => void;
  preDeleteCheck?: (ids: number[]) => Promise<boolean>;
  canDelete?: boolean;
}

const WrappedTable: React.FC<TableParams> = ({
  columns,
  endpoint,
  archivable,
  pageSize,
  setEditItem,
  setOpen,
  preDeleteCheck,
  canDelete = true // FIXME: this should probably default to false, but we don't have permissions for other tables yet
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'userTable' });
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [user] = useGlobal('user');
  const {
    state,
    dispatch,
    loading: { startLoading, stopLoading, FullScreenLoading, isLoading }
  } = useTableContext();

  const {
    state: { shouldRefresh, isDialogOpen },
    dispatch: dispatchRefresh
  } = useTableRefreshContext();

  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const pageNumber = searchParams.get('page');
  const navigate = useNavigate();
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [sessionTablePreferences] = useGlobal('sessionTablePreferences');

  const [workspaceNameLookup, setWorkspaceNameLookup] = React.useState<{
    [key: number]: string;
  }>();

  React.useEffect(() => {
    const fetchWorkspacesAll = async () => {
      const w = await fetchWorkspaces({
        globalFilter: '',
        exclude: [
          'contacts',
          'region',
          'countries',
          'organizations',
          'workspaceType',
          'parent',
          'children'
        ]
      });
      const lookup: { [key: number]: string } =
        w?.reduce((a, v) => {
          return { ...a, [v.id]: v.name };
        }, {}) ?? {};
      setWorkspaceNameLookup(lookup);
    };
    fetchWorkspacesAll();
  }, []);

  const handleChangePage = (_event: unknown, newPage: number) => {
    searchParams.set('page', `${newPage}`);
    const path = `${pathname}?${searchParams.toString()}`;
    navigate(path);
  };

  const sortBy = React.useMemo(
    () => [
      {
        id: 'updatedAt',
        desc: true
      }
    ],
    []
  );

  // How much of this can be moved to the wrapper so that it's available in child components?
  const tableInstance = useTable(
    {
      columns,
      data: React.useMemo(() => state.tableData ?? [], [state.tableData]),
      initialState: {
        pageIndex: sessionTablePreferences?.user?.pageNumber ?? pageNumber ?? 0,
        pageSize: sessionTablePreferences?.user?.pageSize ?? pageSize ?? 10,
        sortBy: sessionTablePreferences?.user?.sortBy ?? sortBy,
        globalFilter: sessionTablePreferences?.user?.globalFilter ?? [], // This is distinct from the q text being used outside of this function.
        filters: sessionTablePreferences?.user?.filters ?? [],
        selectedRowIds: {} as Record<string, boolean>,
        hiddenColumns: columns
          .filter(column => column?.hide)
          .map(column => column.accessor)
      }, // Pass our hoisted table state
      filterTypes: undefined,
      manualFilters: true,
      manualGlobalFilter: true,
      manualPagination: true,
      manualSortBy: true,
      pageCount
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.allColumns.push(allColumns => [
        {
          id: 'selection',
          minWidth: 15,
          width: 15,
          maxWidth: 15,

          // eslint-disable-next-line react/display-name
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          )
        },
        ...allColumns
      ]);
    }
  );

  const { queryString, includeSoftDeleted, canSelect } = state;

  const fetchedData = useCallback(async () => {
    if (
      !queryString ||
      (queryString && queryString !== '' && queryString.length >= 3)
    ) {
      startLoading();
      let filters = [
        ...(tableInstance.state.globalFilter ?? []),
        ...(tableInstance.state.filters ?? []),
        ...(state.filters ?? [])
      ];
      const sort = tableInstance.state.sortBy.map(sortObject => {
        return [sortObject.id, sortObject.desc ? 'DESC' : 'ASC'];
      });
      const apiPage = +tableInstance.state.pageIndex + 1;
      const limit = tableInstance.state.pageSize;

      const hasGlobalContext = hasUserRole({
        user,
        permission: 'user_search'
      });
      if (!hasGlobalContext) {
        const regionUserRoles = filterUserRoles({
          user,
          permission: 'user_search',
          context: 'contextRegionId'
        });
        const workspaceUserRoles = filterUserRoles({
          user,
          permission: 'user_search',
          context: 'contextWorkspaceId'
        });
        const selectedWorkspaces = filters
          .find(f => f.id === 'workspaceId')
          ?.value?.map((v: any) => v.id);
        if (selectedWorkspaces) {
          filters = filters.filter(f => f.id !== 'workspaceId');
        }
        if (regionUserRoles?.length) {
          const regionWorkspaceMap = await getRegionWorkspaceMap();
          let userWorkspaces = [
            ...new Set(
              regionUserRoles
                .map(w => w.contextRegionId)
                .flatMap(r => r && regionWorkspaceMap[r])
                .concat(
                  workspaceUserRoles
                    ? workspaceUserRoles?.map(w => w.contextWorkspaceId)
                    : []
                )
            )
          ];
          if (selectedWorkspaces) {
            userWorkspaces = userWorkspaces.filter(uw =>
              selectedWorkspaces.includes(uw)
            );
          }
          filters.push({
            id: 'workspaceId',
            value: userWorkspaces
          });
        } else if (workspaceUserRoles?.length) {
          let userWorkspaces = [
            ...new Set(workspaceUserRoles?.map(w => w.contextWorkspaceId))
          ];
          if (selectedWorkspaces) {
            userWorkspaces = userWorkspaces.filter(uw =>
              selectedWorkspaces.includes(uw)
            );
          }
          filters.push({
            id: 'workspaceId',
            value: userWorkspaces
          });
        }
      }

      const options: QueryOptions = {
        limit,
        apiPage,
        sort,
        filters,
        globalFilter: queryString ?? '',
        exclude: [],
        extraParams: {
          includeRoleDetails: true
        }
      };

      options[
        archivable ? 'includeArchived' : 'includeSoftDeleted'
      ] = includeSoftDeleted;

      try {
        // FIXME: each time page changed a first called is made with a string and a value that is not the page but apparently the offset...
        if (typeof apiPage === 'number') {
          const res = await fetchAll('user', options);
          dispatch({ type: 'setTableData', tableData: res.data.results });
          setTotalRecords(res.data.pagination.total);
          setPageCount(Math.ceil(res.data.pagination.total / limit));
        }

        const newTablePreferences = {
          pageIndex: tableInstance.state.pageIndex,
          pageSize: limit,
          sortBy: tableInstance.state.sortBy,
          filters: tableInstance.state.filters,
          globalFilter: tableInstance.state.globalFilter,
          queryString
        };
        setGlobal(g => {
          return {
            sessionTablePreferences: {
              ...g.sessionTablePreferences,
              user: newTablePreferences,
              // We stash the options sent to the API so that
              // we can reference them for exporting to excel
              userExport: options
            }
          };
        });
      } catch (e) {
        console.error('error', e);
      } finally {
        stopLoading();
      }
    }
  }, [
    archivable,
    includeSoftDeleted,
    queryString,
    startLoading,
    tableInstance.state.globalFilter,
    tableInstance.state.filters,
    tableInstance.state.sortBy,
    tableInstance.state.pageIndex,
    tableInstance.state.pageSize,
    state.filters,
    dispatch,
    stopLoading
  ]);

  useEffect(() => {
    if (sessionTablePreferences === undefined) {
      setGlobal({
        sessionTablePreferences: {
          user: {
            pageIndex: tableInstance.state.pageIndex,
            pageSize: tableInstance.state.pageSize,
            sortBy: tableInstance.state.sortBy,
            filters: tableInstance.state.filters,
            globalFilter: tableInstance.state.globalFilter,
            queryString: undefined
          }
        }
      });
    }
  }, [
    sessionTablePreferences,
    tableInstance.state.pageIndex,
    tableInstance.state.sortBy,
    tableInstance.state.filters,
    tableInstance.state.globalFilter,
    tableInstance.state.pageSize
  ]);

  useEffect(() => {
    if (pageNumber) {
      tableInstance.gotoPage(+pageNumber);
    }
  }, [pageNumber, tableInstance]);

  const filterHash = JSON.stringify(state.filters);

  const { refresh } = state;
  useEffect(() => {
    fetchedData();
  }, [
    fetchedData,
    refresh,
    filterHash // FIXME: The fact that we're relying on this filterhash
    // means that there is probably a state problem somewhere in our
    // TableProvider. Can't figure out what though
  ]);

  useEffect(() => {
    if (!isDialogOpen && shouldRefresh) {
      dispatch({ type: 'toggleRefresh' });
      dispatchRefresh({ type: 'setShouldRefresh', shouldRefresh: false });
    }
  }, [isDialogOpen, shouldRefresh]);

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    tableInstance.setPageSize(Number(event.target.value));
    tableInstance.gotoPage(0);
  };

  const setDialogData = React.useCallback(
    async data => {
      if (data) {
        setEditItem?.(data);
        setOpen?.(true);
      }
    },
    [setEditItem, setOpen]
  );

  const deleteItem = React.useCallback(
    async (id: number, alreadyConfirmed = false) => {
      if (!alreadyConfirmed) {
        await confirm({
          description: `Are you sure you want to ${
            archivable ? 'archive' : 'delete'
          } these ${endpoint}s?`
        });
      }
      try {
        await deleteById('user', id);
        dispatch({ type: 'toggleRefresh' });
        enqueueSnackbar(t('userDeleted'), {
          preventDuplicate: true
        });
      } catch (e) {
        console.error('error', e);
      }
    },
    [confirm, dispatch, enqueueSnackbar, t]
  );

  const removeByIndexs = (array: any[], indexs: number[]) => {
    array.forEach((item: any, index: number) => {
      if (indexs.includes(index)) {
        deleteItem(item.id, true);
      }
    });
    return array.filter((_, i) => !indexs.includes(i));
  };

  const deleteRowHandler = async () => {
    try {
      await confirm({
        description: t('areYouSureDeleteUser', { count: 2 })
      });
      if (state.tableData && !state.allRowsSelected) {
        removeByIndexs(
          state.tableData,
          Object.keys(tableInstance.state.selectedRowIds).map(x =>
            parseInt(x, 10)
          )
        );
        dispatch({ type: 'toggleRefresh' });
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <TableToolbar
        tableInstance={tableInstance}
        endpoint={endpoint}
        numSelected={Object.keys(tableInstance.state.selectedRowIds).length}
        deleteRowHandler={deleteRowHandler}
        totalRecords={totalRecords}
        archivable={archivable}
      />
      <FilterChipBar instance={tableInstance} />
      <FullScreenLoading />

      <MaUTable {...tableInstance.getTableProps()}>
        {/* // uncomment in case we opt for a classical table hearer sort 
        <TableHead>
          <TableCell
            key={tableInstance.id}
            sx={{ justifyContent: 'space-between' }}
          >
            {tableInstance.headerGroups.map(headerGroup => (
              <>
                {headerGroup.headers.map(column => {
                  if (
                    hideColumn({
                      archivable,
                      includeSoftDeleted,
                      columnId: column.id
                    })
                  ) {
                    return true;
                  }
                  if (column.id === 'selection' && !canSelect) {
                    return true;
                  }
                  if (column.id === 'selection') {
                    return true;
                  }
                  if (column.disableSortBy) {
                    return true;
                  }
                  return (
                    <TableCell
                      {...(column.id === 'selection'
                        ? column.getHeaderProps()
                        : column.getHeaderProps(column.getSortByToggleProps()))}
                      key={column.id}
                    >
                      {column.render('Header')}
                      {column.id !== 'actions' && column.id !== 'selection' && (
                        <TableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                        />
                      )}
                    </TableCell>
                  );
                })}
              </>
            ))}
          </TableCell>
        </TableHead> */}
        <TableBody>
          {tableInstance.page.length === 0 && !isLoading && (
            <tr>
              <td>
                <PaperWrapper>
                  <Typography variant="h6">{t('noActiveUsers')}</Typography>
                </PaperWrapper>
              </td>
            </tr>
          )}
          {tableInstance.page.map(row => {
            tableInstance.prepareRow(row);
            return (
              <>
                <UserItem
                  endpoint={endpoint}
                  user={row.original}
                  key={row.id}
                  row={row}
                  includeSoftDeleted={includeSoftDeleted}
                  visibleColumns={tableInstance.visibleColumns}
                  preDeleteCheck={preDeleteCheck}
                  canDelete={canDelete}
                  setDialogData={setDialogData}
                  workspaceNameLookup={workspaceNameLookup}
                />
              </>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={3}
              count={totalRecords}
              rowsPerPage={tableInstance.state.pageSize}
              page={tableInstance.state.pageIndex}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </MaUTable>
    </div>
  );
};

interface UserTableProps {
  endpoint: string;
  setOpen?: (val: boolean) => void;
  setEditItem?: (item: any) => void;
  columns: any[];
}

const UserTable: React.FC<UserTableProps> = ({ ...props }) => {
  return (
    <TableProvider
      initialState={{
        canSelect: false,
        tableData: [],
        includeSoftDeleted: false
      }}
    >
      <WrappedTable {...props} />
    </TableProvider>
  );
};

export default UserTable;

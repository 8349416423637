import React from 'react';
import Grid from '@mui/material/Grid';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { Button } from 'uninfo-components';
import { DialogActions } from '@mui/material';

export const DataSourceForm = (props: any) => {
  const { t } = useTranslation('common');
  const { editItem, onSubmit: onSubmitFunc } = props;
  const defaultValues = {
    name: editItem.datasourceVersion?.name || ''
  };
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues
  });

  const handleSubmitForm = handleSubmit(data => {
    onSubmitFunc(data);
    reset(defaultValues);
  });

  return (
    <form onSubmit={handleSubmitForm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            rules={{ required: true }}
            name="name"
            render={({ onChange, value }) => (
              <TextField
                autoComplete="fname"
                error={errors.name}
                variant="outlined"
                label={t('Name')}
                name="name"
                type="text"
                placeholder="Name"
                fullWidth
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            {editItem.id ? 'Edit' : 'Add'}
          </Button>
        </DialogActions>
      </Grid>
    </form>
  );
};

export default { DataSourceForm };

/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  setGlobal,
  useGlobal
} from 'reactn';

import { useConfirm } from 'material-ui-confirm';
import { useLocation, useNavigate } from 'react-router-dom';
import MaUTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useFilters,
  useExpanded,
  useTable
} from 'react-table';

import {
  fetchAll,
  deleteById,
  fetchById,
  archiveById
} from 'services/AdminService';

import { getRegionWorkspaceMap } from 'services/WorkspaceService';

import {
  filterBoolNestedOrganizationContacts,
  filterDataItemNestedOrganizationContacts,
  filterNestedOrganizationContacts,
  findFilter
} from 'components/common/export/exportUtils';

import { IMS_DOMAIN_ID } from 'utils/constants';
import { hasUserRole, filterUserRoles } from 'components/common/PermissionGate';

import TableToolbar from './TableToolbar';
import { TableProvider, useTableContext, Filter } from './TableProvider';
import useTableQueryParameters from './useTableQueryParameters';
import FilterChipBar from './FilterChipBar';
import TablePaginationActions from './TablePaginationActions';
import { IndeterminateCheckbox } from './IndeterminateCheckbox';
import ActionButtons from './ActionButtons';

const hideColumn = ({
  archivable,
  columnId,
  includeSoftDeleted
}: {
  archivable?: boolean;
  columnId: any;
  includeSoftDeleted?: boolean;
}): boolean => {
  if (archivable && columnId === 'deletedAt') {
    return true;
  }
  if (
    (columnId === 'deletedAt' || columnId === 'archivedAt') &&
    !includeSoftDeleted
  ) {
    return true;
  }
  return false;
};

interface QueryOptions {
  limit: number;
  apiPage: number;
  sort: any;
  includeSoftDeleted?: boolean;
  includeArchived?: boolean;
  filters: Filter[];
  globalFilter: string;
  statuses?: string[];
  exclude?: string[];
}

interface TableParams {
  endpoint: string;
  archivable?: boolean;
  columns: any[];
  hiddenColumns: any[];
  pageSize?: number;
  renderRowSubComponent?: (obj: any) => React.ReactElement;
  linkTo?: string;
  setEditItem?: (item: any) => void;
  noAdd?: boolean;
  canDelete?: boolean;
  setOpen?: (val: boolean) => void;
  additionalQueryParams?: Filter[];
  allowMerge?: boolean;
  preDeleteCheck?: (ids: number[]) => Promise<boolean>;
}

const WrappedTable: React.FC<TableParams> = ({
  endpoint,
  columns,
  archivable,
  hiddenColumns = [],
  pageSize,
  renderRowSubComponent,
  linkTo,
  setEditItem,
  noAdd,
  setOpen,
  preDeleteCheck,
  canDelete = true, // FIXME: this should probably default to false, but we don't have permissions for other tables yet
  additionalQueryParams
}) => {
  const {
    state,
    dispatch,
    loading: { startLoading, stopLoading, FullScreenLoading }
  } = useTableContext();
  const navigate = useNavigate();
  const { isPlanTable, includeSoftDeleted } = state;
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const pageNumber = searchParams.get('page');
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [sessionTablePreferences] = useGlobal('sessionTablePreferences');
  const [user] = useGlobal('user');

  const skipPageResetRef = useRef<boolean | undefined>();

  const confirm = useConfirm();

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: any[], id: any, filterValue: string) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    searchParams.set('page', `${newPage}`);
    const path = `${pathname}?${searchParams.toString()}`;
    navigate(path);
  };

  const sortBy = React.useMemo(
    () => [
      !isPlanTable
        ? {
            id: 'id',
            desc: true
          }
        : {
            id: 'updatedAt',
            desc: true
          }
    ],
    [isPlanTable]
  );

  const editRow = React.useCallback(
    async id => {
      if (id) {
        if (linkTo) {
          navigate(`${linkTo}/${id}`);
        } else {
          try {
            const res = await fetchById(endpoint, id);
            setEditItem?.(res.data);
            setOpen?.(true);
          } catch (e) {
            // console.error(e);
          }
        }
      }
    },
    [endpoint, navigate, linkTo, setEditItem, setOpen]
  );
  // How much of this can be moved to the wrapper so that it's available in child components?
  const tableInstance = useTable(
    {
      columns,
      data: state.tableData ?? [],
      initialState: {
        pageIndex:
          sessionTablePreferences?.[endpoint]?.pageNumber ?? pageNumber ?? 0,
        pageSize:
          sessionTablePreferences?.[endpoint]?.pageSize ?? pageSize ?? 10,
        sortBy: sessionTablePreferences?.[endpoint]?.sortBy ?? sortBy,
        globalFilter: sessionTablePreferences?.[endpoint]?.globalFilter ?? [], // This is distinct from the q text being used outside
        // of this function.
        filters: additionalQueryParams?.length
          ? additionalQueryParams
          : sessionTablePreferences?.[endpoint]?.filters ?? [],
        selectedRowIds: ([] as unknown) as Record<string, boolean>,
        hiddenColumns
      }, // Pass our hoisted table state
      autoResetPage: false,
      filterTypes,
      manualFilters: true,
      manualGlobalFilter: true,
      manualPagination: true,
      manualSortBy: true,
      pageCount
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.allColumns.push(allColumns => [
        {
          id: 'selection',
          minWidth: 15,
          width: 15,
          maxWidth: 15,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...allColumns,
        {
          id: 'actions',
          Header: '',
          Cell: ({ row }) => (
            <ActionButtons
              row={row}
              endpoint={endpoint}
              linkTo={linkTo}
              editRow={editRow}
              archivable={archivable}
              preDeleteCheck={preDeleteCheck}
              canDelete={canDelete}
            />
          )
        }
      ]);
    }
  );

  const fetchedData = useCallback(
    async (limit, page, sort, queryFilters, queryString) => {
      if (
        user &&
        (!queryString ||
          (queryString && queryString !== '' && queryString.length >= 3))
      ) {
        startLoading();
        const apiPage = page + 1;
        let filters = queryFilters;
        try {
          let regionUserRoles;
          let workspaceUserRoles;
          if (endpoint === 'contact') {
            const hasGlobalContext = hasUserRole({
              user,
              permission: 'contact_view',
              domainId: IMS_DOMAIN_ID,
              or: true
            });
            if (!hasGlobalContext) {
              regionUserRoles = filterUserRoles({
                user,
                permission: 'contact_view',
                domainId: IMS_DOMAIN_ID,
                or: true,
                context: 'contextRegionId'
              });
              workspaceUserRoles = filterUserRoles({
                user,
                permission: 'contact_view',
                domainId: IMS_DOMAIN_ID,
                or: true,
                context: 'contextWorkspaceId'
              });
            }
          }

          if (endpoint === 'fileDocument') {
            const hasGlobalContext = hasUserRole({
              user,
              permission: 'fileDocument_view',
              domainId: IMS_DOMAIN_ID,
              or: true
            });
            if (!hasGlobalContext) {
              regionUserRoles = filterUserRoles({
                user,
                permission: 'fileDocument_view',
                domainId: IMS_DOMAIN_ID,
                or: true,
                context: 'contextRegionId'
              });
              workspaceUserRoles = filterUserRoles({
                user,
                permission: 'fileDocument_view',
                domainId: IMS_DOMAIN_ID,
                or: true,
                context: 'contextWorkspaceId'
              });
            }
          }

          if (regionUserRoles || workspaceUserRoles) {
            const selectedWorkspaces = filters
              .find((f: any) => f.id === 'workspaceId')
              ?.value?.map((v: any) => v.id);

            if (selectedWorkspaces) {
              filters = filters.filter((f: any) => f.id !== 'workspaceId');
            }

            if (regionUserRoles && regionUserRoles.length) {
              const regionWorkspaceMap = await getRegionWorkspaceMap();
              let userWorkspaces = [
                ...new Set(
                  regionUserRoles
                    .map(w => w.contextRegionId)
                    .flatMap(r => r && regionWorkspaceMap[r])
                    .concat(
                      workspaceUserRoles
                        ? workspaceUserRoles?.map(w => w.contextWorkspaceId)
                        : []
                    )
                )
              ];
              if (selectedWorkspaces) {
                userWorkspaces = userWorkspaces.filter(uw =>
                  selectedWorkspaces.includes(uw)
                );
              }
              filters.push({
                id: 'workspaceId',
                value: userWorkspaces
              });
            } else if (workspaceUserRoles && workspaceUserRoles.length) {
              let userWorkspaces = [
                ...new Set(workspaceUserRoles?.map(w => w.contextWorkspaceId))
              ];
              if (selectedWorkspaces) {
                userWorkspaces = userWorkspaces.filter(uw =>
                  selectedWorkspaces.includes(uw)
                );
              }
              filters.push({
                id: 'workspaceId',
                value: user.userRoles?.map(w => w.contextWorkspaceId)
              });
            }
          }

          const options: QueryOptions = {
            limit,
            apiPage,
            sort,
            filters,
            globalFilter: queryString
          };

          options[
            archivable ? 'includeArchived' : 'includeSoftDeleted'
          ] = includeSoftDeleted;

          if (endpoint === 'organization') {
            options.statuses = ['disabled', 'active'];
          }

          // These should be passed to the component, but doing so
          // causes an infinite re-render. Requires digging deeper.
          if (endpoint === 'plan') {
            options.exclude = ['assessments', 'planSubmissions'];
          } else if (endpoint === 'country') {
            options.exclude = [
              'organizations',
              'teams',
              'workspaces',
              'children'
            ];
          }

          let res;
          // FIXME: each time page changed a first called is made with a string and a value that is not the page but apparently the offset...
          if (typeof apiPage === 'number') {
            res = await fetchAll(endpoint, options);
            if (endpoint === 'contact') {
              const filterWorkspaces = findFilter(filters, 'workspaceId');
              const filterOrganizations = findFilter(filters, 'organizationId');
              const filterTeamLeaders = findFilter(options.filters, 'teamLead');
              const filterCommsRCOContact = findFilter(
                options.filters,
                'commsRCOContact'
              );
              const filterJobTitleContact = findFilter(
                options.filters,
                'jobTitleId'
              );

              if (filterWorkspaces || filterOrganizations) {
                res.data.results = (res.data.results as Contact[]).map(c => {
                  let { organizationContacts } = c;
                  organizationContacts = filterNestedOrganizationContacts(
                    organizationContacts,
                    filterWorkspaces,
                    'workspaceId'
                  );
                  organizationContacts = filterNestedOrganizationContacts(
                    organizationContacts,
                    filterOrganizations,
                    'organizationId'
                  );
                  organizationContacts = filterBoolNestedOrganizationContacts(
                    organizationContacts,
                    filterTeamLeaders,
                    'teamLead'
                  );
                  organizationContacts = filterBoolNestedOrganizationContacts(
                    organizationContacts,
                    filterCommsRCOContact,
                    'commsRCOContact'
                  );
                  organizationContacts = filterDataItemNestedOrganizationContacts(
                    organizationContacts,
                    filterJobTitleContact,
                    'jobTitle'
                  );
                  return {
                    ...c,
                    organizationContacts
                  };
                });
              }
            }

            skipPageResetRef.current = true;
            dispatch({ type: 'setTableData', tableData: res.data.results });
            setTotalRecords(res.data.pagination.total);
            setPageCount(Math.ceil(res.data.pagination.total / limit));
          }

          const newTablePreferences = {
            pageIndex: tableInstance.state.pageIndex,
            pageSize: limit,
            sortBy: tableInstance.state.sortBy,
            filters: tableInstance.state.filters,
            globalFilter: tableInstance.state.globalFilter,
            queryString
          };

          setGlobal(g => {
            return {
              sessionTablePreferences: {
                ...g.sessionTablePreferences,
                [endpoint]: newTablePreferences,
                // We stash the options sent to the API so that
                // we can reference them for exporting to excel

                [`${endpoint}Export`]: options
              }
            };
          });
        } catch (e) {
          // console.error('error', e);
        } finally {
          stopLoading();
        }
      }
    },
    [
      startLoading,
      endpoint,
      user,
      archivable,
      includeSoftDeleted,
      additionalQueryParams,
      tableInstance.state.pageIndex,
      tableInstance.state.sortBy,
      tableInstance.state.filters,
      tableInstance.state.globalFilter,
      dispatch,
      stopLoading
    ]
  );

  useEffect(() => {
    if (sessionTablePreferences === undefined) {
      setGlobal({
        sessionTablePreferences: {
          [endpoint]: {
            pageIndex: tableInstance.state.pageIndex,
            pageSize: tableInstance.state.pageSize,
            sortBy: tableInstance.state.sortBy,
            filters: tableInstance.state.filters,
            globalFilter: tableInstance.state.globalFilter,
            queryString: undefined
          }
        }
      });
    }
  }, [
    sessionTablePreferences,
    endpoint,
    tableInstance.state.pageIndex,
    tableInstance.state.sortBy,
    tableInstance.state.filters,
    tableInstance.state.globalFilter,
    tableInstance.state.pageSize
  ]);

  useEffect(() => {
    if (pageNumber) {
      tableInstance.gotoPage(+pageNumber);
    }
  }, [pageNumber, tableInstance]);

  useEffect(() => {
    const index = +tableInstance.state.pageIndex;
    const size = tableInstance.state.pageSize;
    const sort = tableInstance.state.sortBy.map(sortObject => {
      return [sortObject.id, sortObject.desc ? 'DESC' : 'ASC'];
    });

    const queryFilters = [
      ...(tableInstance.state.globalFilter ?? []),
      ...(tableInstance.state.filters ?? [])
    ];
    fetchedData(size, index, sort, queryFilters, state.queryString);
    skipPageResetRef.current = false;
  }, [
    tableInstance.state.pageIndex,
    tableInstance.state.pageSize,
    tableInstance.state.sortBy,
    tableInstance.state.filters,
    tableInstance.state.globalFilter,
    endpoint,
    fetchedData,
    state.refresh,
    state.queryString
  ]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    tableInstance.setPageSize(Number(event.target.value));
    tableInstance.gotoPage(0);
  };

  const deleteRowHandler = async () => {
    try {
      if (state.tableData) {
        const indexes = Object.keys(tableInstance.state.selectedRowIds).map(x =>
          parseInt(x, 10)
        );
        const ids = state.tableData
          .map((item, index) => {
            if (indexes.includes(index)) {
              return item.id;
            }
            return null;
          })
          .filter(id => !!id);

        if (!preDeleteCheck) {
          await confirm({
            description: `Are you sure you want to ${
              archivable ? 'archive' : 'delete'
            } these ${endpoint}s?`
          });
        } else {
          await preDeleteCheck(ids);
        }

        await Promise.all(
          ids.map(id => {
            if (archivable) {
              return archiveById(endpoint, { id });
            }
            return deleteById(endpoint, id);
          })
        );

        dispatch({ type: 'toggleRefresh' });
        return null;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  // Render the UI for your table
  return (
    <div style={{ position: 'relative' }}>
      <TableContainer>
        <TableToolbar
          tableInstance={tableInstance}
          endpoint={endpoint}
          setOpen={setOpen}
          noAdd={noAdd}
          linkTo={linkTo}
          numSelected={Object.keys(tableInstance.state.selectedRowIds).length}
          deleteRowHandler={deleteRowHandler}
          totalRecords={totalRecords}
          archivable={archivable}
        />

        <FilterChipBar instance={tableInstance} />
        <FullScreenLoading />

        <MaUTable {...tableInstance.getTableProps()}>
          {!state.isPlanTable && (
            <TableHead>
              {tableInstance.headerGroups.map(headerGroup => (
                <TableRow
                  {...headerGroup.getHeaderGroupProps()}
                  key={
                    headerGroup.id ??
                    headerGroup.headers.map(h => h.id ?? h.Header).join('-')
                  }
                >
                  {headerGroup.headers.map(column => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (column.hideToggleHide) {
                      return true;
                    }
                    if (
                      hideColumn({
                        archivable,
                        includeSoftDeleted,
                        columnId: column.id
                      })
                    ) {
                      return true;
                    }
                    return (
                      <TableCell
                        // key={column.id}
                        {...(column.id === 'selection'
                          ? column.getHeaderProps()
                          : column.getHeaderProps(
                              column.getSortByToggleProps()
                            ))}
                      >
                        {column.render('Header')}
                        {column.id !== 'actions' && column.id !== 'selection' && (
                          <TableSortLabel
                            active={column.isSorted}
                            // react-table has a unsorted state which is not treated here
                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
          )}
          <TableBody>
            {tableInstance.page.map(row => {
              tableInstance.prepareRow(row);
              const isSoftDeleted =
                includeSoftDeleted &&
                (row.values.deletedAt || row.values.archivedAt);
              return (
                <React.Fragment key={row.id}>
                  <TableRow
                    {...row.getRowProps()}
                    style={
                      isSoftDeleted
                        ? {
                            backgroundColor: 'var(--color-alert)'
                          }
                        : undefined
                    }
                  >
                    {row.cells.map(cell => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      if (cell.column.hideToggleHide) {
                        return true;
                      }
                      if (
                        hideColumn({
                          archivable,
                          includeSoftDeleted,
                          columnId: cell.column.id
                        })
                      ) {
                        return null;
                      }
                      return (
                        <TableCell
                          {...cell.getCellProps([
                            {
                              style: {
                                wordWrap: 'break-word',
                                ...(isSoftDeleted ? { color: 'white' } : {})
                              }
                            }
                          ])}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {row.isExpanded ? (
                    <TableRow>
                      <TableCell colSpan={tableInstance.visibleColumns.length}>
                        {renderRowSubComponent &&
                          renderRowSubComponent({ row })}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </React.Fragment>
              );
            })}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ borderBottom: 'none' }}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={999}
                count={totalRecords}
                rowsPerPage={+tableInstance.state.pageSize}
                page={+tableInstance.state.pageIndex}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </MaUTable>
      </TableContainer>
    </div>
  );
};

const EnhancedTable: React.FC<TableParams> = ({
  endpoint,
  columns,
  ...props
}) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { loadingFilters, searchParamFilters } = useTableQueryParameters(
    searchParams,
    columns
  );
  if (loadingFilters) {
    return null;
  }

  const { additionalQueryParams, allowMerge = false } = props;
  const isPlanTable = endpoint === 'plan';

  const queryFilters = (searchParamFilters || additionalQueryParams) && [
    ...(searchParamFilters ?? []),
    ...(additionalQueryParams ?? [])
  ];

  return (
    <TableProvider
      initialState={{
        canSelect: false,
        isPlanTable,
        allowMerge,
        tableData: [],
        includeSoftDeleted: false,
        filters: queryFilters
      }}
      endpoint={endpoint}
    >
      <WrappedTable
        {...props}
        columns={columns}
        endpoint={endpoint}
        additionalQueryParams={queryFilters}
      />
    </TableProvider>
  );
};

export default EnhancedTable;

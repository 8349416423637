import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import Link from '@mui/material/Link';

import { Input, Select } from '@mui/material';

import PaperWrapper from 'components/common/PaperWrapper';
import { Button } from 'uninfo-components';

import Api, { BASE_URL } from 'utils/Api';

import { getAuthHeader } from 'services/AuthService';

const Settings: React.FC = () => {
  const [path, setPath] = React.useState('');
  const [method, setMethod] = React.useState('GET');
  const { enqueueSnackbar } = useSnackbar();

  const onClick = async () => {
    await Api.post(
      `${BASE_URL}/endpointCache/clear`,
      {
        path,
        method
      },
      getAuthHeader()
    );
    enqueueSnackbar('Cache cleared', {
      variant: 'success',
      preventDuplicate: true
    });
  };

  return (
    <PaperWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Clear the following endpoint
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input
            value={path}
            placeholder="eg. 'plan'"
            onChange={val => {
              setPath(val.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            value={method}
            onChange={e => setMethod(e.target.value as string)}
          >
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
          </Select>
          <Button variant="outlined" onClick={onClick}>
            Clear Endpoint Cache
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Log in as user</Typography>
        </Grid>
        <Grid item xs={12}>
          <Link href="/dashboard/admin/tokentime">
            <Typography>Go to console →</Typography>
          </Link>
        </Grid>
      </Grid>
    </PaperWrapper>
  );
};

export default Settings;

export const linkColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    disableFilters: true,
    sortType: 'basic'
  }
];

export default {
  linkColumns
};

import { Checkbox, FormControlLabel, Popover, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(
  createStyles({
    columnsPopOver: {
      padding: 24
    },
    popoverTitle: {
      fontWeight: 500,
      padding: '0 24px 24px 0',
      textTransform: 'uppercase'
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 198px)',
      '@media (max-width: 600px)': {
        gridTemplateColumns: 'repeat(1, 160px)'
      },
      gridColumnGap: 6,
      gridRowGap: 6
    }
  })
);

const id = 'popover-column-hide';

interface Props {
  instance: any;
  anchorEl: any;
  onClose: () => void;
  show: boolean;
}

export default function ColumnHide({
  instance,
  anchorEl,
  onClose,
  show
}: Props) {
  const classes = useStyles({});
  const { allColumns, toggleHideColumn } = instance;
  const hideableColumns = allColumns.filter(
    (column: any) =>
      !(
        column.id === 'selection' ||
        column.id === 'actions' ||
        column.hideToggleHide
      )
  );
  const checkedCount = hideableColumns.reduce(
    (acc: number, val: any) => acc + (val.isVisible ? 0 : 1),
    0
  );

  const onlyOneOptionLeft = checkedCount + 1 >= hideableColumns.length;

  return hideableColumns.length > 1 ? (
    <div>
      <Popover
        anchorEl={anchorEl}
        className={classes.columnsPopOver}
        id={id}
        onClose={onClose}
        open={show}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className={classes.columnsPopOver}>
          <Typography className={classes.popoverTitle}>
            Visible Columns
          </Typography>
          <div className={classes.grid}>
            {hideableColumns.map((column: any) => {
              return (
                <FormControlLabel
                  key={column.id}
                  control={
                    <Checkbox
                      value={`${column.id}`}
                      disabled={column.isVisible && onlyOneOptionLeft}
                    />
                  }
                  label={column.render('Header')}
                  checked={column.isVisible}
                  onChange={() => toggleHideColumn(column.id, column.isVisible)}
                />
              );
            })}
          </div>
        </div>
      </Popover>
    </div>
  ) : null;
}

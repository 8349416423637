/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, {
  useCallback,
  useState,
  useGlobal,
  useEffect,
  setGlobal
} from 'reactn';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { Checkbox } from 'components/common/form/Checkbox';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Autocomplete } from 'components/common/AutoComplete';
import PermissionGate from 'components/common/PermissionGate';

import { fetchOrganizations } from 'services/OrganizationService';
import {
  fetchUserById,
  editUser,
  fetchProfile,
  deleteUser as deleteUserRequest
} from 'services/UserService';

import { Button, CircularProgress } from 'uninfo-components';

import FormError from 'components/common/form/FormError';
import { Select } from 'components/common/form/Select';
import { isExisting } from 'utils/typeguards';
import Switch from '@mui/material/Switch';

import { Theme } from '@mui/material';

import { useConfirm } from 'material-ui-confirm';
import { Trans, useTranslation } from 'react-i18next';

import { useTableRefreshContext } from 'components/table/TableRefreshProvider';

import { fetchWorkspaces } from 'services/WorkspaceService';
import { UserRolesForm } from './UserRolesForm';

const useUserFormStyles = makeStyles((theme: Theme) => ({
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  saveButton: {
    marginTop: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(0, 1),
    color: 'black'
  }
}));

const userStatusOptions = [
  {
    label: 'Registered',
    value: 'registered'
  },
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Deactivated',
    value: 'deactivated'
  }
];

interface UserFormProps {
  user: User;
  refresh: any;
  onClose?: () => void;
}
export const UserForm: React.FC<UserFormProps> = ({
  user,
  refresh,
  onClose
}) => {
  const { t } = useTranslation();
  const confirm = useConfirm();

  const [loading, setLoading] = useState(false);
  const [loggedInUser] = useGlobal('user');

  const classes = useUserFormStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { dispatch: dispatchRefresh } = useTableRefreshContext();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: user.email ?? null,
      firstname: user.firstname ?? null,
      lastname: user.lastname ?? null,
      username: user.username ?? null,
      isDmo: user.isDmo ?? false,
      organization: {
        id: user.organization?.id,
        name: user.organization?.name,
        abbreviation: user.organization?.abbreviation
      },
      status: user.status,
      position: user.position ?? null
    }
  });

  const { handleSubmit, control, errors, setValue } = methods;

  const fetchedOrganizations = useCallback(async inputValue => {
    return fetchOrganizations({
      q: inputValue,
      exclude: [
        'agencyCells',
        'contributingPartnerCells',
        'implementingPartnerCells',
        'countries',
        'organizationCountries',
        'organizationType',
        'children',
        'occupants',
        'workspaces'
      ]
    });
  }, []);

  const deleteUser = async (data: any) => {
    if (!loading) {
      try {
        await confirm({
          title: `Are you sure you want to delete this user (${user.username})?`,
          description: (
            <>
              <Trans
                i18nKey="removeUser"
                values={{
                  name: user.username
                }}
              >
                This will delete <strong>{user.username}</strong>
              </Trans>
              <br />
              <br />
              This action is not reversible
            </>
          )
        });
        setLoading(true);

        await deleteUserRequest(user.id);
        // await refresh();
        dispatchRefresh({ type: 'setShouldRefresh', shouldRefresh: true });

        enqueueSnackbar('User deleted', {
          variant: 'success',
          autoHideDuration: 3000,
          preventDuplicate: true
        });
        setLoading(false);
        onClose?.();
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }
  };

  const save = async (data: any) => {
    if (!loading) {
      try {
        await confirm({
          title: `Please confirm the update of this user (${user.username})?`,
          description: (
            <>
              <Trans
                i18nKey="addUserRole"
                values={{
                  name: user.username
                }}
              >
                This will update <strong>{user.username}</strong>
              </Trans>
              <br />
            </>
          )
        });
        setLoading(true);

        const localItem = data;
        localItem.id = user.id;
        localItem.organizationId = localItem.organization?.id;
        await editUser(localItem);
        // await refresh();

        enqueueSnackbar('User updated', {
          variant: 'success',
          autoHideDuration: 3000,
          preventDuplicate: true
        });

        setLoading(false);
        dispatchRefresh({ type: 'setShouldRefresh', shouldRefresh: true });
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {user?.id && (
        <>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(save)}>
              <fieldset disabled={false}>
                <Grid sx={{ marginTop: '0.3rem' }} container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="firstname"
                      as={TextField}
                      control={control}
                      rules={{ required: true }}
                      fullWidth
                      placeholder="First Name"
                      required
                      label="First Name"
                      variant="outlined"
                      size="small"
                    />
                    <FormError errors={errors} name="firstname" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="lastname"
                      as={TextField}
                      control={control}
                      rules={{ required: true }}
                      fullWidth
                      placeholder="Last Name"
                      required
                      label="Last Name"
                      variant="outlined"
                      size="small"
                    />
                    <FormError errors={errors} name="lastname" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="email"
                      as={TextField}
                      control={control}
                      rules={{
                        pattern: {
                          message: 'Invalid email address',
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                        },
                        required: true
                      }}
                      fullWidth
                      placeholder="Email"
                      required
                      label="Email"
                      variant="outlined"
                      size="small"
                    />
                    <FormError errors={errors} name="email" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="position"
                      as={TextField}
                      control={control}
                      fullWidth
                      label="Position"
                      placeholder="Position"
                      variant="outlined"
                      size="small"
                    />
                    <FormError errors={errors} name="position" />
                  </Grid>
                  {loggedInUser?.isAdmin && (
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="username"
                        as={TextField}
                        control={control}
                        fullWidth
                        label="Username"
                        rules={{
                          required: true,
                          pattern: {
                            message: 'Invalid username',
                            value: /^[-a-zA-Z0-9_.]{3,50}$/
                          }
                        }}
                        placeholder="Username"
                        variant="outlined"
                        size="small"
                      />
                      <FormError
                        errors={errors}
                        name="username"
                        message="Usernames must be between 3 and 30 characters and not contain spaces"
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="organization"
                      control={control}
                      // onChange={([, value]: OrganizationType[]) => value}
                      render={({ onChange, ...props }) => (
                        <Autocomplete
                          fetchOptionsCallback={fetchedOrganizations}
                          onChange={(_event: any, data: OrganizationType) => {
                            onChange(data);
                          }}
                          fullWidth
                          label="Organization"
                          required
                          {...props}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </Grid>

                  <PermissionGate scopes="user_update">
                    <Grid item xs={12} sm={6}>
                      <Select
                        name="status"
                        label="User Status"
                        options={userStatusOptions}
                      />
                    </Grid>
                  </PermissionGate>
                  <Grid item xs={12} sm={6}>
                    {/* <Controller
                    name="isDmo"
                    control={control}
                    label="Is DMO"
                    render={({ onChange, value, ...props }) => (
                      <>
                        Is DMO
                        <Switch
                          checked={value}
                          onChange={() => setValue('isDmo', !value)}
                          value="isDmo"
                          inputProps={{
                            'aria-label': 'is the user DMO?'
                          }}
                        />
                      </>
                    )}
                  /> */}
                    <Checkbox
                      name="isDmo"
                      label="Is DMO?"
                      moreInfo="is the user DMO?"
                    />
                    <FormError errors={errors} name="isDmo" />
                  </Grid>
                  <PermissionGate scopes="user_update">
                    <Button
                      type="submit"
                      disabled={!isEmpty(errors)}
                      isLoading={loading}
                      variant="contained"
                      color="primary"
                      className={classes.saveButton}
                    >
                      Save
                    </Button>
                  </PermissionGate>
                  <PermissionGate scopes="user_softDelete">
                    <Button
                      onClick={deleteUser}
                      color="primary"
                      variant="outlined"
                      className={classes.saveButton}
                    >
                      Delete
                    </Button>
                  </PermissionGate>
                </Grid>
              </fieldset>
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
};

export const getRegionWorkspaceMap = async () => {
  const workspaces = await fetchWorkspaces({
    globalFilter: '',
    exclude: [
      'contacts',
      'region',
      'countries',
      'organizations',
      'workspaceType',
      'parent',
      'children'
    ]
  });
  const regionWorkspaceMap =
    workspaces?.reduce((a, v) => {
      if (!a[v.regionId]) {
        return { ...a, [v.regionId]: [v.id] };
      }
      return { ...a, [v.regionId]: [...a[v.regionId], v.id] };
    }, {} as { [key: number]: number[] }) ?? {};
  return regionWorkspaceMap;
};

interface AdminUserBuilder {
  id: string | number;
  onClose?: () => void;
}
const AdminUserBuilder: React.FC<AdminUserBuilder> = ({
  id,
  onClose
}: AdminUserBuilder): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [isViewPermitted, setViewPermitted] = useState(false);
  const [user, setUser] = useState<User>();
  // TODO: use just user.userRoles when API stabilizes
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);

  const fetchCb = useCallback(async () => {
    try {
      setLoading(true);
      const userData = await fetchUserById(+id, true);
      setUser(userData);
      setUserRoles(userData.userRoles ?? []);

      // TODO: move it to the table level, there is no need to get/set each time here
      const { data: globalUser } = await fetchProfile();
      setGlobal({ user: globalUser });

      if (
        globalUser.isAdmin ||
        !!globalUser.userRoles.find(
          ur =>
            !ur.contextRegionId &&
            !ur.contextWorkspaceId &&
            !!ur.role.rolePermissions.find(
              rP =>
                rP.permission?.key &&
                [
                  'user_update',
                  'userRole_assign',
                  'userRole_batchAssign'
                ].includes(rP.permission?.key)
            )
        )
      ) {
        setViewPermitted(true);
      } else {
        let userWorkspaceIds = userData.userRoles
          .map(ur => ur.contextWorkspaceId)
          .filter(n => n);
        const userRegionIds = userData.userRoles
          .map(ur => ur.contextRegionId)
          .filter(n => n);
        if (userRegionIds.length) {
          const regionWorkspaceMap = await getRegionWorkspaceMap();
          userWorkspaceIds = [
            ...new Set(
              userRegionIds
                .flatMap(r => r && regionWorkspaceMap[r])
                .concat(userWorkspaceIds)
            )
          ];
        }
        const isContextFound = globalUser.userRoles.find(
          ur =>
            (userWorkspaceIds.includes(ur.contextWorkspaceId) ||
              userRegionIds.includes(ur.contextRegionId)) &&
            !!ur.role.rolePermissions.find(
              rP =>
                rP.permission?.key &&
                [
                  'user_update',
                  'userRole_assign',
                  'userRole_batchAssign'
                ].includes(rP.permission?.key)
            )
        );
        if (isContextFound) {
          setViewPermitted(true);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }, [id]);

  React.useEffect(() => {
    fetchCb();
  }, [fetchCb]);

  return (
    <>
      {!loading && isViewPermitted && isExisting(user) && (
        <>
          <UserForm user={user} refresh={fetchCb} onClose={onClose} />
          <UserRolesForm user={user} roles={userRoles} />
        </>
      )}
      {loading && <CircularProgress />}
      {!loading && !isViewPermitted && (
        <Typography>View not permitted</Typography>
      )}
    </>
  );
};

export default AdminUserBuilder;

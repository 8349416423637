import settings from 'config';
import Api from '../utils/Api';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;

const WORKSPACE_API_BASE_URL = `${BASE_URL}/workspace/`;
const REGION_API_BASE_URL = `${BASE_URL}/region/`;
const WORKSPACE_ORGANIZATION_API_BASE_URL = `${BASE_URL}/workspaceOrganization/`;

export const addWorkspace = (workspace: Partial<Workspace>) => {
  return Api.post(`${WORKSPACE_API_BASE_URL}`, workspace, getAuthHeader());
};

export const editWorkspace = (workspace: Partial<Workspace>) => {
  return Api.put(
    `${WORKSPACE_API_BASE_URL}${workspace.id}`,
    workspace,
    getAuthHeader()
  ).then(res => res.data);
};

interface FetchWorkspacesOptions {
  globalFilter?: string;
  page?: number;
  limit?: number;
  workspaceIds?: (number | null)[] | null;
  regionIds?: (number | null)[] | null;
  parentIds?: number[];
  exclude?: any[];
}

export const fetchWorkspaces = async (
  options?: FetchWorkspacesOptions
): Promise<Workspace[]> => {
  const data = {
    q: options?.globalFilter ?? null,
    page: options?.page ?? null,
    workspaceIds: options?.workspaceIds ?? null,
    regionIds: options?.regionIds ?? null,
    parentIds: options?.parentIds ?? null,
    limit: options?.limit ?? 500,
    sort: [['name', 'ASC']],
    exclude: options?.exclude ?? null
  };

  const res = await Api.post(
    `${WORKSPACE_API_BASE_URL}search`,
    data,
    getAuthHeader()
  );
  return res.data.results;
};

export const fetchWorkspaceById = async (
  workspaceId: number | string
): Promise<Workspace> => {
  return Api.get(
    `${WORKSPACE_API_BASE_URL}${workspaceId}`,
    getAuthHeader()
  ).then(res => res.data);
};

export const fetchWorkspacesForUser = async (user: User): Promise<any> => {
  const workspaceIds = user.userRoles.map(uR => uR.contextWorkspaceId);
  const data = {
    workspaceIds: workspaceIds ?? null,
    limit: 500,
    sort: [['name', 'ASC']]
  };

  return Api.post(
    `${WORKSPACE_API_BASE_URL}search`,
    data,
    getAuthHeader()
  ).then(res => res.data.results);
};

interface FetchRegionsOptions {
  globalFilter: string;
  page?: number;
  limit?: number;
  regionIds?: number[];
}

export const fetchRegions = async (
  options: FetchRegionsOptions
): Promise<Region[]> => {
  const data = {
    q: options?.globalFilter ?? null,
    page: options?.page ?? null,
    regionIds: options?.regionIds ?? null,
    limit: options?.limit ?? 500,
    sort: [['name', 'ASC']]
  };

  const res = await Api.post(
    `${REGION_API_BASE_URL}search`,
    data,
    getAuthHeader()
  );
  return res.data.results;
};

interface FetchWorkspaceOrgOptions extends APIQueryOptions {
  page?: number;
  limit?: number;
  organizationIds?: number[];
  workspaceIds?: number[];
}

export const fetchWorkspaceOrganizations = async (
  options: FetchWorkspaceOrgOptions
): Promise<WorkspaceOrganization[]> => {
  const data = {
    ...options,
    limit: options?.limit ?? 500,
    sort: [['organization.name', 'ASC']]
  };

  const res = await Api.post(
    `${WORKSPACE_ORGANIZATION_API_BASE_URL}search`,
    data,
    getAuthHeader()
  );
  return res.data.results;
};

export const updateWorkspaceOrganization = async (
  id: number,
  workspaceOrganization: Partial<WorkspaceOrganization>
): Promise<WorkspaceOrganization> => {
  const res = await Api.put(
    `${WORKSPACE_ORGANIZATION_API_BASE_URL}${id}`,
    workspaceOrganization,
    getAuthHeader()
  );
  return res.data;
};

export const createWorkspaceOrganization = async (
  workspaceOrganization: Partial<WorkspaceOrganization>
): Promise<WorkspaceOrganization> => {
  const res = await Api.post(
    `${WORKSPACE_ORGANIZATION_API_BASE_URL}`,
    workspaceOrganization,
    getAuthHeader()
  );
  return res.data;
};

export const deleteWorkspaceOrganization = async (
  id: number
): Promise<void> => {
  const res = await Api.delete(
    `${WORKSPACE_ORGANIZATION_API_BASE_URL}${id}`,
    getAuthHeader()
  );
  return res.data;
};

export const getRegionWorkspaceMap = async () => {
  const workspaces = await fetchWorkspaces({
    globalFilter: '',
    exclude: [
      'contacts',
      'region',
      'countries',
      'organizations',
      'workspaceType',
      'parent',
      'children'
    ]
  });
  const regionWorkspaceMap =
    workspaces?.reduce((a, v) => {
      if (!a[v.regionId]) {
        return { ...a, [v.regionId]: [v.id] };
      }
      return { ...a, [v.regionId]: [...a[v.regionId], v.id] };
    }, {} as { [key: number]: number[] }) ?? {};
  return regionWorkspaceMap;
};

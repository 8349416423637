import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Theme, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useForm, FormProvider } from 'react-hook-form';

import { Button } from 'uninfo-components';
import {
  batchCreateOrganizations,
  fetchOrganizations,
  fetchOrganizationTypes
} from 'services/OrganizationService';
import { fetchCountries } from 'services/LocationService';

import Table from 'components/table/TableWrapper';
import Check from '@mui/icons-material/Check';
import Warning from '@mui/icons-material/Warning';
import BulkCSVUpload, {
  mapCSVDataToJson
} from 'components/common/BulkCSVUpload';

const allowedBlanks = ['parent_id', 'country'];

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& .upload': {
      background: `${theme.palette.success.main} !important`,
      color: 'white'
    },
    '& .conflict': {
      background: `${theme.palette.warning.main} !important`,
      color: 'white'
    }
  }
}));

export const BulkOrganizationForm: React.FC = () => {
  const methods = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [titles, setTitles] = React.useState<string[]>([]);
  const [imports, setImports] = React.useState<any[]>([]);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [organizationTypes, setOrganizationTypes] = React.useState<
    OrganizationType[]
  >([]);
  const classes = useStyles();

  React.useEffect(() => {
    const callback = async () => {
      try {
        const results = await fetchOrganizationTypes();
        setOrganizationTypes(results);

        const countryResults = await fetchCountries({
          limit: 300
        });
        setCountries(countryResults);
      } catch (e) {
        console.error(e);
      }
    };

    callback();
  }, []);

  const handleOnFileLoad = async (data: { data: string[] }[]) => {
    setTitles(data[0].data);
    const titleData = data.shift();
    setTitles(titleData?.data ?? []);
    if (titleData) {
      const tempImports = mapCSVDataToJson(data, titleData, allowedBlanks);

      const promises = tempImports
        .filter(i => i.organization_name && i.name !== '')
        .map(async i => {
          const orgs = await fetchOrganizations({
            q: i.organization_name as string
          });

          i.existingMatches = orgs;
          i.type = organizationTypes.find(oT => oT.name === i.type);
          if (i.country && i.country !== '') {
            const relatedCountry = countries.find(
              c => c.name?.toLowerCase() === i.country.toLowerCase()
            );
            i.country = {
              id: relatedCountry?.id ?? null,
              name: relatedCountry?.name ?? i.country
            };
          }

          if (i.existingMatches?.length > 0) {
            const hasSameCountry = i.existingMatches.find((e: Organization) =>
              e.countries?.find((c: Country) => c.id === i.country)
            );
            const hasSameAbbreviation = i.existingMatches.find(
              (e: Organization) => e.abbreviation === i.abbreviation
            );
            let hasSameType;
            if (i.type) {
              hasSameType = i.existingMatches?.find(
                (e: Organization) => e.typeId === i.type.id
              );
            }
            const hasSameParentId = i.existingMatches?.find(
              (e: Organization) => e.parentId === i.parent_id
            );

            if (
              hasSameCountry &&
              hasSameAbbreviation &&
              hasSameType &&
              hasSameParentId
            ) {
              i.status = 'exists';
            } else {
              i.status = 'conflict';
              i.statusConflict = {
                hasSameCountry,
                hasSameAbbreviation,
                hasSameType,
                hasSameParentId
              };
            }
          }
          return i;
        });
      const results = await Promise.all(promises);
      setImports(results);
    }
  };

  const importReady = imports.filter(i => i.status === 'upload');

  const handleUpload = async () => {
    setLoading(true);
    try {
      await batchCreateOrganizations(
        importReady.map(o => ({
          name: o.organization_name,
          abbreviation: o.abbreviation,
          parentId: o.parent_id !== '' ? +o.parent_id : null,
          organizationCountries: o.country
            ? [{ countryId: o.country?.id }]
            : [],
          typeId: o.type.id,
          status: 'active'
        }))
      );
      enqueueSnackbar(`Created organizations`, {
        variant: 'success',
        autoHideDuration: 3000,
        preventDuplicate: true
      });
      setImports([]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BulkCSVUpload
            setImports={setImports}
            handleOnFileLoad={handleOnFileLoad}
          />
        </Grid>
        {imports && imports.length > 0 && (
          <Grid item xs={12}>
            <Table className={classes.table}>
              <thead>
                <tr>
                  {titles.map(t => (
                    <th key={t}>{t}</th>
                  ))}
                  <th style={{ textAlign: 'right' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {imports.map(i => (
                  <tr key={JSON.stringify(i)} className={i.status}>
                    {titles.map(t => (
                      <>
                        <td key={t}>
                          {i[t]?.name ? `${i[t].name} (id: ${i[t].id})` : i[t]}
                        </td>
                      </>
                    ))}
                    <td style={{ textAlign: 'right' }}>
                      {i.status === 'upload' && <>Will upload</>}
                      {i.status === 'exists' && (
                        <>
                          <Check style={{ verticalAlign: 'middle' }} /> Name
                          Exists, Won&lsquo;t Upload
                        </>
                      )}
                      {i.status === 'conflict' && (
                        <Tooltip
                          title={`Conflict with ${i.existingMatches
                            .map((o: Organization) => `${o.id}: ${o.name}`)
                            .join(', ')}`}
                        >
                          <div>
                            <Warning style={{ verticalAlign: 'middle' }} />{' '}
                            Conflict with existing org
                          </div>
                        </Tooltip>
                      )}
                      {i.status === 'missingData' && (
                        <>
                          <Warning style={{ verticalAlign: 'middle' }} />{' '}
                          Missing {i.missing?.join(', ')} Info
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Grid>
        )}
        {importReady && importReady.length > 0 && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUpload()}
              isLoading={loading}
            >
              Import Green Rows
            </Button>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
};

export default BulkOrganizationForm;

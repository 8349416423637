import React from 'react';
import { useSnackbar } from 'notistack';

import { TextField } from 'components/common/form/TextField';
import KeyboardDatePicker from 'components/common/KeyboardDatePicker';
import { Controller, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Autocomplete } from 'components/common/AutoComplete';
import { autocomplete } from 'services/AutocompleteService';
import { keyBy } from 'lodash';

import { MonetaryFormatCustom } from 'components/common/form/NumberFormat';
import {
  FormControlLabel,
  InputBaseComponentProps,
  Radio,
  Typography,
  RadioGroup
} from '@mui/material';
import { fetchOrganizations } from 'services/OrganizationService';
import { AGENCY_TYPE_ID } from 'utils/constants';
import { Button } from 'uninfo-components';
import Checkbox from 'components/common/form/Checkbox';
import PermissionGate from 'components/common/PermissionGate';

import {
  SurveyEvaluationResponse,
  SurveyFundingResponse,
  SurveyPreparationResponse,
  SurveySignatoryResponse
} from 'services/PlanSurveyService';
import { Question } from './utils';

export type SurveyVersion =
  | SurveyEvaluationResponse['surveyEvaluationVersion']
  | SurveyPreparationResponse['surveyPreparationVersion']
  | SurveyFundingResponse['surveyFundingVersion']
  | SurveySignatoryResponse['surveySignatoryVersion'];

const errorMap: { [key: string]: string } = {
  requiredOrNone: 'Either choose a response or mark question as None',
  notBoth: "Question can't have both a response and be marked none",
  required: 'This question is required'
};

const QuestionRow: React.FC<{
  question: Question;
  dependsOnQuestion?: Question;
  surveyVersion: SurveyVersion;
}> = ({ question, surveyVersion, dependsOnQuestion }) => {
  const { setValue, watch, getValues, errors } = useFormContext();
  const dependsOn = question.dependsOn ? watch(question.dependsOn) : undefined;
  // Not sure this is scalable. Must be a better solution.
  const parentQuestionVisible =
    dependsOnQuestion?.dependsOnFnc && dependsOnQuestion?.dependsOn
      ? dependsOnQuestion.dependsOnFnc(watch(dependsOnQuestion.dependsOn))
      : undefined;
  const dependsOnFalsey = !dependsOn || dependsOn === 'false';

  const fetchedDataitem = React.useCallback(async (inputValue, id: number) => {
    return autocomplete('dataitem', {
      parentId: id,
      simple: true,
      q: inputValue
    });
  }, []);

  const fetchAgencies = React.useCallback(async inputValue => {
    return fetchOrganizations({
      q: inputValue,
      typeIds: [AGENCY_TYPE_ID],
      limit: 20
    });
  }, []);

  React.useEffect(() => {
    if (dependsOn) {
      // if the question this one depends on is changed so that
      // this question would no longer be shown, we should set
      // the value of this question to null
      if (
        (dependsOnFalsey && !question.dependsOnFnc) ||
        (question.dependsOnFnc && !question.dependsOnFnc(dependsOn))
      ) {
        setValue(question.slug, question.multiple ? [] : null);
      }
    }
  }, [dependsOn, dependsOnFalsey, question, setValue, getValues]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(`${event.target.name}`, event.target.value);
  };

  if (
    parentQuestionVisible === false ||
    (question.dependsOn && dependsOnFalsey && !question.dependsOnFnc) ||
    (question.dependsOn &&
      question.dependsOnFnc &&
      !question.dependsOnFnc(dependsOn))
  ) {
    return null;
  }

  return (
    <tr>
      <td style={{ width: '50%', padding: '.5em 0' }}>
        {question.type === 'title' ? (
          <Typography variant="h2" style={{ marginTop: '2rem' }}>
            {question.question}
          </Typography>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: question.question }} />
        )}
        {question.description ? (
          <small
            style={{ display: 'block', fontSize: '.8rem' }}
            dangerouslySetInnerHTML={{ __html: question.description }}
          />
        ) : (
          ''
        )}
      </td>
      <td style={{ padding: '1rem', textAlign: 'right' }}>
        {question.type === 'textField' && (
          <TextField
            hiddenLabel
            label="Your response"
            fullWidth
            id="multiYearFundingFramework"
            size="small"
            name={question.slug}
            required={question.required}
          />
        )}
        {question.type === 'boolean' && (
          <Controller
            name={question.slug}
            // required={question.required}
            rules={{
              validate: {
                required: (d: unknown) => {
                  if (question.required) {
                    return d !== '' && d !== 'undefined';
                  }
                  return true;
                }
              }
            }}
            as={
              <RadioGroup
                aria-label="gender"
                style={{ flexDirection: 'row', justifyContent: 'flex-end' }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            }
          />
        )}
        {question.type === 'datetime' && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name={question.slug}
              render={({ ref, ...props }) => (
                <KeyboardDatePicker
                  {...props}
                  label="Pick date"
                  id={question.slug}
                  inputRef={ref}
                  required={question.required}
                />
              )}
            />
          </LocalizationProvider>
        )}
        {question.type === 'dataitem' && question.filterId && (
          <>
            <Controller
              name={question.slug}
              onChange={([, value]: DataItem[]) => value}
              render={({ onChange, ref, ...props }) => (
                <Autocomplete
                  fetchOptionsCallback={(e: unknown) =>
                    fetchedDataitem(e, question.filterId ?? 0)
                  }
                  onChange={(_event: unknown, data: DataItem) => onChange(data)}
                  fullWidth
                  label={question.placeholder ?? 'Start typing...'}
                  multiple={question.multiple}
                  {...props}
                />
              )}
              rules={{
                validate: {
                  requiredOrNone: (d: unknown[]) => {
                    if (question.multiple && question.allowNone) {
                      const noneValue = getValues(`${question.slug}None`);
                      if (d.length === 0 && question.allowNone) {
                        return noneValue;
                      }

                      if (question.allowNone && noneValue && d.length > 0) {
                        return false;
                      }

                      return d.length > 0;
                    }
                    return true;
                  },
                  required: (d: unknown) => {
                    if (
                      question.multiple &&
                      !question.allowNone &&
                      question.required
                    ) {
                      if (Array.isArray(d) && d.length === 0) {
                        return false;
                      }
                      return true;
                    }
                    return question.required ? !!d : true;
                  }
                }
              }}
            />
            {question.multiple && question.allowNone && (
              <Checkbox label="None" name={`${question.slug}None`} />
            )}
          </>
        )}
        {question.type === 'organization' && (
          <>
            <Controller
              name={question.slug}
              onChange={([, value]: Organization[]) => value}
              render={({ onChange, ref, ...props }) => (
                <Autocomplete
                  fetchOptionsCallback={(e: unknown) => fetchAgencies(e)}
                  onChange={(_event: unknown, data: DataItem) => onChange(data)}
                  fullWidth
                  label="Start typing..."
                  multiple={question.multiple}
                  // requireSearch
                  {...props}
                />
              )}
              rules={{
                validate: {
                  requiredOrNone: (d: unknown[]) => {
                    const noneValue = getValues(`${question.slug}None`);
                    if (d.length === 0 && question.allowNone) {
                      return !!noneValue;
                    }

                    return d.length > 0;
                  },
                  notBoth: (d: unknown[]) => {
                    const noneValue = getValues(`${question.slug}None`);

                    if (question.allowNone && noneValue && d.length > 0) {
                      return false;
                    }
                    return true;
                  }
                }
              }}
            />
            {question.multiple && question.allowNone && (
              <Checkbox
                label="No organizations"
                name={`${question.slug}None`}
                defaultValue={false}
              />
            )}
          </>
        )}
        {question.type === 'numberField' && (
          <TextField
            hiddenLabel
            label="Enter a number"
            name={question.slug}
            required={question.required}
            onChange={handleChange}
            InputProps={{
              inputComponent: (MonetaryFormatCustom as unknown) as React.FC<
                InputBaseComponentProps
              >,
              inputProps: {
                decimalScale: 0,
                style: { textAlign: 'right' }
              }
            }}
          />
        )}
        {surveyVersion && question.type === 'value' && (
          <>
            {question.valueType === 'boolean' && (
              <>
                {surveyVersion[question.slug] ? (
                  <span style={{ color: 'var(--color-sdg-blue' }}>Yes</span>
                ) : (
                  <span style={{ color: 'var(--color-alert)' }}>No</span>
                )}
              </>
            )}
            {question.valueType === 'number' && (
              <>{surveyVersion[question.slug]}</>
            )}
            {question.valueType === 'money' && (
              <>${(surveyVersion[question.slug] ?? 0)?.toLocaleString()}</>
            )}
          </>
        )}

        {errors[question.slug] && (
          <div
            style={{
              // marginTop: '.5rem',
              background: 'var(--color-alert)',
              color: 'white',
              fontWeight: 'bold',
              padding: '.25rem .5rem'
            }}
          >
            {errorMap[errors[question.slug].type]}
          </div>
        )}
        {question.Calculated && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <question.Calculated
              question={question}
              value={getValues(question.slug) ?? surveyVersion?.[question.slug]}
              surveyVersion={surveyVersion}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

export const QuestionTable: React.FC<{
  questions: Question[];
  surveyVersion: SurveyVersion;
}> = ({ questions, surveyVersion }) => {
  return (
    <table style={{ width: '100%' }}>
      <tbody>
        {questions.map(question => (
          <QuestionRow
            question={question}
            dependsOnQuestion={questions.find(
              q => q.slug === question.dependsOn
            )}
            key={question.slug}
            surveyVersion={surveyVersion}
          />
        ))}
      </tbody>
    </table>
  );
};

export const QuestionForm: React.FC<{
  questions: Question[];
  plan: Plan;
  disabled?: boolean;
  surveyVersion: SurveyVersion;
  onSubmit: (data: any) => Promise<void>;
}> = ({ questions, onSubmit, disabled, surveyVersion, plan }) => {
  const { enqueueSnackbar } = useSnackbar();
  const questionsMapped = keyBy(questions, 'slug');

  const onError = React.useCallback(
    err => {
      enqueueSnackbar(
        <div style={{ flexDirection: 'column' }}>
          There was an error:
          <br />
          {Object.keys(err).map(errKey => (
            <div key={errKey}>
              <span
                dangerouslySetInnerHTML={{
                  __html: questionsMapped[errKey].question
                }}
              />
              : {errorMap[err[errKey].type]}
            </div>
          ))}
        </div>,
        {
          variant: 'error',
          autoHideDuration: 5000,
          preventDuplicate: true
        }
      );
    },
    [enqueueSnackbar, questionsMapped]
  );
  const methods = useFormContext();

  return (
    <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
      <fieldset disabled={disabled}>
        <QuestionTable questions={questions} surveyVersion={surveyVersion} />
        <PermissionGate
          scopes={['planSurvey_create', 'planSurvey_update']}
          plan={plan}
        >
          <div style={{ textAlign: 'right' }}>
            <Button type="submit" disabled={disabled}>
              Save
            </Button>
          </div>
        </PermissionGate>
      </fieldset>
    </form>
  );
};

export default QuestionForm;

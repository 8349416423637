import React, { useGlobal } from 'reactn';
import RouteWrapper from 'routes';
import ErrorBoundary from 'components/layout/ErrorBoundary';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import theme from 'theme';
import SessionExpirePopUp from 'components/dialogs/SessionExpirePopUp';
import { isEmpty } from 'lodash';
import { Theme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface Props {
  user: User | undefined;
}
const useStyles = makeStyles((lTheme: Theme) => ({
  root: {
    display: 'flex'
  },
  splash: {
    flexGrow: 1,
    '& > div': {
      padding: 0
    }
  },
  loggedIn: {
    flexGrow: 1,
    paddingTop: lTheme.spacing(3),
    paddingLeft: lTheme.spacing(3)
  },
  grid: {
    paddingTop: 105,
    paddingRight: 30,
    backgroundColor: 'var(--color-main-background)',
    paddingLeft: 240,
    alignContent: 'flex-start',
    flexGrow: 1,
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 20px)'
    }
  },
  loadingState: {
    opacity: 0.05
  }
}));

const MainBody: React.FC<Props> = ({ user }: Props) => {
  const [drawer] = useGlobal('drawer');
  const classes = useStyles();
  const loggedInPadding = isEmpty(user) ? 0 : 80;
  const { pathname } = useLocation();
  return (
    <main className={!isEmpty(user) ? classes.loggedIn : classes.splash}>
      <Helmet>
        <title>UN Info Workspaces {pathname.split('/').join(' - ')}</title>
      </Helmet>
      <Grid
        style={{
          paddingLeft:
            drawer === 'full' && !isEmpty(user) ? 250 : loggedInPadding
        }}
        container
        className={classes.grid}
      >
        <SessionExpirePopUp />
        <ErrorBoundary>
          <RouteWrapper />
        </ErrorBoundary>
      </Grid>
    </main>
  );
};

export default MainBody;

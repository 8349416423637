import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { useGlobal } from 'reactn';
import useLoadingAnimation from 'utils/useLoadingAnimation';
import _ from 'lodash';

export interface Filter {
  id: string;
  value:
    | number
    | string
    | number[]
    | {
        id: number;
        name?: string;
      }[]
    | null;
}

interface State {
  canSelect?: boolean;
  isPlanTable?: boolean;
  filters?: Filter[];
  tableData?: any[];
  queryString?: string;
  refresh?: boolean;
  includeSoftDeleted?: boolean;
  allowMerge?: boolean;
  allRowsSelected?: boolean;
}

type SetFilters = {
  type: 'setFilters';
  filters: Filter[];
};

type ToggleCanSelectAction = {
  type: 'toggleCanSelect';
};

type ToggleAllRowsSelected = {
  type: 'toggleAllRowsSelected';
};

type SetAllRowsSelected = {
  type: 'setAllRowsSelected';
  allRowsSelected: boolean;
};

type SetCanSelectAction = {
  type: 'setCanSelect';
  canSelect: boolean;
};

type SetTableDataAction = {
  type: 'setTableData';
  tableData: any[];
};

type SetQueryString = {
  type: 'setQueryString';
  queryString: string;
};

type ToggleRefresh = {
  type: 'toggleRefresh';
};

type ToggleIncludeSoftDeleted = {
  type: 'toggleIncludeSoftDeleted';
};

type Actions =
  | ToggleCanSelectAction
  | SetCanSelectAction
  | SetTableDataAction
  | ToggleRefresh
  | SetFilters
  | ToggleIncludeSoftDeleted
  | ToggleAllRowsSelected
  | SetAllRowsSelected
  | SetQueryString;

const tableReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'toggleCanSelect':
      return {
        ...state,
        canSelect: !state.canSelect
      };
    case 'toggleIncludeSoftDeleted':
      return {
        ...state,
        includeSoftDeleted: !state.includeSoftDeleted
      };
    case 'setCanSelect':
      return {
        ...state,
        canSelect: action.canSelect
      };
    case 'setTableData':
      return {
        ...state,
        tableData: action.tableData
      };
    case 'toggleRefresh':
      return {
        ...state,
        refresh: !state.refresh
      };
    case 'setFilters':
      if (!_.isEqual(state.filters, action.filters)) {
        return {
          ...state,
          filters: action.filters
        };
      }
      return state;

    case 'toggleAllRowsSelected':
      return {
        ...state,
        allRowsSelected: !state.allRowsSelected
      };

    case 'setAllRowsSelected':
      return {
        ...state,
        allRowsSelected: action.allRowsSelected
      };

    case 'setQueryString':
      return {
        ...state,
        queryString: action.queryString
      };
    default:
      return state;
  }
};

const TableContext = createContext({} as [State, React.Dispatch<Actions>]);

interface TableProviderProps {
  initialState: State;
  endpoint?: string;
}

export const TableProvider: React.FC<TableProviderProps> = ({
  children,
  initialState = {
    canSelect: false,
    tableData: []
  },
  endpoint
}) => {
  const [sessionTablePreferences] = useGlobal('sessionTablePreferences');
  const [state, dispatch] = useReducer(tableReducer, {
    ...initialState,
    ...(endpoint && sessionTablePreferences?.[endpoint]?.queryString
      ? { queryString: sessionTablePreferences?.[endpoint]?.queryString }
      : {})
  });

  useEffect(() => {
    dispatch({
      type: 'setFilters',
      filters: [...(initialState.filters ?? [])]
    });
  }, [initialState.filters]);

  return (
    <TableContext.Provider value={[state, dispatch]}>
      {children}
    </TableContext.Provider>
  );
};

export const useTableContext = () => {
  const loadingContext = useLoadingAnimation();
  const [state, dispatch] = useContext(TableContext);
  return { state, dispatch, loading: loadingContext };
};

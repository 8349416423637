import React from 'react';
import { TextField, Theme } from '@mui/material';
import { Button } from 'uninfo-components';

import { makeStyles } from '@mui/styles';

export const useAuthenticationStyles = makeStyles((theme: Theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export const SubmitButton: React.FC = ({ children }) => {
  const classes = useAuthenticationStyles();
  return (
    <Button
      type="submit"
      name="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
    >
      {children}
    </Button>
  );
};

interface EmailInputProps {
  email: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const EmailInput: React.FC<EmailInputProps> = ({ email, onChange }) => {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      required
      id="email"
      label="Email / Username"
      name="email"
      autoComplete="email"
      autoFocus
      placeholder="Enter your e-mail or username"
      value={email}
      onChange={onChange}
      InputLabelProps={{
        shrink: true
      }}
    />
  );
};

interface PasswordInputProps {
  password: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  label?: string;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  onChange,
  label = 'Password'
}) => {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      required
      name="password"
      label={label}
      type="password"
      id="password"
      autoComplete="current-password"
      placeholder="Enter your password"
      value={password}
      onChange={onChange}
      InputLabelProps={{
        shrink: true
      }}
    />
  );
};

export default {
  useAuthenticationStyles
};

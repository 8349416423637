import { cloneDeep, omit } from 'lodash';
import * as FileSaver from 'file-saver';
import Api from 'utils/Api';
import { BASE_URL, SUBOUTPUT_ENTITY_ID } from 'utils/constants';
import { getAuthHeader, getXlsxExportHeader } from './AuthService';

const PLAN_API_BASE_URL = `${BASE_URL}/plan/`;
const REPORT_API_BASE_URL = `${BASE_URL}/report/`;
const CP_API_BASE_URL = `${BASE_URL}/commonPremises/`;

export interface PlanFilters extends APIQueryOptions {
  blueprintIds?: number[];
  planTypeIds?: number[];
  workspaceIds?: number[];
  status?: 'inDev' | 'isPublished';
  sort?: Sort[];
  queryByBlueprint?: boolean;
}

export type Sort = [SortColumns, SortDirection];
type SortDirection = 'DESC' | 'ASC';
type SortColumns =
  | string
  | 'updatedAt'
  | 'id'
  | 'planVersion.startDate'
  | 'planVersion.name'
  | 'typeId';

// FIXME: type entity
export const addEntity = (entity: any, ePId: number) => {
  const formattedEntity = entity;
  formattedEntity.entityPrototypeId = ePId;
  return Api.post(
    `${PLAN_API_BASE_URL}entity`,
    formattedEntity,
    getAuthHeader()
  );
};

export const addPlan = (plan: Plan) => {
  const formattedPlan = plan;
  return Api.post(PLAN_API_BASE_URL, formattedPlan, getAuthHeader());
};

export const addPlanDocument = (options: {
  fileDocumentId: number;
  planId: number;
}) => {
  return Api.post(`${PLAN_API_BASE_URL}document`, options, getAuthHeader());
};

export const deletePlanDocument = (id: number): Promise<void> => {
  return Api.delete(`${PLAN_API_BASE_URL}document/${id}`, getAuthHeader());
};

export const addPlanTag = (planId: number) => {
  const tag = { planId: +planId, major: true };
  return Api.post(`${PLAN_API_BASE_URL}tag`, tag, getAuthHeader());
};

export const batchAddPlan = (plans: Plan[]) => {
  return Api.post(
    `${PLAN_API_BASE_URL}batchCreate`,
    { plans, blueprintId: plans[0].blueprintId },
    getAuthHeader()
  );
};

export const deleteEntity = (entityId: number) => {
  return Api.delete(`${PLAN_API_BASE_URL}entity/${entityId}`, getAuthHeader());
};

export const deletePlan = (planId: number) => {
  return Api.delete(`${PLAN_API_BASE_URL}${planId}`, getAuthHeader());
};

// FIXME: type entity
export const editEntity = (entity: any) => {
  const formattedEntity = entity;
  return Api.put(
    `${PLAN_API_BASE_URL}entity/${entity.id}`,
    formattedEntity,
    getAuthHeader()
  );
};

export const editPlan = (plan: Plan): Promise<void> => {
  const planToUpdate = cloneDeep(plan);
  return Api.put(
    `${PLAN_API_BASE_URL}${plan.id}`,
    omit(planToUpdate, [
      'workspace',
      'planType',
      'entityPrototypes',
      'planSubmissions'
    ]),
    getAuthHeader()
  );
};

export const bulkSubmitPlans = (
  planIds: number[],
  userId: number | null // Null means "unsubmitting"
): Promise<number[]> => {
  return Api.post(
    `${PLAN_API_BASE_URL}bulkSubmit`,
    {
      planIds,
      userId
    },
    getAuthHeader()
  ).then(res => res.data);
};

export const bulkTag = (planIds: number[]): Promise<number[]> => {
  return Api.post(
    `${PLAN_API_BASE_URL}bulkTag`,
    {
      planIds
    },
    getAuthHeader()
  ).then(res => res.data);
};

export const fetchUserAuditExport = (
  planId: string,
  entityPrototypeId: string
): Promise<any> => {
  const data = { entityPrototypeId, planId };
  return Api.post(
    `${REPORT_API_BASE_URL}userAudit/planEntity`,
    data,
    getAuthHeader()
  ).then(res => res.data);
};

interface EntityFilters extends APIQueryOptions {
  entityPrototypeIds?: number[];
  parentIds?: number[] | null;
  includeFieldData?: boolean;
  includeChildren?: boolean;
  includeNarrative?: boolean;
  coreentityTypeIds?: number[];
  agencyIds?: number[];
  planIds?: number[];
  exclude?: string[];
  planEntityIds?: number[];
  q?: string;
}

export const getPrivateEntityReport = async (planOptions: {
  planId?: number;
  coreentityTypeId?: number;
  sort?: any[];
  limit?: number | null;
  exclude?: string[];
}): Promise<PlanEntity[]> => {
  return Api.post(
    `${BASE_URL}/report/planEntity/${planOptions.coreentityTypeId}`,
    {
      exclude: [
        'children',
        'agencies',
        'planEntityLocations',
        'planEntityVersions'
      ],
      ...planOptions,
      limit: null,
      allPlans: true
    },
    getAuthHeader()
  ).then(res => res.data.results);
};

export const fetchEntities = (
  options: EntityFilters
): Promise<PlanEntity[]> => {
  const data: EntityFilters = { ...options };
  if (options) {
    data.limit = options.limit ?? 500;
    data.sort = options.sort || [['planEntityVersion.name', 'DESC']];
  }
  return Api.post(
    `${PLAN_API_BASE_URL}entity/search`,
    data,
    getAuthHeader()
  ).then(res => res.data.results);
};

export const fetchPlanById = (planId: number): Promise<Plan> => {
  const data = getAuthHeader();
  // FIXME: let's revert this once it's no longer needed.
  const cacheBustingDate = new Date();
  return Api.get(
    `${PLAN_API_BASE_URL}${planId}?cacheBust=${cacheBustingDate.getTime()}`,
    data
  ).then(res => res.data);
};

export const fetchPlanBaseEntities = async (planId: number) => {
  const res = await fetchPlanById(planId);
  const plan = res;
  const entityPrototypes = plan.entityPrototypes.sort((a, b) =>
    a.properties.step.id > b.properties.step.id ? 1 : -1
  );
  const socleEntities = entityPrototypes.filter(eP => eP.properties.step.socle);
  return {
    plan: res,
    socleEntities,
    entityPrototypes,
    workspaceId: res.workspaceId
  };
};

export const fetchPlanEntityAggregation = (
  entityId: number,
  metricTypes: string[] | undefined
): Promise<APISingleResult<{ results: any[]; aggregatedData: any[] }>> => {
  const data = {
    ...getAuthHeader(),
    params: {
      metricTypes
    }
  };
  return Api.get(`${PLAN_API_BASE_URL}entity/aggregation/${entityId}`, data);
};

export const fetchPlanEntityById = (entityId: number): Promise<PlanEntity> => {
  return Api.get(
    `${PLAN_API_BASE_URL}entity/${entityId}`,
    getAuthHeader()
  ).then(res => res.data);
};

export const fetchPlanExportLink = (
  planId: number,
  option?: { spreadsheet: boolean }
) => {
  const opts = option || {};
  return Api.post(
    `${REPORT_API_BASE_URL}planExport/${planId}`,
    { ...opts },
    getAuthHeader()
  ).then(res => res.data);
};

interface FetchPlans {
  globalFilter?: string;
  apiPage?: number;
  limit?: number;
  filters?: PlanFilters;
  planTypeIds?: number[];
  domainIds?: number[];
  planIds?: number[];
  workspaceIds?: number[];
  blueprintIds?: number[];
  status?: 'inDev' | 'isPublished';
  sort?: Sort[];
  exclude?: string[];
  includeBlueprint?: boolean;
  year?: number;
}

export const fetchPlans = ({
  globalFilter,
  apiPage,
  filters,
  sort,
  ...remainder
}: FetchPlans): Promise<APIResults<Plan>> => {
  const data: PlanFilters = {
    q: globalFilter,
    page: apiPage,
    ...filters,
    ...remainder
  };

  if (sort?.length) {
    data.sort = sort;
  }
  return Api.post(`${PLAN_API_BASE_URL}search`, data, getAuthHeader()).then(
    res => res.data
  );
};

export interface FetchPlanDataQuery {
  page?: number;
  limit?: number | null;
  planTypeIds?: number[];
  planIds?: number[];
  workspaceIds?: number[];
  formIds?: number[];
  formFieldIds?: number[];
  startDate?: string | null;
  endDate?: string | null;
  organizationIds?: number[];
  blueprintId: number;
  spreadsheet?: boolean;
  status?: 'inDev' | 'isPublished';
  sort?: Sort[];
}

interface FetchPlanDataResponse {
  dataSheets: { label: string; square: any[][] }[];
  fileUrl: string;
  report: Report;
}

export const fetchPlanDataQuery = ({
  spreadsheet,
  page,
  limit,
  sort,
  ...filters
}: FetchPlanDataQuery): Promise<FetchPlanDataResponse> => {
  const data: FetchPlanDataQuery = {
    spreadsheet,
    page,
    limit,
    ...filters
  };

  if (sort?.length) {
    data.sort = sort;
  }
  if (spreadsheet) {
    delete data.limit;
  }

  return Api.post(
    `${REPORT_API_BASE_URL}crossPlansExport/${filters.blueprintId}`,
    data,
    getAuthHeader()
  ).then(res => res.data);
};

interface SupportFilters extends APIQueryOptions {
  planIds?: number[];
  entityPrototypeIds?: number[];
}

export const fetchSupport = (
  options: SupportFilters
): Promise<APIResults<Support>> => {
  const data: SupportFilters = { ...options };
  if (options) {
    data.limit = 300;
    if (options.sort && options.sort.length) {
      data.sort = options.sort;
    }
  }
  return Api.post(
    `${PLAN_API_BASE_URL}entity/supportable`,
    data,
    getAuthHeader()
  ).then(res => res.data);
};

export const fetchPlanTypes = (
  domainIds?: number[]
): Promise<APIResults<PlanType>> => {
  let options = {};
  if (domainIds) {
    options = { domainIds };
  }
  return Api.post(`${BASE_URL}/planType/search`, options, getAuthHeader()).then(
    res => res.data
  );
};

export const fetchCpOptionsByPlanById = (planId: number): Promise<any> => {
  const data = getAuthHeader();
  return Api.get(`${CP_API_BASE_URL}relocationOptions/${planId}`, data).then(
    res => res.data
  );
};

export const fetchPlanEntityNarrative = (data: {
  planEntityIds: number[];
}): Promise<APIResults<PlanEntityNarrative>> => {
  return Api.post(
    `${BASE_URL}/planEntityNarrative/search`,
    data,
    getAuthHeader()
  ).then(res => res.data);
};

export const createPlanEntityNarrative = (data: {
  value: string;
  planEntityId: number;
  timeframeId: number;
}): Promise<unknown> => {
  return Api.post(
    `${BASE_URL}/planEntityNarrative`,
    data,
    getAuthHeader()
  ).then(res => res.data);
};

export const updatePlanEntityNarrative = (
  id: number,
  data: {
    value: string;
  }
): Promise<unknown> => {
  return Api.put(
    `${BASE_URL}/planEntityNarrative/${id}`,
    data,
    getAuthHeader()
  ).then(res => res.data);
};

export const planSurveyReport = (options: {
  workspaceIds: number[];
  version?: string;
  includeSurveys?: string[];
}): Promise<unknown> => {
  return Api.post(
    `${BASE_URL}/planSurvey/report`,
    options,
    getXlsxExportHeader()
  ).then(res => {
    const currentDate = new Date().toLocaleDateString();
    const filename = `plan_survey_report-${currentDate}_${options?.version}`;
    const blob = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    FileSaver.saveAs(blob, filename);
  });
};

import React from 'reactn';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';

interface Props {
  name: string;
  thumbnailUrl: string | undefined | null;
}

const ThumbnailCard: React.FC<Props> = ({ name, thumbnailUrl }) => {
  return (
    <Grid item xs={2} sx={{ margin: 1 }}>
      <Card sx={{ textAlign: 'center' }} variant="outlined">
        <CardHeader
          title="Thumbnail from PDF file cover page"
          disableTypography={false}
          titleTypographyProps={{ variant: 'body2' }}
        />
        <CardContent>
          <img
            src={thumbnailUrl ?? '#'}
            alt={name ?? 'thumbnail-generated'}
            width="170"
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ThumbnailCard;

import { Grid, Select, MenuItem, FormLabel } from '@mui/material';
import React from 'reactn';

import { Controller, useFormContext } from 'react-hook-form';
import RcoForm from 'pages/admin/contact/Rco';
import RcForm from 'pages/admin/contact/Rc';
import UnctMemberForm from 'pages/admin/contact/UnctMember';
import UncgMemberForm from 'pages/admin/contact/UncgMember';
import UnicForm from 'pages/admin/contact/Unic';

export const ContactOrganizationForm: React.FC<{
  contactTypes: string[];
  mainRcoContact?: WorkspaceOrganizationContact | null;
  customFormType?: 'UNIC' | 'UNDCO';
}> = ({ contactTypes, customFormType, mainRcoContact }) => {
  const { watch } = useFormContext();

  const contactType = watch('type');
  const isDco = customFormType === 'UNDCO';
  const isUnic = customFormType === 'UNIC';
  const isRCO = customFormType !== 'UNIC' && customFormType !== 'UNDCO';
  return (
    <Grid container spacing={2}>
      <Grid item xs={3} style={{ marginTop: '1rem' }}>
        <FormLabel style={{ display: 'block' }}>Group</FormLabel>
        <Controller
          name="type"
          render={({ onChange, ref, ...props }) => (
            <Select
              onChange={onChange}
              {...props}
              size="small"
              style={{ backgroundColor: 'white' }}
              fullWidth
            >
              {contactTypes.map(ct => (
                <MenuItem key={ct} value={ct}>
                  {ct}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>
      {(isRCO || isDco) && (contactType === 'RC' || contactType === 'RD') && (
        <RcForm />
      )}
      {(isRCO || isDco) && (contactType === 'RCO' || contactType === 'RO') && (
        <RcoForm
          mainRcoContact={mainRcoContact ?? null}
          {...(isDco ? { jobTitleDataSourceId: 142 } : {})}
        />
      )}
      {isRCO && contactType === 'Entity representative' && <UnctMemberForm />}
      {isRCO && contactType === 'UNCG member' && <UncgMemberForm />}

      {isUnic && contactType === 'RCO' && <UnicForm />}
    </Grid>
  );
};

export default ContactOrganizationForm;

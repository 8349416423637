import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Button } from 'uninfo-components';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { useLoadingAnimation } from 'utils/useLoadingAnimation';
import styled from 'styled-components';

import { upload } from 'services/FileService';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ThumbnailCard from 'components/common/ThumbnailCard';
import PermissionGate from 'components/common/PermissionGate';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;
interface DropZoneProps {
  multiple?: boolean;
  onChange: any;
  disabled?: boolean;
}
export const Dropzone: React.FC<DropZoneProps> = ({
  multiple,
  onChange,
  disabled,
  ...props
}) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    multiple,
    ...props
  });

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Container {...getRootProps()}>
      <>
        <Typography>Upload new document</Typography>
        <Button
          startIcon={<CloudUploadIcon />}
          variant="contained"
          color="primary"
          disabled={disabled}
        >
          Click to choose
        </Button>

        <input {...getInputProps({ onChange })} />
        <ul
          style={{
            fontWeight: 'bold',
            marginTop: '10px'
          }}
        >
          {files}
        </ul>
        <Typography>
          After clicking save, the previously uploaded file will be replaced.
        </Typography>
      </>
    </Container>
  );
};
interface DropZoneFieldProps {
  name: string;
  file: any;
  multiple: boolean;
  disabled?: boolean;
}

export const DropzoneField: React.FC<DropZoneFieldProps> = ({
  name,
  multiple,
  file,
  disabled,
  ...props
}) => {
  const { control, setValue } = useFormContext();
  const {
    FullScreenLoading,
    isLoading,
    startLoading,
    stopLoading
  } = useLoadingAnimation();

  const uploadFile = async (newFile: any, fileName: string): Promise<any> => {
    return upload(newFile, fileName);
  };

  const downloadFile = async (fileInfo: any) => {
    axios({
      url: fileInfo.url,
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      saveAs(response.data, fileInfo.name);
    });
  };

  return (
    <>
      {isLoading && <FullScreenLoading />}
      {file?.url && (
        <Grid item xs={12}>
          <Button
            style={{ color: 'white', fontSize: '18px' }}
            onClick={() => downloadFile(file)}
          >
            Current file: {file.name}
          </Button>
        </Grid>
      )}
      <PermissionGate
        scopes={[
          'fileDocument_update',
          'fileDocument_view',
          'fileDocument_create',
          'fileDocument_softDelete'
        ]}
      >
        {file?.thumbnail && (
          <ThumbnailCard name={file?.name} thumbnailUrl={file?.thumbnail} />
        )}
      </PermissionGate>
      <PermissionGate
        scopes={[
          'fileDocument_update',
          'fileDocument_create',
          'fileDocument_softDelete'
        ]}
      >
        <Controller
          render={({ onChange }) => (
            <Dropzone
              multiple={multiple}
              disabled={disabled}
              onChange={async (e: any) => {
                startLoading();
                onChange(multiple ? e.target.files : e.target.files[0]);
                const res = await uploadFile(e.target.files[0], 'test.pdf');
                if (res && res.name) {
                  setValue(name, res);
                }
                stopLoading();
              }}
              {...props}
            />
          )}
          name={name}
          control={control}
        />
      </PermissionGate>
    </>
  );
};

export default { DropzoneField };

const settings = {
  API_URL:
    window.ENV?.API_URL || 'https://dco-uninfo-api-staging.dev.devqube.io/',
  API_VERSION: window.ENV?.API_VERSION || 'v0.1',
  EXTERNAL_STORAGE:
    window.ENV?.EXTERNAL_STORAGE ||
    'https://minio.dev.devqube.io/uninfo-staging-main/',
  NODE_ENV: window.ENV?.NODE_ENV,
  MAINTENANCE: window.ENV?.MAINTENANCE || false,
  SENTRY_DSN: window.ENV?.SENTRY_DSN
};

export default settings;

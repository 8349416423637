import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import CircularProgress from 'components/dialogs/CircularProgress';

import {
  createPlanSurveys,
  SurveySignatoryResponse,
  updatePlanSurveys
} from 'services/PlanSurveyService';
import { useSnackbar } from 'notistack';

import { QuestionForm } from './QuestionForm';
import {
  createDefaultValues,
  Datum,
  processData,
  processExistingSurveyForDisplay,
  Question
} from './utils';

export const questionsForParent: Question[] = [
  {
    question: 'Is the Entity a signatory to the framework? *',
    slug: 'isEntitySignatoryToFramework',
    type: 'dataitem',
    required: true,
    filterId: 435
  }
];

export const questionsForChildren: Question[] = [
  {
    question:
      "C.1.3.8.1 - Was the RC involved in the derivation of the Entity's country level programming? *",
    description:
      'Definition of country level programming: Country level programming is the framework of the United Nations Development system entities, which identifies how they are derived from the Cooperation Framework. They identify the planned support in a given country towards the achievement of the agreed outcomes and outputs in the Cooperation Framework',
    slug: 'wasRcInvolvedInDerivation',
    type: 'boolean',
    required: true,
    dependsOn: 'isEntitySignatoryToFramework',
    dependsOnFnc: (field: { name: string }): boolean => {
      if (field === undefined) {
        // In this case, the questionsForParent array isn't loaded, so it's
        // not actually dependent on it (cause we're a child workspace)
        return true;
      }
      return !field?.name?.startsWith('No') && !!field?.name;
    }
  },
  {
    question:
      'C.1.3.8.1.1 - RC involvement in the derivation of the Entity’s country level programming (in line with MAF): *',
    slug: 'surveySignatoryRC',
    type: 'dataitem',
    filterId: 431,
    multiple: true,
    dependsOn: 'wasRcInvolvedInDerivation',
    dependsOnFnc: (field: string): boolean => field === 'true'
  },
  {
    question:
      'C.1.3.8.1.2 - Options for derivation of Entity’s country level programming *',
    slug: 'optionsForDerivationOfEntities',
    type: 'dataitem',
    required: true,
    filterId: 331,
    dependsOn: 'isEntitySignatoryToFramework',
    dependsOnFnc: (field: { name: string }): boolean => {
      if (field === undefined) {
        // In this case, the questionsForParent array isn't loaded, so it's
        // not actually dependent on it (cause we're a child workspace)
        return true;
      }
      return !field?.name?.startsWith('No') && !!field?.name;
    }
  },
  {
    question:
      'C.1.3.8.1.2.1 - If not derived from the Cooperation Framework, please explain',
    slug: 'notDerivedFromExplanation',
    type: 'textField',
    dependsOn: 'optionsForDerivationOfEntities',
    dependsOnFnc: (field: { name: string }): boolean => {
      return field?.name?.startsWith('D') ?? false;
    }
  }
];

const surveyName = 'signatory';

const CFSignatoryForm: React.FC<{
  orgWorkspace: WorkspaceOrganization;
  survey?: SurveySignatoryResponse;
  reload: () => void;
  questions: Question[];
  isParentSignatory?: any;
  plan: Plan;
}> = ({ orgWorkspace, reload, survey, questions, isParentSignatory, plan }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = React.useState(false);

  const params = useParams();
  const [signatory, setSignatory] = React.useState<
    SurveySignatoryResponse['surveySignatoryVersion']
  >();
  const methods = useForm({
    defaultValues: createDefaultValues(questions)
  });

  const { reset } = methods;

  const onSubmit = React.useCallback(
    async (data: { [key: string]: Datum }) => {
      if (params.id && orgWorkspace) {
        setIsSaving(true);
        // In the case of all of signatories, we send data
        // for both the parent and the child questions
        const processedData = processData(
          [...questionsForParent, ...questionsForChildren],
          {
            ...data,
            ...(isParentSignatory
              ? { isEntitySignatoryToFramework: isParentSignatory }
              : {})
          }
        );
        try {
          if (signatory && signatory.surveySignatoryId) {
            await updatePlanSurveys(signatory.surveySignatoryId, {
              surveyName,
              organizationId: orgWorkspace.organizationId,
              workspaceId: survey?.workspaceId ?? orgWorkspace.workspaceId,
              ...processedData
            });
          } else {
            await createPlanSurveys({
              planId: +params.id,
              organizationId: orgWorkspace.organizationId,
              workspaceId: survey?.workspaceId ?? orgWorkspace.workspaceId,
              surveyName,
              ...processedData
            });
          }
          enqueueSnackbar('Updated signatory', {
            variant: 'success',
            preventDuplicate: true
          });
          reload?.();
          setIsSaving(false);
        } catch (e) {
          enqueueSnackbar('Something went wrong', {
            variant: 'error',
            preventDuplicate: true
          });
        }
      }
    },
    [
      params.id,
      survey,
      isParentSignatory,
      orgWorkspace,
      signatory,
      enqueueSnackbar,
      reload
    ]
  );

  React.useEffect(() => {
    if (orgWorkspace && survey) {
      const processed = processExistingSurveyForDisplay(questions, {
        ...(survey?.surveySignatoryVersion ?? {}),
        ...(survey ?? {})
      });

      setSignatory(processed);
      reset(createDefaultValues(questions, processed));
    }
  }, [orgWorkspace, survey, reset, questions]);

  return (
    <FormProvider {...methods}>
      <Typography variant="h2">{survey?.workspace?.name}</Typography>
      <QuestionForm
        questions={questions}
        plan={plan}
        onSubmit={onSubmit}
        disabled={isSaving}
        surveyVersion={signatory}
      />
    </FormProvider>
  );
};

const CFSignatoryFormWrapper: React.FC<{
  orgWorkspace: WorkspaceOrganization;
  workspaceSurveys?: (SurveySignatoryResponse | undefined)[];
  reload: () => void;
  plan: Plan;
}> = ({ orgWorkspace, reload, workspaceSurveys, plan }) => {
  const [isReloading, setIsReloading] = React.useState(false);

  const reloadWrapper = React.useCallback(async () => {
    setIsReloading(true);
    await reload();
    setIsReloading(false);
  }, [reload]);

  if ((workspaceSurveys?.length ?? 0) > 1) {
    const parentSurvey = workspaceSurveys?.[0];
    const childSurveys = workspaceSurveys?.slice(1);

    const isParentSignatory =
      parentSurvey?.surveySignatoryVersion?.isEntitySignatoryToFramework;

    // If it's undefined it's also no
    const answerIsNo = isParentSignatory?.name?.includes('No') ?? true;

    return (
      <>
        {isReloading && <CircularProgress />}
        <CFSignatoryForm
          orgWorkspace={orgWorkspace}
          plan={plan}
          survey={parentSurvey}
          reload={reloadWrapper}
          questions={questionsForParent}
        />

        {!answerIsNo &&
          childSurveys
            ?.sort((a, b) =>
              (a?.workspace?.name ?? '') > (b?.workspace?.name ?? '') ? 1 : -1
            )
            .map(survey => (
              <div
                key={survey?.id}
                style={{
                  marginBottom: '2rem',
                  paddingBottom: '2rem',
                  borderBottom: '1px solid var(--color-grey)'
                }}
              >
                <CFSignatoryForm
                  questions={questionsForChildren}
                  plan={plan}
                  orgWorkspace={orgWorkspace}
                  survey={survey}
                  reload={reloadWrapper}
                  isParentSignatory={isParentSignatory}
                />
              </div>
            ))}
      </>
    );
  }
  return (
    <>
      {isReloading && <CircularProgress />}
      <CFSignatoryForm
        orgWorkspace={orgWorkspace}
        survey={workspaceSurveys?.[0]}
        plan={plan}
        reload={reloadWrapper}
        questions={[...questionsForParent, ...questionsForChildren]}
      />
    </>
  );
};

export default CFSignatoryFormWrapper;

import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Button, { ButtonProps } from '@mui/material/Button';
import { Theme } from '@mui/material';

export const TextButton = withStyles(() => ({
  root: {
    textTransform: 'none',
    // wordBreak: 'none'
    whiteSpace: 'nowrap'
  }
}))(Button);

const useLinkButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline'
    },
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
}));

export const LinkButton: React.FC<ButtonProps> = ({
  onClick,
  children,
  ...props
}) => {
  const classes = useLinkButtonStyles(props);

  return (
    <button
      type="button"
      className={classes.root}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      }}
      {...props}
    >
      {children}
    </button>
  );
};

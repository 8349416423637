import React, { memo } from 'reactn';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { isEmpty } from 'lodash';
import { BaseDialog } from 'uninfo-components';

import EnhancedTable from 'components/table/EnhancedTable';
import { unitColumns } from './columns';
import UnitForm from './UnitForm';

const UnitsAdmin = () => {
  const columns = React.useMemo(() => unitColumns, []);
  const [open, setOpen] = React.useState(false);
  const [editItem, setEditItem] = React.useState<Unit | undefined>(undefined);

  const handleClose = () => {
    setOpen(false);
    setEditItem(undefined);
  };

  const isEdit = !isEmpty(editItem);

  return (
    <Grid item xs={12}>
      <Paper>
        <EnhancedTable
          setOpen={setOpen}
          setEditItem={setEditItem}
          endpoint="unit"
          columns={columns}
          hiddenColumns={[]}
        />
        <BaseDialog
          open={open}
          onClose={handleClose}
          title={isEdit ? 'Edit Unit' : 'Add unit'}
        >
          <UnitForm
            unit={editItem}
            afterSubmitAction={handleClose}
            isEdit={isEdit}
          />
        </BaseDialog>
      </Paper>
    </Grid>
  );
};

export default memo(UnitsAdmin);

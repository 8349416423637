import { startCase } from 'lodash';
import { fetchPlanById } from 'services/PlanService';
import {
  createPlanSurveys,
  searchPlanSurveys,
  SurveyResponse,
  updatePlanSurveys
} from 'services/PlanSurveyService';
import { isExisting } from 'utils/typeguards';
import { SurveyVersion } from './QuestionForm';

export interface Question {
  question: string;
  description?: string;
  slug: string;
  type:
    | 'numberField'
    | 'textField'
    | 'boolean'
    | 'dataitem'
    | 'datetime'
    | 'organization'
    | 'value'
    | 'title';
  required?: boolean;
  dependsOn?: string;
  dependsOnFnc?: (field: any) => boolean;
  multiple?: boolean;
  filterId?: number;
  placeholder?: string;
  allowNone?: boolean;
  valueType?: 'boolean' | 'number' | 'money';
  Calculated?: ({
    question,
    value,
    surveyVersion
  }: {
    question?: Question;
    value?: unknown;
    surveyVersion?: SurveyVersion;
  }) => React.ReactElement | null;
}

export const defaultValue = (q: {
  type: string;
  multiple?: boolean;
}): string | number | null | unknown[] | false => {
  if (q.type === 'numberField') return '0';
  if (q.type === 'textField') return '';
  if (q.type === 'boolean') return '';
  if (q.type === 'dataitem' && q.multiple) return [];
  if (q.type === 'organization' && q.multiple) return [];

  return null;
};

export const createDefaultValues = (
  questions: Question[],
  existing?: { [key: string]: unknown }
): { [key: string]: unknown } => {
  return questions.reduce((aggr, q) => {
    const allowNone: { [key: string]: unknown } = {};
    if (q.allowNone) {
      allowNone[`${q.slug}None`] = existing?.[`${q.slug}None`];
    }
    return {
      ...aggr,
      ...allowNone,
      [q.slug]: existing?.[q.slug] ?? defaultValue(q)
    };
  }, {});
};

export type Datum = { id: number } | null | string | number | boolean;

const processIndividualDatum = (
  question: Question,
  key: string,
  datum: Datum
) => {
  let value: Datum | number[] = datum ?? null;

  if (question.multiple && !datum) {
    value = [];
  }

  if (question?.type === 'boolean') {
    if (datum === 'true') {
      value = true;
    } else if (datum === 'false') {
      value = false;
    } else {
      value = null;
    }
  }
  if (isExisting(datum) && datum?.id) value = datum.id;
  if (datum && question?.type === 'numberField') value = +datum;
  if (Array.isArray(datum)) {
    value = datum.map(d => d.id);
  }
  return value;
};

export const processData = (
  questions: Question[],
  data: {
    [key: string]: Datum;
  }
): { [key: string]: unknown } => {
  const reduced = questions.reduce((aggr, q) => {
    let key = q.slug;

    const value = processIndividualDatum(q, key, data[key]);

    if (Array.isArray(value)) {
      key += 'Ids';
    }
    const newObj = {
      ...aggr,
      [key]: value
    };

    if (q.allowNone) {
      newObj[`${q.slug}None`] = data[`${q.slug}None`] ?? null;
    }
    return newObj;
  }, {} as { [key: string]: unknown });

  return reduced;
};

export const processExistingSurveyForDisplay = <T extends SurveyResponse>(
  questions: Question[],
  surveyVersion: T
): T => {
  const boolQuestions = questions.filter(q => q.type === 'boolean');
  boolQuestions.forEach(q => {
    // @ts-ignore
    if (surveyVersion[q.slug] !== null) {
      // @ts-ignore
      surveyVersion[q.slug] = `${surveyVersion[q.slug]}`;
    }
  });

  const dateQuestions = questions.filter(q => q.type === 'datetime');

  dateQuestions.forEach(q => {
    // @ts-ignore
    surveyVersion[q.slug] = surveyVersion[q.slug]?.split('T')[0];
  });

  const singleItemQuestions = questions
    .filter(q => q.type === 'dataitem' && !q.multiple)
    .map(q => q.slug);

  if (singleItemQuestions.length > 0) {
    const singleKeys = (Object.keys(surveyVersion) as Array<
      keyof T
    >).filter(key => singleItemQuestions.includes(key as string));

    singleKeys.forEach(key => {
      if (surveyVersion[key]) {
        // @ts-ignore
        surveyVersion[key].id = surveyVersion[key].dataitemId;
      }
    });
  }

  const multipleItemQuestions = questions
    .filter(
      q => (q.type === 'dataitem' || q.type === 'organization') && q.multiple
    )
    .map(q => `${q.slug}s`);

  if (multipleItemQuestions.length > 0) {
    const multipleKeys = (Object.keys(surveyVersion) as Array<
      keyof T
    >).filter(key => multipleItemQuestions.includes(key as string));

    multipleKeys.forEach(key => {
      const sliced = (key as string).slice(0, -1);
      // @ts-ignore not sure how to circumvent this
      surveyVersion[sliced] = surveyVersion[key].map(obj => ({
        id:
          obj[`${sliced}Version`].dataitemId ??
          obj[`${sliced}Version`].organizationId,
        name: obj[`${sliced}Version`].name
      }));
    });
  }
  return surveyVersion;
};

export const createOrUpdate = async <T>({
  questions,
  planId,
  data,
  surveyName,
  survey
}: {
  questions: Question[];
  planId: number;
  data: { [key: string]: Datum };
  surveyName: 'preparation' | 'funding' | 'evaluation';
  survey: T;
}): Promise<string> => {
  const processedData = processData(questions, data);
  let msg = `Created the ${surveyName} survey`;
  // @ts-ignore
  const hasSurveyId = survey?.[`survey${startCase(surveyName)}Id`];
  if (!hasSurveyId) {
    await createPlanSurveys({
      planId,
      surveyName,
      ...processedData
    });
  } else {
    await updatePlanSurveys(hasSurveyId, {
      surveyName,
      ...processedData
    });
    msg = `Updated the ${surveyName} survey`;
  }
  return msg;
};

export const fetchPlanAndBuildSurveyForm = async <T>({
  id,
  questions,
  surveyName
}: {
  id?: string;
  questions: Question[];
  surveyName: 'evaluation' | 'funding' | 'preparation';
}): Promise<{ form: T | undefined; plan?: Plan }> => {
  let form: T | undefined;
  let fetchedPlan: Plan | undefined;
  if (id) {
    fetchedPlan = await fetchPlanById(+id);
    const planSurveyId = fetchedPlan?.planSurveys?.[0]?.id;

    if (planSurveyId) {
      const fetchedSurvey = await searchPlanSurveys<T>({
        surveyName,
        planSurveyId: +planSurveyId
      });

      const val = fetchedSurvey.results.map(r => ({ ...r }))?.[0];
      if (val) {
        form = processExistingSurveyForDisplay(questions, {
          // @ts-ignore
          ...val[`survey${startCase(surveyName)}Version`],
          ...val
        });
      }
    }
  }
  return { form, plan: fetchedPlan };
};

import React, { useGlobal } from 'reactn';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TableInstance } from 'react-table';

import CircularProgress from '@mui/material/CircularProgress';

import utils from './exportUtils';

interface ExportExcel {
  table: TableInstance<any>;
  endpoint: string | null;
  full?: boolean;
  current?: boolean;
}

const ExportExcel: React.FC<ExportExcel> = React.forwardRef(
  ({ table, endpoint, full = true, current = true }, ref) => {
    const [anchorEl, setAnchorEl] = React.useState<
      (EventTarget & HTMLButtonElement) | undefined
    >(undefined);
    const [loading, setLoading] = React.useState(false);
    const isMenuOpen = Boolean(anchorEl);
    const [sessionTablePreferences] = useGlobal('sessionTablePreferences');

    const exportToExcel = (
      csv: ReactTableRow<any>[],
      visibleColumns: any[]
    ) => {
      utils.exportCurrentToExcel(endpoint, csv, visibleColumns);
    };

    async function exportFullToExcel() {
      setLoading(true);
      await utils.exportFullToExcel({
        endpoint,
        table,
        options: sessionTablePreferences[`${endpoint}Export`]
      });
      setLoading(false);
    }

    const menuId = 'primary-search-account-menu';

    const handleClose = React.useCallback(() => {
      setAnchorEl(undefined);
    }, []);

    const handleExportMenuOpen = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setAnchorEl(event.currentTarget);
    };

    return (
      <>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={handleClose}
        >
          {endpoint && full && (
            <MenuItem onClick={() => exportFullToExcel()} disabled={loading}>
              {loading && (
                <CircularProgress size="1rem" sx={{ marginRight: '5px' }} />
              )}
              Export full data (with filters)
            </MenuItem>
          )}
          {current && (
            <MenuItem
              disabled={loading}
              onClick={() => exportToExcel(table.rows, table.visibleColumns)}
            >
              Export current view
            </MenuItem>
          )}
        </Menu>
        <Tooltip title="Export" aria-label="Export" ref={ref}>
          <IconButton
            aria-label="Export"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleExportMenuOpen}
            color="inherit"
            size="large"
          >
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  }
);

ExportExcel.displayName = 'ExportExcel';

export default ExportExcel;

export const dataSourceColumns = [
  {
    Header: 'Id',
    accessor: 'id',
    aggregate: 'count',
    disableFilters: true,
    sortType: 'basic'
  },
  {
    Header: 'Name',
    accessor: 'datasourceVersion.name',
    aggregate: 'count',
    disableFilters: true,
    sortType: 'basic'
  }
];

export default {
  dataSourceColumns
};

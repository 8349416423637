/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React from 'reactn';
import { useParams } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
import { Button } from 'uninfo-components';

import { Autocomplete } from 'components/common/AutoComplete';
import PaperWrapper from 'components/common/PaperWrapper';

import { fetchRoleById, updateRole } from 'services/RolesService';
import LoadingBlocks from 'pages/PlanBuilder/cfDetails/LoadingBlocks';
import fetchPermissions from 'services/PermissionService';

const RoleEditor: React.FC = () => {
  const { id } = useParams();
  const [role, setRole] = React.useState<Role>();
  const [permissions, setPermissions] = React.useState<Permission[]>([]);

  const fetchRole = React.useCallback(async (roleId: number) => {
    const fetchedRole = await fetchRoleById(roleId);
    const rolePermissions = await fetchPermissions({
      permissionIds: fetchedRole.rolePermissions.map(rp => rp.permissionId)
    });
    setPermissions(rolePermissions);
    setRole(fetchedRole);
  }, []);

  React.useEffect(() => {
    if (id) {
      fetchRole(+id);
    }
  }, [fetchRole, id]);

  if (!role) {
    <LoadingBlocks />;
  }

  const fetchPermissionsCallback = React.useCallback(async value => {
    try {
      const fetchedPermissions = await fetchPermissions({
        q: value,
        limit: 20
      });
      return fetchedPermissions;
    } catch (e) {
      console.error(e);
    }
    return [];
  }, []);

  const onSave = React.useCallback(async () => {
    if (id) {
      await updateRole(+id, { rolePermissions: permissions.map(p => p.id) });
    }
  }, [permissions, id]);

  return (
    (role && (
      <PaperWrapper component="form">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">{role.name}</Typography>
            <Typography variant="subtitle1">{role.domain?.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              value={permissions}
              label="Permissions"
              fetchOptionsCallback={fetchPermissionsCallback}
              onChange={(_e: unknown, value: Permission[]) => {
                setPermissions(value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '.75rem' }}>
          <Grid item xs={12}>
            <Button onClick={onSave}>Save</Button>
          </Grid>
        </Grid>
      </PaperWrapper>
    )) ??
    null
  );
};

export default RoleEditor;

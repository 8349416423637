import React, { memo } from 'reactn';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { isEmpty } from 'lodash';
import { BaseDialog } from 'uninfo-components';

import EnhancedTable from 'components/table/EnhancedTable';
import { metricColumns } from './columns';
import MetricForm from './MetricForm';

const MetricsAdmin = () => {
  const columns = React.useMemo(() => metricColumns, []);
  const [open, setOpen] = React.useState(false);
  const [editItem, setEditItem] = React.useState<MetricBase | undefined>(
    undefined
  );

  const handleClose = () => {
    setOpen(false);
    setEditItem(undefined);
  };

  const isEdit = !isEmpty(editItem);

  return (
    <Grid item xs={12}>
      <Paper>
        <EnhancedTable
          setOpen={setOpen}
          setEditItem={setEditItem}
          endpoint="metric"
          columns={columns}
          hiddenColumns={[]}
        />
        <BaseDialog
          open={open}
          onClose={handleClose}
          title={isEdit ? 'Edit Metric' : 'Add Metric'}
        >
          <MetricForm
            metric={editItem}
            afterSubmitAction={handleClose}
            isEdit={isEdit}
          />
        </BaseDialog>
      </Paper>
    </Grid>
  );
};

export default memo(MetricsAdmin);

import React, { useGlobal, useEffect } from 'reactn';
import withStyles from '@mui/styles/withStyles';
import { useSnackbar } from 'notistack';
import { useForm, FormProvider } from 'react-hook-form';

import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { AccountCircle, ArrowDropDown, Help } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Chip, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'react-i18next';
import { logOut } from 'services/AuthService';
import { fetchWorkspaceById } from 'services/WorkspaceService';
import { ReactComponent as UNSDGLogo } from 'images/undg_logo.svg';
import logo from 'images/logo_elkano.png';
import settings from 'config';
import { Button } from 'uninfo-components';

import { WorkspaceAutocomplete } from 'components/common/form/WorkspaceAutocomplete';
import { useClearCache } from 'react-clear-cache';
import NotificationPopUp from './NotificationPopUp';

// const logo = require('images/logo_elkano.png');

const LANGUAGES_ENABLED = false;

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    boxShadow: 'none',
    minHeight: 56,
    paddingBottom: 0,
    backgroundColor: 'white'
  },
  inline: {
    display: 'inline'
  },
  flex: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    '& img': {
      marginLeft: 50,
      marginRight: 50
    }
  }
}));

const WorkspaceForm = withStyles(theme => ({
  root: {
    margin: theme.spacing(1, 0, 2),
    minWidth: 380
  }
}))(FormControl);

const WorkspaceSelect = () => {
  const [user] = useGlobal('user');
  const [globalWorkspace, setGlobalWorkspace] = useGlobal('currentWorkspace');
  const methods = useForm();
  const { getValues, reset } = methods;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchedWorkspace = async (workspaceId: number) => {
      if (workspaceId) {
        const res = await fetchWorkspaceById(workspaceId);
        if (res.id) {
          setGlobalWorkspace(res);
        }
      }
    };
    if (user && user.preferences?.preferedWorkspace) {
      const workspaceId = user.userRoles.find(
        (uR: any) =>
          uR.contextWorkspaceId === user.preferences?.preferedWorkspace?.id
      )?.contextWorkspaceId;
      if (workspaceId) {
        // fetchedWorkspace(workspaceId);
      }
    }
  }, [setGlobalWorkspace, user]);

  const onWorkspaceChange = () => {
    setGlobalWorkspace(getValues('workspace'));
    navigate('/dashboard');
  };

  useEffect(() => {
    if (globalWorkspace) {
      reset({ workspace: globalWorkspace });
    }
  }, [globalWorkspace, reset]);

  return (
    <FormProvider {...methods}>
      <WorkspaceForm variant="filled">
        <WorkspaceAutocomplete
          variant="filled"
          onWorkspaceChange={onWorkspaceChange}
        />
      </WorkspaceForm>
    </FormProvider>
  );
};

const LanguageButton = styled(Button)`
  padding-top: 20;
  padding-bottom: 20;
  min-width: 'auto';
`;

const LanguageMenu = () => {
  const [anchorLanguage, setAnchorLanguage] = React.useState<
    (EventTarget & HTMLElement) | null
  >(null);
  const { i18n } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLanguage(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorLanguage(null);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <>
      <LanguageButton
        color="primary"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {i18n.language} <ArrowDropDown />
      </LanguageButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorLanguage}
        keepMounted
        open={Boolean(anchorLanguage)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeLanguage('en')}>EN</MenuItem>
        <MenuItem onClick={() => changeLanguage('fr')}>FR</MenuItem>
        <MenuItem onClick={() => changeLanguage('es')}>ES</MenuItem>
      </Menu>
    </>
  );
};

const UserMenu: React.FC = () => {
  const [user, setUser] = useGlobal('user');
  const [, setCurrentWorkspace] = useGlobal('currentWorkspace');
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLElement) | null
  >(null);
  const { enqueueSnackbar } = useSnackbar();
  const menuId = 'primary-search-account-menu';
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  async function handleLogout() {
    setUser(undefined);
    setCurrentWorkspace(undefined);
    await logOut();
    handleMenuClose();
    enqueueSnackbar('Logout success', {
      preventDuplicate: true
    });

    setTimeout(() => {
      window.location.assign('/');
    }, 1000);
  }

  return (
    <>
      {user && user.id ? (
        <>
          <IconButton
            color="primary"
            aria-label="helpdesk"
            component="a"
            href="https://help.uninfo.org"
            target="_blank"
            title="Helpdesk"
            rel="noopener noreferrer"
            size="large"
          >
            <Help fontSize="large" color="secondary" />
          </IconButton>
          <NotificationPopUp />

          <IconButton
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            size="large"
          >
            <AccountCircle fontSize="large" color="secondary" />
          </IconButton>
        </>
      ) : null}
      {user && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          id={menuId}
          keepMounted
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem>
            <Link to="/user/profile">Profile</Link>
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      )}
    </>
  );
};

const Topbar: React.FC = () => {
  const [user] = useGlobal('user');
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const classes = useStyles();

  const isProd = settings.NODE_ENV === 'production';

  return (
    <AppBar
      data-cy="app-bar"
      position="absolute"
      className={classes.appBar}
      style={
        isEmpty(user)
          ? { backgroundColor: 'transparent', boxShadow: 'none' }
          : {
              borderBottom: '1px solid rgba(0, 0, 0, .12)'
              // ...(!isProd
              //   ? {
              //       backgroundColor: 'rgba(213, 213, 213)'
              //     }
              //   : {})
            }
      }
      color="default"
    >
      {!isEmpty(user) && (
        <Toolbar>
          <Grid
            container
            className={classes.flex}
            justifyContent="space-between"
          >
            <Grid item>
              <Grid container>
                <Grid item className={classes.logo}>
                  <Typography variant="h6" color="inherit" noWrap>
                    <Link to="/" className={classes.link}>
                      <UNSDGLogo width={170} />
                      <img width={100} src={logo} alt="" />
                    </Link>
                  </Typography>
                </Grid>
                {user && user.id && (
                  <Grid item>
                    <WorkspaceSelect />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item className={classes.flex}>
              {!isLatestVersion && (
                <Button
                  onClick={e => {
                    e.preventDefault();
                    emptyCacheStorage();
                  }}
                >
                  Update version
                </Button>
              )}
              {!isProd && (
                <Chip
                  label="Staging"
                  variant="outlined"
                  style={{
                    marginRight: '1rem',
                    fontWeight: 'bold',
                    color: 'white',
                    background: `repeating-linear-gradient(
                    45deg,
                    rgb(200, 10, 10),
                    rgb(200, 10, 10) 10px,
                    rgb(160, 10, 10) 10px,
                    rgb(160, 10, 10) 20px
                  )`
                  }}
                />
              )}
              {LANGUAGES_ENABLED && <LanguageMenu />}
              <UserMenu />
            </Grid>
          </Grid>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Topbar;

import React, { memo, useState } from 'reactn';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { BaseDialog } from 'uninfo-components';

import EnhancedTable from 'components/table/EnhancedTable';
import { add, edit } from 'services/AdminService';
import useAddMultipleToggle from 'utils/useAddMultipleToggle';

import { isExisting } from 'utils/typeguards';
import { linkColumns } from './link/LinkColumns';
import { LinkForm } from './link/LinkForm';

const AdminLink = () => {
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState({});
  const {
    resetSwitch,
    switchState,
    ToggleInFormDescription
  } = useAddMultipleToggle();

  const columns = React.useMemo(() => linkColumns, []);

  async function handleSubmit(item: Link | LinkNew) {
    try {
      let res;
      if (isExisting(editItem)) {
        res = await edit<Link>('link', editItem.id, item);
      } else {
        res = await add<Link>('link', item);
      }
      if (switchState.addMultiple) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    } catch (e) {
      console.error('error: ', e);
    }
  }

  const handleClose = () => {
    setOpen(false);
    resetSwitch();
  };

  return (
    <Grid item xs={12}>
      <Paper>
        <EnhancedTable
          setOpen={setOpen}
          setEditItem={setEditItem}
          endpoint="link"
          columns={columns}
          hiddenColumns={[]}
        />
        <div>
          <BaseDialog
            title="Add Link"
            open={open}
            onClose={handleClose}
            description={<ToggleInFormDescription />}
          >
            <LinkForm handleSubmitForm={handleSubmit} editItem={editItem} />
          </BaseDialog>
        </div>
      </Paper>
    </Grid>
  );
};

export default memo(AdminLink);

import React, { memo } from 'reactn';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import EnhancedTable from 'components/table/EnhancedTable';
import RoleColumns from './RoleColumns';

const AdminDocument = () => {
  const columns = React.useMemo(() => RoleColumns, []);

  return (
    <Grid item xs={12} style={{ overflowX: 'scroll' }}>
      <Paper elevation={0}>
        <EnhancedTable
          linkTo="/dashboard/admin/roles"
          endpoint="role"
          pageSize={50}
          columns={columns}
          hiddenColumns={[]}
        />
      </Paper>
    </Grid>
  );
};

export default memo(AdminDocument);

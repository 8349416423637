import React, { useGlobal } from 'reactn';
import { Autocomplete } from 'components/common/AutoComplete';
import { autocomplete } from 'services/AutocompleteService';
import { fetchOrganizations } from 'services/OrganizationService';
import { get } from 'lodash';
import { AGENCY_TYPE_ID } from 'utils/constants';
import { filterUserRoles, hasUserRole } from 'components/common/PermissionGate';

interface AsynchronousProps {
  column: {
    filterValue: string;
    setFilter: (value: string | null, bool: boolean) => void;
    endpoint: string;
    filterLabel: string;
    filterAccessor?: string;
    filterByUserWorkspaces?: boolean;
    // it wont catch wrong type on column definition
    permission?: Scopes;
  };
}

// FIXME: this seems like a bit of a hacky way to type this. Feel like there should
// be a way to say generic `T` can be any one of these types. I guess this way it
// has to confirm to at least the `AsynchronousResults` format.
interface AsynchronousResults {
  name?: string;
  abbreviation?: string;
  id: number;
}

function Asynchronous<T extends AsynchronousResults>({
  column: {
    filterValue,
    setFilter,
    endpoint,
    filterLabel,
    filterAccessor,
    filterByUserWorkspaces,
    permission
  }
}: AsynchronousProps): React.ReactElement {
  const [user] = useGlobal('user');
  const fetchOptionsCallback = React.useCallback(
    async value => {
      let items: (T | Organization | Workspace)[] = []; // FIXME: this feels like it's a hack
      if (endpoint === 'organization') {
        let options: { [key: string]: any } = {
          q: value || ''
        };
        if (filterLabel === 'Agency') {
          options = {
            ...options,
            typeIds: [AGENCY_TYPE_ID]
          };
        }
        items = await fetchOrganizations(options);
      } else {
        items = await autocomplete<T>(endpoint, {
          q: value || '',
          limit: 500,
          simple: false
        });
      }
      if (filterByUserWorkspaces && endpoint === 'workspace') {
        const hasGlobalContext = hasUserRole({
          user,
          permission
        });
        if (!hasGlobalContext) {
          const regionIds = filterUserRoles({
            user,
            permission,
            context: 'contextRegionId'
          }).map(r => r.contextRegionId);
          const workspaceIds = filterUserRoles({
            user,
            permission,
            context: 'contextWorkspaceId'
          }).map(r => r.contextWorkspaceId);

          items = (items as Workspace[]).filter(i => {
            if (workspaceIds && workspaceIds.includes(i.id)) {
              return true;
            }
            if (regionIds && regionIds.includes(i.regionId)) {
              return true;
            }
            return false;
          });
        }
      }

      const formattedOptions = items.map(item => {
        if (filterAccessor) {
          return {
            name: get(item, filterAccessor),
            id: item.id
          };
        }
        return {
          name: `${item.name} ${
            'abbreviation' in item && item.abbreviation
              ? `(${item.abbreviation})`
              : ''
          }`,
          id: item.id
        };
      });

      return formattedOptions;
    },
    [endpoint, filterAccessor, user] // This is highlighted by eslint because it doesn't know how to handle the generic T
  );

  return (
    <Autocomplete
      style={{ width: 200 }}
      multiple
      label={filterLabel}
      value={filterValue || []}
      onChange={(_e: any, value: string | null) => {
        if (Array.isArray(value) && value.length === 0) {
          setFilter(null, true);
        } else {
          setFilter(value, true);
        }
      }}
      fetchOptionsCallback={fetchOptionsCallback}
    />
  );
}

export default Asynchronous;

import React from 'reactn';
import Grid from '@mui/material/Grid';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextField } from 'components/common/form/TextField';

import { addMetric, updateMetric } from 'services/MetricService';
import { Button } from 'uninfo-components';
import { Select } from 'components/common/form/Select';
import { DialogActions, InputLabel } from '@mui/material';

interface FormProps {
  metric?: MetricBase;
  afterSubmitAction: (arg0?: MetricBase) => void;
  isEdit?: boolean;
}

const metricTypeOptions = [
  {
    label: 'Measure',
    value: 'measure'
  },
  {
    label: 'Expenditure',
    value: 'expenditure'
  },
  {
    label: 'Goal',
    value: 'goal'
  },
  {
    label: 'Budget',
    value: 'budget'
  },
  {
    label: 'Calculated',
    value: 'calculated'
  }
];

const MetricForm: React.FC<FormProps> = ({
  metric,
  afterSubmitAction,
  isEdit = false
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { t } = useTranslation('common');
  const methods = useForm({
    defaultValues: metric ?? {
      name: '',
      type: ''
    }
  });
  const { handleSubmit } = methods;
  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    try {
      let newMetric;
      if (!isEdit) {
        newMetric = await addMetric(data);
      } else if (metric) {
        newMetric = await updateMetric(metric.id, data);
      }
      afterSubmitAction(newMetric?.data);
    } catch (e) {
      console.error(`Error: ${(e as any).data.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={e => {
          e.stopPropagation();
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputLabel>Name</InputLabel>
            <TextField name="name" placeholder="Volunteers" required />
          </Grid>
          <Grid item xs={6}>
            <Select
              withTitle
              name="type"
              label="Type"
              options={metricTypeOptions}
            />
          </Grid>
          <DialogActions style={{ marginTop: 10 }}>
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              color="primary"
            >
              {isEdit ? 'Save' : 'Add'} Metric
            </Button>
          </DialogActions>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default MetricForm;

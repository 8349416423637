import React from 'react';
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
  Theme,
  darken
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { Check } from '@mui/icons-material';
import { Palette } from '@mui/material/styles/createPalette';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

// export const TextButtonNew = withStyles(() => ({
//   root: {
//     textTransform: 'none',
//     // wordBreak: 'none'
//     whiteSpace: 'nowrap'
//   }
// }))(MuiButton);

type StyleProps = {
  customPaletteColor?: Partial<Palette['primary']>;
  variant?: 'outlined' | 'contained' | 'text';
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // borderRadius: '2rem',
    padding: theme.spacing(1, 3),
    textTransform: 'none',
    whiteSpace: 'nowrap',
    maxHeight: '2.5rem',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2)
    }
  },
  label: {
    background: 'red'
  },
  contained: {
    backgroundColor: (props: StyleProps) => props.customPaletteColor?.main,
    '&:hover': {
      backgroundColor: (props: StyleProps) =>
        props.customPaletteColor?.main
          ? darken(props.customPaletteColor.main, 0.2)
          : undefined
    }
  },
  outlined: {
    // borderColor: (props: StyleProps) => props.customPaletteColor?.main,
    // color: (props: StyleProps) => props.customPaletteColor?.main
  },
  fabProgress: {
    marginRight: theme.spacing(1),
    zIndex: 1,
    color: props => {
      if (props.variant === 'contained') {
        return 'white';
      }
      return props.customPaletteColor?.main;
    }
  },
  input: {
    display: 'none'
  }
}));

export interface UnInfoButtonProps extends ButtonProps {
  isLoading?: boolean;
  showSuccess?: boolean;
  customPaletteColor?: Partial<Palette['primary']>;
}

const Button: React.FC<UnInfoButtonProps> = ({
  children,
  onClick,
  variant,
  isLoading,
  startIcon,
  className,
  showSuccess,
  customPaletteColor,
  disabled,
  ...props
}) => {
  const classes = useStyles({ ...props, customPaletteColor, variant });
  const [loadingWasToggled, setLoadingWasToggled] = React.useState(false);
  const classNames = clsx(classes.root, className);

  const [shouldShowSuccess, setShouldShowSuccess] = React.useState(false);

  React.useEffect(() => {
    if (showSuccess && loadingWasToggled && !isLoading) {
      setShouldShowSuccess(true);
      setLoadingWasToggled(false);
    }
    const timer = setTimeout(() => {
      setShouldShowSuccess(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [showSuccess, loadingWasToggled, isLoading]);

  React.useEffect(() => {
    if (isLoading) {
      setLoadingWasToggled(true);
    }
  }, [isLoading]);

  return (
    <MuiButton
      classes={{
        contained: classes.contained,
        outlined: classes.outlined
      }}
      className={classNames}
      style={{ borderRadius: '2rem' }}
      onClick={onClick ? e => onClick(e) : undefined}
      variant={variant ?? 'text'}
      startIcon={shouldShowSuccess ? <Check /> : startIcon}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading && (
        <CircularProgress size={24} className={classes.fabProgress} />
      )}
      {children}
    </MuiButton>
  );
};

export default Button;

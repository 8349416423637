import React from 'react';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useForm, FormProvider } from 'react-hook-form';

import { Button } from 'uninfo-components';
import { uploadLocationCSV } from 'services/LocationService';

import Table from 'components/table/TableWrapper';
import { Dropzone } from 'components/common/form/FileUpload';

export const BulkLocationForm: React.FC<{ country: Country }> = ({
  country
}) => {
  const methods = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = React.useState<File>();
  const [isSaving, setIsSaving] = React.useState(false);
  const [problemRows, setProblemRows] = React.useState<unknown[]>();

  const uploadFile = async () => {
    if (file) {
      setIsSaving(true);
      const resp = await uploadLocationCSV(file, country.id);

      if (resp.untraitedRows.length !== 0) {
        enqueueSnackbar(`Encountered some locations we couldn't handle`);
        setProblemRows(resp.untraitedRows);
      } else {
        enqueueSnackbar(`Uploaded ${resp.traitedLocations.length} locations`);
      }
      setIsSaving(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Dropzone
            onChange={(e: any, b: any) => {
              setFile(e.target.files[0]);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={uploadFile}
            isLoading={isSaving}
          >
            Upload &amp; Save
          </Button>
        </Grid>
        {(problemRows?.length ?? 0) > 0 && (
          <Grid item xs={12}>
            These rows had issues
            <Table>
              <tbody>
                {problemRows?.map(a => (
                  <tr key={JSON.stringify(a)}>
                    <td>{JSON.stringify(a)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
};

export default BulkLocationForm;

import Asynchronous from 'components/table/AsynchronousFilter';

export const roleColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    sortType: 'basic',
    disableFilters: true,
    style: { maxWidth: '300px' }
  },
  {
    Header: 'Short',
    id: 'short',
    accessor: 'short',
    sortType: 'basic',
    disableFilters: true
  },
  {
    Header: 'Domain',
    id: 'domainId',
    accessor: 'domain.name',
    endpoint: 'domain',
    style: { wordBreak: 'break-word', maxWidth: '200px' },
    filterLabel: 'domain',
    Filter: Asynchronous
  }
];

export default roleColumns;

import React from 'reactn';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import GetAppIcon from '@mui/icons-material/GetApp';
import { format } from 'date-fns';

import PlanStepsCompleted from 'components/common/PlanStepsCompleted';
import { Button, BaseDialog } from 'uninfo-components';
import { fetchPlans } from 'services/PlanService';

import { createXLSXFromData } from 'utils/export';
import { DATE_FORMAT } from 'utils/constants';
import Link from '@mui/material/Link';

// FIXME: What are the types for react-table? It's not super stable
// yet.
const BlueprintProgress = ({ cell: { row } }: { cell: { row: any } }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [plans, setPlans] = React.useState<Plan[]>([]);

  const fetchPlansForBlueprintCallback = React.useCallback(
    async blueprintId => {
      try {
        const fetchedPlans = await fetchPlans({
          filters: { blueprintIds: [blueprintId], queryByBlueprint: true },
          limit: 300 // FIXME: pagination
        });

        setPlans(fetchedPlans.results);
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  const onClick = () => {
    setIsOpen(true);
    fetchPlansForBlueprintCallback(row.original.id);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const exportToExcel = React.useCallback(() => {
    const mappedPlans = plans.map(plan => {
      const steps = plan.entityPrototypes
        .filter(eP => eP.properties.step.socle)
        .sort((a, b) => {
          return a.properties?.step?.id - b.properties?.step?.id;
        })
        .reduce((obj, eP) => {
          return {
            ...obj,
            [eP.properties?.step?.name ?? eP.name]: eP.complete
              ? 'Complete'
              : ''
          };
        }, {});

      return {
        workspace: plan.workspace?.name,
        plan: plan.planVersion.name,
        code: plan.planVersion.code,
        startDate: plan.planVersion.startDate,
        ...steps,
        submitted: plan.lastSubmittedById ? 'Yes' : 'No',
        published: plan.latestTaggedVersion ? 'Yes' : 'No'
      };
    });
    createXLSXFromData(
      mappedPlans,
      `${row.original.name}-progress-${format(new Date(), DATE_FORMAT)}`
    );
  }, [plans, row.original.name]);

  return (
    <>
      <BaseDialog
        title="Blueprint Progress"
        open={isOpen}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
      >
        <Button
          onClick={exportToExcel}
          variant="outlined"
          startIcon={<GetAppIcon />}
        >
          Download Excel
        </Button>
        {plans && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Workspace</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Steps</TableCell>
                <TableCell>Submitted</TableCell>
                <TableCell>Published</TableCell>
                <TableCell>Admin</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.map(plan => (
                <TableRow key={plan.id}>
                  <TableCell>{plan.workspace?.name}</TableCell>
                  <TableCell>
                    <Link href={`/dashboard/plan/${plan.id}/build`}>
                      {plan.planVersion.name}
                    </Link>
                  </TableCell>
                  <TableCell>{plan.planVersion.code}</TableCell>
                  <TableCell>{plan.planVersion.startDate}</TableCell>
                  <TableCell>
                    <PlanStepsCompleted
                      entityPrototypes={plan.entityPrototypes}
                    />
                  </TableCell>
                  <TableCell>{plan.lastSubmittedById ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    {plan.latestTaggedVersion ? 'Yes' : 'No'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </BaseDialog>
      <Button variant="outlined" onClick={onClick}>
        Progress Report
      </Button>
    </>
  );
};

export default BlueprintProgress;

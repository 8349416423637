import React from 'reactn';
import Check from '@mui/icons-material/Check';

const BooleanCheckCell = ({
  cell: { value }
}: ReactTableCell<string, any>): React.ReactElement => (
  <>{value ? <Check /> : null}</>
);

export default BooleanCheckCell;

import React from 'reactn';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CallMerge from '@mui/icons-material/CallMerge';
import SwapHoriz from '@mui/icons-material/SwapHoriz';

import { TableInstance } from 'react-table';
import Chip from '@mui/material/Chip';

import { Button, BaseDialog } from 'uninfo-components';
import { mergeByIds } from 'services/AdminService';
import { useTableContext } from './TableProvider';

const MergeButton: React.FC<{
  tableInstance: TableInstance;
  endpoint: string;
}> = ({ tableInstance, endpoint }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isMerging, setIsMerging] = React.useState(false);
  const { state, dispatch } = useTableContext();

  const selectedRowIndexes = React.useMemo(
    () => Object.keys(tableInstance.state.selectedRowIds),
    [tableInstance.state.selectedRowIds]
  );
  const tableData = React.useMemo(() => state.tableData, [state.tableData]);

  const [objects, setObjects] = React.useState<{ id: number; name: number }[]>(
    []
  );

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    setObjects(
      selectedRowIndexes
        .map(x => tableData?.[parseInt(x, 10)])
        .filter(id => !!id)
    );
  }, [selectedRowIndexes, tableData]);

  const mergeRowHandler = async () => {
    setIsMerging(true);
    try {
      if (objects.length === 2) {
        const ids = objects.map(loc => loc.id);
        await mergeByIds(endpoint, ids[0], ids[1]);
        enqueueSnackbar(`${endpoint}s merged`, {
          preventDuplicate: true
        });
        dispatch({ type: 'toggleRefresh' });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsMerging(false);
    }
  };

  const swapLocations = () => {
    const object1 = objects[0];
    const object2 = objects[1];
    setObjects([object2, object1]);
  };

  return objects.length === 2 ? (
    <>
      <Tooltip title="Merge">
        <IconButton
          aria-label="unarchive"
          onClick={() => setDialogOpen(true)}
          size="large"
        >
          <CallMerge />
        </IconButton>
      </Tooltip>
      <BaseDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={`Merging ${endpoint}s`}
        actions={
          <>
            <Button
              variant="outlined"
              onClick={swapLocations}
              startIcon={<SwapHoriz />}
            >
              Swap
            </Button>
            <Button
              variant="outlined"
              onClick={mergeRowHandler}
              isLoading={isMerging}
            >
              Merge
            </Button>
          </>
        }
      >
        You&apos;re proposing to merge {endpoint}{' '}
        <Chip label={objects[0].name} /> into <Chip label={objects[1].name} />.
      </BaseDialog>
    </>
  ) : null;
};

export default MergeButton;

import Api from 'utils/Api';
import settings from 'config';
import { getAuthHeader } from './AuthService';

const BASE_URL = `${settings.API_URL}${settings.API_VERSION}`;
const BLUEPRINT_API_BASE_URL = `${BASE_URL}/blueprint/`;

interface BlueprintOptions extends APIQueryOptions {
  status?: any;
  planTypeId?: number | number[];
}

export const fetchBlueprints = (
  options: BlueprintOptions
): Promise<Blueprint[]> => {
  const data = { limit: 100, ...options };
  if (options) {
    if (options.sort && options.sort.length) {
      data.sort = options.sort;
    }
  }

  return Api.post(
    `${BLUEPRINT_API_BASE_URL}search`,
    data,
    getAuthHeader()
  ).then(res => res.data.results);
};

export const fetchBlueprintById = (
  blueprintId: number | string
): Promise<Blueprint> => {
  return Api.get(
    `${BLUEPRINT_API_BASE_URL}${blueprintId}`,
    getAuthHeader()
  ).then(res => res.data);
};

export const deleteBlueprint = (blueprintId: number) => {
  return Api.delete(`${BLUEPRINT_API_BASE_URL}${blueprintId}`, getAuthHeader());
};

export const addBlueprint = (blueprint: Blueprint) => {
  const formattedBlueprint = blueprint;
  return Api.post(BLUEPRINT_API_BASE_URL, formattedBlueprint, getAuthHeader());
};

export const editBlueprint = (id: number | string, blueprint: Blueprint) => {
  return Api.put(`${BLUEPRINT_API_BASE_URL}${id}`, blueprint, getAuthHeader());
};

export const unsetPlansCompleteFlag = (blueprintId: number) => {
  return Api.put(
    `${BLUEPRINT_API_BASE_URL}unsetPlansCompleteFlag/${blueprintId}`,
    {},
    getAuthHeader()
  );
};
interface CoreEntityOptions extends APIQueryOptions {
  filters?: {
    id: string;
    value: {
      id: number;
    }[];
  }[];
}

export const fetchCoreEntities = async (options?: CoreEntityOptions) => {
  const data: any = {};
  if (options) {
    data.limit = 100;
    if (options.filters) {
      options.filters.forEach(filter => {
        data[`${filter.id}s`] = filter.value.map(v => v.id).join(',');
      });
    }
    if (options.sort && options.sort.length) {
      data.sort = options.sort;
    }
  }

  return Api.post(
    `${BASE_URL}/coreentityType/search`,
    data,
    getAuthHeader()
  ).then(res => res.data.results);
};
